/**
 * The below file contains the cwc state objects
 * @author: vishal.mishra@velsof.com
 * @date : 28-09-2023
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_CWC,
  EDIT_CWC,
  SET_CWC,
  SET_CWC_LISTING_PARAM,
  SET_CWC_LISTING,
  UPDATE_CWC_STATUS,
  SET_EXPORT_CWC_DATA,
  GET_EXPORT_CWC_DATA,
} from "../constants/Cwc";

//Initial state
const initState = {
  //Keys for cwc listing
  cwcList: {},
  isCWCDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  cwcListingParam: {},
  cwcExportedData: {},
  //Keys for cwc listing

  //Keys for cwc add page
  addCWCForm: {},
  //Keys for cwc add page

  //Keys for cwc edit page
  cwcFormData: {},
  //Keys for cwc edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const cwc = (state = initState, action) => {
  switch (action.type) {
    //Cases for CWC listing
    case SET_CWC_LISTING:
      return {
        ...state,
        cwcList: action.payload,
        isCWCDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_CWC_LISTING_PARAM:
      return {
        ...state,
        cwcListingParam: action.payload,
      };

    case UPDATE_CWC_STATUS:
      let updateCWCListData = {
        ...state.cwcList,
        data: state.cwcList.data?.map((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        }),
      };
      return {
        ...state,
        cwcList: updateCWCListData,
      };

    case GET_EXPORT_CWC_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_CWC_DATA:
      return {
        ...state,
        cwcExportedData: action.payload,
      };
    //Cases for CWC listing

    //Cases for CWC add page
    case ADD_CWC:
      return {
        ...state,
        addCWCForm: action.payload,
      };
    //Cases for CWC add page

    //Cases for CWC edit page
    case EDIT_CWC:
      return {
        ...state,
        addCWCForm: action.payload,
      };

    case SET_CWC:
      return {
        ...state,
        cwcFormData: action.payload,
      };

    //Cases for CWC edit page

    default:
      return state;
  }
};

export default cwc;
