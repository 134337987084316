/**
 * The below file contains the constants used in the relief camp master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-11-2022
 */

/**Constants for relief camp Listing page */
export const GET_ALL_RELIEF_CAMP = "GET_ALL_RELIEF_CAMP";
export const SET_RELIEF_CAMP_LISTING = "SET_RELIEF_CAMP_LISTING";
export const SET_RELIEF_CAMP_LISTING_PARAM = "SET_RELIEF_CAMP_LISTING_PARAM";
export const UPDATE_RELIEF_CAMP_STATUS = "UPDATE_RELIEF_CAMP_STATUS";
export const CHANGE_RELIEF_CAMP_STATUS = "CHANGE_RELIEF_CAMP_STATUS";
export const GET_EXPORT_RELIEF_CAMP_DATA = "GET_EXPORT_RELIEF_CAMP_DATA";
export const SET_EXPORT_RELIEF_CAMP_DATA = "SET_EXPORT_RELIEF_CAMP_DATA";
/**Constants for relief camp Listing page */

/**Constants for relief camp Add page */
export const ADD_RELIEF_CAMP = "ADD_RELIEF_CAMP";
/**Constants for relief camp Add page */

/**Constants for relief camp Edit page */
export const EDIT_RELIEF_CAMP = "EDIT_RELIEF_CAMP";
export const GET_RELIEF_CAMP = "GET_RELIEF_CAMP";
export const SET_RELIEF_CAMP = "SET_RELIEF_CAMP";
/**Constants for relief camp Edit page */

/**Constants for relief camp Import page */
export const RELIEF_CAMP_IMPORT = "RELIEF_CAMP_IMPORT";
/**Constants for relief camp Import page */
