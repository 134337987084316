/**
 * The below file contains the action methods for river master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 01-12-2022
 */

import {
  ADD_RIVER,
  CHANGE_RIVER_STATUS,
  RIVER_IMPORT,
  EDIT_RIVER,
  GET_ALL_RIVER,
  GET_RIVER,
  GET_EXPORT_RIVER_DATA,
  SET_RIVER,
  SET_RIVER_LISTING,
  SET_RIVER_LISTING_PARAM,
  SET_EXPORT_RIVER_DATA,
  UPDATE_RIVER_STATUS,
} from "../constants/River";

//#region Action methods for river listing page

/**
 * Method for getting the river listing
 * @param {any} params River Params
 * @returns Json Object
 */
export const getAllRiver = (params) => {
  return {
    type: GET_ALL_RIVER,
    payload: params,
  };
};

/**
 * Method for setting the river in the state
 * @param {any} river River Data
 * @returns Json Object
 */
export const setRiverListing = (river) => {
  return {
    type: SET_RIVER_LISTING,
    payload: river,
  };
};

/**
 * Method for setting the river parameters
 * @param {any} data River Params
 * @returns Json Object
 */
export const setRiverTableParam = (data) => {
  return {
    type: SET_RIVER_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the river status
 * @param {any} data River Data
 * @returns Json Object
 */
export const updateRiverStatus = (data) => {
  return {
    type: UPDATE_RIVER_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the river status
 * @param {any} data River Data
 * @returns Json Object
 */
export const changeRiverStatus = (data) => {
  return {
    type: CHANGE_RIVER_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of river
 * @returns Json Object
 */
export const getExportRiver = () => {
  return {
    type: GET_EXPORT_RIVER_DATA,
  };
};

/**
 * Method for setting the export details in the state
 * @param {any} data Export Data
 * @returns Json Object
 */
export const setExportRiver = (data) => {
  return {
    type: SET_EXPORT_RIVER_DATA,
    payload: data,
  };
};
//#endregion Action methods for river listing page

//#region Action methods for river add page
/**
 * Method for adding the river
 * @param {any} data River Data
 * @returns Json Object
 */
export const addRiver = (data) => {
  return {
    type: ADD_RIVER,
    payload: data,
  };
};
//#endregion Action methods for river add page

//#region Action methods for river edit page

/**
 * Method for editing the river
 * @param {any} data River Data
 * @returns Json Object
 */
export const editRiver = (data) => {
  return {
    type: EDIT_RIVER,
    payload: data,
  };
};

/**
 * Method for getting the river details
 * @param {any} id River Id
 * @returns Json Object
 */
export const getRiver = (id) => {
  return {
    type: GET_RIVER,
    payload: id,
  };
};

/**
 * Method for setting the river details in the state
 * @param {any} river River Data
 * @returns Json Object
 */
export const setRiver = (river) => {
  return {
    type: SET_RIVER,
    payload: river,
  };
};
//#endregion Action methods for river edit page

//#region Action methods for river edit page
/**
 * Method for river import
 * @param {any} data River Data
 * @returns Json Object
 */
export const riverImport = (data) => {
  return {
    type: RIVER_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for river import page
