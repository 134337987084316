/**
 * The below file contains the action methods for infDepartment master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-11-2022
 */

import {
  ADD_INFDEPARTMENT,
  CHANGE_INFDEPARTMENT_STATUS,
  INFDEPARTMENT_IMPORT,
  EDIT_INFDEPARTMENT,
  GET_ALL_INFDEPARTMENT,
  GET_INFDEPARTMENT,
  GET_EXPORT_INFDEPARTMENT_DATA,
  SET_INFDEPARTMENT,
  SET_INFDEPARTMENT_LISTING,
  SET_INFDEPARTMENT_LISTING_PARAM,
  SET_EXPORT_INFDEPARTMENT_DATA,
  UPDATE_INFDEPARTMENT_STATUS,
} from "../constants/InfDepartment";

//#region Action methods for infDepartment listing page

/**
 * Method for getting the infDepartment listing
 * @param {any} params Parameter Data
 * @returns Json Object
 */
export const getAllInfDepartment = (params) => {
  return {
    type: GET_ALL_INFDEPARTMENT,
    payload: params,
  };
};

/**
 * Method for setting the infDepartment in the state
 * @param {any} infDepartment Infrastructure Department Data
 * @returns Json Object
 */
export const setInfDepartmentListing = (infDepartment) => {
  return {
    type: SET_INFDEPARTMENT_LISTING,
    payload: infDepartment,
  };
};

/**
 * Method for setting the infDepartment parameters
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const setInfDepartmentTableParam = (data) => {
  return {
    type: SET_INFDEPARTMENT_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the infDepartment status
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const updateInfDepartmentStatus = (data) => {
  return {
    type: UPDATE_INFDEPARTMENT_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the infDepartment status
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const changeInfDepartmentStatus = (data) => {
  return {
    type: CHANGE_INFDEPARTMENT_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of infDepartment
 * @returns Json Object
 */
export const getExportInfDepartment = () => {
  return {
    type: GET_EXPORT_INFDEPARTMENT_DATA,
  };
};

/**
 * Method for setting the export details in the state
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const setExportInfDepartment = (data) => {
  return {
    type: SET_EXPORT_INFDEPARTMENT_DATA,
    payload: data,
  };
};
//#endregion Action methods for infDepartment listing page

//#region Action methods for infDepartment add page
/**
 * Method for adding the infDepartment
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const addInfDepartment = (data) => {
  return {
    type: ADD_INFDEPARTMENT,
    payload: data,
  };
};
//#endregion Action methods for infDepartment add page

//#region Action methods for infDepartment edit page

/**
 * Method for editing the infDepartment
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const editInfDepartment = (data) => {
  return {
    type: EDIT_INFDEPARTMENT,
    payload: data,
  };
};

/**
 * Method for getting the infDepartment details
 * @param {any} id Infrastructure Department Id
 * @returns Json Object
 */
export const getInfDepartment = (id) => {
  return {
    type: GET_INFDEPARTMENT,
    payload: id,
  };
};

/**
 * Method for setting the infDepartment details in the state
 * @param {any} infDepartment Infrastructure Department Data
 * @returns Json Object
 */
export const setInfDepartment = (infDepartment) => {
  return {
    type: SET_INFDEPARTMENT,
    payload: infDepartment,
  };
};
//#endregion Action methods for infDepartment edit page

//#region Action methods for infDepartment import page
/**
 * Method for infDepartment import
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const infDepartmentImport = (data) => {
  return {
    type: INFDEPARTMENT_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for infDepartment import page
