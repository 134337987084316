/**
 * The below file contains the action methods for station.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import {
  GET_ALL_STATION,
  SET_ALL_STATION,
  SET_STATION_LISTING_PARAM,
} from "redux/constants/Station";

//#region Action methods for fetching station

/**
 * Method for getting the station
 * @param {any} params station Data
 * @returns Json Object
 */
export const getAllStation = (params) => {
  return {
    type: GET_ALL_STATION,
    payload: params,
  };
};

/**
 * Method for setting the station
 * @param {any} params station Data
 * @returns Json Object
 */
export const setAllStation = (data) => {
  return {
    type: SET_ALL_STATION,
    payload: data,
  };
};

/**
 * Method for setting the station parameters
 * @param {any} data Station Params
 * @returns Json Object
 */
export const setStationTableParam = (data) => {
  return {
    type: SET_STATION_LISTING_PARAM,
    payload: data,
  };
};

//#endregion
