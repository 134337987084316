/**
 * The below file contains the action methods for reliefOtherItem master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import {
  ADD_RELIEFOTHERITEM,
  CHANGE_RELIEFOTHERITEM_STATUS,
  RELIEFOTHERITEM_IMPORT,
  EDIT_RELIEFOTHERITEM,
  GET_ALL_RELIEFOTHERITEM,
  GET_RELIEFOTHERITEM,
  GET_EXPORT_RELIEFOTHERITEM_DATA,
  SET_RELIEFOTHERITEM,
  SET_RELIEFOTHERITEM_LISTING,
  SET_RELIEFOTHERITEM_LISTING_PARAM,
  SET_EXPORT_RELIEFOTHERITEM_DATA,
  UPDATE_RELIEFOTHERITEM_STATUS,
} from "../constants/ReliefOtherItem";

//#region Action methods for reliefOtherItem listing page

/**
 * Method for getting the reliefOtherItem listing
 * @param {any} params ReliefOtherItem Params
 * @returns Json Object
 */
export const getAllReliefOtherItem = (params) => {
  return {
    type: GET_ALL_RELIEFOTHERITEM,
    payload: params,
  };
};

/**
 * Method for setting the reliefOtherItem in the state
 * @param {any} reliefOtherItem ReliefOtherItem Data
 * @returns Json Object
 */
export const setReliefOtherItemListing = (reliefOtherItem) => {
  return {
    type: SET_RELIEFOTHERITEM_LISTING,
    payload: reliefOtherItem,
  };
};

/**
 * Method for setting the reliefOtherItem parameters
 * @param {any} data ReliefOtherItem Params
 * @returns Json Object
 */
export const setReliefOtherItemTableParam = (data) => {
  return {
    type: SET_RELIEFOTHERITEM_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the reliefOtherItem status
 * @param {any} data ReliefOtherItem Data
 * @returns Json Object
 */
export const updateReliefOtherItemStatus = (data) => {
  return {
    type: UPDATE_RELIEFOTHERITEM_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the reliefOtherItem status
 * @param {any} data ReliefOtherItem Data
 * @returns Json Object
 */
export const changeReliefOtherItemStatus = (data) => {
  return {
    type: CHANGE_RELIEFOTHERITEM_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of reliefOtherItem
 * @returns Json Object
 */
export const getExportReliefOtherItem = () => {
  return {
    type: GET_EXPORT_RELIEFOTHERITEM_DATA,
  };
};

/**
 * Method for setting the export details in the state
 * @param {any} data Export Data
 * @returns Json Object
 */
export const setExportReliefOtherItem = (data) => {
  return {
    type: SET_EXPORT_RELIEFOTHERITEM_DATA,
    payload: data,
  };
};
//#endregion Action methods for reliefOtherItem listing page

//#region Action methods for reliefOtherItem add page
/**
 * Method for adding the reliefOtherItem
 * @param {any} data ReliefOtherItem Data
 * @returns Json Object
 */
export const addReliefOtherItem = (data) => {
  return {
    type: ADD_RELIEFOTHERITEM,
    payload: data,
  };
};
//#endregion Action methods for reliefOtherItem add page

//#region Action methods for reliefOtherItem edit page

/**
 * Method for editing the reliefOtherItem
 * @param {any} data ReliefOtherItem Data
 * @returns Json Object
 */
export const editReliefOtherItem = (data) => {
  return {
    type: EDIT_RELIEFOTHERITEM,
    payload: data,
  };
};

/**
 * Method for getting the reliefOtherItem details
 * @param {any} id ReliefOtherItem Id
 * @returns Json Object
 */
export const getReliefOtherItem = (id) => {
  return {
    type: GET_RELIEFOTHERITEM,
    payload: id,
  };
};

/**
 * Method for setting the reliefOtherItem details in the state
 * @param {any} reliefOtherItem ReliefOtherItem Data
 * @returns Json Object
 */
export const setReliefOtherItem = (reliefOtherItem) => {
  return {
    type: SET_RELIEFOTHERITEM,
    payload: reliefOtherItem,
  };
};
//#endregion Action methods for reliefOtherItem edit page

//#region Action methods for reliefOtherItem edit page
/**
 * Method for reliefOtherItem import
 * @param {any} data ReliefOtherItem Data
 * @returns Json Object
 */
export const reliefOtherItemImport = (data) => {
  return {
    type: RELIEFOTHERITEM_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for reliefOtherItem import page
