/**
 * The below file contains the constants used in the CWC master.
 * @author: vishal.mishra@velsof.com
 * @date : 28-09-2023
 */

/**Constants for CWC Listing page */
export const GET_ALL_CWC = "GET_ALL_CWC";
export const SET_CWC_LISTING = "SET_CWC_LISTING";
export const SET_CWC_LISTING_PARAM = "SET_CWC_LISTING_PARAM";
export const UPDATE_CWC_STATUS = "UPDATE_CWC_STATUS";
export const CHANGE_CWC_STATUS = "CHANGE_CWC_STATUS";
export const GET_EXPORT_CWC_DATA = "GET_EXPORT_CWC_DATA";
export const SET_EXPORT_CWC_DATA = "SET_EXPORT_CWC_DATA";
/**Constants for CWC Listing page */

/**Constants for CWC Add page */
export const ADD_CWC = "ADD_CWC";
/**Constants for CWC Add page */

/**Constants for CWC Edit page */
export const EDIT_CWC = "EDIT_CWC";
export const GET_CWC = "GET_CWC";
export const SET_CWC = "SET_CWC";
/**Constants for CWC Edit page */

/**Constants for CWC Import page */
export const CWC_IMPORT = "CWC_IMPORT";
/**Constants for CWC Import page */
