/**
 * The below file contains the earthquake reports state objects
 * @author: shivam.sharma2@velsof.com
 * @date : 21-12-2022
 */

import {
  BasicSection,
  CampInmatesSection,
  DiscardButton,
  ErodedSection,
  HllSection,
  HouseDamagedSection,
  InfDamageOtherSection,
  InfDamageSection,
  LiveStockSection,
  PopAffectedSection,
  ReliefCampSection,
  ReliefDistributionSection,
  RemarkSection,
  RescueOperationSection,
  SubmitButton,
} from "constants/ApplicationConstant";
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_EARTHQUAKE_DATA,
  EARTHQUAKE_ADD_BASIC_SECTION,
  EARTHQUAKE_ADD_INFDAMAGEOTHER_SECTION,
  EARTHQUAKE_ADD_INFDAMAGE_SECTION,
  EARTHQUAKE_ADD_REMARK_SECTION,
  EARTHQUAKE_EDIT_BASIC_SECTION,
  EARTHQUAKE_REPORT_DISCARD,
  EARTHQUAKE_REPORT_SUBMIT,
  EARTHQUAKE_RESET_BUTTON_LOADING,
  EARTHQUAKE_SET_ALL_SECTION,
  EARTHQUAKE_SET_DATA_SAVED,
  EARTHQUAKE_SET_NIL_REPORTING,
  EARTHQUAKE_SET_PARENT_ID,
  EARTHQUAKE_SET_REDIRECT_TO_LISTING,
  SET_EXPORT_EARTHQUAKE_DATA,
  SET_EARTHQUAKE_LISTING,
  SET_EARTHQUAKE_LISTING_PARAM,
  EARTHQUAKE_ADD_POPAFFECTED_SECTION,
  EARTHQUAKE_ADD_ERODED_SECTION,
  EARTHQUAKE_ADD_CAMPINMATES_SECTION,
  EARTHQUAKE_ADD_RELIEFCAMP_SECTION,
  EARTHQUAKE_ADD_HLL_SECTION,
  EARTHQUAKE_ADD_LIVESTOCK_SECTION,
  EARTHQUAKE_ADD_HOUSEDAMAGED_SECTION,
  EARTHQUAKE_ADD_RELIEFDISTRIBUTION_SECTION,
  EARTHQUAKE_ADD_RESCUEOPERATION_SECTION,
  SET_EARTHQUAKE_DISTRICT_REVIEW_LIST,
  SET_EARTHQUAKE_STATE_REVIEW_LIST,
  EARTHQUAKE_REVENUE_REPORT_DISAPPROVE,
  EARTHQUAKE_REVENUE_REPORT_VERIFY,
  EARTHQUAKE_DISTRICT_REPORT_APPROVE,
  EARTHQUAKE_DISTRICT_REPORT_DISAPPROVE,
  EARTHQUAKE_DISTRICT_REPORT_VERIFY,
  EARTHQUAKE_STATE_REPORT_APPROVE,
  EARTHQUAKE_STATE_REPORT_DISAPPROVE,
  SET_EXPORT_FO_LEVEL_EARTHQUAKE_REPORT,
  SET_STATE_CUMULATIVE_EARTHQUAKE_REPORT,
  SET_DISTRICT_CUMULATIVE_EARTHQUAKE_REPORT,
} from "redux/constants/Earthquake";

//Initial state
const initState = {
  //Keys for listing
  earthquakeList: {},
  isEarthquakeDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  earthquakeListingParam: {},
  earthquakeExportedData: {},
  exportFoLevelEarthquakeReport: {
    report: "",
    format: "",
  },
  exportStateCumulativeEarthquakeReport: {
    report: "",
    format: "",
  },
  exportDistrictCumulativeEarthquakeReport: {
    report: "",
    format: "",
  },
  //Keys for listing

  //Keys for All Section Data
  earthquakeParentId: "",
  earthquakeAllSectionData: {},
  earthquakeButtonLoading: {
    basicSection: false,
    popAffectedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
    submitButton: false,
    discardButton: false,
    approveButton: false,
    disapproveButton: false,
    verifyButton: false,
  },
  earthquakeDataSaved: {
    basicSection: false,
    popAffectedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
  },
  earthquakeIsNilReporting: false,
  earthquakeRedirectToListing: false,
  //Keys for All Section Data

  //Keys for Approval Disapproval
  earthquakeDistrictReviewList: {},
  earthquakeStateReviewList: {},
  //Keys for Approval Disapproval
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const earthquake = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_EARTHQUAKE_LISTING:
      return {
        ...state,
        earthquakeList: action.payload,
        isEarthquakeDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_EARTHQUAKE_LISTING_PARAM:
      return {
        ...state,
        earthquakeListingParam: action.payload,
      };

    case GET_EXPORT_EARTHQUAKE_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_EARTHQUAKE_DATA:
      return {
        ...state,
        earthquakeExportedData: action.payload,
      };
    //Cases for listing

    //Cases for All Section
    case EARTHQUAKE_SET_ALL_SECTION:
      return {
        ...state,
        earthquakeAllSectionData: action.payload,
      };

    case EARTHQUAKE_RESET_BUTTON_LOADING:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            basicSection: false,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            popAffectedSection: false,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            reliefCampSection: false,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            campInmatesSection: false,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            hLLSection: false,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            liveStockSection: false,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            houseDamagedSection: false,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            rescueOperationSection: false,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            reliefDistributionSection: false,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            infDamageOtherSection: false,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            remarkSection: false,
          },
        };
      } else if (action.payload.section === SubmitButton) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            submitButton: false,
          },
        };
      } else if (action.payload.section === DiscardButton) {
        return {
          ...state,
          earthquakeButtonLoading: {
            ...state.earthquakeButtonLoading,
            discardButton: false,
          },
        };
      } else {
        return {
          ...state,
          earthquakeButtonLoading: {
            basicSection: false,
            popAffectedSection: false,
            reliefCampSection: false,
            campInmatesSection: false,
            hLLSection: false,
            liveStockSection: false,
            houseDamagedSection: false,
            rescueOperationSection: false,
            reliefDistributionSection: false,
            infDamageOtherSection: false,
            remarkSection: false,
            submitButton: false,
            discardButton: false,
            approveButton: false,
            disapproveButton: false,
            verifyButton: false,
          },
        };
      }

    case EARTHQUAKE_SET_PARENT_ID:
      return {
        ...state,
        earthquakeParentId: action.payload,
      };

    case EARTHQUAKE_SET_REDIRECT_TO_LISTING:
      return {
        ...state,
        earthquakeRedirectToListing: action.payload,
      };

    case EARTHQUAKE_SET_DATA_SAVED:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          earthquakeDataSaved: {
            ...state.earthquakeDataSaved,
            basicSection: action.payload.value,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          earthquakeDataSaved: {
            ...state.earthquakeDataSaved,
            popAffectedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          earthquakeDataSaved: {
            ...state.earthquakeDataSaved,
            reliefCampSection: action.payload.value,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          earthquakeDataSaved: {
            ...state.earthquakeDataSaved,
            campInmatesSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          earthquakeDataSaved: {
            ...state.earthquakeDataSaved,
            hLLSection: action.payload.value,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          earthquakeDataSaved: {
            ...state.earthquakeDataSaved,
            liveStockSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          earthquakeDataSaved: {
            ...state.earthquakeDataSaved,
            houseDamagedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          earthquakeDataSaved: {
            ...state.earthquakeDataSaved,
            rescueOperationSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          earthquakeDataSaved: {
            ...state.earthquakeDataSaved,
            reliefDistributionSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          earthquakeDataSaved: {
            ...state.earthquakeDataSaved,
            infDamageOtherSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          earthquakeDataSaved: {
            ...state.earthquakeDataSaved,
            remarkSection: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case EARTHQUAKE_REPORT_SUBMIT:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          submitButton: true,
        },
      };

    case EARTHQUAKE_REPORT_DISCARD:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          approveButton: true,
        },
      };

    case EARTHQUAKE_REVENUE_REPORT_DISAPPROVE:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          disapproveButton: true,
        },
      };

    case EARTHQUAKE_REVENUE_REPORT_VERIFY:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          verifyButton: true,
        },
      };
    //#endregion

    case EARTHQUAKE_REPORT_DISCARD:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          discardButton: true,
        },
      };

    case EARTHQUAKE_DISTRICT_REPORT_APPROVE:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          approveButton: true,
        },
      };

    case EARTHQUAKE_DISTRICT_REPORT_DISAPPROVE:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          disapproveButton: true,
        },
      };

    case EARTHQUAKE_DISTRICT_REPORT_VERIFY:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          verifyButton: true,
        },
      };

    case EARTHQUAKE_STATE_REPORT_APPROVE:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          approveButton: true,
        },
      };

    case EARTHQUAKE_STATE_REPORT_DISAPPROVE:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          disapproveButton: true,
        },
      };
    //Cases for All Section

    //Cases for Basic Section
    case EARTHQUAKE_ADD_BASIC_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          basicSection: true,
        },
      };

    case EARTHQUAKE_EDIT_BASIC_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          basicSection: true,
        },
      };

    case EARTHQUAKE_SET_NIL_REPORTING:
      return {
        ...state,
        earthquakeIsNilReporting: action.payload,
      };
    //Cases for Basic Section

    //Cases for InfDamageOther Section
    case EARTHQUAKE_ADD_INFDAMAGEOTHER_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          infDamageOtherSection: true,
        },
      };

    //Cases for Remark Section
    case EARTHQUAKE_ADD_REMARK_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          remarkSection: true,
        },
      };

    //Cases for Earthquake Pop Affected Section
    case EARTHQUAKE_ADD_POPAFFECTED_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          popAffectedSection: true,
        },
      };
    //Cases for Earthquake Pop Affected Section

    //Cases for Earthquake Relief Camp Section
    case EARTHQUAKE_ADD_RELIEFCAMP_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          reliefCampSection: true,
        },
      };
    //Cases for Earthquake Relief Camp Section

    //Cases for Earthquake Relief Camp Section
    case EARTHQUAKE_ADD_CAMPINMATES_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          campInmatesSection: true,
        },
      };
    //Cases for Earthquake Relief Camp Section

    //Cases for Earthquake Hll Section
    case EARTHQUAKE_ADD_HLL_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          hLLSection: true,
        },
      };
    //Cases for Earthquake Hll Section

    //Cases for Earthquake LiveStock Section
    case EARTHQUAKE_ADD_LIVESTOCK_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          liveStockSection: true,
        },
      };
    //Cases for Earthquake LiveStock Section

    //Cases for Earthquake House Damaged Section
    case EARTHQUAKE_ADD_HOUSEDAMAGED_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          houseDamagedSection: true,
        },
      };
    //Cases for Earthquake House Damaged Section

    //Cases for Earthquake Rescue Operation Section
    case EARTHQUAKE_ADD_RESCUEOPERATION_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          rescueOperationSection: true,
        },
      };
    //Cases for Earthquake Rescue Operation Section

    //Cases for Earthquake Relief Distribution Section
    case EARTHQUAKE_ADD_RELIEFDISTRIBUTION_SECTION:
      return {
        ...state,
        earthquakeButtonLoading: {
          ...state.earthquakeButtonLoading,
          reliefDistributionSection: true,
        },
      };
    //Cases for Earthquake Relief Distribution Section

    // Cases for Earthquake form PDF download section
    case SET_EXPORT_FO_LEVEL_EARTHQUAKE_REPORT:
      return {
        ...state,
        exportFoLevelEarthquakeReport: {
          report: action.payload.url,
          format: action.payload.format,
        },
      };
    // Cases for Earthquake form export file section

    //Cases for Approval and Disapproval
    case SET_EARTHQUAKE_DISTRICT_REVIEW_LIST:
      return {
        ...state,
        earthquakeDistrictReviewList: action.payload,
      };

    case SET_EARTHQUAKE_STATE_REVIEW_LIST:
      return {
        ...state,
        earthquakeStateReviewList: action.payload,
      };
    //Cases for Approval and Disapproval

    // Cases for Earthquake state cumulative report
    case SET_STATE_CUMULATIVE_EARTHQUAKE_REPORT:
      return {
        ...state,
        exportStateCumulativeEarthquakeReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Earthquake state cumulative report

    // Cases for Earthquake district cumulative report
    case SET_DISTRICT_CUMULATIVE_EARTHQUAKE_REPORT:
      return {
        ...state,
        exportDistrictCumulativeEarthquakeReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Earthquake district cumulative report

    default:
      return state;
  }
};

export default earthquake;
