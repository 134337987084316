/**
 * The below file contains the action methods for special call type master.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import {
  GET_ALL_SPECIAL_CALL_TYPE,
  SET_ALL_SPECIAL_CALL_TYPE,
} from "redux/constants/SpecialCallType";

//#region Action methods for fetching special call type categories

/**
 * Method for getting the special call type categories
 * @param {any} params Special call type Data
 * @returns Json Object
 */
export const getAllSpecialCallType = () => {
  return {
    type: GET_ALL_SPECIAL_CALL_TYPE,
  };
};

/**
 * Method for setting the special call type categories
 * @param {any} params Special call type Data
 * @returns Json Object
 */
export const setAllSpecialCallType = (data) => {
  return {
    type: SET_ALL_SPECIAL_CALL_TYPE,
    payload: data,
  };
};

//#endregion