/**
 * The below file contains the action methods for rescueEquipment master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import {
  ADD_RESCUEEQUIPMENT,
  CHANGE_RESCUEEQUIPMENT_STATUS,
  RESCUEEQUIPMENT_IMPORT,
  EDIT_RESCUEEQUIPMENT,
  GET_ALL_RESCUEEQUIPMENT,
  GET_RESCUEEQUIPMENT,
  GET_EXPORT_RESCUEEQUIPMENT_DATA,
  SET_RESCUEEQUIPMENT,
  SET_RESCUEEQUIPMENT_LISTING,
  SET_RESCUEEQUIPMENT_LISTING_PARAM,
  SET_EXPORT_RESCUEEQUIPMENT_DATA,
  UPDATE_RESCUEEQUIPMENT_STATUS,
} from "../constants/RescueEquipment";

//#region Action methods for rescueEquipment listing page

/**
 * Method for getting the rescueEquipment listing
 * @param {any} params RescueEquipment Params
 * @returns Json Object
 */
export const getAllRescueEquipment = (params) => {
  return {
    type: GET_ALL_RESCUEEQUIPMENT,
    payload: params,
  };
};

/**
 * Method for setting the rescueEquipment in the state
 * @param {any} rescueEquipment RescueEquipment Data
 * @returns Json Object
 */
export const setRescueEquipmentListing = (rescueEquipment) => {
  return {
    type: SET_RESCUEEQUIPMENT_LISTING,
    payload: rescueEquipment,
  };
};

/**
 * Method for setting the rescueEquipment parameters
 * @param {any} data RescueEquipment Params
 * @returns Json Object
 */
export const setRescueEquipmentTableParam = (data) => {
  return {
    type: SET_RESCUEEQUIPMENT_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the rescueEquipment status
 * @param {any} data RescueEquipment Data
 * @returns Json Object
 */
export const updateRescueEquipmentStatus = (data) => {
  return {
    type: UPDATE_RESCUEEQUIPMENT_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the rescueEquipment status
 * @param {any} data RescueEquipment Data
 * @returns Json Object
 */
export const changeRescueEquipmentStatus = (data) => {
  return {
    type: CHANGE_RESCUEEQUIPMENT_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of rescueEquipment
 * @returns Json Object
 */
export const getExportRescueEquipment = () => {
  return {
    type: GET_EXPORT_RESCUEEQUIPMENT_DATA,
  };
};

/**
 * Method for setting the export details in the state
 * @param {any} data Export Data
 * @returns Json Object
 */
export const setExportRescueEquipment = (data) => {
  return {
    type: SET_EXPORT_RESCUEEQUIPMENT_DATA,
    payload: data,
  };
};
//#endregion Action methods for rescueEquipment listing page

//#region Action methods for rescueEquipment add page
/**
 * Method for adding the rescueEquipment
 * @param {any} data RescueEquipment Data
 * @returns Json Object
 */
export const addRescueEquipment = (data) => {
  return {
    type: ADD_RESCUEEQUIPMENT,
    payload: data,
  };
};
//#endregion Action methods for rescueEquipment add page

//#region Action methods for rescueEquipment edit page

/**
 * Method for editing the rescueEquipment
 * @param {any} data RescueEquipment Data
 * @returns Json Object
 */
export const editRescueEquipment = (data) => {
  return {
    type: EDIT_RESCUEEQUIPMENT,
    payload: data,
  };
};

/**
 * Method for getting the rescueEquipment details
 * @param {any} id RescueEquipment Id
 * @returns Json Object
 */
export const getRescueEquipment = (id) => {
  return {
    type: GET_RESCUEEQUIPMENT,
    payload: id,
  };
};

/**
 * Method for setting the rescueEquipment details in the state
 * @param {any} rescueEquipment RescueEquipment Data
 * @returns Json Object
 */
export const setRescueEquipment = (rescueEquipment) => {
  return {
    type: SET_RESCUEEQUIPMENT,
    payload: rescueEquipment,
  };
};
//#endregion Action methods for rescueEquipment edit page

//#region Action methods for rescueEquipment edit page
/**
 * Method for rescueEquipment import
 * @param {any} data RescueEquipment Data
 * @returns Json Object
 */
export const rescueEquipmentImport = (data) => {
  return {
    type: RESCUEEQUIPMENT_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for rescueEquipment import page
