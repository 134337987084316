/**
 * The below file contains the Sagas method for Resource Document.
 * @author: dhruv.kumar@velsof.com
 * @date : 28-07-2023
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_RESOURCEDOCUMENT,
  EDIT_RESOURCEDOCUMENT,
  GET_ALL_RESOURCEDOCUMENT,
  GET_RESOURCEDOCUMENT,
} from "../constants/ResourceDocument";

import ResourceDocumentService from "services/ResourceDocumentService";
import {
  setResourceDocument,
  setResourceDocumentListing,
} from "redux/actions/ResourceDocument";
import { showMessage } from "redux/actions/Common";
import {
  ResourceDocument_Added,
  ResourceDocument_Updated,
} from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";

//#region Method for resourceDocument listing

/**
 * Method for getting the resourceDocument details
 */
export function* getAllResourceDocument() {
  yield takeEvery(GET_ALL_RESOURCEDOCUMENT, function* ({ payload }) {
    try {
      const resourceDocument = yield call(
        ResourceDocumentService.getAllResourceDocument,
        payload
      );
      if (resourceDocument.error != null && resourceDocument.error.message) {
        yield put(
          showMessage({ message: resourceDocument.error.message, type: Error })
        );
      } else {
        yield put(setResourceDocumentListing(resourceDocument));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for resourceDocument listing

//#region Method for resourceDocument add page

/**
 * Method for adding the resourceDocument
 */
export function* addResourceDocument() {
  yield takeEvery(ADD_RESOURCEDOCUMENT, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const resourceDocument = yield call(
        ResourceDocumentService.addResourceDocument,
        final
      );
      if (resourceDocument.error != null && resourceDocument.error.message) {
        yield put(
          showMessage({ message: resourceDocument.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: ResourceDocument_Added, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for resourceDocument add page

//#region Method for resourceDocument edit page

/**
 * Method for editing the resourceDocument
 */
export function* editResourceDocument() {
  yield takeEvery(EDIT_RESOURCEDOCUMENT, function* ({ payload }) {
    try {
      const { id, ...postData } = payload;
      let final = {
        data: {
          ...postData
        },
      };
      const resourceDocument = yield call(
        ResourceDocumentService.editResourceDocument,
        id,
        final
      );
      if (resourceDocument.error != null && resourceDocument.error.message) {
        yield put(
          showMessage({ message: resourceDocument.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: ResourceDocument_Updated, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the resourceDocument detail
 */
export function* getResourceDocument() {
  yield takeEvery(GET_RESOURCEDOCUMENT, function* ({ payload }) {
    try {
      let param = {
        "populate[0]": "file",
      };
      const resourceDocument = yield call(
        ResourceDocumentService.getResourceDocument,
        payload,
        param
      );
      if (resourceDocument.error != null && resourceDocument.error.message) {
        yield put(
          showMessage({ message: resourceDocument.error.message, type: Error })
        );
      } else {
        yield put(setResourceDocument(resourceDocument));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for resourceDocument edit page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllResourceDocument),
    fork(addResourceDocument),
    fork(editResourceDocument),
    fork(getResourceDocument),
  ]);
}
