/**
 * The below file contains the district state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 04-10-2022
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_DISTRICT,
  EDIT_DISTRICT,
  SET_DISTRICT,
  SET_DISTRICT_LISTING_PARAM,
  SET_DISTRICT_LISTING,
  UPDATE_DISTRICT_STATUS,
  SET_EXPORT_DISTRICT_DATA,
  GET_EXPORT_DISTRICT_DATA,
} from "../constants/District";

//Initial state
const initState = {
  //Keys for district listing
  districtList: {},
  isDistrictDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  districtListingParam: {},
  districtExportedData: {},
  //Keys for district listing

  //Keys for district add page
  addDistrictForm: {},
  //Keys for district add page

  //Keys for district edit page
  districtFormData: {},
  //Keys for district edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const district = (state = initState, action) => {
  switch (action.type) {
    //Cases for District listing
    case SET_DISTRICT_LISTING:
      return {
        ...state,
        districtList: action.payload,
        isDistrictDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_DISTRICT_LISTING_PARAM:
      return {
        ...state,
        districtListingParam: action.payload,
      };

    case UPDATE_DISTRICT_STATUS:
      let updateDistrictListData = {
        ...state.districtList,
        data: state.districtList.data?.map((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        }),
      };
      return {
        ...state,
        districtList: updateDistrictListData,
      };

    case GET_EXPORT_DISTRICT_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_DISTRICT_DATA:
      return {
        ...state,
        districtExportedData: action.payload,
      };
    //Cases for District listing

    //Cases for District add page
    case ADD_DISTRICT:
      return {
        ...state,
        addDistrictForm: action.payload,
      };
    //Cases for District add page

    //Cases for District edit page
    case EDIT_DISTRICT:
      return {
        ...state,
        addDistrictForm: action.payload,
      };
    case SET_DISTRICT:
      return {
        ...state,
        districtFormData: action.payload,
      };
    //Cases for District edit page

    default:
      return state;
  }
};

export default district;
