/**
 * The below file contains the Sagas method for Vehicle Category.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Vehicle_Categories_Imported,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setAllVehicleCategory,
} from "redux/actions/VehicleCategory";
import {
  GET_ALL_VEHICLE_CATEGORY,
} from "redux/constants/VehicleCategory";
import VehicleCategoryService from "services/VehicleCategoryService";

//#region Method for listing

/**
 * Method for getting the Vehicle Category
 */
export function* getAllVehicleCategory() {
  yield takeEvery(GET_ALL_VEHICLE_CATEGORY, function* () {
    try {
      const vehicleCategory = yield call(VehicleCategoryService.getAllVehicleCategory);
      if (vehicleCategory.error != null && vehicleCategory.error.message) {
        yield put(showMessage({ message: vehicleCategory.error.message, type: Error }));
      } else {
        yield put(setAllVehicleCategory(vehicleCategory));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion Method for the Vehicle Category

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllVehicleCategory),
  ]);
}
