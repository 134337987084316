/**
 * The below file is the flood service which contains method for doing the API calls.
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Blob,
  Get_All_Flood,
  Get_Export_Flood,
  Multipart_Form_Data,
  Flood_Import,
  Flood_Add_Basic_Section,
  Flood_Edit_Basic_Section,
  Flood_Get_All_Section,
  Flood_Add_InfDamage_Section,
  Flood_Add_InfDamageOther_Section,
  Flood_Add_Remark_Section,
  Flood_Submit_Report,
  Flood_Discard_Report,
  Flood_Add_PopAffected_Section,
  Flood_Add_Eroded_Section,
  Flood_Add_ReliefCamp_Section,
  Flood_Add_CampInmates_Section,
  Flood_Add_HLL_Section,
  Flood_Cause_Of_Death_HLL_Section,
  Flood_Add_LiveStock_Section,
  Flood_Add_HouseDamaged_Section,
  Flood_Add_RescueOperation_Section,
  Flood_Add_ReliefDistribution_Section,
  Flood_Fo_Level_Report,
  Flood_Cumulative_State_Report,
  Flood_Cumulative_District_Report,
  Flood_Get_District_Review_List,
  Flood_Revenue_Report_Verify,
  Flood_Revenue_Report_Disapprove,
  Flood_Revenue_Report_Approve,
  Flood_Get_State_Review_List,
  Flood_District_Report_Approve,
  Flood_District_Report_Disapprove,
  Flood_District_Report_Verify,
  Flood_State_Report_Approve,
  Flood_State_Report_Disapprove,
  Flood_Add_NonCampInmates_Section,
  Flood_State_Freeze_Unfreeze_Report_Range,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { GET_FLOOD_DISTRICT_REVIEW_LIST } from "redux/constants/Flood";

const FloodService = {};

//#region Method for listing page.

/**
 * Method for getting the flood listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
FloodService.getAllFlood = function (param) {
  return fetch({
    url: Get_All_Flood,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the flood export data
 * @returns Fetch Method
 */
FloodService.getExportFloodData = function () {
  return fetch({
    url: Get_Export_Flood,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};
//#endregion Method for listing page.

//#region Method for flood import page.

/**
 * Method for importing the flood
 * @param {any} data API data
 * @returns Fetch Method
 */
FloodService.floodImport = function (data) {
  return fetch({
    url: Flood_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for flood import page.

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**Method for getting all section data
 * @param {number} id Form Id
 * @param {object} param Param object
 * @returns Fetch Method
 */
FloodService.floodGetAllSection = function (id, param) {
  return fetch({
    url: format(Flood_Get_All_Section, id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**Method for submitting the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
FloodService.floodReportSubmit = function (id) {
  return fetch({
    url: format(Flood_Submit_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

/**Method for discarding the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
FloodService.floodReportDiscard = function (id) {
  return fetch({
    url: format(Flood_Discard_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the basic section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddBasicSection = function (data) {
  return fetch({
    url: Flood_Add_Basic_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for editing the basic section
 * @param {number} id Form Id
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodEditBasicSection = function (id, data) {
  return fetch({
    url: format(Flood_Edit_Basic_Section, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Basic Section

//#region InfDamage Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the infDamage section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddInfDamageSection = function (data) {
  return fetch({
    url: Flood_Add_InfDamage_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the infDamageOther section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddInfDamageOtherSection = function (data) {
  return fetch({
    url: Flood_Add_InfDamageOther_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamageOther Section

//#region Remark Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the remark section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddRemarkSection = function (data) {
  return fetch({
    url: Flood_Add_Remark_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Remark Section

//#region Popaffected Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the popaffected section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddPopAffectedSection = function (data) {
  return fetch({
    url: Flood_Add_PopAffected_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Popaffected Section

//#region Eroded Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the eroded section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddErodedSection = function (data) {
  return fetch({
    url: Flood_Add_Eroded_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion eroded Section

//#region Relief Camp Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the relief camp section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddReliefCampSection = function (data) {
  return fetch({
    url: Flood_Add_ReliefCamp_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion relief camp Section

//#region Camp inmates Section
/**
 * Method for adding the camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddCampInmatesSection = function (data) {
  return fetch({
    url: Flood_Add_CampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion camp inmates Section

//#region Non Camp inmates Section
/**
 * Method for adding the non camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddNonCampInmatesSection = function (data) {
  return fetch({
    url: Flood_Add_NonCampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion camp inmates Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the Human Live Lost section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddHLLSection = function (data) {
  return fetch({
    url: Flood_Add_HLL_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the LiveStock section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddLiveStockSection = function (data) {
  return fetch({
    url: Flood_Add_LiveStock_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion LiveStock Section

//#region House Damaged Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the House Damaged section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddHouseDamagedSection = function (data) {
  return fetch({
    url: Flood_Add_HouseDamaged_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion House Damaged Section

//#region Rescue Operation Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the Rescue Operation section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddRescueOperationSection = function (data) {
  return fetch({
    url: Flood_Add_RescueOperation_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Rescue Operation Section

//#region Relief Distribution Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the Relief Distribution section
 * @param {object} data API data
 * @returns Fetch Method
 */
FloodService.floodAddReliefDistributionSection = function (data) {
  return fetch({
    url: Flood_Add_ReliefDistribution_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Relief Distribution Section

//#region Method for Approve and Disapprove
/**Method for approving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {number} id Form Id
 * @returns Fetch Method
 */
FloodService.floodRevenueReportApprove = function (payload) {
  return fetch({
    url: Flood_Revenue_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {any} payload Payload
 * @returns Fetch Method
 */
FloodService.floodRevenueReportDisapprove = function (payload) {
  return fetch({
    url: Flood_Revenue_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {any} payload Payload
 * @returns Fetch Method
 */
FloodService.floodRevenueReportVerify = function (payload) {
  return fetch({
    url: Flood_Revenue_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};
/**
 * Method to approve the district approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} payload Payload
 * @returns Fetch Method
 */
FloodService.floodDistrictReportApprove = function (payload) {
  return fetch({
    url: Flood_District_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {any} payload Payload
 * @returns Fetch Method
 */
FloodService.floodDistrictReportDisapprove = function (payload) {
  return fetch({
    url: Flood_District_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {any} payload Payload
 * @returns Fetch Method
 */
FloodService.floodDistrictReportVerify = function (payload) {
  return fetch({
    url: Flood_District_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method to approve the state approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} payload Payload
 * @returns Fetch Method
 */
FloodService.floodStateReportApprove = function (payload) {
  return fetch({
    url: Flood_State_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {any} payload Payload
 * @returns Fetch Method
 */
FloodService.floodStateReportDisapprove = function (payload) {
  return fetch({
    url: Flood_State_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for getting the district review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
FloodService.getFloodDistrictReviewList = function (payload) {
  return fetch({
    url: Flood_Get_District_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};

/**
 * Method for getting the state review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
FloodService.getFloodStateReviewList = function (payload) {
  return fetch({
    url: Flood_Get_State_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};
//#endregion Method for Approve and Disapprove

//#region
/**
 * Below method is for downloading the exported file for viewFlood.js
 */
FloodService.getExportFoLevelFloodReport = function (data) {
  return fetch({
    url: Flood_Fo_Level_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 */
FloodService.getExportStateCumulativeFloodReport = function (data) {
  return fetch({
    url: Flood_Cumulative_State_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 */
FloodService.getExportDistrictCumulativeFloodReport = function (data) {
  return fetch({
    url: Flood_Cumulative_District_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion
export default FloodService;

/**
 * Method for Freezing/Unfreezing flood report for a selected date range
 * @author: vishal.mishra@velsof.com
 * @date : 03-04-2024
 * @param {any} payload Payload
 * @returns Fetch Method
 */
FloodService.floodFreezeReportRange = function (payload) {
  return fetch({
    url: Flood_State_Freeze_Unfreeze_Report_Range,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};
