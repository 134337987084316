/**
 * The below file contains the action methods for origin area.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import {
  GET_ALL_ORIGIN_AREA,
  SET_ALL_ORIGIN_AREA,
} from "redux/constants/OriginArea";

//#region Action methods for fetching Origin Area

/**
 * Method for getting the Origin Area
 * @param {any} params Origin Data
 * @returns Json Object
 */
export const getAllOriginArea = () => {
  return {
    type: GET_ALL_ORIGIN_AREA,
  };
};

/**
 * Method for setting the Origin Area
 * @param {any} params Origin Data
 * @returns Json Object
 */
export const setAllOriginArea = (data) => {
  return {
    type: SET_ALL_ORIGIN_AREA,
    payload: data,
  };
};

//#endregion