/**
 * The below file contains the erosion reports state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 17-11-2022
 */

import {
  BasicSection,
  CampInmatesSection,
  DiscardButton,
  ErodedSection,
  HllSection,
  HouseDamagedSection,
  InfDamageOtherSection,
  InfDamageSection,
  LiveStockSection,
  PopAffectedSection,
  ReliefCampSection,
  ReliefDistributionSection,
  RemarkSection,
  RescueOperationSection,
  SubmitButton,
} from "constants/ApplicationConstant";
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_EROSION_DATA,
  EROSION_ADD_BASIC_SECTION,
  EROSION_ADD_INFDAMAGEOTHER_SECTION,
  EROSION_ADD_INFDAMAGE_SECTION,
  EROSION_ADD_REMARK_SECTION,
  EROSION_EDIT_BASIC_SECTION,
  EROSION_REPORT_DISCARD,
  EROSION_REPORT_SUBMIT,
  EROSION_RESET_BUTTON_LOADING,
  EROSION_SET_ALL_SECTION,
  EROSION_SET_DATA_SAVED,
  EROSION_SET_NIL_REPORTING,
  EROSION_SET_PARENT_ID,
  EROSION_SET_REDIRECT_TO_LISTING,
  SET_EXPORT_EROSION_DATA,
  SET_EROSION_LISTING,
  SET_EROSION_LISTING_PARAM,
  EROSION_ADD_POPAFFECTED_SECTION,
  EROSION_ADD_ERODED_SECTION,
  EROSION_ADD_CAMPINMATES_SECTION,
  EROSION_ADD_RELIEFCAMP_SECTION,
  EROSION_ADD_HLL_SECTION,
  EROSION_ADD_LIVESTOCK_SECTION,
  EROSION_ADD_HOUSEDAMAGED_SECTION,
  EROSION_ADD_RELIEFDISTRIBUTION_SECTION,
  EROSION_ADD_RESCUEOPERATION_SECTION,
  SET_EROSION_DISTRICT_REVIEW_LIST,
  SET_EROSION_STATE_REVIEW_LIST,
  EROSION_REVENUE_REPORT_APPROVE,
  EROSION_REVENUE_REPORT_DISAPPROVE,
  EROSION_REVENUE_REPORT_VERIFY,
  EROSION_DISTRICT_REPORT_APPROVE,
  EROSION_DISTRICT_REPORT_DISAPPROVE,
  EROSION_DISTRICT_REPORT_VERIFY,
  EROSION_STATE_REPORT_APPROVE,
  EROSION_STATE_REPORT_DISAPPROVE,
  SET_EXPORT_FO_LEVEL_EROSION_REPORT,
  SET_STATE_CUMULATIVE_EROSION_REPORT,
  SET_DISTRICT_CUMULATIVE_EROSION_REPORT,
} from "redux/constants/Erosion";

//Initial state
const initState = {
  //Keys for listing
  erosionList: {},
  isErosionDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  erosionListingParam: {},
  erosionExportedData: {},
  exportFoLevelErosionReport: {
    report: "",
    format: "",
  },
  exportStateCumulativeErosionReport: {
    report: "",
    format: "",
  },
  exportDistrictCumulativeErosionReport: {
    report: "",
    format: "",
  },
  //Keys for listing

  //Keys for All Section Data
  erosionParentId: "",
  erosionAllSectionData: {},
  erosionButtonLoading: {
    basicSection: false,
    popAffectedSection: false,
    erodedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
    submitButton: false,
    discardButton: false,
    approveButton: false,
    disapproveButton: false,
    verifyButton: false,
  },
  erosionDataSaved: {
    basicSection: false,
    popAffectedSection: false,
    erodedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
  },
  erosionIsNilReporting: false,
  erosionRedirectToListing: false,
  //Keys for All Section Data

  //Keys for Approval Disapproval
  erosionDistrictReviewList: {},
  erosionStateReviewList: {},
  //Keys for Approval Disapproval
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const erosion = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_EROSION_LISTING:
      return {
        ...state,
        erosionList: action.payload,
        isErosionDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_EROSION_LISTING_PARAM:
      return {
        ...state,
        erosionListingParam: action.payload,
      };

    case GET_EXPORT_EROSION_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_EROSION_DATA:
      return {
        ...state,
        erosionExportedData: action.payload,
      };
    //Cases for listing

    //Cases for All Section
    case EROSION_SET_ALL_SECTION:
      return {
        ...state,
        erosionAllSectionData: action.payload,
      };

    case EROSION_RESET_BUTTON_LOADING:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            basicSection: false,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            popAffectedSection: false,
          },
        };
      } else if (action.payload.section === ErodedSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            erodedSection: false,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            reliefCampSection: false,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            campInmatesSection: false,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            hLLSection: false,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            liveStockSection: false,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            houseDamagedSection: false,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            rescueOperationSection: false,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            reliefDistributionSection: false,
          },
        };
      } else if (action.payload.section === InfDamageSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            infDamageSection: false,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            infDamageOtherSection: false,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            remarkSection: false,
          },
        };
      } else if (action.payload.section === SubmitButton) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            submitButton: false,
          },
        };
      } else if (action.payload.section === DiscardButton) {
        return {
          ...state,
          erosionButtonLoading: {
            ...state.erosionButtonLoading,
            discardButton: false,
          },
        };
      } else {
        return {
          ...state,
          erosionButtonLoading: {
            basicSection: false,
            popAffectedSection: false,
            erodedSection: false,
            reliefCampSection: false,
            campInmatesSection: false,
            hLLSection: false,
            liveStockSection: false,
            houseDamagedSection: false,
            rescueOperationSection: false,
            reliefDistributionSection: false,
            infDamageSection: false,
            infDamageOtherSection: false,
            remarkSection: false,
            submitButton: false,
            discardButton: false,
            approveButton: false,
            disapproveButton: false,
            verifyButton: false,
          },
        };
      }

    case EROSION_SET_PARENT_ID:
      return {
        ...state,
        erosionParentId: action.payload,
      };

    case EROSION_SET_REDIRECT_TO_LISTING:
      return {
        ...state,
        erosionRedirectToListing: action.payload,
      };

    case EROSION_SET_DATA_SAVED:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            basicSection: action.payload.value,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            popAffectedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ErodedSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            erodedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            reliefCampSection: action.payload.value,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            campInmatesSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            hLLSection: action.payload.value,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            liveStockSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            houseDamagedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            rescueOperationSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            reliefDistributionSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            infDamageSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            infDamageOtherSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          erosionDataSaved: {
            ...state.erosionDataSaved,
            remarkSection: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case EROSION_REPORT_SUBMIT:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          submitButton: true,
        },
      };

    case EROSION_REVENUE_REPORT_APPROVE:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          approveButton: true,
        },
      };

    case EROSION_REVENUE_REPORT_DISAPPROVE:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          disapproveButton: true,
        },
      };

    case EROSION_REVENUE_REPORT_VERIFY:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          verifyButton: true,
        },
      };
    //#endregion

    case EROSION_REPORT_DISCARD:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          discardButton: true,
        },
      };

    case EROSION_DISTRICT_REPORT_APPROVE:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          approveButton: true,
        },
      };

    case EROSION_DISTRICT_REPORT_DISAPPROVE:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          disapproveButton: true,
        },
      };

    case EROSION_DISTRICT_REPORT_VERIFY:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          verifyButton: true,
        },
      };

    case EROSION_STATE_REPORT_APPROVE:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          approveButton: true,
        },
      };

    case EROSION_STATE_REPORT_DISAPPROVE:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          disapproveButton: true,
        },
      };
    //Cases for All Section

    //Cases for Basic Section
    case EROSION_ADD_BASIC_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          basicSection: true,
        },
      };

    case EROSION_EDIT_BASIC_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          basicSection: true,
        },
      };

    case EROSION_SET_NIL_REPORTING:
      return {
        ...state,
        erosionIsNilReporting: action.payload,
      };
    //Cases for Basic Section

    //Cases for InfDamage Section
    case EROSION_ADD_INFDAMAGE_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          infDamageSection: true,
        },
      };
    //Cases for InfDamage Section

    //Cases for InfDamageOther Section
    case EROSION_ADD_INFDAMAGEOTHER_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          infDamageOtherSection: true,
        },
      };

    //Cases for Remark Section
    case EROSION_ADD_REMARK_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          remarkSection: true,
        },
      };

    //Cases for Erosion Pop Affected Section
    case EROSION_ADD_POPAFFECTED_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          popAffectedSection: true,
        },
      };
    //Cases for Erosion Pop Affected Section

    //Cases for Erosion Eroded Section
    case EROSION_ADD_ERODED_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          erodedSection: true,
        },
      };
    //Cases for Erosion Eroded Section

    //Cases for Erosion Relief Camp Section
    case EROSION_ADD_RELIEFCAMP_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          reliefCampSection: true,
        },
      };
    //Cases for Erosion Relief Camp Section

    //Cases for Erosion Relief Camp Section
    case EROSION_ADD_CAMPINMATES_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          campInmatesSection: true,
        },
      };
    //Cases for Erosion Relief Camp Section

    //Cases for Erosion Hll Section
    case EROSION_ADD_HLL_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          hLLSection: true,
        },
      };
    //Cases for Erosion Hll Section

    //Cases for Erosion LiveStock Section
    case EROSION_ADD_LIVESTOCK_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          liveStockSection: true,
        },
      };
    //Cases for Erosion LiveStock Section

    //Cases for Erosion House Damaged Section
    case EROSION_ADD_HOUSEDAMAGED_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          houseDamagedSection: true,
        },
      };
    //Cases for Erosion House Damaged Section

    //Cases for Erosion Rescue Operation Section
    case EROSION_ADD_RESCUEOPERATION_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          rescueOperationSection: true,
        },
      };
    //Cases for Erosion Rescue Operation Section

    //Cases for Erosion Relief Distribution Section
    case EROSION_ADD_RELIEFDISTRIBUTION_SECTION:
      return {
        ...state,
        erosionButtonLoading: {
          ...state.erosionButtonLoading,
          reliefDistributionSection: true,
        },
      };
    //Cases for Erosion Relief Distribution Section

    // Cases for Erosion form PDF download section
    case SET_EXPORT_FO_LEVEL_EROSION_REPORT:
      return {
        ...state,
        exportFoLevelErosionReport: {
          report: action.payload.url,
          format: action.payload.format,
        },
      };
    // Cases for Erosion form export file section

    //Cases for Approval and Disapproval
    case SET_EROSION_DISTRICT_REVIEW_LIST:
      return {
        ...state,
        erosionDistrictReviewList: action.payload,
      };

    case SET_EROSION_STATE_REVIEW_LIST:
      return {
        ...state,
        erosionStateReviewList: action.payload,
      };
    //Cases for Approval and Disapproval

    // Cases for Erosion state cumulative report
    case SET_STATE_CUMULATIVE_EROSION_REPORT:
      return {
        ...state,
        exportStateCumulativeErosionReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Erosion state cumulative report

    // Cases for Erosion district cumulative report
    case SET_DISTRICT_CUMULATIVE_EROSION_REPORT:
      return {
        ...state,
        exportDistrictCumulativeErosionReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Erosion district cumulative report

    default:
      return state;
  }
};

export default erosion;
