/**
 * The below file is the apache superset service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 24-03-2023
 */
import fetch from "auth/FetchInterceptor";
import {
  Add_Agency,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_Agency_Status,
  Agency_Import,
  Edit_Agency,
  Get_All_Agency,
  Get_Agency,
  Get_Export_Agency,
  Multipart_Form_Data,
  Superset_Login,
  Superset_Guest_Token,
  Superset_Base_URL,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const ApacheSupersetService = {};

//#region Method for Apache superset.

/**
 * Method for login the user in superset and getting the token
 * @param {any} data API body data
 * @returns Fetch Method
 */
ApacheSupersetService.supersetLogin = function (data) {
  return fetch({
    baseURL: Superset_Base_URL,
    url: Superset_Login,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
    },
    data: data,
  });
};

/**
 * Method for getting the guest token
 * @returns Fetch Method
 */
ApacheSupersetService.getGuestToken = function (data) {
  const { access_token, ...restData } = data;
  return fetch({
    baseURL: Superset_Base_URL,
    url: Superset_Guest_Token,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: `Bearer ${access_token}`,
    },
    data: restData,
  });
};
//#endregion Method for Apache Superset.

export default ApacheSupersetService;
