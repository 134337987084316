/**
 * The below file contains the action methods for district master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 04-10-2022
 */

import {
  ADD_DISTRICT,
  CHANGE_DISTRICT_STATUS,
  DISTRICT_IMPORT,
  EDIT_DISTRICT,
  GET_ALL_DISTRICT,
  GET_DISTRICT,
  GET_EXPORT_DISTRICT_DATA,
  SET_DISTRICT,
  SET_DISTRICT_LISTING,
  SET_DISTRICT_LISTING_PARAM,
  SET_EXPORT_DISTRICT_DATA,
  UPDATE_DISTRICT_STATUS,
} from "../constants/District";

//#region Action methods for district listing page

/**
 * Method for getting the district listing
 * @param {any} params District Params
 * @returns Json Object
 */
export const getAllDistrict = (params) => {
  return {
    type: GET_ALL_DISTRICT,
    payload: params,
  };
};

/**
 * Method for setting the district in the state
 * @param {any} district District Data
 * @returns Json Object
 */
export const setDistrictListing = (district) => {
  return {
    type: SET_DISTRICT_LISTING,
    payload: district,
  };
};

/**
 * Method for setting the district parameters
 * @param {any} data District Params
 * @returns Json Object
 */
export const setDistrictTableParam = (data) => {
  return {
    type: SET_DISTRICT_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the district status
 * @param {any} data District Data
 * @returns Json Object
 */
export const updateDistrictStatus = (data) => {
  return {
    type: UPDATE_DISTRICT_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the district status
 * @param {any} data District Data
 * @returns Json Object
 */
export const changeDistrictStatus = (data) => {
  return {
    type: CHANGE_DISTRICT_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of district
 * @returns Json Object
 */
export const getExportDistrict = () => {
  return {
    type: GET_EXPORT_DISTRICT_DATA,
  };
};

/**
 * Method for setting the export details in the state
 * @param {any} data Export Data
 * @returns Json Object
 */
export const setExportDistrict = (data) => {
  return {
    type: SET_EXPORT_DISTRICT_DATA,
    payload: data,
  };
};
//#endregion Action methods for district listing page

//#region Action methods for district add page
/**
 * Method for adding the district
 * @param {any} data District Data
 * @returns Json Object
 */
export const addDistrict = (data) => {
  return {
    type: ADD_DISTRICT,
    payload: data,
  };
};
//#endregion Action methods for district add page

//#region Action methods for district edit page

/**
 * Method for editing the district
 * @param {any} data District Data
 * @returns Json Object
 */
export const editDistrict = (data) => {
  return {
    type: EDIT_DISTRICT,
    payload: data,
  };
};

/**
 * Method for getting the district details
 * @param {any} id District Id
 * @returns Json Object
 */
export const getDistrict = (id) => {
  return {
    type: GET_DISTRICT,
    payload: id,
  };
};

/**
 * Method for setting the district details in the state
 * @param {any} district District Data
 * @returns Json Object
 */
export const setDistrict = (district) => {
  return {
    type: SET_DISTRICT,
    payload: district,
  };
};
//#endregion Action methods for district edit page

//#region Action methods for district edit page
/**
 * Method for district import
 * @param {any} data District Data
 * @returns Json Object
 */
export const districtImport = (data) => {
  return {
    type: DISTRICT_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for district import page
