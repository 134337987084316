/**
 * The below file contains the action methods for role master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-02-2023
 */

import {
  ADD_ROLE,
  EDIT_ROLE,
  GET_ALL_ROLE,
  GET_ROLE,
  GET_EXPORT_ROLE_DATA,
  SET_ROLE,
  SET_ROLE_LISTING,
  SET_ROLE_LISTING_PARAM,
  SET_EXPORT_ROLE_DATA,
  GET_ALL_PERMISSION,
  SET_ALL_PERMISSION,
  GET_ALL_ROLE_ID,
  SET_ALL_ROLE_ID,
} from "../constants/Role";

//#region Action methods for role listing page

/**
 * Method for getting the role listing
 * @param {any} params Role Params
 * @returns Json Object
 */
export const getAllRole = (params) => {
  return {
    type: GET_ALL_ROLE,
    payload: params,
  };
};

/**
 * Method for setting the role in the state
 * @param {any} role Role Data
 * @returns Json Object
 */
export const setRoleListing = (role) => {
  return {
    type: SET_ROLE_LISTING,
    payload: role,
  };
};

/**
 * Method for setting the role parameters
 * @param {any} data Role Params
 * @returns Json Object
 */
export const setRoleTableParam = (data) => {
  return {
    type: SET_ROLE_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for getting the export file of role
 * @returns Json Object
 */
export const getExportRole = () => {
  return {
    type: GET_EXPORT_ROLE_DATA,
  };
};

/**
 * Method for setting the export details in the state
 * @param {any} data Export Data
 * @returns Json Object
 */
export const setExportRole = (data) => {
  return {
    type: SET_EXPORT_ROLE_DATA,
    payload: data,
  };
};
//#endregion Action methods for role listing page

//#region Action methods for role add page
/**
 * Method for adding the role
 * @param {any} data Role Data
 * @returns Json Object
 */
export const addRole = (data) => {
  return {
    type: ADD_ROLE,
    payload: data,
  };
};
//#endregion Action methods for role add page

//#region Action methods for role edit page

/**
 * Method for editing the role
 * @param {any} data Role Data
 * @returns Json Object
 */
export const editRole = (data) => {
  return {
    type: EDIT_ROLE,
    payload: data,
  };
};

/**
 * Method for getting the role details
 * @param {any} id Role Id
 * @returns Json Object
 */
export const getRole = (id) => {
  return {
    type: GET_ROLE,
    payload: id,
  };
};

/**
 * Method for setting the role details in the state
 * @param {any} role Role Data
 * @returns Json Object
 */
export const setRole = (role) => {
  return {
    type: SET_ROLE,
    payload: role,
  };
};
//#endregion Action methods for role edit page

/**
 * Method for getting the permissions
 * @param {any} role Role Data
 * @returns Json Object
 */
export const getPermission = (role) => {
  return {
    type: GET_ALL_PERMISSION,
    payload: role,
  };
};

/**
 * Method for setting the permission in the state
 * @param {any} role Role Data
 * @returns Json Object
 */
export const setPermission = (role) => {
  return {
    type: SET_ALL_PERMISSION,
    payload: role,
  };
};

/**
 * Method for getting the id of all roles
 * @param {any} role Role Data
 * @returns Json Object
 */
export const getAllRoleId = (role) => {
  return {
    type: GET_ALL_ROLE_ID,
    payload: role,
  };
};

/**
 * Method for setting the role id in the state
 * @param {any} role Role Data
 * @returns Json Object
 */
export const setAllRoleId = (role) => {
  return {
    type: SET_ALL_ROLE_ID,
    payload: role,
  };
};
