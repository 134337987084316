// freezeUnfreezeReducer.js

import {
  SET_ALL_CREATE_FREEZE_UNFREEZE_REPORT,
  SET_ALL_FREEZE_UNFREEZE_REPORT,
  SET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT,
} from "redux/constants/FloodFreezeReport";

// Define an initial state
const initialState = {
  freezeUnfreezeReport: [],
  isFreezeUnfreezeReportDataFetched: false,
};

// Reducer function
const floodFreezeReport = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_FREEZE_UNFREEZE_REPORT:
      return {
        ...state,
        freezeUnfreezeReport: action.payload,
        isFreezeUnfreezeReportDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_CREATE_FREEZE_UNFREEZE_REPORT:
      // Handle the logic to create a new report here
      // You can update the state accordingly
      // For example, if you want to store the new report data, you can do something like this:

      return {
        ...state,
        freezeUnfreezeReport: action.payload,
        isFreezeUnfreezeReportDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT:
      return {
        ...state,
        freezeUnfreezeReport: action.payload,
        isFreezeUnfreezeReportDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    default:
      return state;
  }
};

export default floodFreezeReport;
