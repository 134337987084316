/**
 * The below file contains the constants used in the infrastructure other damage master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-11-2022
 */

/**Constants for InfOtherDamage Listing page */
export const GET_ALL_INFOTHERDAMAGE = "GET_ALL_INFOTHERDAMAGE";
export const SET_INFOTHERDAMAGE_LISTING = "SET_INFOTHERDAMAGE_LISTING";
export const SET_INFOTHERDAMAGE_LISTING_PARAM =
  "SET_INFOTHERDAMAGE_LISTING_PARAM";
export const UPDATE_INFOTHERDAMAGE_STATUS = "UPDATE_INFOTHERDAMAGE_STATUS";
export const CHANGE_INFOTHERDAMAGE_STATUS = "CHANGE_INFOTHERDAMAGE_STATUS";
export const GET_EXPORT_INFOTHERDAMAGE_DATA = "GET_EXPORT_INFOTHERDAMAGE_DATA";
export const SET_EXPORT_INFOTHERDAMAGE_DATA = "SET_EXPORT_INFOTHERDAMAGE_DATA";
/**Constants for InfOtherDamage Listing page */

/**Constants for InfOtherDamage Add page */
export const ADD_INFOTHERDAMAGE = "ADD_INFOTHERDAMAGE";
/**Constants for InfOtherDamage Add page */

/**Constants for InfOtherDamage Edit page */
export const EDIT_INFOTHERDAMAGE = "EDIT_INFOTHERDAMAGE";
export const GET_INFOTHERDAMAGE = "GET_INFOTHERDAMAGE";
export const SET_INFOTHERDAMAGE = "SET_INFOTHERDAMAGE";
/**Constants for InfOtherDamage Edit page */

/**Constants for InfOtherDamage Import page */
export const INFOTHERDAMAGE_IMPORT = "INFOTHERDAMAGE_IMPORT";
/**Constants for InfOtherDamage Import page */
