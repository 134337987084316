/**
 * The below file contains the Notification object
 * @author: shivam.sharma2@velsof.com
 * @date : 24-01-2023
 */

import {
  GET_UPDATED_STATUS,
  SET_NOTIFICATION,
  SET_UNREAD_NOTIFICATION,
} from "redux/constants/Notification";

//Initial state
const initState = {
  //Keys for Notification
  notificationsList: [],
  unreadNotifications: 0,
  //Keys for Notification
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const Notification = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_NOTIFICATION:
      return {
        ...state,
        notificationsList: [...action.payload],
      };

    case GET_UPDATED_STATUS:
      let updatedNotifications = state.notificationsList.map((item) => {
        if (item.id === action.payload.id) {
          item.readStatus = action.payload.readStatus;
        }
        return item;
      });
      return {
        ...state,
        notificationsList: updatedNotifications,
      };

    case SET_UNREAD_NOTIFICATION:
      return {
        ...state,
        unreadNotifications: action.payload,
      };

    default:
      return state;
  }
};

export default Notification;
