/**
 * The below file contains the resource group state objects
 * @author: dhruv.kumar@velsof.com
 * @date : 28-07-2023
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_RESOURCEGROUP,
  EDIT_RESOURCEGROUP,
  SET_RESOURCEGROUP,
  SET_RESOURCEGROUP_LISTING_PARAM,
  SET_RESOURCEGROUP_LISTING,
} from "../constants/ResourceGroup";

//Initial state
const initState = {
  //Keys for resourceGroup listing
  resourceGroupList: {},
  isResourceGroupDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  resourceGroupListingParam: {},
  //Keys for resourceGroup listing

  //Keys for resourceGroup add page
  addResourceGroupForm: {},
  //Keys for resourceGroup add page

  //Keys for resourceGroup edit page
  resourceGroupFormData: {},
  //Keys for resourceGroup edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const resourceGroup = (state = initState, action) => {
  switch (action.type) {
    //Cases for ResourceGroup listing
    case SET_RESOURCEGROUP_LISTING:
      return {
        ...state,
        resourceGroupList: action.payload,
        isResourceGroupDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_RESOURCEGROUP_LISTING_PARAM:
      return {
        ...state,
        resourceGroupListingParam: action.payload,
      };

    //Cases for ResourceGroup add page
    case ADD_RESOURCEGROUP:
      return {
        ...state,
        addResourceGroupForm: action.payload,
      };
    //Cases for ResourceGroup add page

    //Cases for ResourceGroup edit page
    case EDIT_RESOURCEGROUP:
      return {
        ...state,
        addResourceGroupForm: action.payload,
      };
    case SET_RESOURCEGROUP:
      return {
        ...state,
        resourceGroupFormData: action.payload,
      };
    //Cases for ResourceGroup edit page

    default:
      return state;
  }
};

export default resourceGroup;
