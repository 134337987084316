/**
 * The below file contains the constants used in the Fire Class
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

/**Constants for fire class */
export const GET_ALL_FIRE_CLASS = "GET_ALL_FIRE_CLASS";
export const SET_ALL_FIRE_CLASS = "SET_ALL_FIRE_CLASS";
/**Constants for fire class */
