/**
 * The below file contains the unit state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_UNIT,
  EDIT_UNIT,
  SET_UNIT,
  SET_UNIT_LISTING_PARAM,
  SET_UNIT_LISTING,
  UPDATE_UNIT_STATUS,
  SET_EXPORT_UNIT_DATA,
  GET_EXPORT_UNIT_DATA,
} from "../constants/Unit";

//Initial state
const initState = {
  //Keys for unit listing
  unitList: {},
  isUnitDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  unitListingParam: {},
  unitExportedData: {},
  //Keys for unit listing

  //Keys for unit add page
  addUnitForm: {},
  //Keys for unit add page

  //Keys for unit edit page
  unitFormData: {},
  //Keys for unit edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const unit = (state = initState, action) => {
  switch (action.type) {
    //Cases for Unit listing
    case SET_UNIT_LISTING:
      return {
        ...state,
        unitList: action.payload,
        isUnitDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_UNIT_LISTING_PARAM:
      return {
        ...state,
        unitListingParam: action.payload,
      };

    case UPDATE_UNIT_STATUS:
      let updateUnitListData = {
        ...state.unitList,
        data: state.unitList.data?.map((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        }),
      };
      return {
        ...state,
        unitList: updateUnitListData,
      };

    case GET_EXPORT_UNIT_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_UNIT_DATA:
      return {
        ...state,
        unitExportedData: action.payload,
      };
    //Cases for Unit listing

    //Cases for Unit add page
    case ADD_UNIT:
      return {
        ...state,
        addUnitForm: action.payload,
      };
    //Cases for Unit add page

    //Cases for Unit edit page
    case EDIT_UNIT:
      return {
        ...state,
        addUnitForm: action.payload,
      };
    case SET_UNIT:
      return {
        ...state,
        unitFormData: action.payload,
      };
    //Cases for Unit edit page

    default:
      return state;
  }
};

export default unit;
