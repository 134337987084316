/**
 * The below file contains the constants used in the Special Call Type
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

/**Constants for Special Call Type */
export const GET_ALL_SPECIAL_CALL_TYPE = "GET_ALL_SPECIAL_CALL_TYPE";
export const SET_ALL_SPECIAL_CALL_TYPE = "SET_ALL_SPECIAL_CALL_TYPE";
/**Constants for Special Call Type */
