/**
 * The below file is the village service which contains method for doing the API calls.
 * @author: shivam.sharma2@velsof.com
 * @date : 29-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Get_All_Wards,
  Application_Json,
  Authorization_Token,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const WardsService = {};

//#region Method for getting the API

/**
 * Method for getting the wards name
 * @returns Fetch Method
 */
WardsService.getAllWards = function (param) {
  return fetch({
    url: Get_All_Wards,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

export default WardsService;
