/**
 * The below file contains the infDamage reports state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-11-2022
 */

import {
  BasicSection,
  DiscardButton,
  InfDamageOtherSection,
  InfDamageSection,
  RemarkSection,
  SubmitButton,
} from "constants/ApplicationConstant";
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_INFDAMAGE_DATA,
  INFDAMAGE_ADD_BASIC_SECTION,
  INFDAMAGE_ADD_INFDAMAGEOTHER_SECTION,
  INFDAMAGE_ADD_INFDAMAGE_SECTION,
  INFDAMAGE_ADD_REMARK_SECTION,
  INFDAMAGE_EDIT_BASIC_SECTION,
  INFDAMAGE_REPORT_DISCARD,
  INFDAMAGE_REPORT_SUBMIT,
  INFDAMAGE_RESET_BUTTON_LOADING,
  INFDAMAGE_SET_ALL_SECTION,
  INFDAMAGE_SET_DATA_SAVED,
  INFDAMAGE_SET_NIL_REPORTING,
  INFDAMAGE_SET_PARENT_ID,
  INFDAMAGE_SET_REDIRECT_TO_LISTING,
  SET_EXPORT_INFDAMAGE_DATA,
  SET_INFDAMAGE_LISTING,
  SET_INFDAMAGE_LISTING_PARAM,
} from "redux/constants/InfDamage";

//Initial state
const initState = {
  //Keys for listing
  infDamageList: {},
  isInfDamageDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  infDamageListingParam: {},
  infDamageExportedData: {},
  //Keys for listing

  //Keys for All Section Data
  infDamageParentId: "",
  infDamageAllSectionData: {},
  infDamageButtonLoading: {
    basicSection: false,
    infDamageSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
    submitButton: false,
    discardButton: false,
  },
  infDamageDataSaved: {
    basicSection: false,
    infDamageSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
  },
  infDamageIsNilReporting: false,
  infDamageRedirectToListing: false,
  //Keys for All Section Data

  //Keys for Basic Section Data
  infDamageAddBasicSection: {},
  //Keys for Basic Section Data

  //Keys for InfDamage Section Data
  infDamageAddInfDamageSection: {},
  //Keys for InfDamage Section Data

  //Keys for InfDamageOther Section Data
  infDamageAddInfDamageOtherSection: {},
  //Keys for InfDamageOther Section Data
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const infDamage = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_INFDAMAGE_LISTING:
      return {
        ...state,
        infDamageList: action.payload,
        isInfDamageDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_INFDAMAGE_LISTING_PARAM:
      return {
        ...state,
        infDamageListingParam: action.payload,
      };

    case GET_EXPORT_INFDAMAGE_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_INFDAMAGE_DATA:
      return {
        ...state,
        infDamageExportedData: action.payload,
      };
    //Cases for listing

    //Cases for All Section
    case INFDAMAGE_SET_ALL_SECTION:
      return {
        ...state,
        infDamageAllSectionData: action.payload,
      };

    case INFDAMAGE_RESET_BUTTON_LOADING:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          infDamageButtonLoading: {
            ...state.infDamageButtonLoading,
            basicSection: false,
          },
        };
      } else if (action.payload.section === InfDamageSection) {
        return {
          ...state,
          infDamageButtonLoading: {
            ...state.infDamageButtonLoading,
            infDamageSection: false,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          infDamageButtonLoading: {
            ...state.infDamageButtonLoading,
            infDamageOtherSection: false,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          infDamageButtonLoading: {
            ...state.infDamageButtonLoading,
            remarkSection: false,
          },
        };
      } else if (action.payload.section === SubmitButton) {
        return {
          ...state,
          infDamageButtonLoading: {
            ...state.infDamageButtonLoading,
            submitButton: false,
          },
        };
      } else if (action.payload.section === DiscardButton) {
        return {
          ...state,
          infDamageButtonLoading: {
            ...state.infDamageButtonLoading,
            discardButton: false,
          },
        };
      } else {
        return {
          ...state,
          infDamageButtonLoading: {
            basicSection: false,
            infDamageSection: false,
            infDamageOtherSection: false,
            remarkSection: false,
            submitButton: false,
            discardButton: false,
          },
        };
      }

    case INFDAMAGE_SET_PARENT_ID:
      return {
        ...state,
        infDamageParentId: action.payload,
      };

    case INFDAMAGE_SET_REDIRECT_TO_LISTING:
      return {
        ...state,
        infDamageRedirectToListing: action.payload,
      };

    case INFDAMAGE_SET_DATA_SAVED:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          infDamageDataSaved: {
            ...state.infDamageDataSaved,
            basicSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageSection) {
        return {
          ...state,
          infDamageDataSaved: {
            ...state.infDamageDataSaved,
            infDamageSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          infDamageDataSaved: {
            ...state.infDamageDataSaved,
            infDamageOtherSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          infDamageDataSaved: {
            ...state.infDamageDataSaved,
            remarkSection: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
          infDamageDataSaved: {
            basicSection: false,
            infDamageSection: false,
            infDamageOtherSection: false,
            remarkSection: false,
          },
        };
      }

    case INFDAMAGE_REPORT_SUBMIT:
      return {
        ...state,
        infDamageButtonLoading: {
          ...state.infDamageButtonLoading,
          submitButton: true,
        },
      };

    case INFDAMAGE_REPORT_DISCARD:
      return {
        ...state,
        infDamageButtonLoading: {
          ...state.infDamageButtonLoading,
          discardButton: true,
        },
      };
    //Cases for All Section

    //Cases for Basic Section
    case INFDAMAGE_ADD_BASIC_SECTION:
      return {
        ...state,
        infDamageButtonLoading: {
          ...state.infDamageButtonLoading,
          basicSection: true,
        },
      };

    case INFDAMAGE_EDIT_BASIC_SECTION:
      return {
        ...state,
        infDamageButtonLoading: {
          ...state.infDamageButtonLoading,
          basicSection: true,
        },
      };

    case INFDAMAGE_SET_NIL_REPORTING:
      return {
        ...state,
        infDamageIsNilReporting: action.payload,
      };
    //Cases for Basic Section

    //Cases for InfDamage Section
    case INFDAMAGE_ADD_INFDAMAGE_SECTION:
      return {
        ...state,
        infDamageButtonLoading: {
          ...state.infDamageButtonLoading,
          infDamageSection: true,
        },
      };
    //Cases for InfDamage Section

    //Cases for InfDamageOther Section
    case INFDAMAGE_ADD_INFDAMAGEOTHER_SECTION:
      return {
        ...state,
        infDamageButtonLoading: {
          ...state.infDamageButtonLoading,
          infDamageOtherSection: true,
        },
      };

    //Cases for Remark Section
    case INFDAMAGE_ADD_REMARK_SECTION:
      return {
        ...state,
        infDamageButtonLoading: {
          ...state.infDamageButtonLoading,
          remarkSection: true,
        },
      };

    default:
      return state;
  }
};

export default infDamage;
