/**
 * The below file contains the Sagas method for River.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 01-12-2022
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_RIVER,
  CHANGE_RIVER_STATUS,
  RIVER_IMPORT,
  EDIT_RIVER,
  GET_ALL_RIVER,
  GET_RIVER,
  GET_EXPORT_RIVER_DATA,
} from "../constants/River";

import RiverService from "services/RiverService";
import {
  setRiver,
  setRiverListing,
  setExportRiver,
  updateRiverStatus,
} from "redux/actions/River";
import { showMessage } from "redux/actions/Common";
import {
  River_Added,
  River_Imported,
  River_Updated,
} from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";

//#region Method for river listing

/**
 * Method for getting the river details
 */
export function* getAllRiver() {
  yield takeEvery(GET_ALL_RIVER, function* ({ payload }) {
    try {
      const river = yield call(RiverService.getAllRiver, payload);
      if (river.error != null && river.error.message) {
        yield put(showMessage({ message: river.error.message, type: Error }));
      } else {
        yield put(setRiverListing(river));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the river export details
 */
export function* getRiverExport() {
  yield takeEvery(GET_EXPORT_RIVER_DATA, function* () {
    try {
      const river = yield call(RiverService.getExportRiverData);
      if (river.error != null && river.error.message) {
        yield put(showMessage({ message: river.error.message, type: Error }));
      } else {
        let exportData = {
          url: window.URL.createObjectURL(river),
        };
        yield put(setExportRiver(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the river status details
 */
export function* changeRiverStatus() {
  yield takeEvery(CHANGE_RIVER_STATUS, function* ({ payload }) {
    try {
      const river = yield call(RiverService.changeRiverStatus, payload);
      if (river.error != null && river.error.message) {
        yield put(showMessage({ message: river.error.message, type: Error }));
      } else {
        let json = {
          id: river.data.id,
          status: river.data.status,
        };
        yield put(updateRiverStatus(json));
        yield put(showMessage({ message: River_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for river listing

//#region Method for river add page

/**
 * Method for adding the river
 */
export function* addRiver() {
  yield takeEvery(ADD_RIVER, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const river = yield call(RiverService.addRiver, final);
      if (river.error != null && river.error.message) {
        yield put(showMessage({ message: river.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: River_Added, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for river add page

//#region Method for river edit page

/**
 * Method for editing the river
 */
export function* editRiver() {
  yield takeEvery(EDIT_RIVER, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          riverName: payload.riverName,
          riverCode: payload.riverCode,
          status: payload.status,
          state: payload.state,
        },
      };
      const river = yield call(RiverService.editRiver, id, final);
      if (river.error != null && river.error.message) {
        yield put(showMessage({ message: river.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: River_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the river detail
 */
export function* getRiver() {
  yield takeEvery(GET_RIVER, function* ({ payload }) {
    try {
      const river = yield call(RiverService.getRiver, payload);
      if (river.error != null && river.error.message) {
        yield put(showMessage({ message: river.error.message, type: Error }));
      } else {
        yield put(setRiver(river));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for river edit page

//#region Method for river import page

/**
 * Method for importing the river
 */
export function* riverImport() {
  yield takeEvery(RIVER_IMPORT, function* ({ payload }) {
    try {
      const river = yield call(RiverService.riverImport, payload);
      if (river.error != null && river.error.message) {
        yield put(showMessage({ message: river.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: River_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for river import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllRiver),
    fork(addRiver),
    fork(editRiver),
    fork(getRiver),
    fork(getRiverExport),
    fork(changeRiverStatus),
    fork(riverImport),
  ]);
}
