/**
 * The below file contains the constants used in the Fire Detection
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

/**Constants for Fire Detection */
export const GET_ALL_FIRE_DETECTION = "GET_ALL_FIRE_DETECTION";
export const SET_ALL_FIRE_DETECTION = "SET_ALL_FIRE_DETECTION";
/**Constants for Fire Detection */
