/**
 * The below file contains the special call type state objects
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  SET_ALL_SPECIAL_CALL_TYPE,
} from "redux/constants/SpecialCallType";

//Initial state
const initState = {
  // Keys for dropdown 
  specialCallTypes: [],
  isSpecialCallTypeDataFetched: false,
  // Keys for dropdown 
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const specialCallType = (state = initState, action) => {
  switch (action.type) {
    //Cases for Special call type category dropdown
    case SET_ALL_SPECIAL_CALL_TYPE:
      return {
        ...state,
        specialCallTypes: action.payload,
        isSpecialCallTypeDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };
    //Cases for Special call type category dropdown

    default:
      return state;
  }
};

export default specialCallType;
