/**
 * The below file contains the Sagas method for Fire.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Special_Call_Type_Imported,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setAllSpecialCallType,
} from "redux/actions/SpecialCallType";
import {
  GET_ALL_SPECIAL_CALL_TYPE,
} from "redux/constants/SpecialCallType";
import SpecialCallTypeService from "services/SpecialCallTypeService";

//#region Method for listing

/**
 * Method for getting the fires
 */
export function* getAllSpecialCallType() {
  yield takeEvery(GET_ALL_SPECIAL_CALL_TYPE, function* () {
    try {
      const specialCallType = yield call(SpecialCallTypeService.getAllSpecialCallType);
      if (specialCallType.error != null && specialCallType.error.message) {
        yield put(showMessage({ message: specialCallType.error.message, type: Error }));
      } else {
        yield put(setAllSpecialCallType(specialCallType));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion Method for listing

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllSpecialCallType),
  ]);
}
