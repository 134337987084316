/**
 * The below file contains the Sagas method for Fire Cause.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import {
  all,
  takeEvery,
  put,
  fork,
  call,
  takeLatest,
} from "redux-saga/effects";
import {
  setAllCreateFreezeUnfreezeReport,
  setAllFreezeUnfreezeReport,
  setAllUpdateFreezeUnfreezeReport,
} from "redux/actions";
import { showMessage } from "redux/actions/Common";
import {
  GET_ALL_FREEZE_UNFREEZE_REPORT,
  GET_ALL_CREATE_FREEZE_UNFREEZE_REPORT,
  GET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT,
} from "redux/constants/FloodFreezeReport";
import FloodFreezeReportServices from "services/FloodFreezeReportServices";

export function* getAllFreezeUnfreezeReport() {
  yield takeEvery(GET_ALL_FREEZE_UNFREEZE_REPORT, function* ({ payload }) {
    try {
      const freezeData = yield call(FloodFreezeReportServices.getAll, payload);
      if (freezeData.error != null && freezeData.error.message) {
        yield put(
          showMessage({ message: freezeData.error.message, type: Error })
        );
      } else {
        yield put(setAllFreezeUnfreezeReport(freezeData));
      }
    } catch (error) {
      yield put(
        showMessage({ message: error.response.data.error.message, type: Error })
      );
    }
  });
}

// Saga for creating a new Freeze Unfreeze report
export function* getAllCreateFreezeUnfreezeReport() {
  yield takeEvery(
    GET_ALL_CREATE_FREEZE_UNFREEZE_REPORT,
    function* ({ payload }) {
      try {
        const freezeData = yield call(
          FloodFreezeReportServices.create,
          payload
        );
        if (freezeData.error != null && freezeData.error.message) {
          yield put(
            showMessage({ message: freezeData.error.message, type: Error })
          );
        } else {
          yield put(setAllCreateFreezeUnfreezeReport(freezeData));
          yield put(
            showMessage({ message: "Marked as Freezed", type: Success })
          );
        }
      } catch (error) {
        yield put(
          showMessage({
            message: error.response.data.error.message,
            type: Error,
          })
        );
      }
    }
  );
}

// Saga for updating an existing Freeze Unfreeze report
function* getAllUpdateFreezeUnfreezeReport() {
  yield takeEvery(
    GET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT,
    function* ({ payload, id }) {
      try {
        const freezeData = yield call(
          FloodFreezeReportServices.update,
          payload,
          id
        );
        if (freezeData.error != null && freezeData.error.message) {
          yield put(
            showMessage({ message: freezeData.error.message, type: Error })
          );
        } else {
          yield put(setAllUpdateFreezeUnfreezeReport(freezeData));
          yield put(
            showMessage({ message: "Status of Report Changed", type: Success })
          );
        }
      } catch (error) {
        yield put(showMessage({ message: error.message, type: Error }));
      }
    }
  );
}

//#endregion Method for listing

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllFreezeUnfreezeReport),
    fork(getAllCreateFreezeUnfreezeReport),
    fork(getAllUpdateFreezeUnfreezeReport),
  ]);
}
