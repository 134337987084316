/**
 * The below file contains the constants used in the Vehicle Category
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

/**Constants for Vehicle Category */
export const GET_ALL_VEHICLE_CATEGORY = "GET_ALL_VEHICLE_CATEGORY";
export const SET_ALL_VEHICLE_CATEGORY = "SET_ALL_VEHICLE_CATEGORY";
/**Constants for Vehicle Category */
