import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { MARQUEE_TEXT, STAGING, THEME_CONFIG } from "./configs/AppConfig";
import Marquee from "react-fast-marquee";
import "./app.css";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      {STAGING && (
        <Marquee
          gradient={false}
          style={{
            top: 0,
            zIndex: 999,
            background: "yellow",
            color: "red",
            fontSize: "1rem",
            fontWeight: 500,
            position: "sticky",
          }}
        >
          {MARQUEE_TEXT}
        </Marquee>
      )}
      <Provider store={store}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <Router>
            <Switch>
              <Route path="/" component={Views} />
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
