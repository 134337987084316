import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button, Tag, Image } from "antd";
import { BellOutlined, MailOutlined } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import { connect, useDispatch } from "react-redux";
import {
  getNotification,
  getUnreadNotification,
  markAllRead,
  setNotificationStatus,
} from "redux/actions";
import moment from "moment";
import {
  DATE_FORMAT_DD_MM_YYYY,
  DATE_FORMAT_HH_mm,
  DATE_FORMAT_YYYY_MM_DD,
} from "constants/DateConstant";

const getDateTime = (data) => {
  let date = "";
  if (data.reportDate !== null) {
    date +=
      moment(data.reportDate, DATE_FORMAT_YYYY_MM_DD)
        .format(DATE_FORMAT_DD_MM_YYYY)
        .toString() + " ";
  }
  if (data.reportTime !== null) {
    date += moment(data.reportTime, DATE_FORMAT_HH_mm)
      .format(DATE_FORMAT_HH_mm)
      .toString();
  }
  return date;
};

const getReadStatus = (readStatus) => {
  switch (readStatus) {
    case 0:
      return "unread";
    case 1:
      return "read";
    default:
      return "";
  }
};

const getNotificationBody = (list, readStatusHandler) => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item
          className={`list-clickable ${getReadStatus(item.readStatus)}`}
          onClick={() => {
            readStatusHandler(item);
          }}
        >
          <Flex alignItems="center">
            <div className="pr-3">
              <Avatar
                className={`ant-avatar-info`}
                size="large"
                icon={
                  item.readStatus ? (
                    <Image
                      src="/img/others/open-envelope.png"
                      width="1em"
                      height="1em"
                      preview={false}
                    />
                  ) : (
                    <MailOutlined />
                  )
                }
              ></Avatar>
            </div>
            <div className="mr-3">
              <div className="font-weight-bold text-dark">{item.title} </div>
              <div className="text-gray-light">{item.description}</div>
              {item.disasterType !== null && <Tag>{item.disasterType}</Tag>}
              <div>
                <small className="ml-auto">{getDateTime(item)}</small>
              </div>
            </div>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        //src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        src="/img/others/no-notification.svg"
        alt="empty"
      />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
};

export const NavNotification = (props) => {
  //#region
  const { notificationsList, loginUser, unreadNotifications } = props;

  const notificationParam = {
    sort: "updatedAt:desc",
    "populate[0]": "user",
    "filters[user][id][$eq]": `${loginUser?.id}`,
    "pagination[page]": 1,
    "pagination[pageSize]": 100,
  };

  //#region for states
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isInitialDataBind, setIsInitialDataBind] = useState(false);
  const [count, setCount] = useState(0);
  //#endregion

  //#region for object and method

  const dispatch = useDispatch();

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const readStatusHandler = (data) => {
    if (data.readStatus === 0) {
      dispatch(
        setNotificationStatus({
          id: data.id,
          data: {
            readStatus: 1,
          },
        })
      );
    }
  };

  /**
   * For marking all the notifications as read
   */
  const notificationMarkAllRead = () => {
    if (data.some((item) => item.readStatus === 0)) {
      let param = {
        data: {
          userId: loginUser?.id,
        },
      };
      dispatch(markAllRead(param));
    }
  };

  /**
   * For getting all notificaiton
   */
  const getAllNotifications = () => {
    let param = {
      ...notificationParam,
    };
    dispatch(getNotification(param));
  };

  /**
   * For getting read notification
   */
  const getReadNotifications = () => {
    let param = {
      ...notificationParam,
      "filters[readStatus][$eq]": 1,
    };
    dispatch(getNotification(param));
  };

  /**
   * For getting unread notification
   */
  const getUnReadNotifications = () => {
    let param = {
      ...notificationParam,
      "filters[readStatus][$eq]": 0,
    };
    dispatch(getNotification(param));
  };

  const NotificationsList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <div>
          <Avatar
            className={`ant-avatar-info`}
            icon={
              <Image
                src="/img/others/all.png"
                width="1.2em"
                height="1.2em"
                className="mt-1"
                preview={false}
              />
            }
            onClick={getAllNotifications}
          ></Avatar>
          <Avatar
            className={`ant-avatar-info`}
            icon={<MailOutlined />}
            onClick={getUnReadNotifications}
          ></Avatar>
          <Avatar
            className={`ant-avatar-info`}
            icon={
              <Image
                src="/img/others/open-envelope.png"
                width="1em"
                height="1em"
                preview={false}
              />
            }
            onClick={getReadNotifications}
          ></Avatar>
        </div>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(data, readStatusHandler)}
      </div>
      {data.length > 0 ? (
        <div className="nav-notification-footer">
          <a className="d-block" onClick={notificationMarkAllRead}>
            Mark all read
          </a>
        </div>
      ) : null}
    </div>
  );
  //#endregion

  //#region useEffect

  /**
   * For fetching the Notifications
   */

  useEffect(() => {
    if (!isInitialDataBind && loginUser !== null) {
      setIsInitialDataBind(true);
      let param = {
        ...notificationParam,
      };
      dispatch(getNotification(param));
    }
  }, [loginUser]);

  useEffect(() => {
    if (notificationsList) {
      setData(notificationsList);
    }
    if (isInitialDataBind) {
      let param = {
        ...notificationParam,
        "filters[readStatus][$eq]": 0,
      };
      dispatch(getUnreadNotification(param));
      setCount(unreadNotifications);
    }
  }, [isInitialDataBind, notificationsList]);

  //#endregion

  return (
    <Dropdown
      placement="bottomRight"
      overlay={NotificationsList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={count}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

/**
 * State Props sending to reducer
 * @param {any} param0 object
 * @returns object
 */
const mapStateToProps = ({ notification, auth }) => {
  const { loginUser } = auth;
  const { notificationsList, unreadNotifications } = notification;
  return {
    notificationsList,
    unreadNotifications,
    loginUser,
  };
};

export default connect(mapStateToProps, null)(NavNotification);
