import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/masters`}
          component={lazy(() => import(`./masters`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/map`}
          component={lazy(() => import(`./map/Map`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/disasters`}
          component={lazy(() => import(`./disasters`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/resource`}
          component={lazy(() => import(`./resources`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/disasters`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
