/**
 * The below file contains the action methods for village master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 19-10-2022
 */

import {
  ADD_VILLAGE,
  CHANGE_VILLAGE_STATUS,
  EDIT_VILLAGE,
  GET_ALL_VILLAGE,
  GET_EXPORT_VILLAGE_DATA,
  GET_VILLAGE,
  SET_EXPORT_VILLAGE_DATA,
  SET_VILLAGE,
  SET_VILLAGE_LISTING,
  SET_VILLAGE_LISTING_PARAM,
  UPDATE_VILLAGE_STATUS,
  VILLAGE_IMPORT,
} from "redux/constants/Village";

//#region Action methods for village listing page

/**
 * Method for getting the village listing
 * @param {any} params Village Data
 * @returns Json Object
 */
export const getAllVillage = (params) => {
  return {
    type: GET_ALL_VILLAGE,
    payload: params,
  };
};

/**
 * Method for setting the village listing details in state
 * @param {any} village Village Data
 * @returns Json Object
 */
export const setVillageListing = (village) => {
  return {
    type: SET_VILLAGE_LISTING,
    payload: village,
  };
};

/**
 * Method for setting the village parameters
 * @param {any} data Village Data
 * @returns Json Object
 */
export const setVillageTableParam = (data) => {
  return {
    type: SET_VILLAGE_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the village status
 * @param {any} data Village Data
 * @returns Json Object
 */
export const updateVillageStatus = (data) => {
  return {
    type: UPDATE_VILLAGE_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the village status
 * @param {any} data Village Data
 * @returns Json Object
 */
export const changeVillageStatus = (data) => {
  return {
    type: CHANGE_VILLAGE_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of village
 * @returns Json Object
 */
export const getExportVillage = () => {
  return {
    type: GET_EXPORT_VILLAGE_DATA,
  };
};

/**
 * Method for setting the village export data in the state
 * @param {any} data Village Data
 * @returns Json Object
 */
export const setExportVillage = (data) => {
  return {
    type: SET_EXPORT_VILLAGE_DATA,
    payload: data,
  };
};
//#endregion Action methods for village listing page

//#region Action methods for village add page

/**
 * Method for adding the village
 * @param {any} data Village Data
 * @returns Json Object
 */
export const addVillage = (data) => {
  return {
    type: ADD_VILLAGE,
    payload: data,
  };
};
//#endregion Action methods for village add page

//#region Action methods for village edit page

/**
 * Method for editing the village
 * @param {any} data Village Data
 * @returns Json Object
 */
export const editVillage = (data) => {
  return {
    type: EDIT_VILLAGE,
    payload: data,
  };
};

/**
 * Method for getting the village details
 * @param {any} id Village Id
 * @returns Json Object
 */
export const getVillage = (id) => {
  return {
    type: GET_VILLAGE,
    payload: id,
  };
};

/**
 * Method for setting the village details in the state
 * @param {any} village Village Data
 * @returns Json Object
 */
export const setVillage = (village) => {
  return {
    type: SET_VILLAGE,
    payload: village,
  };
};
//#endregion Action methods for village edit page

//#region Action methods for village import page
/**
 * Method for village import
 * @param {any} data Village Data
 * @returns Json Object
 */
export const villageImport = (data) => {
  return {
    type: VILLAGE_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for village import page
