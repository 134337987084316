/**
 * The below file contains the Sagas method for Landslide reports.
 * @author: neeraj.singh.rawat@velsof.com
 * @date: 08-12-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  BasicSection,
  DiscardButton,
  InfDamageOtherSection,
  InfDamageSection,
  PopAffectedSection,
  ErodedSection,
  RemarkSection,
  SubmitButton,
  approveButton,
  disapproveButton,
  ReliefCampSection,
  CampInmatesSection,
  HllSection,
  LiveStockSection,
  HouseDamagedSection,
  RescueOperationSection,
  ReliefDistributionSection,
  verifyButton,
} from "constants/ApplicationConstant";
import {
  Landslide_Added_Basic_Section,
  Landslide_Added_CampInmates_Section,
  Landslide_Added_Eroded_Section,
  Landslide_Added_HLL_Section,
  Landslide_Added_HouseDamaged_Section,
  Landslide_Added_InfDamageOther_Section,
  Landslide_Added_InfDamage_Section,
  Landslide_Added_LiveStock_Section,
  Landslide_Added_PopAffected_Section,
  Landslide_Added_ReliefCamp_Section,
  Landslide_Added_ReliefDistribution_Section,
  Landslide_Added_Remark_Section,
  Landslide_Added_RescueOperation_Section,
  Landslide_Imported,
  Landslide_Report_Discard,
  Landslide_Report_Submit,
  Landslide_Report_Approved,
  Landslide_Report_Disapproved,
  Landslide_Updated_Basic_Section,
  Landslide_Report_Verify,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  landslideResetButtonLoading,
  landslideSetAllSection,
  landslideSetDataSaved,
  landslideSetParentId,
  landslideSetRedirectToListing,
  setExportLandslide,
  setLandslideListing,
  setLandslideDistrictReviewList,
  setExportDistrictCumulativeLandslideReport,
  setExportStateCumulativeLandslideReport,
  setExportFOLevelLandslideReport,
  setLandslideStateReviewList,
} from "redux/actions/Landslide";
import {
  GET_ALL_LANDSLIDE,
  GET_EXPORT_LANDSLIDE_DATA,
  LANDSLIDE_ADD_BASIC_SECTION,
  LANDSLIDE_ADD_INFDAMAGEOTHER_SECTION,
  LANDSLIDE_ADD_INFDAMAGE_SECTION,
  LANDSLIDE_ADD_REMARK_SECTION,
  LANDSLIDE_EDIT_BASIC_SECTION,
  LANDSLIDE_GET_ALL_SECTION,
  LANDSLIDE_IMPORT,
  LANDSLIDE_REPORT_DISCARD,
  LANDSLIDE_REPORT_SUBMIT,
  LANDSLIDE_ADD_POPAFFECTED_SECTION,
  LANDSLIDE_ADD_ERODED_SECTION,
  LANDSLIDE_ADD_RELIEFCAMP_SECTION,
  LANDSLIDE_ADD_CAMPINMATES_SECTION,
  LANDSLIDE_ADD_HLL_SECTION,
  LANDSLIDE_ADD_LIVESTOCK_SECTION,
  LANDSLIDE_ADD_HOUSEDAMAGED_SECTION,
  LANDSLIDE_ADD_RESCUEOPERATION_SECTION,
  LANDSLIDE_ADD_RELIEFDISTRIBUTION_SECTION,
  GET_LANDSLIDE_DISTRICT_REVIEW_LIST,
  GET_LANDSLIDE_STATE_REVIEW_LIST,
  EXPORT_FO_LEVEL_LANDSLIDE_REPORT,
  LANDSLIDE_REVENUE_REPORT_APPROVE,
  LANDSLIDE_REVENUE_REPORT_DISAPPROVE,
  LANDSLIDE_REVENUE_REPORT_VERIFY,
  LANDSLIDE_DISTRICT_REPORT_APPROVE,
  LANDSLIDE_DISTRICT_REPORT_DISAPPROVE,
  LANDSLIDE_DISTRICT_REPORT_VERIFY,
  LANDSLIDE_STATE_REPORT_APPROVE,
  LANDSLIDE_STATE_REPORT_DISAPPROVE,
  GET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT,
  GET_STATE_CUMULATIVE_LANDSLIDE_REPORT,
} from "redux/constants/Landslide";
import LandslideService from "services/LandslideService";

//#region Method for listing

/**
 * Method for getting the landslides
 */
export function* getAllLandslide() {
  yield takeEvery(GET_ALL_LANDSLIDE, function* ({ payload }) {
    try {
      const landslide = yield call(LandslideService.getAllLandslide, payload);
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(setLandslideListing(landslide));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the landslide export details
 */
export function* getLandslideExport() {
  yield takeEvery(GET_EXPORT_LANDSLIDE_DATA, function* () {
    try {
      const landslide = yield call(LandslideService.getExportLandslideData);
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(landslide),
        };
        yield put(setExportLandslide(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for landslide import page

/**
 * Method for importing the landslide
 */
export function* landslideImport() {
  yield takeEvery(LANDSLIDE_IMPORT, function* ({ payload }) {
    try {
      const landslide = yield call(LandslideService.landslideImport, payload);
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: Landslide_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for landslide import page

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/**
 * Method for getting the all section data
 */
export function* landslideGetAllSection() {
  yield takeEvery(LANDSLIDE_GET_ALL_SECTION, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        LandslideService.landslideGetAllSection,
        payload.id,
        payload.param
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(landslideSetAllSection(infDepartment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for submitting the report
 */
export function* landslideReportSubmit() {
  yield takeEvery(LANDSLIDE_REPORT_SUBMIT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        LandslideService.landslideReportSubmit,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: Landslide_Report_Submit, type: Success })
        );
        yield put(landslideSetRedirectToListing(true));
        yield put(landslideResetButtonLoading({ section: SubmitButton }));
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: SubmitButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for discarding the report
 */
export function* landslideReportDiscard() {
  yield takeEvery(LANDSLIDE_REPORT_DISCARD, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        LandslideService.landslideReportDiscard,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: Landslide_Report_Discard, type: Success })
        );
        yield put(landslideSetRedirectToListing(true));
        yield put(landslideResetButtonLoading({ section: DiscardButton }));
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: DiscardButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding basic section
 */
export function* landslideAddBasicSection() {
  yield takeEvery(LANDSLIDE_ADD_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };

      const landslide = yield call(
        LandslideService.landslideAddBasicSection,
        final
      );
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(landslideSetParentId(landslide.data.id));
        yield put(landslideResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({ message: Landslide_Added_Basic_Section, type: Success })
        );
        yield put(
          landslideSetDataSaved({ section: BasicSection, value: true })
        );
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for editing basic section
 */
export function* landslideEditBasicSection() {
  yield takeEvery(LANDSLIDE_EDIT_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const landslide = yield call(
        LandslideService.landslideEditBasicSection,
        payload.id,
        final
      );
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(landslideResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: Landslide_Updated_Basic_Section,
            type: Success,
          })
        );
        yield put(
          landslideSetDataSaved({ section: BasicSection, value: true })
        );
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Basic Section

//#region InfDamage Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding infDamage section
 */
export function* landslideAddInfDamageSection() {
  yield takeEvery(LANDSLIDE_ADD_INFDAMAGE_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const landslide = yield call(
        LandslideService.landslideAddInfDamageSection,
        final
      );
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(landslideResetButtonLoading({ section: InfDamageSection }));
        yield put(
          showMessage({
            message: Landslide_Added_InfDamage_Section,
            type: Success,
          })
        );
        yield put(
          landslideSetDataSaved({ section: InfDamageSection, value: true })
        );
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: InfDamageSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding infDamageOther section
 */
export function* landslideAddInfDamageOtherSection() {
  yield takeEvery(
    LANDSLIDE_ADD_INFDAMAGEOTHER_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const landslide = yield call(
          LandslideService.landslideAddInfDamageOtherSection,
          final
        );
        if (landslide.error != null && landslide.error.message) {
          yield put(
            showMessage({ message: landslide.error.message, type: Error })
          );
        } else {
          yield put(
            landslideResetButtonLoading({ section: InfDamageOtherSection })
          );
          yield put(
            showMessage({
              message: Landslide_Added_InfDamageOther_Section,
              type: Success,
            })
          );
          yield put(
            landslideSetDataSaved({
              section: InfDamageOtherSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          landslideResetButtonLoading({ section: InfDamageOtherSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion InfDamageOther Section

//#region Remark Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding Remark section
 */
export function* landslideAddRemarkSection() {
  yield takeEvery(LANDSLIDE_ADD_REMARK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const Landslide = yield call(
        LandslideService.landslideAddRemarkSection,
        final
      );
      if (Landslide.error != null && Landslide.error.message) {
        yield put(
          showMessage({ message: Landslide.error.message, type: Error })
        );
      } else {
        yield put(landslideResetButtonLoading({ section: RemarkSection }));
        yield put(
          showMessage({
            message: Landslide_Added_Remark_Section,
            type: Success,
          })
        );
        yield put(
          landslideSetDataSaved({ section: RemarkSection, value: true })
        );
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: RemarkSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Remark Section

//#region PopAffected Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding popaffected section
 */
export function* landslideAddPopAffectedSection() {
  yield takeEvery(LANDSLIDE_ADD_POPAFFECTED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const landslide = yield call(
        LandslideService.landslideAddPopAffectedSection,
        final
      );
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(landslideResetButtonLoading({ section: PopAffectedSection }));
        yield put(
          showMessage({
            message: Landslide_Added_PopAffected_Section,
            type: Success,
          })
        );
        yield put(
          landslideSetDataSaved({ section: PopAffectedSection, value: true })
        );
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: PopAffectedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion PopAffected Section

//#region Eroded Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding eroded section
 */
export function* landslideAddErodedSection() {
  yield takeEvery(LANDSLIDE_ADD_ERODED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const landslide = yield call(
        LandslideService.landslideAddErodedSection,
        final
      );
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(landslideResetButtonLoading({ section: ErodedSection }));
        yield put(
          showMessage({
            message: Landslide_Added_Eroded_Section,
            type: Success,
          })
        );
        yield put(
          landslideSetDataSaved({ section: ErodedSection, value: true })
        );
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: ErodedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Eroded Section

//#region Relief Camp Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding relief camp section
 */
export function* landslideAddReliefCampSection() {
  yield takeEvery(LANDSLIDE_ADD_RELIEFCAMP_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const landslide = yield call(
        LandslideService.landslideAddReliefCampSection,
        final
      );
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(landslideResetButtonLoading({ section: ReliefCampSection }));
        yield put(
          showMessage({
            message: Landslide_Added_ReliefCamp_Section,
            type: Success,
          })
        );
        yield put(
          landslideSetDataSaved({ section: ReliefCampSection, value: true })
        );
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: ReliefCampSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Relief Camp Section

//#region Camp inmates Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding camp inmates section
 */
export function* landslideAddCampInmatesSection() {
  yield takeEvery(LANDSLIDE_ADD_CAMPINMATES_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const landslide = yield call(
        LandslideService.landslideAddCampInmatesSection,
        final
      );
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(landslideResetButtonLoading({ section: CampInmatesSection }));
        yield put(
          showMessage({
            message: Landslide_Added_CampInmates_Section,
            type: Success,
          })
        );
        yield put(
          landslideSetDataSaved({ section: CampInmatesSection, value: true })
        );
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: CampInmatesSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Camp Inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding Human Live Lost section
 */
export function* landslideAddHLLSection() {
  yield takeEvery(LANDSLIDE_ADD_HLL_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const landslide = yield call(
        LandslideService.landslideAddHLLSection,
        final
      );
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(landslideResetButtonLoading({ section: HllSection }));
        yield put(
          showMessage({
            message: Landslide_Added_HLL_Section,
            type: Success,
          })
        );
        yield put(landslideSetDataSaved({ section: HllSection, value: true }));
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: HllSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding LiveStock section
 */
export function* landslideAddLiveStockSection() {
  yield takeEvery(LANDSLIDE_ADD_LIVESTOCK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const landslide = yield call(
        LandslideService.landslideAddLiveStockSection,
        final
      );
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(landslideResetButtonLoading({ section: LiveStockSection }));
        yield put(
          showMessage({
            message: Landslide_Added_LiveStock_Section,
            type: Success,
          })
        );
        yield put(
          landslideSetDataSaved({ section: LiveStockSection, value: true })
        );
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: LiveStockSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding HouseDamaged section
 */
export function* landslideAddHouseDamagedSection() {
  yield takeEvery(LANDSLIDE_ADD_HOUSEDAMAGED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const landslide = yield call(
        LandslideService.landslideAddHouseDamagedSection,
        final
      );
      if (landslide.error != null && landslide.error.message) {
        yield put(
          showMessage({ message: landslide.error.message, type: Error })
        );
      } else {
        yield put(
          landslideResetButtonLoading({ section: HouseDamagedSection })
        );
        yield put(
          showMessage({
            message: Landslide_Added_HouseDamaged_Section,
            type: Success,
          })
        );
        yield put(
          landslideSetDataSaved({ section: HouseDamagedSection, value: true })
        );
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: HouseDamagedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding RescueOperation section
 */
export function* landslideAddRescueOperationSection() {
  yield takeEvery(
    LANDSLIDE_ADD_RESCUEOPERATION_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const landslide = yield call(
          LandslideService.landslideAddRescueOperationSection,
          final
        );
        if (landslide.error != null && landslide.error.message) {
          yield put(
            showMessage({ message: landslide.error.message, type: Error })
          );
        } else {
          yield put(
            landslideResetButtonLoading({ section: RescueOperationSection })
          );
          yield put(
            showMessage({
              message: Landslide_Added_RescueOperation_Section,
              type: Success,
            })
          );
          yield put(
            landslideSetDataSaved({
              section: RescueOperationSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          landslideResetButtonLoading({ section: RescueOperationSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date: 08-12-2022

/** Method for adding ReliefDistribution section
 */
export function* landslideAddReliefDistributionSection() {
  yield takeEvery(
    LANDSLIDE_ADD_RELIEFDISTRIBUTION_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const landslide = yield call(
          LandslideService.landslideAddReliefDistributionSection,
          final
        );
        if (landslide.error != null && landslide.error.message) {
          yield put(
            showMessage({ message: landslide.error.message, type: Error })
          );
        } else {
          yield put(
            landslideResetButtonLoading({ section: ReliefDistributionSection })
          );
          yield put(
            showMessage({
              message: Landslide_Added_ReliefDistribution_Section,
              type: Success,
            })
          );
          yield put(
            landslideSetDataSaved({
              section: ReliefDistributionSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          landslideResetButtonLoading({ section: ReliefDistributionSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion ReliefDistribution Section

/**
 * The below method is for exporting the report in landslide view form
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */

/**
 * Method for getting the district export details
 */
export function* getExportFoLevelLandslideReport() {
  yield takeEvery(EXPORT_FO_LEVEL_LANDSLIDE_REPORT, function* ({ payload }) {
    try {
      const exportedReport = yield call(
        LandslideService.getExportFoLevelLandslideReport,
        payload
      );

      if (exportedReport.error != null && exportedReport.error.message) {
        yield put(
          showMessage({ message: exportedReport.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(exportedReport),
          format: payload.format,
        };
        yield put(setExportFOLevelLandslideReport(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#region Method for Approval and Disapproval
/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for approving the revenue report
 */
export function* landslideRevenueReportApprove() {
  yield takeEvery(LANDSLIDE_REVENUE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const landslideReportStatus = yield call(
        LandslideService.landslideRevenueReportApprove,
        payload
      );
      if (
        landslideReportStatus.error != null &&
        landslideReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: landslideReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Landslide_Report_Approved, type: Success })
        );
        yield put(landslideSetRedirectToListing(true));
        yield put(landslideResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the revenue report
 */
export function* landslideRevenueReportDisapprove() {
  yield takeEvery(LANDSLIDE_REVENUE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const landslideReportStatus = yield call(
        LandslideService.landslideRevenueReportDisapprove,
        payload
      );
      if (
        landslideReportStatus.error != null &&
        landslideReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: landslideReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Landslide_Report_Disapproved, type: Success })
        );
        yield put(landslideSetRedirectToListing(true));
        yield put(landslideResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: disapproveButton }));
    }
  });
}

/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for verify the revenue report
 */
export function* landslideRevenueReportVerify() {
  yield takeEvery(LANDSLIDE_REVENUE_REPORT_VERIFY, function* ({ payload }) {
    try {
      const landslideReportStatus = yield call(
        LandslideService.landslideRevenueReportVerify,
        payload
      );
      if (
        landslideReportStatus.error != null &&
        landslideReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: landslideReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Landslide_Report_Verify, type: Success })
        );
        yield put(landslideSetRedirectToListing(true));
        yield put(landslideResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* landslideDistrictReportApprove() {
  yield takeEvery(LANDSLIDE_DISTRICT_REPORT_APPROVE, function* ({ payload }) {
    try {
      const landslideReportStatus = yield call(
        LandslideService.landslideDistrictReportApprove,
        payload
      );
      if (
        landslideReportStatus.error != null &&
        landslideReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: landslideReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Landslide_Report_Approved, type: Success })
        );
        yield put(landslideSetRedirectToListing(true));
        yield put(landslideResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* landslideDistrictReportDisapprove() {
  yield takeEvery(
    LANDSLIDE_DISTRICT_REPORT_DISAPPROVE,
    function* ({ payload }) {
      try {
        const landslideReportStatus = yield call(
          LandslideService.landslideDistrictReportDisapprove,
          payload
        );
        if (
          landslideReportStatus.error != null &&
          landslideReportStatus.error.message
        ) {
          yield put(
            showMessage({
              message: landslideReportStatus.error.message,
              type: Error,
            })
          );
        } else {
          yield put(
            showMessage({
              message: Landslide_Report_Disapproved,
              type: Success,
            })
          );
          yield put(landslideSetRedirectToListing(true));
          yield put(landslideResetButtonLoading({ section: disapproveButton }));
        }
      } catch (err) {
        yield put(landslideResetButtonLoading({ section: disapproveButton }));
      }
    }
  );
}

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* landslideDistrictReportVerify() {
  yield takeEvery(LANDSLIDE_DISTRICT_REPORT_VERIFY, function* ({ payload }) {
    try {
      const landslideReportStatus = yield call(
        LandslideService.landslideDistrictReportVerify,
        payload
      );
      if (
        landslideReportStatus.error != null &&
        landslideReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: landslideReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Landslide_Report_Verify, type: Success })
        );
        yield put(landslideSetRedirectToListing(true));
        yield put(landslideResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* landslideStateReportApprove() {
  yield takeEvery(LANDSLIDE_STATE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const landslideReportStatus = yield call(
        LandslideService.landslideStateReportApprove,
        payload
      );
      if (
        landslideReportStatus.error != null &&
        landslideReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: landslideReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Landslide_Report_Approved, type: Success })
        );
        yield put(landslideSetRedirectToListing(true));
        yield put(landslideResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* landslideStateReportDisapprove() {
  yield takeEvery(LANDSLIDE_STATE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const landslideReportStatus = yield call(
        LandslideService.landslideStateReportDisapprove,
        payload
      );
      if (
        landslideReportStatus.error != null &&
        landslideReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: landslideReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Landslide_Report_Disapproved, type: Success })
        );
        yield put(landslideSetRedirectToListing(true));
        yield put(landslideResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(landslideResetButtonLoading({ section: disapproveButton }));
    }
  });
}
//#endregion Method for Approval and Disapproval

//#region
/**
 * The below method is for exporting the report in landslide cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */

/**
 * Method for getting the cumulative state export details
 */
export function* getExportStateCumulativeLandslideReport() {
  yield takeEvery(
    GET_STATE_CUMULATIVE_LANDSLIDE_REPORT,
    function* ({ payload }) {
      try {
        const exportedReport = yield call(
          LandslideService.getExportStateCumulativeLandslideReport,
          payload
        );
        if (exportedReport.error != null && exportedReport.error.message) {
          yield put(
            showMessage({ message: exportedReport.error.message, type: Error })
          );
        } else {
          if (payload?.sendEmail) {
            // Convert the Blob response to text
            const textResponse = yield exportedReport?.text();
            const parsedResponse = JSON.parse(textResponse);
            yield put(
              showMessage({
                message:
                  parsedResponse?.message,
                type: Success,
              })
            );
          } else {
            let exportData = {
              reportData:
                payload.format === "html"
                  ? exportedReport
                  : window.URL.createObjectURL(exportedReport),
              format: payload.format,
            };
            yield put(setExportStateCumulativeLandslideReport(exportData));
          }
        }
      } catch (err) {
        let errPromise = yield err.response.data.text();
        yield put(
          showMessage({
            message: JSON.parse(errPromise).error.message,
            type: Error,
          })
        );
      }
    }
  );
}
//#endregion

/**
 * Method for getting the cumulative district export details
 */
export function* getExportDistrictCumulativeLandslideReport() {
  yield takeEvery(
    GET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT,
    function* ({ payload }) {
      try {
        const exportedReport = yield call(
          LandslideService.getExportDistrictCumulativeLandslideReport,
          payload
        );
        if (exportedReport.error != null && exportedReport.error.message) {
          yield put(
            showMessage({ message: exportedReport.error.message, type: Error })
          );
        } else {
          let exportData = {
            reportData:
              payload.format === "html"
                ? exportedReport
                : window.URL.createObjectURL(exportedReport),
            format: payload.format,
          };
          yield put(setExportDistrictCumulativeLandslideReport(exportData));
        }
      } catch (err) {
        let errPromise = yield err.response.data.text();
        yield put(
          showMessage({
            message: JSON.parse(errPromise).error.message,
            type: Error,
          })
        );
      }
    }
  );
}
//#endregion

/**
 * Method for getting the district review list
 */
export function* getLandslideDistrictReviewList() {
  yield takeEvery(GET_LANDSLIDE_DISTRICT_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        LandslideService.getLandslideDistrictReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setLandslideDistrictReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the state review list
 */
export function* getLandslideStateReviewList() {
  yield takeEvery(GET_LANDSLIDE_STATE_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        LandslideService.getLandslideStateReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setLandslideStateReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for Approval and Disapproval

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllLandslide),
    fork(getLandslideExport),
    fork(landslideImport),
    fork(landslideAddBasicSection),
    fork(landslideEditBasicSection),
    fork(landslideGetAllSection),
    fork(landslideAddInfDamageSection),
    fork(landslideAddInfDamageOtherSection),
    fork(landslideAddRemarkSection),
    fork(landslideReportSubmit),
    fork(landslideReportDiscard),
    fork(landslideAddPopAffectedSection),
    fork(landslideAddErodedSection),
    fork(landslideAddReliefCampSection),
    fork(landslideAddCampInmatesSection),
    fork(landslideAddHLLSection),
    fork(landslideAddLiveStockSection),
    fork(landslideAddHouseDamagedSection),
    fork(landslideAddRescueOperationSection),
    fork(landslideAddReliefDistributionSection),
    fork(landslideRevenueReportApprove),
    fork(landslideRevenueReportDisapprove),
    fork(landslideRevenueReportVerify),
    fork(landslideDistrictReportApprove),
    fork(landslideDistrictReportDisapprove),
    fork(landslideDistrictReportVerify),
    fork(landslideStateReportApprove),
    fork(landslideStateReportDisapprove),
    fork(getExportFoLevelLandslideReport),
    fork(getLandslideDistrictReviewList),
    fork(getLandslideStateReviewList),
    fork(getExportStateCumulativeLandslideReport),
    fork(getExportDistrictCumulativeLandslideReport),
  ]);
}
