/**
 * The below file contains the constants used in the Unit master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

/**Constants for Unit Listing page */
export const GET_ALL_UNIT = "GET_ALL_UNIT";
export const SET_UNIT_LISTING = "SET_UNIT_LISTING";
export const SET_UNIT_LISTING_PARAM = "SET_UNIT_LISTING_PARAM";
export const UPDATE_UNIT_STATUS = "UPDATE_UNIT_STATUS";
export const CHANGE_UNIT_STATUS = "CHANGE_UNIT_STATUS";
export const GET_EXPORT_UNIT_DATA = "GET_EXPORT_UNIT_DATA";
export const SET_EXPORT_UNIT_DATA = "SET_EXPORT_UNIT_DATA";
/**Constants for Unit Listing page */

/**Constants for Unit Add page */
export const ADD_UNIT = "ADD_UNIT";
/**Constants for Unit Add page */

/**Constants for Unit Edit page */
export const EDIT_UNIT = "EDIT_UNIT";
export const GET_UNIT = "GET_UNIT";
export const SET_UNIT = "SET_UNIT";
/**Constants for Unit Edit page */

/**Constants for Unit Import page */
export const UNIT_IMPORT = "UNIT_IMPORT";
/**Constants for Unit Import page */
