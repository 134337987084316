/**
 * The below file contains the constants used in the Station
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

/**Constants for Station */
export const GET_ALL_STATION = "GET_ALL_STATION";
export const SET_ALL_STATION = "SET_ALL_STATION";
export const SET_STATION_LISTING_PARAM = "SET_STATION_LISTING_PARAM";
/**Constants for Station */
