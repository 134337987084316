/**
 * The below file contains the fire cause state objects
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { AUTH_TOKEN } from "redux/constants/Auth";
import { SET_ALL_FLOOD_DEATH_CAUSE } from "redux/constants/FloodDeathCause";

//Initial state
const initState = {
  // Keys for dropdown 
  floodDeathCauses: [],
  isFloodDeathCausesDataFetched: false,
  // Keys for dropdown 
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const floodDeathCause = (state = initState, action) => {
  switch (action.type) {
    //Cases for Fire cause dropdown
    case SET_ALL_FLOOD_DEATH_CAUSE:
      return {
        ...state,
        floodDeathCauses: action.payload,
        isFloodDeathCausesDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };
    //Cases for Fire cause dropdown

    default:
      return state;
  }
};

export default floodDeathCause;
