/**
 * File contains the sagas method related to Apache Superset
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 24-03-2023
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  setGuestToken,
  setSupersetLoginResponse,
} from "../actions/ApacheSuperset";

import ApacheSupersetService from "services/ApacheSupersetService";
import { showMessage } from "redux/actions/Common";
import { Error } from "constants/ApiConstant";
import { GET_GUEST_TOKEN, SUPERSET_LOGIN } from "../constants/ApacheSuperset";

export function* supersetLogin() {
  yield takeEvery(SUPERSET_LOGIN, function* ({ payload }) {
    try {
      let login = yield call(ApacheSupersetService.supersetLogin, payload);
      if (login.error != null && login.error.message) {
        yield put(showMessage({ message: login.error.message, type: Error }));
      } else {
        yield put(setSupersetLoginResponse(login));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

export function* getGuestToken() {
  yield takeEvery(GET_GUEST_TOKEN, function* ({ payload }) {
    try {
      let response = yield call(ApacheSupersetService.getGuestToken, payload);
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({ message: response.error.message, type: Error })
        );
      } else {
        yield put(setGuestToken(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(supersetLogin), fork(getGuestToken)]);
}
