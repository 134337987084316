/**
 * The below file contains the Sagas method for Role.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-02-2023
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_ROLE,
  EDIT_ROLE,
  GET_ALL_ROLE,
  GET_ROLE,
  GET_EXPORT_ROLE_DATA,
  GET_ALL_PERMISSION,
  GET_ALL_ROLE_ID,
} from "../constants/Role";

import RoleService from "services/RoleService";
import {
  setRole,
  setRoleListing,
  setExportRole,
  updateRoleStatus,
} from "redux/actions/Role";
import { showMessage } from "redux/actions/Common";
import { Role_Added, Role_Updated } from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";
import { setAllRoleId, setPermission } from "../actions/Role";

//#region Method for role listing

/**
 * Method for getting the role details
 */
export function* getAllRole() {
  yield takeEvery(GET_ALL_ROLE, function* ({ payload }) {
    try {
      const role = yield call(RoleService.getAllRole, payload);
      if (role.error != null && role.error.message) {
        yield put(showMessage({ message: role.error.message, type: Error }));
      } else {
        yield put(setRoleListing(role));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the role export details
 */
export function* getRoleExport() {
  yield takeEvery(GET_EXPORT_ROLE_DATA, function* () {
    try {
      const role = yield call(RoleService.getExportRoleData);
      if (role.error != null && role.error.message) {
        yield put(showMessage({ message: role.error.message, type: Error }));
      } else {
        let exportData = {
          url: window.URL.createObjectURL(role),
        };
        yield put(setExportRole(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for role listing

//#region Method for role add page

/**
 * Method for adding the role
 */
export function* addRole() {
  yield takeEvery(ADD_ROLE, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const role = yield call(RoleService.addRole, final);
      if (role.error != null && role.error.message) {
        yield put(showMessage({ message: role.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Role_Added, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for role add page

//#region Method for role edit page

/**
 * Method for editing the role
 */
export function* editRole() {
  yield takeEvery(EDIT_ROLE, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          roleName: payload.roleName,
          roleCode: payload.roleCode,
          status: payload.status,
          state: payload.state,
        },
      };
      const role = yield call(RoleService.editRole, id, final);
      if (role.error != null && role.error.message) {
        yield put(showMessage({ message: role.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Role_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the role detail
 */
export function* getRole() {
  yield takeEvery(GET_ROLE, function* ({ payload }) {
    try {
      const role = yield call(RoleService.getRole, payload);
      if (role.error != null && role.error.message) {
        yield put(showMessage({ message: role.error.message, type: Error }));
      } else {
        yield put(setRole(role));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for role edit page

/**
 * Method for getting all permission
 */
export function* getPermission() {
  yield takeEvery(GET_ALL_PERMISSION, function* ({ payload }) {
    try {
      const role = yield call(RoleService.getPermission, payload);
      if (role.error != null && role.error.message) {
        yield put(showMessage({ message: role.error.message, type: Error }));
      } else {
        yield put(setPermission(role));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the id of all roles
 */
export function* getAllRolesId() {
  yield takeEvery(GET_ALL_ROLE_ID, function* ({ payload }) {
    try {
      const role = yield call(RoleService.getAllRoleId, payload);
      if (role.error != null && role.error.message) {
        yield put(showMessage({ message: role.error.message, type: Error }));
      } else {
        yield put(setAllRoleId(role));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllRole),
    fork(addRole),
    fork(editRole),
    fork(getRole),
    fork(getRoleExport),
    fork(getPermission),
    fork(getAllRolesId),
  ]);
}
