/**
 * The below file contains the action methods for CWC master.
 * @author: vishal.mishra@velsof.com
 * @date : 29-09-2023
 */

import {
  ADD_CWC,
  CHANGE_CWC_STATUS,
  CWC_IMPORT,
  EDIT_CWC,
  GET_ALL_CWC,
  GET_CWC,
  GET_EXPORT_CWC_DATA,
  SET_CWC,
  SET_CWC_LISTING,
  SET_CWC_LISTING_PARAM,
  SET_EXPORT_CWC_DATA,
  UPDATE_CWC_STATUS,
} from "../constants/Cwc";

//#region Action methods for CWC listing page

/**
 * Method for getting the CWC listing
 * @param {any} params CWC Params
 * @returns Json Object
 */
export const getAllCWC = (params) => {
  return {
    type: GET_ALL_CWC,
    payload: params,
  };
};

/**
 * Method for setting the CWC in the state
 * @param {any} CWC CWC Data
 * @returns Json Object
 */
export const setCWCListing = (CWC) => {
  return {
    type: SET_CWC_LISTING,
    payload: CWC,
  };
};

/**
 * Method for setting the CWC parameters
 * @param {any} data CWC Params
 * @returns Json Object
 */
export const setCWCTableParam = (data) => {
  return {
    type: SET_CWC_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the CWC status
 * @param {any} data CWC Data
 * @returns Json Object
 */
export const updateCWCStatus = (data) => {
  return {
    type: UPDATE_CWC_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the CWC status
 * @param {any} data CWC Data
 * @returns Json Object
 */
export const changeCWCStatus = (data) => {
  return {
    type: CHANGE_CWC_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of CWC
 * @returns Json Object
 */
export const getExportCWC = () => {
  return {
    type: GET_EXPORT_CWC_DATA,
  };
};

/**
 * Method for setting the export details in the state
 * @param {any} data Export Data
 * @returns Json Object
 */
export const setExportCWC = (data) => {
  return {
    type: SET_EXPORT_CWC_DATA,
    payload: data,
  };
};
//#endregion Action methods for CWC listing page

//#region Action methods for CWC add page
/**
 * Method for adding the CWC
 * @param {any} data CWC Data
 * @returns Json Object
 */
export const addCWC = (data) => {
  return {
    type: ADD_CWC,
    payload: data,
  };
};
//#endregion Action methods for CWC add page

//#region Action methods for CWC edit page

/**
 * Method for editing the CWC
 * @param {any} data CWC Data
 * @returns Json Object
 */
export const editCWC = (data) => {
  return {
    type: EDIT_CWC,
    payload: data,
  };
};

/**
 * Method for getting the CWC details
 * @param {any} id CWC Id
 * @returns Json Object
 */
export const getCWC = (id) => {
  return {
    type: GET_CWC,
    payload: id,
  };
};

/**
 * Method for setting the CWC details in the state
 * @param {any} CWC CWC Data
 * @returns Json Object
 */
export const setCWC = (CWC) => {
  return {
    type: SET_CWC,
    payload: CWC,
  };
};
//#endregion Action methods for CWC edit page

//#region Action methods for CWC edit page
/**
 * Method for CWC import
 * @param {any} data CWC Data
 * @returns Json Object
 */
export const CWCImport = (data) => {
  return {
    type: CWC_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for CWC import page
