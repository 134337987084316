/**
 * The below file contains the fire reports state objects
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */

import {
  BasicSection,
  CallerDetailsSection,
  CampInmatesSection,
  DiscardButton,
  ErodedSection,
  HllSection,
  HouseDamagedSection,
  InfDamageOtherSection,
  InfDamageSection,
  LiveStockSection,
  PopAffectedSection,
  ReliefCampSection,
  ReliefDistributionSection,
  RemarkSection,
  RescueOperationSection,
  SubmitButton,
  TurnOutDetailsSection,
  FireDetailsSection,
  PropertyInvolvedSection,
  OwnerOfPropertySection,
  EmergencyServicePersonnelLifeSection,
  ESPLSection,
} from "constants/ApplicationConstant";
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_FIRE_DATA,
  FIRE_ADD_BASIC_SECTION,
  FIRE_ADD_REMARK_SECTION,
  FIRE_EDIT_BASIC_SECTION,
  FIRE_REPORT_DISCARD,
  FIRE_REPORT_SUBMIT,
  FIRE_RESET_BUTTON_LOADING,
  FIRE_SET_ALL_SECTION,
  FIRE_SET_DATA_SAVED,
  FIRE_SET_NIL_REPORTING,
  FIRE_SET_PARENT_ID,
  FIRE_SET_REDIRECT_TO_LISTING,
  SET_EXPORT_FIRE_DATA,
  SET_FIRE_LISTING,
  SET_FIRE_LISTING_PARAM,
  FIRE_ADD_HLL_SECTION,
  FIRE_ADD_LIVESTOCK_SECTION,
  FIRE_ADD_HOUSEDAMAGED_SECTION,
  FIRE_ADD_RESCUEOPERATION_SECTION,
  FIRE_ADD_CALLER_DETAILS_SECTION,
  FIRE_SET_DELETED_IMAGE_RESPONSE,
  FIRE_ADD_ESPL_SECTION,
  FIRE_ADD_OWNER_OF_PROPERTY_SECTION,
  FIRE_ADD_PROPERTY_INVOLVED_SECTION,
  FIRE_ADD_FIRE_DETAILS_SECTION,
  FIRE_ADD_TURN_OUT_DETAILS_SECTION,
  FIRE_SET_INCIDENT_PHOTO_RESPONSE,
  SET_EXPORT_FS_LEVEL_FIRE_REPORT,
} from "redux/constants/Fire";

//Initial state
const initState = {
  //Keys for listing
  fireList: {},
  isFireDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  fireListingParam: {},
  exportFsLevelFireReport: {
    report: "",
    format: "",
  },
  //Keys for listing

  //Keys for All Section Data
  fireParentId: "",
  fireAllSectionData: {},
  fireButtonLoading: {
    basicSection: false,
    callerDetailsSection: false,
    turnOutDetailsSection: false,
    fireDetailsSection: false,
    propertyInvolvedSection: false,
    ownerOfPropertySection: false,
    houseDamagedSection: false,
    hLLSection: false,
    liveStockSection: false,
    rescueOperationSection: false,
    ESPLSection: false,
    remarkSection: false,
    submitButton: false,
    discardButton: false,
    approveButton: false,
    disapproveButton: false,
    verifyButton: false,
  },
  fireDataSaved: {
    basicSection: false,
    callerDetailsSection: false,
    turnOutDetailsSection: false,
    fireDetailsSection: false,
    propertyInvolvedSection: false,
    ownerOfPropertySection: false,
    houseDamagedSection: false,
    hLLSection: false,
    liveStockSection: false,
    rescueOperationSection: false,
    ESPLSection: false,
    remarkSection: false,
  },
  fireIsNilReporting: false,
  fireRedirectToListing: false,
  //Keys for All Section Data

  //Keys for Approval Disapproval
  fireDistrictReviewList: {},
  fireStateReviewList: {},
  //Keys for Approval Disapproval

  // Keys for Incident Photos
  fireIncidentPhotoDeletedResponse: "",
  fireIncidentPhotoGetResponse: [],
  // Keys for Incident Photos
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const fire = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_FIRE_LISTING:
      return {
        ...state,
        fireList: action.payload,
        isFireDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case GET_EXPORT_FIRE_DATA:
      return {
        ...state,
      };

    //Cases for listing

    //Cases for All Section
    case FIRE_SET_ALL_SECTION:
      return {
        ...state,
        fireAllSectionData: action.payload,
      };

    case FIRE_RESET_BUTTON_LOADING:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            basicSection: false,
          },
        };
      } else if (action.payload.section === CallerDetailsSection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            callerDetailsSection: false,
          },
        };
      } else if (action.payload.section === TurnOutDetailsSection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            turnOutDetailsSection: false,
          },
        };
      } else if (action.payload.section === FireDetailsSection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            fireDetailsSection: false,
          },
        };
      } else if (action.payload.section === PropertyInvolvedSection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            propertyInvolvedSection: false,
          },
        };
      } else if (action.payload.section === OwnerOfPropertySection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            ownerOfPropertySection: false,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            houseDamagedSection: false,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            hLLSection: false,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            liveStockSection: false,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            rescueOperationSection: false,
          },
        };
      } else if (action.payload.section === ESPLSection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            ESPLSection: false,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            remarkSection: false,
          },
        };
      } else if (action.payload.section === SubmitButton) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            submitButton: false,
          },
        };
      } else if (action.payload.section === DiscardButton) {
        return {
          ...state,
          fireButtonLoading: {
            ...state.fireButtonLoading,
            discardButton: false,
          },
        };
      } else {
        return {
          ...state,
          fireButtonLoading: {
            basicSection: false,
            popAffectedSection: false,
            erodedSection: false,
            reliefCampSection: false,
            campInmatesSection: false,
            hLLSection: false,
            liveStockSection: false,
            houseDamagedSection: false,
            rescueOperationSection: false,
            reliefDistributionSection: false,
            infDamageSection: false,
            infDamageOtherSection: false,
            remarkSection: false,
            submitButton: false,
            discardButton: false,
            approveButton: false,
            disapproveButton: false,
            verify: false,
          },
        };
      }

    case FIRE_SET_PARENT_ID:
      return {
        ...state,
        fireParentId: action.payload,
      };

    case FIRE_SET_REDIRECT_TO_LISTING:
      return {
        ...state,
        fireRedirectToListing: action.payload,
      };

    case FIRE_SET_DATA_SAVED:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            basicSection: action.payload.value,
          },
        };
      } else if (action.payload.section === CallerDetailsSection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            callerDetailsSection: action.payload.value,
          },
        };
      } else if (action.payload.section === TurnOutDetailsSection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            turnOutDetailsSection: action.payload.value,
          },
        };
      } else if (action.payload.section === FireDetailsSection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            fireDetailsSection: action.payload.value,
          },
        };
      } else if (action.payload.section === PropertyInvolvedSection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            propertyInvolvedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === OwnerOfPropertySection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            ownerOfPropertySection: action.payload.value,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            houseDamagedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            hLLSection: action.payload.value,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            liveStockSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            rescueOperationSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ESPLSection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            ESPLSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          fireDataSaved: {
            ...state.fireDataSaved,
            remarkSection: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case FIRE_REPORT_SUBMIT:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          submitButton: true,
        },
      };

    case FIRE_REPORT_DISCARD:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          discardButton: true,
        },
      };
    //Cases for All Section

    //Cases for Basic Section
    case FIRE_ADD_BASIC_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          basicSection: true,
        },
      };

    case FIRE_EDIT_BASIC_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          basicSection: true,
        },
      };

    case FIRE_SET_NIL_REPORTING:
      return {
        ...state,
        fireIsNilReporting: action.payload,
      };
    //Cases for Basic Section

    // Cases for Caller Details Section
    case FIRE_ADD_CALLER_DETAILS_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          callerDetailsSection: true,
        },
      };

    //Cases for Fire Turnout Details Section
    case FIRE_ADD_TURN_OUT_DETAILS_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          turnOutDetailsSection: true,
        },
      };
    //Cases for Fire Turnout Details Section

    //Cases for Fire Details Section
    case FIRE_ADD_FIRE_DETAILS_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          fireDetailsSection: true,
        },
      };
    //Cases for Fire Details Section

    //Cases for Fire Property Involved Section
    case FIRE_ADD_PROPERTY_INVOLVED_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          propertyInvolvedSection: true,
        },
      };
    //Cases for Fire Property Involved Section

    //Cases for Fire Property Owner Section
    case FIRE_ADD_OWNER_OF_PROPERTY_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          ownerOfPropertySection: true,
        },
      };
    //Cases for Fire Property Owner Section

    //Cases for Fire House Damaged Section
    case FIRE_ADD_HOUSEDAMAGED_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          houseDamagedSection: true,
        },
      };
    //Cases for Fire House Damaged Section

    //Cases for Fire Hll Section
    case FIRE_ADD_HLL_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          hLLSection: true,
        },
      };
    //Cases for Fire Hll Section

    //Cases for Fire LiveStock Section
    case FIRE_ADD_LIVESTOCK_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          liveStockSection: true,
        },
      };
    //Cases for Fire LiveStock Section

    //Cases for Fire Rescue Operation Section
    case FIRE_ADD_RESCUEOPERATION_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          rescueOperationSection: true,
        },
      };
    //Cases for Fire Rescue Operation Section

    //Cases for ESPL Section
    case FIRE_ADD_ESPL_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          ESPLSection: true,
        },
      };
    //Cases for ESPL Section

    //Cases for Remark Section
    case FIRE_ADD_REMARK_SECTION:
      return {
        ...state,
        fireButtonLoading: {
          ...state.fireButtonLoading,
          remarkSection: true,
        },
      };
    //Cases for Remark Section

    //#region for incident photo deleted response
    case FIRE_SET_DELETED_IMAGE_RESPONSE:
      return {
        ...state,
        fireIncidentPhotoDeletedResponse: action.payload,
      };
    //#endregion

    //#region for deleted photo after response
    case FIRE_SET_INCIDENT_PHOTO_RESPONSE:
      return {
        ...state,
        fireIncidentPhotoGetResponse: action.payload,
      };
    //#endregion

    //#region
    case SET_FIRE_LISTING_PARAM:
      return {
        ...state,
        fireListingParam: action.payload,
      };
    //#endregion
    case SET_EXPORT_FS_LEVEL_FIRE_REPORT:
      return {
        ...state,
        exportFsLevelFireReport: {
          report: action.payload.url,
          format: action.payload.format,
        },
      };

    default:
      return state;
  }
};

export default fire;
