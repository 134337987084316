/**
 * The below file is the resource document service which contains method for doing the API calls.
 * @author: dhruv.kumar@velsof.com
 * @date : 28-07-2023
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_ResourceDocument,
  Application_Json,
  Authorization_Token,
  Blob,
  Edit_ResourceDocument,
  Get_All_ResourceDocument,
  Get_ResourceDocument,
  Multipart_Form_Data,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const ResourceDocumentService = {};

//#region Method for resourceDocument listing page.

/**
 * Method for getting the resourceDocument listing
 * @param {any} param API Parameter
 * @returns Fetch Method
 */
ResourceDocumentService.getAllResourceDocument = function (param) {
  return fetch({
    url: Get_All_ResourceDocument,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};
//#endregion Method for resourceDocument listing page.

//#region Method for resourceDocument add page.

/**
 * Method for adding the resourceDocument
 * @param {any} data API Data
 * @returns Fetch Method
 */
ResourceDocumentService.addResourceDocument = function (data) {
  return fetch({
    url: Add_ResourceDocument,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for resourceDocument add page.

//#region Method for resourceDocument edit page.

/**
 * Method for editing the resourceDocument
 * @param {number} id Resource Document id
 * @param {any} data API Data
 * @returns Fetch Method
 */
ResourceDocumentService.editResourceDocument = function (id, data) {
  return fetch({
    url: format(Edit_ResourceDocument, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the resourceDocument detail
 * @param {any} data API Data
 * @returns Fetch Method
 */
ResourceDocumentService.getResourceDocument = function (data, param) {
  return fetch({
    url: format(Get_ResourceDocument, data.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};
//#endregion Method for resourceDocument edit page.

export default ResourceDocumentService;
