/**
 * The below file contains the constants used in the user master.
 * @author: shivam.sharma2@velsof.com
 * @date : 05-01-2023
 */

/**Constants for user Listing page */
export const GET_ALL_USER = "GET_ALL_USER";
export const SET_USER_LISTING = "SET_USER_LISTING";
export const SET_USER_LISTING_PARAM = "SET_USER_LISTING_PARAM";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const GET_EXPORT_USER_DATA = "GET_EXPORT_USER_DATA";
export const SET_EXPORT_USER_DATA = "SET_EXPORT_USER_DATA";
/**Constants for user Listing page */

/**Constants for user Add page */
export const ADD_USER = "ADD_USER";
/**Constants for user Add page */

/**Constants for USER Edit page */
export const EDIT_USER = "EDIT_USER";
export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";
/**Constants for USER Edit page */

/**Constants for user Import page */
export const USER_IMPORT = "USER_IMPORT";
/**Constants for user Import page */

/**Constants for reset user password */
export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD";
/**Constants for reset user password */

//Constants for change password
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
