/**
 * The below file contains the wards name object
 * @author: shivam.sharma2@velsof.com
 * @date : 29-12-2022
 */

import {
  SET_ALL_WARDS
} from "redux/constants/Wards";

//Initial state
const initState = {
  //Keys for wards name 
  wardsName: [],
  //Keys for wards name 
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const Wards = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_ALL_WARDS:
      return {
        ...state,
        wardsName: action.payload.data,
      };

    default:
      return state;
  }
};

export default Wards;
