/**
 * The below file contains the action methods for storm report.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */

import {
  GET_ALL_STORM,
  GET_EXPORT_STORM_DATA,
  SET_EXPORT_STORM_DATA,
  SET_STORM_LISTING,
  SET_STORM_LISTING_PARAM,
  STORM_IMPORT,
  STORM_ADD_BASIC_SECTION,
  STORM_EDIT_BASIC_SECTION,
  STORM_ADD_INFDAMAGEOTHER_SECTION,
  STORM_GET_ALL_SECTION,
  STORM_SET_ALL_SECTION,
  STORM_RESET_BUTTON_LOADING,
  STORM_SET_PARENT_ID,
  STORM_ADD_REMARK_SECTION,
  STORM_SET_NIL_REPORTING,
  STORM_REPORT_SUBMIT,
  STORM_REPORT_DISCARD,
  STORM_SET_REDIRECT_TO_LISTING,
  STORM_SET_DATA_SAVED,
  STORM_ADD_POPAFFECTED_SECTION,
  STORM_ADD_ERODED_SECTION,
  STORM_ADD_RELIEFCAMP_SECTION,
  STORM_ADD_CAMPINMATES_SECTION,
  STORM_ADD_HLL_SECTION,
  STORM_ADD_LIVESTOCK_SECTION,
  STORM_ADD_HOUSEDAMAGED_SECTION,
  STORM_ADD_RESCUEOPERATION_SECTION,
  STORM_ADD_RELIEFDISTRIBUTION_SECTION,
  EXPORT_FO_LEVEL_STORM_REPORT,
  SET_EXPORT_FO_LEVEL_STORM_REPORT,
  STORM_REVENUE_REPORT_APPROVE,
  STORM_REVENUE_REPORT_DISAPPROVE,
  STORM_REVENUE_REPORT_VERIFY,
  GET_STORM_DISTRICT_REVIEW_LIST,
  SET_STORM_DISTRICT_REVIEW_LIST,
  GET_STORM_STATE_REVIEW_LIST,
  SET_STORM_STATE_REVIEW_LIST,
  STORM_DISTRICT_REPORT_APPROVE,
  STORM_DISTRICT_REPORT_DISAPPROVE,
  STORM_DISTRICT_REPORT_VERIFY,
  STORM_STATE_REPORT_APPROVE,
  STORM_STATE_REPORT_DISAPPROVE,
  GET_STATE_CUMULATIVE_STORM_REPORT,
  SET_STATE_CUMULATIVE_STORM_REPORT,
  GET_DISTRICT_CUMULATIVE_STORM_REPORT,
  SET_DISTRICT_CUMULATIVE_STORM_REPORT,
} from "redux/constants/Storm";

//#region Action methods for storm listing page

/**
 * Method for getting the storm listing
 * @param {any} params Storm Data
 * @returns Json Object
 */
export const getAllStorm = (params) => {
  return {
    type: GET_ALL_STORM,
    payload: params,
  };
};

/**
 * Method for setting the storm listing details in state
 * @param {any} storm Storm Data
 * @returns Json Object
 */
export const setStormListing = (storm) => {
  return {
    type: SET_STORM_LISTING,
    payload: storm,
  };
};

/**
 * Method for setting the storm parameters
 * @param {any} data Storm Data
 * @returns Json Object
 */
export const setStormTableParam = (data) => {
  return {
    type: SET_STORM_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for getting the export file of storm
 * @returns Json Object
 */
export const getExportStorm = () => {
  return {
    type: GET_EXPORT_STORM_DATA,
  };
};

/**
 * Method for setting the storm export data in the state
 * @param {any} data Storm Data
 * @returns Json Object
 */
export const setExportStorm = (data) => {
  return {
    type: SET_EXPORT_STORM_DATA,
    payload: data,
  };
};
//#endregion Action methods for storm listing page

//#region Action methods for storm import page
/**
 * Method for storm import
 * @param {any} data Storm Data
 * @returns Json Object
 */
export const stormImport = (data) => {
  return {
    type: STORM_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for storm import page

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for getting the all section details
 * @param {number} data Basic Section Data Form Id and param
 * @returns Json Object
 */
export const stormGetAllSection = (data) => {
  return {
    type: STORM_GET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const stormSetAllSection = (data) => {
  return {
    type: STORM_SET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the parent id
 * @param {number} id Parent Id
 * @returns Json Object
 */
export const stormSetParentId = (id) => {
  return {
    type: STORM_SET_PARENT_ID,
    payload: id,
  };
};

/**
 * Method for reseting the button loading status
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const stormResetButtonLoading = (data) => {
  return {
    type: STORM_RESET_BUTTON_LOADING,
    payload: data,
  };
};

/**
 * Method for submitting the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const stormReportSubmit = (data) => {
  return {
    type: STORM_REPORT_SUBMIT,
    payload: data,
  };
};

/**
 * Method for discarding the report
 * @param {object} data Report Data
 * @returns Json Object
 */
export const stormReportDiscard = (data) => {
  return {
    type: STORM_REPORT_DISCARD,
    payload: data,
  };
};

/**
 * Method for setting the redirect to listing variable
 * @param {object} data  Data
 * @returns Json Object
 */
export const stormSetRedirectToListing = (data) => {
  return {
    type: STORM_SET_REDIRECT_TO_LISTING,
    payload: data,
  };
};

/**
 * Method for setting the value of stormDataSaved object
 * @param {object} data Data
 * @returns Json Object
 */
export const stormSetDataSaved = (data) => {
  return {
    type: STORM_SET_DATA_SAVED,
    payload: data,
  };
};
//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const stormAddBasicSection = (data) => {
  return {
    type: STORM_ADD_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for editing the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const stormEditBasicSection = (data) => {
  return {
    type: STORM_EDIT_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the nil reporting value
 * @param {boolean} data Bool value
 * @returns Json Object
 */
export const stormSetNilReporting = (data) => {
  return {
    type: STORM_SET_NIL_REPORTING,
    payload: data,
  };
};
//#endregion Basic Section

//#region Infrastructure Damage Other Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the infDamageOther section details
 * @param {object} data InfDamageOther Section Data
 * @returns Json Object
 */
export const stormAddInfDamageOtherSection = (data) => {
  return {
    type: STORM_ADD_INFDAMAGEOTHER_SECTION,
    payload: data,
  };
};
//#endregion InfDamageOther Section

//#region Remark Section
/**
 * Method for adding the remarks
 * @param {object} data Remark Section Data
 * @returns Json Object
 */
export const stormAddRemarkSection = (data) => {
  return {
    type: STORM_ADD_REMARK_SECTION,
    payload: data,
  };
};
//#endregion Remark Section

//#region Population Affected Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the popAffected section details
 * @param {object} data PopAffected Section Data
 * @returns Json Object
 */
export const stormAddPopAffectedSection = (data) => {
  return {
    type: STORM_ADD_POPAFFECTED_SECTION,
    payload: data,
  };
};
//#endregion Population Affected Section

//#region Relief Camp Section
/**
 * Method for adding the Relief Camp section details
 * @param {object} data Relief Camp Section Data
 * @returns Json Object
 */
export const stormAddReliefCampSection = (data) => {
  return {
    type: STORM_ADD_RELIEFCAMP_SECTION,
    payload: data,
  };
};
//#endregion Relief Camp Section

//#region Camp Inmates Section
/**
 * Method for adding the Camp Inmates section details
 * @param {object} data Camp Inmates Section Data
 * @returns Json Object
 */
export const stormAddCampInmatesSection = (data) => {
  return {
    type: STORM_ADD_CAMPINMATES_SECTION,
    payload: data,
  };
};
//#endregion Camp Inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the Human Live Lost section details
 * @param {object} data Human Live Lost Section Data
 * @returns Json Object
 */
export const stormAddHllSection = (data) => {
  return {
    type: STORM_ADD_HLL_SECTION,
    payload: data,
  };
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the LiveStock section details
 * @param {object} data LiveStock Section Data
 * @returns Json Object
 */
export const stormAddLiveStockSection = (data) => {
  return {
    type: STORM_ADD_LIVESTOCK_SECTION,
    payload: data,
  };
};
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the HouseDamaged section details
 * @param {object} data HouseDamaged Section Data
 * @returns Json Object
 */
export const stormAddHouseDamagedSection = (data) => {
  return {
    type: STORM_ADD_HOUSEDAMAGED_SECTION,
    payload: data,
  };
};
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the RescueOperation section details
 * @param {object} data RescueOperation Section Data
 * @returns Json Object
 */
export const stormAddRescueOperationSection = (data) => {
  return {
    type: STORM_ADD_RESCUEOPERATION_SECTION,
    payload: data,
  };
};
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the ReliefDistribution section details
 * @param {object} data ReliefDistribution Section Data
 * @returns Json Object
 */
export const stormAddReliefDistributionSection = (data) => {
  return {
    type: STORM_ADD_RELIEFDISTRIBUTION_SECTION,
    payload: data,
  };
};
//#endregion ReliefDistribution Section

//#region Methods for Reports and Approval and Disapproval
/**
 * Method for getting the FO level export report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Data
 * @returns Json Object
 */
export const getExportFoLevelStormReport = (data) => {
  return {
    type: EXPORT_FO_LEVEL_STORM_REPORT,
    payload: data,
  };
};

/**
 * Method for setting the exported data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Data
 * @returns Json Object
 */
export const setExportFOLevelStormReport = (data) => {
  return {
    type: SET_EXPORT_FO_LEVEL_STORM_REPORT,
    payload: data,
  };
};

/**
 * Method for approving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const stormRevenueReportApprove = (data) => {
  return {
    type: STORM_REVENUE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const stormRevenueReportDisapprove = (data) => {
  return {
    type: STORM_REVENUE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const stormRevenueReportVerify = (data) => {
  return {
    type: STORM_REVENUE_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for getting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getStormDistrictReviewList = (data) => {
  return {
    type: GET_STORM_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setStormDistrictReviewList = (data) => {
  return {
    type: SET_STORM_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for getting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getStormStateReviewList = (data) => {
  return {
    type: GET_STORM_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setStormStateReviewList = (data) => {
  return {
    type: SET_STORM_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const stormDistrictReportApprove = (data) => {
  return {
    type: STORM_DISTRICT_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const stormDistrictReportDisapprove = (data) => {
  return {
    type: STORM_DISTRICT_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report Data
 * @returns Json Object
 */
export const stormDistrictReportVerify = (data) => {
  return {
    type: STORM_DISTRICT_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const stormStateReportApprove = (data) => {
  return {
    type: STORM_STATE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const stormStateReportDisapprove = (data) => {
  return {
    type: STORM_STATE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for exporting state cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportStateCumulativeStormReport = (data) => {
  return {
    type: GET_STATE_CUMULATIVE_STORM_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportStateCumulativeStormReport = (data) => {
  return {
    type: SET_STATE_CUMULATIVE_STORM_REPORT,
    payload: data,
  };
};

/**
 * Method for exporting district cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportDistrictCumulativeStormReport = (data) => {
  return {
    type: GET_DISTRICT_CUMULATIVE_STORM_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportDistrictCumulativeStormReport = (data) => {
  return {
    type: SET_DISTRICT_CUMULATIVE_STORM_REPORT,
    payload: data,
  };
};
//#endregion Methods for Reports and Approval and Disapproval
