/**
 * The below file contains the constants used in the infrastructure damage report.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-11-2022
 */

/**Constants for infDamage Listing page */
export const GET_ALL_INFDAMAGE = "GET_ALL_INFDAMAGE";
export const SET_INFDAMAGE_LISTING = "SET_INFDAMAGE_LISTING";
export const SET_INFDAMAGE_LISTING_PARAM = "SET_INFDAMAGE_LISTING_PARAM";
export const GET_EXPORT_INFDAMAGE_DATA = "GET_EXPORT_INFDAMAGE_DATA";
export const SET_EXPORT_INFDAMAGE_DATA = "SET_EXPORT_INFDAMAGE_DATA";
/**Constants for infDamage Listing page */

/**Constants for infDamage Import page */
export const INFDAMAGE_IMPORT = "INFDAMAGE_IMPORT";
/**Constants for infDamage Import page */

//#region All Section
/**Constants for all section details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 11-11-2022
 */
export const INFDAMAGE_GET_ALL_SECTION = "INFDAMAGE_GET_ALL_SECTION";
export const INFDAMAGE_SET_ALL_SECTION = "INFDAMAGE_SET_ALL_SECTION";
export const INFDAMAGE_RESET_BUTTON_LOADING = "INFDAMAGE_RESET_BUTTON_LOADING";
export const INFDAMAGE_SET_PARENT_ID = "INFDAMAGE_SET_PARENT_ID";
export const INFDAMAGE_REPORT_SUBMIT = "INFDAMAGE_REPORT_SUBMIT";
export const INFDAMAGE_REPORT_DISCARD = "INFDAMAGE_REPORT_DISCARD";
export const INFDAMAGE_SET_REDIRECT_TO_LISTING =
  "INFDAMAGE_SET_REDIRECT_TO_LISTING";
export const INFDAMAGE_SET_DATA_SAVED = "INFDAMAGE_SET_DATA_SAVED";
//#endregion All Section

//#region Basic Section
/**Constants for adding the basic section details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-11-2022
 */
export const INFDAMAGE_ADD_BASIC_SECTION = "INFDAMAGE_ADD_BASIC_SECTION";
export const INFDAMAGE_EDIT_BASIC_SECTION = "INFDAMAGE_EDIT_BASIC_SECTION";
export const INFDAMAGE_SET_NIL_REPORTING = "INFDAMAGE_SET_NIL_REPORTING";
//#endregion Basic Section

//#region InfDamage Section
/**Constants for adding the infrastructure damage details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 11-11-2022
 */
export const INFDAMAGE_ADD_INFDAMAGE_SECTION =
  "INFDAMAGE_ADD_INFDAMAGE_SECTION";
//#endregion InfDamage Section

//#region InfDamageOther Section
/**Constants for adding the infrastructure others damage details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 11-11-2022
 */
export const INFDAMAGE_ADD_INFDAMAGEOTHER_SECTION =
  "INFDAMAGE_ADD_INFDAMAGEOTHER_SECTION";
//#endregion InfDamageOther Section

//#region Remark Section
/**Constants for adding the Remark details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 15-11-2022
 */
export const INFDAMAGE_ADD_REMARK_SECTION = "INFDAMAGE_ADD_REMARK_SECTION";
//#endregion Remark Section
