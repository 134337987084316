/**
 * The below file contains the action methods for fire report.
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */

import {
  GET_ALL_FIRE,
  GET_EXPORT_FIRE_DATA,
  SET_EXPORT_FIRE_DATA,
  SET_FIRE_LISTING,
  SET_FIRE_LISTING_PARAM,
  FIRE_IMPORT,
  FIRE_ADD_BASIC_SECTION,
  FIRE_EDIT_BASIC_SECTION,
  FIRE_GET_ALL_SECTION,
  FIRE_SET_ALL_SECTION,
  FIRE_RESET_BUTTON_LOADING,
  FIRE_SET_PARENT_ID,
  FIRE_ADD_REMARK_SECTION,
  FIRE_SET_NIL_REPORTING,
  FIRE_REPORT_SUBMIT,
  FIRE_REPORT_DISCARD,
  FIRE_SET_REDIRECT_TO_LISTING,
  FIRE_SET_DATA_SAVED,
  FIRE_ADD_HLL_SECTION,
  FIRE_ADD_LIVESTOCK_SECTION,
  FIRE_ADD_HOUSEDAMAGED_SECTION,
  FIRE_ADD_RESCUEOPERATION_SECTION,
  FIRE_ADD_RELIEFDISTRIBUTION_SECTION,
  FIRE_ADD_CALLER_DETAILS_SECTION,
  FIRE_ADD_FIRE_DETAILS_SECTION,
  FIRE_ADD_PROPERTY_INVOLVED_SECTION,
  FIRE_ADD_TURN_OUT_DETAILS_SECTION,
  FIRE_ADD_ESPL_SECTION,
  FIRE_ADD_OWNER_OF_PROPERTY_SECTION,
  FIRE_DELETE_INCIDENT_PHOTO,
  FIRE_SET_DELETED_IMAGE_RESPONSE,
  FIRE_SET_INCIDENT_PHOTO_RESPONSE,
  FIRE_DFES_REPORT_APPROVE,
  FIRE_DFES_REPORT_DISAPPROVE,
  FIRE_SPFE_REPORT_APPROVE,
  FIRE_SPFE_REPORT_DISAPPROVE,
  FIRE_WING_REPORT_APPROVE,
  FIRE_WING_REPORT_DISAPPROVE,
  FIRE_HQCR_REPORT_APPROVE,
  FIRE_HQCR_REPORT_DISAPPROVE,
  FIRE_HQCR_REPORT_VERIFY,
} from "redux/constants/Fire";
import {
  EXPORT_FS_LEVEL_FIRE_REPORT,
  SET_EXPORT_FS_LEVEL_FIRE_REPORT,
} from "../constants/Fire";

//#region Action methods for fire listing page

/**
 * Method for getting the fire listing
 * @param {any} params Fire Data
 * @returns Json Object
 */
export const getAllFire = (params) => {
  return {
    type: GET_ALL_FIRE,
    payload: params,
  };
};

/**
 * Method for setting the fire listing details in state
 * @param {any} fire Fire Data
 * @returns Json Object
 */
export const setFireListing = (fire) => {
  return {
    type: SET_FIRE_LISTING,
    payload: fire,
  };
};

/**
 * Method for setting the fire parameters
 * @param {any} data Fire Data
 * @returns Json Object
 */
export const setFireTableParam = (data) => {
  return {
    type: SET_FIRE_LISTING_PARAM,
    payload: data,
  };
};
//#endregion Action methods for fire listing page

//#region Action methods for fire import page
/**
 * Method for fire import
 * @param {any} data Fire Data
 * @returns Json Object
 */
export const fireImport = (data) => {
  return {
    type: FIRE_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for fire import page

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 17-11-2022

/**
 * Method for getting the all section details
 * @param {number} data Basic Section Data Form Id and param
 * @returns Json Object
 */
export const fireGetAllSection = (data) => {
  return {
    type: FIRE_GET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const fireSetAllSection = (data) => {
  return {
    type: FIRE_SET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the parent id
 * @param {number} id Parent Id
 * @returns Json Object
 */
export const fireSetParentId = (id) => {
  return {
    type: FIRE_SET_PARENT_ID,
    payload: id,
  };
};

/**
 * Method for reseting the button loading status
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const fireResetButtonLoading = (data) => {
  return {
    type: FIRE_RESET_BUTTON_LOADING,
    payload: data,
  };
};

/**
 * Method for submitting the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const fireReportSubmit = (data) => {
  return {
    type: FIRE_REPORT_SUBMIT,
    payload: data,
  };
};

/**
 * Method for discarding the report
 * @param {object} data Report Data
 * @returns Json Object
 */
export const fireReportDiscard = (data) => {
  return {
    type: FIRE_REPORT_DISCARD,
    payload: data,
  };
};

/**
 * Method for setting the redirect to listing variable
 * @param {object} data  Data
 * @returns Json Object
 */
export const fireSetRedirectToListing = (data) => {
  return {
    type: FIRE_SET_REDIRECT_TO_LISTING,
    payload: data,
  };
};

/**
 * Method for setting the value of fireDataSaved object
 * @param {object} data Data
 * @returns Json Object
 */
export const fireSetDataSaved = (data) => {
  return {
    type: FIRE_SET_DATA_SAVED,
    payload: data,
  };
};
//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const fireAddBasicSection = (data) => {
  return {
    type: FIRE_ADD_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for editing the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const fireEditBasicSection = (data) => {
  return {
    type: FIRE_EDIT_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the nil reporting value
 * @param {boolean} data Bool value
 * @returns Json Object
 */
export const fireSetNilReporting = (data) => {
  return {
    type: FIRE_SET_NIL_REPORTING,
    payload: data,
  };
};
//#endregion Basic Section

//#region Caller Details Section
/**
 * Method for adding the Caller Details section details
 * @param {object} data Caller Details Section Data
 * @returns Json Object
 */
export const fireAddCallerDetailsSection = (data) => {
  return {
    type: FIRE_ADD_CALLER_DETAILS_SECTION,
    payload: data,
  };
};
//#endregion Caller Details Section

//#region Turn Out Details Section
/**
 * Method for adding the Turn Out Details section details
 * @param {object} data Turn Out Details Section Data
 * @returns Json Object
 */
export const fireAddTurnOutDetailsSection = (data) => {
  return {
    type: FIRE_ADD_TURN_OUT_DETAILS_SECTION,
    payload: data,
  };
};
//#endregion Turn Out Details Section

//#region Fire Details Section
/**
 * Method for adding the Fire Details section details
 * @param {object} data Fire Details Section Data
 * @returns Json Object
 */
export const fireAddFireDetailsSection = (data) => {
  return {
    type: FIRE_ADD_FIRE_DETAILS_SECTION,
    payload: data,
  };
};
//#endregion Fire Details Section

//#region Property Involved Section
/**
 * Method for adding the Property Involved section details
 * @param {object} data Property Involved Section Data
 * @returns Json Object
 */
export const fireAddPropertyInvolvedSection = (data) => {
  return {
    type: FIRE_ADD_PROPERTY_INVOLVED_SECTION,
    payload: data,
  };
};
//#endregion Property Involved Section

//#region Owner of Property Section
/**
 * Method for adding the Owner of Property section details
 * @param {object} data Owner of Property Section Data
 * @returns Json Object
 */
export const fireAddOwnerOfPropertySection = (data) => {
  return {
    type: FIRE_ADD_OWNER_OF_PROPERTY_SECTION,
    payload: data,
  };
};
//#endregion Owner of Property Section

//#region Remark Section
/**
 * Method for adding the remarks
 * @param {object} data Remark Section Data
 * @returns Json Object
 */
export const fireAddRemarkSection = (data) => {
  return {
    type: FIRE_ADD_REMARK_SECTION,
    payload: data,
  };
};
//#endregion Remark Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the Human Live Lost section details
 * @param {object} data Human Live Lost Section Data
 * @returns Json Object
 */
export const fireAddHllSection = (data) => {
  return {
    type: FIRE_ADD_HLL_SECTION,
    payload: data,
  };
};
//#endregion Human Live Lost Section

//#region Emergency Service Personnel Life Section
//@author: shivam.sharma2@velsof.com
//@date : 12-12-2022
/**
 * Method for adding the Emergency Service Personnel Life section details
 * @param {object} data Emergency Service Personnel Life Section Data
 * @returns Json Object
 */
export const fireAddESPLSection = (data) => {
  return {
    type: FIRE_ADD_ESPL_SECTION,
    payload: data,
  };
};
//#endregion Emergency Service Personnel Life Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the LiveStock section details
 * @param {object} data LiveStock Section Data
 * @returns Json Object
 */
export const fireAddLiveStockSection = (data) => {
  return {
    type: FIRE_ADD_LIVESTOCK_SECTION,
    payload: data,
  };
};
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: shivam.sharma2@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the HouseDamaged section details
 * @param {object} data HouseDamaged Section Data
 * @returns Json Object
 */
export const fireAddHouseDamagedSection = (data) => {
  return {
    type: FIRE_ADD_HOUSEDAMAGED_SECTION,
    payload: data,
  };
};
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: shivam.sharma2@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the RescueOperation section details
 * @param {object} data RescueOperation Section Data
 * @returns Json Object
 */
export const fireAddRescueOperationSection = (data) => {
  return {
    type: FIRE_ADD_RESCUEOPERATION_SECTION,
    payload: data,
  };
};
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: shivam.sharma2@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the ReliefDistribution section details
 * @param {object} data ReliefDistribution Section Data
 * @returns Json Object
 */
export const fireAddReliefDistributionSection = (data) => {
  return {
    type: FIRE_ADD_RELIEFDISTRIBUTION_SECTION,
    payload: data,
  };
};
//#endregion ReliefDistribution Section

//#region for deleting the icident photo
export const fireDeleteIncidentPhoto = (data) => {
  return {
    type: FIRE_DELETE_INCIDENT_PHOTO,
    payload: data,
  };
};
export const fireSetDeletedPhotoResponse = (data) => {
  return {
    type: FIRE_SET_DELETED_IMAGE_RESPONSE,
    payload: data,
  };
};
//#endregion

//#region for setting the response for incident photos
export const fireSetIncidentPhotoResponse = (data) => {
  return {
    type: FIRE_SET_INCIDENT_PHOTO_RESPONSE,
    payload: data,
  };
};
//#endregion

//#region for approve, disapprove
/**
 * @author: shivam.sharma2@velosf.com
 * @date : 25-01-2023
 */
/**
 * Method for approving the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const fireHQCRReportApprove = (data) => {
  return {
    type: FIRE_HQCR_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const fireHQCRReportDisapprove = (data) => {
  return {
    type: FIRE_HQCR_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * @author: shivam.sharma2@velosf.com
 * @date : 30-01-2023
 */
/**
 * Method for approving the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const fireWingReportApprove = (data) => {
  return {
    type: FIRE_WING_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const fireWingReportDisapprove = (data) => {
  return {
    type: FIRE_WING_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * @author: shivam.sharma2@velsof.com
 * @date : 30-01-2023
 */
/**
 * Method for approving the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const fireSpfeReportApprove = (data) => {
  return {
    type: FIRE_SPFE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for approving the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const fireDfesReportApprove = (data) => {
  return {
    type: FIRE_DFES_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const fireSpfeReportDisapprove = (data) => {
  return {
    type: FIRE_SPFE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const fireDfesReportDisapprove = (data) => {
  return {
    type: FIRE_DFES_REPORT_DISAPPROVE,
    payload: data,
  };
};

//#endregion

/**
 * Below action will be called when exporting the form data in fire view form
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 07-02-2023
 */
export const getExportFsLevelFireReport = (data) => {
  return {
    type: EXPORT_FS_LEVEL_FIRE_REPORT,
    payload: data,
  };
};

export const setExportFSLevelFireReport = (data) => {
  return {
    type: SET_EXPORT_FS_LEVEL_FIRE_REPORT,
    payload: data,
  };
};
