/**
 * The below file is the district service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 04-10-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_District,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_District_Status,
  District_Import,
  Edit_District,
  Get_All_District,
  Get_District,
  Get_Export_District,
  Multipart_Form_Data,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const DistrictService = {};

//#region Method for district listing page.

/**
 * Method for getting the district listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
DistrictService.getAllDistrict = function (param) {
  return fetch({
    url: Get_All_District,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the district export data
 * @returns Fetch Method
 */
DistrictService.getExportDistrictData = function () {
  return fetch({
    url: Get_Export_District,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the district status
 * @param {any} data API Data
 * @returns Fetch Method
 */
DistrictService.changeDistrictStatus = function (data) {
  return fetch({
    url: Change_District_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for district listing page.

//#region Method for district add page.

/**
 * Method for adding the district
 * @param {any} data API Data
 * @returns Fetch Method
 */
DistrictService.addDistrict = function (data) {
  return fetch({
    url: Add_District,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for district add page.

//#region Method for district edit page.

/**
 * Method for editing the district
 * @param {number} id District Id
 * @param {any} data API Data
 * @returns Fetch Method
 */
DistrictService.editDistrict = function (id, data) {
  return fetch({
    url: format(Edit_District, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the district detail
 * @param {any} data API Data
 * @returns Fetch Method
 */
DistrictService.getDistrict = function (data) {
  return fetch({
    url: format(Get_District, data.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion Method for district edit page.

//#region Method for district import page.

/**
 * Method for importing the district
 * @param {any} data API Data
 * @returns Fetch Method
 */
DistrictService.districtImport = function (data) {
  return fetch({
    url: District_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for district import page.

export default DistrictService;
