/**
 * The below file contains the Sagas method for Relief Camp.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-11-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  ReliefCamp_Added,
  ReliefCamp_Imported,
  ReliefCamp_Updated,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setExportReliefCamp,
  setReliefCamp,
  setReliefCampListing,
  updateReliefCampStatus,
} from "redux/actions/ReliefCamp";
import {
  ADD_RELIEF_CAMP,
  CHANGE_RELIEF_CAMP_STATUS,
  EDIT_RELIEF_CAMP,
  GET_ALL_RELIEF_CAMP,
  GET_EXPORT_RELIEF_CAMP_DATA,
  GET_RELIEF_CAMP,
  RELIEF_CAMP_IMPORT,
} from "redux/constants/ReliefCamp";
import ReliefCampService from "services/ReliefCampService";

//#region Method for listing

/**
 * Method for getting the reliefCamps
 */
export function* getAllReliefCamp() {
  yield takeEvery(GET_ALL_RELIEF_CAMP, function* ({ payload }) {
    try {
      const reliefCamp = yield call(
        ReliefCampService.getAllReliefCamp,
        payload
      );
      if (reliefCamp.error != null && reliefCamp.error.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        yield put(setReliefCampListing(reliefCamp));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the reliefCamp export details
 */
export function* getReliefCampExport() {
  yield takeEvery(GET_EXPORT_RELIEF_CAMP_DATA, function* () {
    try {
      const reliefCamp = yield call(ReliefCampService.getExportReliefCampData);
      if (reliefCamp.error != null && reliefCamp.error.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(reliefCamp),
        };
        yield put(setExportReliefCamp(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the reliefCamp status details
 */
export function* changeReliefCampStatus() {
  yield takeEvery(CHANGE_RELIEF_CAMP_STATUS, function* ({ payload }) {
    try {
      const reliefCamp = yield call(
        ReliefCampService.changeReliefCampStatus,
        payload
      );
      if (reliefCamp.error != null && reliefCamp.error.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        let json = {
          id: reliefCamp.data.id,
          status: reliefCamp.data.status,
        };
        yield put(updateReliefCampStatus(json));
        yield put(showMessage({ message: ReliefCamp_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for add page

/**
 * Method for adding the reliefCamp
 */
export function* addReliefCamp() {
  yield takeEvery(ADD_RELIEF_CAMP, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const reliefCamp = yield call(ReliefCampService.addReliefCamp, final);
      if (reliefCamp.error != null && reliefCamp.error.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: ReliefCamp_Added, type: "success" }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for add page

//#region Method for edit page

/**
 * Method for editing the reliefCamp
 */
export function* editReliefCamp() {
  yield takeEvery(EDIT_RELIEF_CAMP, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          reliefCampName: payload.reliefCampName,
          reliefCampCode: payload.reliefCampCode,
          blockName: payload.blockName,
          block: payload.block,
          status: payload.status,
          districtName: payload.districtName,
          district: payload.district,
          latitude: payload.latitude,
          longitude: payload.longitude,
          childFriendlySpace: payload.childFriendlySpace,
          districtName: payload.districtName,
        },
      };
      const reliefCamp = yield call(
        ReliefCampService.editReliefCamp,
        id,
        final
      );
      if (reliefCamp.error != null && reliefCamp.error.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: ReliefCamp_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the reliefCamp
 */
export function* getReliefCamp() {
  yield takeEvery(GET_RELIEF_CAMP, function* ({ payload }) {
    try {
      let param = {
        "populate[0]": "block",
        "populate[block][populate][0]": "district",
      };
      const reliefCamp = yield call(
        ReliefCampService.getReliefCamp,
        payload,
        param
      );
      if (reliefCamp.error != null && reliefCamp.error.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        yield put(setReliefCamp(reliefCamp));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for add page

//#region Method for reliefCamp import page

/**
 * Method for importing the reliefCamp
 */
export function* reliefCampImport() {
  yield takeEvery(RELIEF_CAMP_IMPORT, function* ({ payload }) {
    try {
      const reliefCamp = yield call(
        ReliefCampService.reliefCampImport,
        payload
      );
      if (reliefCamp.error != null && reliefCamp.error.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: ReliefCamp_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for reliefCamp import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllReliefCamp),
    fork(addReliefCamp),
    fork(editReliefCamp),
    fork(getReliefCamp),
    fork(getReliefCampExport),
    fork(changeReliefCampStatus),
    fork(reliefCampImport),
  ]);
}
