/**
 * The below file is the Types of Occupancy service which contains method for doing the API calls.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Get_All_Types_of_Occupancy,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const TypesOfOccupancyService = {};

//#region Method for Types of Occupancy dropdown

/**
 * Method for getting the Types of Occupancy dropdown
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
TypesOfOccupancyService.getAllTypesOfOccupancy = function () {
  return fetch({
    url: Get_All_Types_of_Occupancy,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

//#endregion Method for Types of Occupancy dropdown.

export default TypesOfOccupancyService;
