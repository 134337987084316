/**
 * The below file is the role service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-02-2023
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_Role,
  Application_Json,
  Authorization_Token,
  Edit_Role,
  Get_All_Role,
  Get_Role,
  Get_Export_Role,
  Get_All_Permission,
  Blob,
  Get_All_Roles_Id,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const RoleService = {};

//#region Method for role listing page.

/**
 * Method for getting the role listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
RoleService.getAllRole = function (param) {
  return fetch({
    url: Get_All_Role,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the role export data
 * @returns Fetch Method
 */
RoleService.getExportRoleData = function () {
  return fetch({
    url: Get_Export_Role,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

//#endregion Method for role listing page.

//#region Method for role add page.

/**
 * Method for adding the role
 * @param {any} data API Data
 * @returns Fetch Method
 */
RoleService.addRole = function (data) {
  return fetch({
    url: Add_Role,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for role add page.

//#region Method for role edit page.

/**
 * Method for editing the role
 * @param {number} id Role Id
 * @param {any} data API Data
 * @returns Fetch Method
 */
RoleService.editRole = function (id, data) {
  return fetch({
    url: format(Edit_Role, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the role detail
 * @param {any} data API Data
 * @returns Fetch Method
 */
RoleService.getRole = function (data) {
  return fetch({
    url: format(Get_Role, data.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion Method for role edit page.

/**
 * Method for getting all the permission
 * @param {any} data API Data
 * @returns Fetch Method
 */
RoleService.getPermission = function (data) {
  return fetch({
    url: Get_All_Permission,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

/**
 * Method for getting the id of all the roles
 * @param {any} data API Data
 * @returns Fetch Method
 */
RoleService.getAllRoleId = function (data) {
  return fetch({
    url: Get_All_Roles_Id,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

export default RoleService;
