/**
 * The below file is the notification service which contains method for doing the API calls.
 * @author: shivam.sharma2@velsof.com
 * @date : 23-01-2023
 */

import fetch from "auth/FetchInterceptor";
import {
  Notification,
  Application_Json,
  Authorization_Token,
  NotificationUpdate,
  Notification_Mark_All_Read,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const NotificationService = {};

//#region Method for getting the API

/**
 * Method for getting the Notification
 * @returns Fetch Method
 */
NotificationService.getNotification = function (param) {
  return fetch({
    url: Notification,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for changing the status of the notification
 * @returns Fetch Method
 */
NotificationService.changeReadStatus = function ({ id, data }) {
  return fetch({
    url: format(NotificationUpdate, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: { data },
  });
};

/**
 * Method for marking all notification as read
 * @returns Fetch Method
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-02-2023
 */
NotificationService.markAllRead = function ({ data }) {
  return fetch({
    url: Notification_Mark_All_Read,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: { data },
  });
};

export default NotificationService;
