/**
 * The below file contains the action methods for user master.
 * @author: shivam.sharma2@velsof.com
 * @date : 05-01-2023
 */

import {
  ADD_USER,
  CHANGE_USER_STATUS,
  EDIT_USER,
  GET_ALL_USER,
  GET_EXPORT_USER_DATA,
  GET_USER,
  SET_EXPORT_USER_DATA,
  SET_USER,
  SET_USER_LISTING,
  SET_USER_LISTING_PARAM,
  UPDATE_USER_STATUS,
  USER_IMPORT,
  RESET_USER_PASSWORD,
} from "redux/constants/User";
import { CHANGE_PASSWORD } from "../constants/User";

//#region Action methods for user listing page

/**
 * Method for getting the user listing
 * @param {any} params User Data
 * @returns Json Object
 */
export const getAllUser = (params) => {
  return {
    type: GET_ALL_USER,
    payload: params,
  };
};

/**
 * Method for setting the user listing details in state
 * @param {any} user User Data
 * @returns Json Object
 */
export const setUserListing = (user) => {
  return {
    type: SET_USER_LISTING,
    payload: user,
  };
};

/**
 * Method for setting the user parameters
 * @param {any} data User Data
 * @returns Json Object
 */
export const setUserTableParam = (data) => {
  return {
    type: SET_USER_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the user status
 * @param {any} data User Data
 * @returns Json Object
 */
export const updateUserStatus = (data) => {
  return {
    type: UPDATE_USER_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the user status
 * @param {any} data User Data
 * @returns Json Object
 */
export const changeUserStatus = (data) => {
  return {
    type: CHANGE_USER_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of user
 * @returns Json Object
 */
export const getExportUser = () => {
  return {
    type: GET_EXPORT_USER_DATA,
  };
};

/**
 * Method for setting the user export data in the state
 * @param {any} data User Data
 * @returns Json Object
 */
export const setExportUser = (data) => {
  return {
    type: SET_EXPORT_USER_DATA,
    payload: data,
  };
};
//#endregion Action methods for user listing page

//#region Action methods for user add page

/**
 * Method for adding the user
 * @param {any} data User Data
 * @returns Json Object
 */
export const addUser = (data) => {
  return {
    type: ADD_USER,
    payload: data,
  };
};
//#endregion Action methods for user add page

//#region Action methods for user edit page

/**
 * Method for editing the user
 * @param {any} data User Data
 * @returns Json Object
 */
export const editUser = (data) => {
  return {
    type: EDIT_USER,
    payload: data,
  };
};

/**
 * Method for getting the user details
 * @param {any} id User Id
 * @returns Json Object
 */
export const getUser = (id) => {
  return {
    type: GET_USER,
    payload: id,
  };
};

/**
 * Method for setting the user details in the state
 * @param {any} user User Data
 * @returns Json Object
 */
export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};
//#endregion Action methods for user edit page

//#region Action methods for user import page
/**
 * Method for user import
 * @param {any} data User Data
 * @returns Json Object
 */
export const userImport = (data) => {
  return {
    type: USER_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for user import page

//#region Action methods for resetting user password
/**
 * Method for reset password
 * @param {any} data User Data
 * @returns Json Object
 */
export const resetUserPassword = (data) => {
  return {
    type: RESET_USER_PASSWORD,
    payload: data,
  };
};
//#endregion Action methods for resetting user password

/**
 * Method for changing the password
 * @param {any} data  Data
 * @returns Json Object
 */
export const changePassword = (data) => {
  return {
    type: CHANGE_PASSWORD,
    payload: data,
  };
};
