/**
 * The below file is the river service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 01-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_River,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_River_Status,
  River_Import,
  Edit_River,
  Get_All_River,
  Get_River,
  Get_Export_River,
  Multipart_Form_Data,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const RiverService = {};

//#region Method for river listing page.

/**
 * Method for getting the river listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
RiverService.getAllRiver = function (param) {
  return fetch({
    url: Get_All_River,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the river export data
 * @returns Fetch Method
 */
RiverService.getExportRiverData = function () {
  return fetch({
    url: Get_Export_River,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the river status
 * @param {any} data API Data
 * @returns Fetch Method
 */
RiverService.changeRiverStatus = function (data) {
  return fetch({
    url: Change_River_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for river listing page.

//#region Method for river add page.

/**
 * Method for adding the river
 * @param {any} data API Data
 * @returns Fetch Method
 */
RiverService.addRiver = function (data) {
  return fetch({
    url: Add_River,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for river add page.

//#region Method for river edit page.

/**
 * Method for editing the river
 * @param {number} id River Id
 * @param {any} data API Data
 * @returns Fetch Method
 */
RiverService.editRiver = function (id, data) {
  return fetch({
    url: format(Edit_River, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the river detail
 * @param {any} data API Data
 * @returns Fetch Method
 */
RiverService.getRiver = function (data) {
  return fetch({
    url: format(Get_River, data.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion Method for river edit page.

//#region Method for river import page.

/**
 * Method for importing the river
 * @param {any} data API Data
 * @returns Fetch Method
 */
RiverService.riverImport = function (data) {
  return fetch({
    url: River_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for river import page.

export default RiverService;
