/**
 * The below file contains the flood reports state objects
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */

import {
  BasicSection,
  CampInmatesSection,
  DiscardButton,
  ErodedSection,
  HllSection,
  HouseDamagedSection,
  InfDamageOtherSection,
  InfDamageSection,
  LiveStockSection,
  NonCampInmatesSection,
  PopAffectedSection,
  ReliefCampSection,
  ReliefDistributionSection,
  RemarkSection,
  RescueOperationSection,
  SubmitButton,
} from "constants/ApplicationConstant";
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_FLOOD_DATA,
  FLOOD_ADD_BASIC_SECTION,
  FLOOD_ADD_INFDAMAGEOTHER_SECTION,
  FLOOD_ADD_INFDAMAGE_SECTION,
  FLOOD_ADD_REMARK_SECTION,
  FLOOD_EDIT_BASIC_SECTION,
  FLOOD_REPORT_DISCARD,
  FLOOD_REPORT_SUBMIT,
  FLOOD_RESET_BUTTON_LOADING,
  FLOOD_SET_ALL_SECTION,
  FLOOD_SET_DATA_SAVED,
  FLOOD_SET_NIL_REPORTING,
  FLOOD_SET_PARENT_ID,
  FLOOD_SET_REDIRECT_TO_LISTING,
  SET_EXPORT_FLOOD_DATA,
  SET_FLOOD_LISTING,
  SET_FLOOD_LISTING_PARAM,
  FLOOD_ADD_POPAFFECTED_SECTION,
  FLOOD_ADD_ERODED_SECTION,
  FLOOD_ADD_CAMPINMATES_SECTION,
  FLOOD_ADD_RELIEFCAMP_SECTION,
  FLOOD_ADD_HLL_SECTION,
  Flood_Cause_Of_Death_HLL_Section,
  FLOOD_ADD_LIVESTOCK_SECTION,
  FLOOD_ADD_HOUSEDAMAGED_SECTION,
  FLOOD_ADD_RELIEFDISTRIBUTION_SECTION,
  FLOOD_ADD_RESCUEOPERATION_SECTION,
  SET_FLOOD_DISTRICT_REVIEW_LIST,
  SET_FLOOD_STATE_REVIEW_LIST,
  FLOOD_DISTRICT_REPORT_APPROVE,
  FLOOD_DISTRICT_REPORT_DISAPPROVE,
  FLOOD_DISTRICT_REPORT_VERIFY,
  FLOOD_STATE_REPORT_APPROVE,
  FLOOD_STATE_REPORT_DISAPPROVE,
  SET_EXPORT_FO_LEVEL_FLOOD_REPORT,
  SET_STATE_CUMULATIVE_FLOOD_REPORT,
  SET_DISTRICT_CUMULATIVE_FLOOD_REPORT,
  FLOOD_REVENUE_REPORT_DISAPPROVE,
  FLOOD_REVENUE_REPORT_VERIFY,
  FLOOD_ADD_NONCAMPINMATES_SECTION,
  FLOOD_SET_RELIEF_CENTER_SECTION,
  FREEZE_UNFREEZE_FLOOD_RANGE_REPORT,
} from "redux/constants/Flood";

//Initial state
const initState = {
  //Keys for listing
  floodList: {},
  isFloodDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  floodListingParam: {},
  floodExportedData: {},
  exportFoLevelFloodReport: {
    report: "",
    format: "",
  },
  exportStateCumulativeFloodReport: {
    report: "",
    format: "",
  },
  exportDistrictCumulativeFloodReport: {
    report: "",
    format: "",
  },
  //Keys for listing

  //Keys for All Section Data
  floodParentId: "",
  floodAllSectionData: {},
  floodReliefCenterData: [],
  floodButtonLoading: {
    basicSection: false,
    popAffectedSection: false,
    erodedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    nonCampInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
    submitButton: false,
    discardButton: false,
    approveButton: false,
    disapproveButton: false,
    verifyButton: false,
    freezeButton: false,
  },
  floodDataSaved: {
    basicSection: false,
    popAffectedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    nonCampInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
  },
  floodIsNilReporting: false,
  floodRedirectToListing: false,
  //Keys for All Section Data

  //Keys for Approval Disapproval
  floodDistrictReviewList: {},
  floodStateReviewList: {},
  //Keys for Approval Disapproval
  //keys for Flood Form Freeze Report Range
  floodFreezeReportRange: {},
  //keys for Flood Form Freeze Report Range
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const flood = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_FLOOD_LISTING:
      return {
        ...state,
        floodList: action.payload,
        isFloodDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_FLOOD_LISTING_PARAM:
      return {
        ...state,
        floodListingParam: action.payload,
      };

    case GET_EXPORT_FLOOD_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_FLOOD_DATA:
      return {
        ...state,
        floodExportedData: action.payload,
      };
    //Cases for listing

    //Cases for All Section
    case FLOOD_SET_ALL_SECTION:
      return {
        ...state,
        floodAllSectionData: action.payload,
      };

    case FLOOD_SET_RELIEF_CENTER_SECTION:
      return {
        ...state,
        floodReliefCenterData: action.payload,
      };

    case FLOOD_RESET_BUTTON_LOADING:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            basicSection: false,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            popAffectedSection: false,
          },
        };
      } else if (action.payload.section === ErodedSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            erodedSection: false,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            reliefCampSection: false,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            campInmatesSection: false,
          },
        };
      } else if (action.payload.section === NonCampInmatesSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            nonCampInmatesSection: false,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            hLLSection: false,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            liveStockSection: false,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            houseDamagedSection: false,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            rescueOperationSection: false,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            reliefDistributionSection: false,
          },
        };
      } else if (action.payload.section === InfDamageSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            infDamageSection: false,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            infDamageOtherSection: false,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            remarkSection: false,
          },
        };
      } else if (action.payload.section === SubmitButton) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            submitButton: false,
          },
        };
      } else if (action.payload.section === DiscardButton) {
        return {
          ...state,
          floodButtonLoading: {
            ...state.floodButtonLoading,
            discardButton: false,
          },
        };
      } else {
        return {
          ...state,
          floodButtonLoading: {
            basicSection: false,
            popAffectedSection: false,
            erodedSection: false,
            reliefCampSection: false,
            campInmatesSection: false,
            nonCampInmatesSection: false,
            hLLSection: false,
            liveStockSection: false,
            houseDamagedSection: false,
            rescueOperationSection: false,
            reliefDistributionSection: false,
            infDamageSection: false,
            infDamageOtherSection: false,
            remarkSection: false,
            submitButton: false,
            discardButton: false,
            approveButton: false,
            disapproveButton: false,
            verify: false,
          },
        };
      }

    case FLOOD_SET_PARENT_ID:
      return {
        ...state,
        floodParentId: action.payload,
      };

    case FLOOD_SET_REDIRECT_TO_LISTING:
      return {
        ...state,
        floodRedirectToListing: action.payload,
      };

    case FLOOD_SET_DATA_SAVED:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            basicSection: action.payload.value,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            popAffectedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ErodedSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            erodedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            reliefCampSection: action.payload.value,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            campInmatesSection: action.payload.value,
          },
        };
      } else if (action.payload.section === NonCampInmatesSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            nonCampInmatesSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            hLLSection: action.payload.value,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            liveStockSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            houseDamagedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            rescueOperationSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            reliefDistributionSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            infDamageSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            infDamageOtherSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          floodDataSaved: {
            ...state.floodDataSaved,
            remarkSection: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case FLOOD_REPORT_SUBMIT:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          submitButton: true,
        },
      };

    case FLOOD_REPORT_DISCARD:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          approveButton: true,
        },
      };

    case FLOOD_REVENUE_REPORT_DISAPPROVE:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          disapproveButton: true,
        },
      };

    case FLOOD_REVENUE_REPORT_VERIFY:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          verifyButton: true,
        },
      };
    //#endregion

    case FLOOD_REPORT_DISCARD:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          discardButton: true,
        },
      };

    case FLOOD_DISTRICT_REPORT_APPROVE:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          approveButton: true,
        },
      };

    case FLOOD_DISTRICT_REPORT_DISAPPROVE:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          disapproveButton: true,
        },
      };

    case FLOOD_DISTRICT_REPORT_VERIFY:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          verifyButton: true,
        },
      };

    case FLOOD_STATE_REPORT_APPROVE:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          approveButton: true,
        },
      };

    case FLOOD_STATE_REPORT_DISAPPROVE:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          disapproveButton: true,
        },
      };
    //Cases for All Section

    //Cases for Basic Section
    case FLOOD_ADD_BASIC_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          basicSection: true,
        },
      };

    case FLOOD_EDIT_BASIC_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          basicSection: true,
        },
      };

    case FLOOD_SET_NIL_REPORTING:
      return {
        ...state,
        floodIsNilReporting: action.payload,
      };
    //Cases for Basic Section

    //Cases for InfDamage Section
    case FLOOD_ADD_INFDAMAGE_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          infDamageSection: true,
        },
      };
    //Cases for InfDamage Section

    //Cases for InfDamageOther Section
    case FLOOD_ADD_INFDAMAGEOTHER_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          infDamageOtherSection: true,
        },
      };

    //Cases for Remark Section
    case FLOOD_ADD_REMARK_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          remarkSection: true,
        },
      };

    //Cases for Flood Pop Affected Section
    case FLOOD_ADD_POPAFFECTED_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          popAffectedSection: true,
        },
      };
    //Cases for Flood Pop Affected Section

    //Cases for Flood Eroded Section
    case FLOOD_ADD_ERODED_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          erodedSection: true,
        },
      };
    //Cases for Flood Eroded Section

    //Cases for Flood Relief Camp Section
    case FLOOD_ADD_RELIEFCAMP_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          reliefCampSection: true,
        },
      };
    //Cases for Flood Relief Camp Section

    //Cases for Flood Relief Camp Section
    case FLOOD_ADD_CAMPINMATES_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          campInmatesSection: true,
        },
      };
    //Cases for Flood Relief Camp Section

    //Cases for Flood Relief Camp Section
    case FLOOD_ADD_NONCAMPINMATES_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          nonCampInmatesSection: true,
        },
      };
    //Cases for Flood Relief Camp Section

    //Cases for Flood Hll Section
    case FLOOD_ADD_HLL_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          hLLSection: true,
        },
      };
    //Cases for Flood Hll Section

    // //Cases for Flood Cause of Death Hll Section
    // case Flood_Cause_Of_Death_HLL_Section:
    //   return {
    //     ...state,
    //     // Omit the floodButtonLoading property from the state update
    //   };

    // //Cases for Flood Cause of Death Hll Section

    //Cases for Flood LiveStock Section
    case FLOOD_ADD_LIVESTOCK_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          liveStockSection: true,
        },
      };
    //Cases for Flood LiveStock Section

    //Cases for Flood House Damaged Section
    case FLOOD_ADD_HOUSEDAMAGED_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          houseDamagedSection: true,
        },
      };
    //Cases for Flood House Damaged Section

    //Cases for Flood Rescue Operation Section
    case FLOOD_ADD_RESCUEOPERATION_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          rescueOperationSection: true,
        },
      };
    //Cases for Flood Rescue Operation Section

    //Cases for Flood Relief Distribution Section
    case FLOOD_ADD_RELIEFDISTRIBUTION_SECTION:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          reliefDistributionSection: true,
        },
      };
    //Cases for Flood Relief Distribution Section

    // Cases for Flood form PDF download section
    case SET_EXPORT_FO_LEVEL_FLOOD_REPORT:
      return {
        ...state,
        exportFoLevelFloodReport: {
          report: action.payload.url,
          format: action.payload.format,
        },
      };
    // Cases for Flood form export file section

    //Cases for Approval and Disapproval
    case SET_FLOOD_DISTRICT_REVIEW_LIST:
      return {
        ...state,
        floodDistrictReviewList: action.payload,
      };

    case SET_FLOOD_STATE_REVIEW_LIST:
      return {
        ...state,
        floodStateReviewList: action.payload,
      };
    //Cases for Approval and Disapproval

    // Cases for Flood state cumulative report
    case SET_STATE_CUMULATIVE_FLOOD_REPORT:
      return {
        ...state,
        exportStateCumulativeFloodReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Flood state cumulative report

    // Cases for Flood district cumulative report
    case SET_DISTRICT_CUMULATIVE_FLOOD_REPORT:
      return {
        ...state,
        exportDistrictCumulativeFloodReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Flood district cumulative report

    // Cases for Flood Freeze report for a selected range

    case FREEZE_UNFREEZE_FLOOD_RANGE_REPORT:
      return {
        ...state,
        floodButtonLoading: {
          ...state.floodButtonLoading,
          floodFreezeButton: true,
        },
        floodFreezeReportRange: action.payload,
      };
    // Cases for Flood Freeze report for a selected range

    default:
      return state;
  }
};

export default flood;
