/**
 * The below file contains the action methods for earthquake report.
 * @author: shivam.sharma2@velsof.com
 * @date : 21-12-2022
 */

import {
  GET_ALL_EARTHQUAKE,
  GET_EXPORT_EARTHQUAKE_DATA,
  SET_EXPORT_EARTHQUAKE_DATA,
  SET_EARTHQUAKE_LISTING,
  SET_EARTHQUAKE_LISTING_PARAM,
  EARTHQUAKE_IMPORT,
  EARTHQUAKE_ADD_BASIC_SECTION,
  EARTHQUAKE_EDIT_BASIC_SECTION,
  EARTHQUAKE_ADD_INFDAMAGE_SECTION,
  EARTHQUAKE_ADD_INFDAMAGEOTHER_SECTION,
  EARTHQUAKE_GET_ALL_SECTION,
  EARTHQUAKE_SET_ALL_SECTION,
  EARTHQUAKE_RESET_BUTTON_LOADING,
  EARTHQUAKE_SET_PARENT_ID,
  EARTHQUAKE_ADD_REMARK_SECTION,
  EARTHQUAKE_SET_NIL_REPORTING,
  EARTHQUAKE_REPORT_SUBMIT,
  EARTHQUAKE_REPORT_DISCARD,
  EARTHQUAKE_SET_REDIRECT_TO_LISTING,
  EARTHQUAKE_SET_DATA_SAVED,
  EARTHQUAKE_ADD_POPAFFECTED_SECTION,
  EARTHQUAKE_ADD_ERODED_SECTION,
  EARTHQUAKE_ADD_RELIEFCAMP_SECTION,
  EARTHQUAKE_ADD_CAMPINMATES_SECTION,
  EARTHQUAKE_ADD_HLL_SECTION,
  EARTHQUAKE_ADD_LIVESTOCK_SECTION,
  EARTHQUAKE_ADD_HOUSEDAMAGED_SECTION,
  EARTHQUAKE_ADD_RESCUEOPERATION_SECTION,
  EARTHQUAKE_ADD_RELIEFDISTRIBUTION_SECTION,
  EXPORT_FO_LEVEL_EARTHQUAKE_REPORT,
  SET_EXPORT_FO_LEVEL_EARTHQUAKE_REPORT,
  EARTHQUAKE_REVENUE_REPORT_APPROVE,
  EARTHQUAKE_REVENUE_REPORT_DISAPPROVE,
  EARTHQUAKE_REVENUE_REPORT_VERIFY,
  GET_EARTHQUAKE_DISTRICT_REVIEW_LIST,
  SET_EARTHQUAKE_DISTRICT_REVIEW_LIST,
  GET_EARTHQUAKE_STATE_REVIEW_LIST,
  SET_EARTHQUAKE_STATE_REVIEW_LIST,
  EARTHQUAKE_DISTRICT_REPORT_APPROVE,
  EARTHQUAKE_DISTRICT_REPORT_DISAPPROVE,
  EARTHQUAKE_DISTRICT_REPORT_VERIFY,
  EARTHQUAKE_STATE_REPORT_APPROVE,
  EARTHQUAKE_STATE_REPORT_DISAPPROVE,
  GET_STATE_CUMULATIVE_EARTHQUAKE_REPORT,
  SET_STATE_CUMULATIVE_EARTHQUAKE_REPORT,
  GET_DISTRICT_CUMULATIVE_EARTHQUAKE_REPORT,
  SET_DISTRICT_CUMULATIVE_EARTHQUAKE_REPORT,
} from "redux/constants/Earthquake";

//#region Action methods for earthquake listing page

/**
 * Method for getting the earthquake listing
 * @param {any} params Earthquake Data
 * @returns Json Object
 */
export const getAllEarthquake = (params) => {
  return {
    type: GET_ALL_EARTHQUAKE,
    payload: params,
  };
};

/**
 * Method for setting the earthquake listing details in state
 * @param {any} earthquake Earthquake Data
 * @returns Json Object
 */
export const setEarthquakeListing = (earthquake) => {
  return {
    type: SET_EARTHQUAKE_LISTING,
    payload: earthquake,
  };
};

/**
 * Method for setting the earthquake parameters
 * @param {any} data Earthquake Data
 * @returns Json Object
 */
export const setEarthquakeTableParam = (data) => {
  return {
    type: SET_EARTHQUAKE_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for getting the export file of earthquake
 * @returns Json Object
 */
export const getExportEarthquake = () => {
  return {
    type: GET_EXPORT_EARTHQUAKE_DATA,
  };
};

/**
 * Method for setting the earthquake export data in the state
 * @param {any} data Earthquake Data
 * @returns Json Object
 */
export const setExportEarthquake = (data) => {
  return {
    type: SET_EXPORT_EARTHQUAKE_DATA,
    payload: data,
  };
};
//#endregion Action methods for earthquake listing page

//#region Action methods for earthquake import page
/**
 * Method for earthquake import
 * @param {any} data Earthquake Data
 * @returns Json Object
 */
export const earthquakeImport = (data) => {
  return {
    type: EARTHQUAKE_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for earthquake import page

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for getting the all section details
 * @param {number} data Basic Section Data Form Id and param
 * @returns Json Object
 */
export const earthquakeGetAllSection = (data) => {
  return {
    type: EARTHQUAKE_GET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const earthquakeSetAllSection = (data) => {
  return {
    type: EARTHQUAKE_SET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the parent id
 * @param {number} id Parent Id
 * @returns Json Object
 */
export const earthquakeSetParentId = (id) => {
  return {
    type: EARTHQUAKE_SET_PARENT_ID,
    payload: id,
  };
};

/**
 * Method for reseting the button loading status
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const earthquakeResetButtonLoading = (data) => {
  return {
    type: EARTHQUAKE_RESET_BUTTON_LOADING,
    payload: data,
  };
};

/**
 * Method for submitting the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const earthquakeReportSubmit = (data) => {
  return {
    type: EARTHQUAKE_REPORT_SUBMIT,
    payload: data,
  };
};

/**
 * Method for discarding the report
 * @param {object} data Report Data
 * @returns Json Object
 */
export const earthquakeReportDiscard = (data) => {
  return {
    type: EARTHQUAKE_REPORT_DISCARD,
    payload: data,
  };
};

/**
 * Method for setting the redirect to listing variable
 * @param {object} data  Data
 * @returns Json Object
 */
export const earthquakeSetRedirectToListing = (data) => {
  return {
    type: EARTHQUAKE_SET_REDIRECT_TO_LISTING,
    payload: data,
  };
};

/**
 * Method for setting the value of earthquakeDataSaved object
 * @param {object} data Data
 * @returns Json Object
 */
export const earthquakeSetDataSaved = (data) => {
  return {
    type: EARTHQUAKE_SET_DATA_SAVED,
    payload: data,
  };
};
//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for adding the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const earthquakeAddBasicSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for editing the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const earthquakeEditBasicSection = (data) => {
  return {
    type: EARTHQUAKE_EDIT_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the nil reporting value
 * @param {boolean} data Bool value
 * @returns Json Object
 */
export const earthquakeSetNilReporting = (data) => {
  return {
    type: EARTHQUAKE_SET_NIL_REPORTING,
    payload: data,
  };
};
//#endregion Basic Section

//#region Infrastructure Damage Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for adding the infDamage section details
 * @param {object} data InfDamage Section Data
 * @returns Json Object
 */
export const earthquakeAddInfDamageSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_INFDAMAGE_SECTION,
    payload: data,
  };
};
//#endregion Infrastructure Damage Section

//#region Infrastructure Damage Other Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for adding the infDamageOther section details
 * @param {object} data InfDamageOther Section Data
 * @returns Json Object
 */
export const earthquakeAddInfDamageOtherSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_INFDAMAGEOTHER_SECTION,
    payload: data,
  };
};
//#endregion InfDamageOther Section

//#region Remark Section
/**
 * Method for adding the remarks
 * @param {object} data Remark Section Data
 * @returns Json Object
 */
export const earthquakeAddRemarkSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_REMARK_SECTION,
    payload: data,
  };
};
//#endregion Remark Section

//#region Population Affected Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for adding the popAffected section details
 * @param {object} data PopAffected Section Data
 * @returns Json Object
 */
export const earthquakeAddPopAffectedSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_POPAFFECTED_SECTION,
    payload: data,
  };
};
//#endregion Population Affected Section

//#region Eroded Section
/**
 * Method for adding the Eroded section details
 * @param {object} data Eroded Section Data
 * @returns Json Object
 */
export const earthquakeAddErodedSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_ERODED_SECTION,
    payload: data,
  };
};
//#endregion Eroded Section

//#region Relief Camp Section
/**
 * Method for adding the Relief Camp section details
 * @param {object} data Relief Camp Section Data
 * @returns Json Object
 */
export const earthquakeAddReliefCampSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_RELIEFCAMP_SECTION,
    payload: data,
  };
};
//#endregion Relief Camp Section

//#region Camp Inmates Section
/**
 * Method for adding the Camp Inmates section details
 * @param {object} data Camp Inmates Section Data
 * @returns Json Object
 */
export const earthquakeAddCampInmatesSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_CAMPINMATES_SECTION,
    payload: data,
  };
};
//#endregion Camp Inmates Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022
/**
 * Method for adding the Human Live Lost section details
 * @param {object} data Human Live Lost Section Data
 * @returns Json Object
 */
export const earthquakeAddHllSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_HLL_SECTION,
    payload: data,
  };
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022
/**
 * Method for adding the LiveStock section details
 * @param {object} data LiveStock Section Data
 * @returns Json Object
 */
export const earthquakeAddLiveStockSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_LIVESTOCK_SECTION,
    payload: data,
  };
};
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022
/**
 * Method for adding the HouseDamaged section details
 * @param {object} data HouseDamaged Section Data
 * @returns Json Object
 */
export const earthquakeAddHouseDamagedSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_HOUSEDAMAGED_SECTION,
    payload: data,
  };
};
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022
/**
 * Method for adding the RescueOperation section details
 * @param {object} data RescueOperation Section Data
 * @returns Json Object
 */
export const earthquakeAddRescueOperationSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_RESCUEOPERATION_SECTION,
    payload: data,
  };
};
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022
/**
 * Method for adding the ReliefDistribution section details
 * @param {object} data ReliefDistribution Section Data
 * @returns Json Object
 */
export const earthquakeAddReliefDistributionSection = (data) => {
  return {
    type: EARTHQUAKE_ADD_RELIEFDISTRIBUTION_SECTION,
    payload: data,
  };
};
//#endregion ReliefDistribution Section

//#region Methods for Reports and Approval and Disapproval
/**
 * Method for getting the FO level export report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Data
 * @returns Json Object
 */
export const getExportFoLevelEarthquakeReport = (data) => {
  return {
    type: EXPORT_FO_LEVEL_EARTHQUAKE_REPORT,
    payload: data,
  };
};

/**
 * Method for setting the exported data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Data
 * @returns Json Object
 */
export const setExportFOLevelEarthquakeReport = (data) => {
  return {
    type: SET_EXPORT_FO_LEVEL_EARTHQUAKE_REPORT,
    payload: data,
  };
};

/**
 * Method for approving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const earthquakeRevenueReportApprove = (data) => {
  return {
    type: EARTHQUAKE_REVENUE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const earthquakeRevenueReportDisapprove = (data) => {
  return {
    type: EARTHQUAKE_REVENUE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const earthquakeRevenueReportVerify = (data) => {
  return {
    type: EARTHQUAKE_REVENUE_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for getting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getEarthquakeDistrictReviewList = (data) => {
  return {
    type: GET_EARTHQUAKE_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setEarthquakeDistrictReviewList = (data) => {
  return {
    type: SET_EARTHQUAKE_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for getting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getEarthquakeStateReviewList = (data) => {
  return {
    type: GET_EARTHQUAKE_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setEarthquakeStateReviewList = (data) => {
  return {
    type: SET_EARTHQUAKE_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const earthquakeDistrictReportApprove = (data) => {
  return {
    type: EARTHQUAKE_DISTRICT_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const earthquakeDistrictReportDisapprove = (data) => {
  return {
    type: EARTHQUAKE_DISTRICT_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report Data
 * @returns Json Object
 */
export const earthquakeDistrictReportVerify = (data) => {
  return {
    type: EARTHQUAKE_DISTRICT_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const earthquakeStateReportApprove = (data) => {
  return {
    type: EARTHQUAKE_STATE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const earthquakeStateReportDisapprove = (data) => {
  return {
    type: EARTHQUAKE_STATE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for exporting state cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportStateCumulativeEarthquakeReport = (data) => {
  return {
    type: GET_STATE_CUMULATIVE_EARTHQUAKE_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportStateCumulativeEarthquakeReport = (data) => {
  return {
    type: SET_STATE_CUMULATIVE_EARTHQUAKE_REPORT,
    payload: data,
  };
};

/**
 * Method for exporting district cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportDistrictCumulativeEarthquakeReport = (data) => {
  return {
    type: GET_DISTRICT_CUMULATIVE_EARTHQUAKE_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportDistrictCumulativeEarthquakeReport = (data) => {
  return {
    type: SET_DISTRICT_CUMULATIVE_EARTHQUAKE_REPORT,
    payload: data,
  };
};
//#endregion Methods for Reports and Approval and Disapproval
