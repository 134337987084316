/**
 * The below file is the landslide service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 07-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Blob,
  Get_All_Landslide,
  Get_Export_Landslide,
  Multipart_Form_Data,
  Landslide_Import,
  Landslide_Add_Basic_Section,
  Landslide_Edit_Basic_Section,
  Landslide_Get_All_Section,
  Landslide_Add_InfDamage_Section,
  Landslide_Add_InfDamageOther_Section,
  Landslide_Add_Remark_Section,
  Landslide_Submit_Report,
  Landslide_Discard_Report,
  Landslide_Add_PopAffected_Section,
  Landslide_Add_Eroded_Section,
  Landslide_Add_ReliefCamp_Section,
  Landslide_Add_CampInmates_Section,
  Landslide_Add_HLL_Section,
  Landslide_Add_LiveStock_Section,
  Landslide_Add_HouseDamaged_Section,
  Landslide_Add_RescueOperation_Section,
  Landslide_Add_ReliefDistribution_Section,
  Landslide_Fo_Level_Report,
  Landslide_Cumulative_State_Report,
  Landslide_Cumulative_District_Report,
  Landslide_Get_District_Review_List,
  Landslide_Revenue_Report_Verify,
  Landslide_Revenue_Report_Disapprove,
  Landslide_Revenue_Report_Approve,
  Landslide_Get_State_Review_List,
  Landslide_District_Report_Approve,
  Landslide_District_Report_Disapprove,
  Landslide_District_Report_Verify,
  Landslide_State_Report_Approve,
  Landslide_State_Report_Disapprove,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const LandslideService = {};

//#region Method for listing page.

/**
 * Method for getting the landslide listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
LandslideService.getAllLandslide = function (param) {
  return fetch({
    url: Get_All_Landslide,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the landslide export data
 * @returns Fetch Method
 */
LandslideService.getExportLandslideData = function () {
  return fetch({
    url: Get_Export_Landslide,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};
//#endregion Method for listing page.

//#region Method for landslide import page.

/**
 * Method for importing the landslide
 * @param {any} data API data
 * @returns Fetch Method
 */
LandslideService.landslideImport = function (data) {
  return fetch({
    url: Landslide_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for landslide import page.

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**Method for getting all section data
 * @param {number} id Form Id
 * @param {object} param Param object
 * @returns Fetch Method
 */
LandslideService.landslideGetAllSection = function (id, param) {
  return fetch({
    url: format(Landslide_Get_All_Section, id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**Method for submitting the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
LandslideService.landslideReportSubmit = function (id) {
  return fetch({
    url: format(Landslide_Submit_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

/**Method for discarding the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
LandslideService.landslideReportDiscard = function (id) {
  return fetch({
    url: format(Landslide_Discard_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the basic section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddBasicSection = function (data) {
  return fetch({
    url: Landslide_Add_Basic_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for editing the basic section
 * @param {number} id Form Id
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideEditBasicSection = function (id, data) {
  return fetch({
    url: format(Landslide_Edit_Basic_Section, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Basic Section

//#region InfDamage Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the infDamage section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddInfDamageSection = function (data) {
  return fetch({
    url: Landslide_Add_InfDamage_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the infDamageOther section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddInfDamageOtherSection = function (data) {
  return fetch({
    url: Landslide_Add_InfDamageOther_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamageOther Section

//#region Remark Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the remark section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddRemarkSection = function (data) {
  return fetch({
    url: Landslide_Add_Remark_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Remark Section

//#region Popaffected Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 21-11-2022

/**
 * Method for adding the popaffected section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddPopAffectedSection = function (data) {
  return fetch({
    url: Landslide_Add_PopAffected_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Popaffected Section

//#region Eroded Section
//@author: dhruv.kumar@velsof.com
//@date : 24-11-2022

/**
 * Method for adding the eroded section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddErodedSection = function (data) {
  return fetch({
    url: Landslide_Add_Eroded_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion eroded Section

//#region Relief Camp Section
//@author: dhruv.kumar@velsof.com
//@date : 25-11-2022

/**
 * Method for adding the relief camp section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddReliefCampSection = function (data) {
  return fetch({
    url: Landslide_Add_ReliefCamp_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion relief camp Section

//#region Camp inmates Section
/**
 * Method for adding the camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddCampInmatesSection = function (data) {
  return fetch({
    url: Landslide_Add_CampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion camp inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the Human Live Lost section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddHLLSection = function (data) {
  return fetch({
    url: Landslide_Add_HLL_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the LiveStock section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddLiveStockSection = function (data) {
  return fetch({
    url: Landslide_Add_LiveStock_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion LiveStock Section

//#region House Damaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the House Damaged section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddHouseDamagedSection = function (data) {
  return fetch({
    url: Landslide_Add_HouseDamaged_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion House Damaged Section

//#region Rescue Operation Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the Rescue Operation section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddRescueOperationSection = function (data) {
  return fetch({
    url: Landslide_Add_RescueOperation_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Rescue Operation Section

//#region Relief Distribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the Relief Distribution section
 * @param {object} data API data
 * @returns Fetch Method
 */
LandslideService.landslideAddReliefDistributionSection = function (data) {
  return fetch({
    url: Landslide_Add_ReliefDistribution_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Relief Distribution Section

//#region Method for Approve and Disapprove
/**Method for approving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {number} id Form Id
 * @returns Fetch Method
 */
LandslideService.landslideRevenueReportApprove = function (payload) {
  return fetch({
    url: Landslide_Revenue_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
LandslideService.landslideRevenueReportDisapprove = function (payload) {
  return fetch({
    url: Landslide_Revenue_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
LandslideService.landslideRevenueReportVerify = function (payload) {
  return fetch({
    url: Landslide_Revenue_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};
/**
 * Method to approve the district approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} payload Payload
 * @returns Fetch Method
 */
LandslideService.landslideDistrictReportApprove = function (payload) {
  return fetch({
    url: Landslide_District_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
LandslideService.landslideDistrictReportDisapprove = function (payload) {
  return fetch({
    url: Landslide_District_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
LandslideService.landslideDistrictReportVerify = function (payload) {
  return fetch({
    url: Landslide_District_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method to approve the state approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} payload Payload
 * @returns Fetch Method
 */
LandslideService.landslideStateReportApprove = function (payload) {
  return fetch({
    url: Landslide_State_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
LandslideService.landslideStateReportDisapprove = function (payload) {
  return fetch({
    url: Landslide_State_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for getting the district review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
LandslideService.getLandslideDistrictReviewList = function (payload) {
  return fetch({
    url: Landslide_Get_District_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};

/**
 * Method for getting the state review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
LandslideService.getLandslideStateReviewList = function (payload) {
  return fetch({
    url: Landslide_Get_State_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};
//#endregion Method for Approve and Disapprove

//#region
/**
 * Below method is for downloading the exported file for viewLandslide.js
 */
LandslideService.getExportFoLevelLandslideReport = function (data) {
  return fetch({
    url: Landslide_Fo_Level_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
LandslideService.getExportStateCumulativeLandslideReport = function (data) {
  return fetch({
    url: Landslide_Cumulative_State_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
LandslideService.getExportDistrictCumulativeLandslideReport = function (data) {
  return fetch({
    url: Landslide_Cumulative_District_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion
export default LandslideService;
