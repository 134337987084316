/**
 * The below file is the Livestocks service which contains method for doing the API calls.
 * @author: shivam.sharma2@velsof.com
 * @date : 12-01-2023
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Get_All_Livestock_Types,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const LivestocksService = {};

//#region Method for Livestocks dropdown

/**
 * Method for getting the Livestocks dropdown
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
LivestocksService.getAllLivestockTypes = function () {
  return fetch({
    url: Get_All_Livestock_Types,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

//#endregion Method for Livestocks dropdown.

export default LivestocksService;
