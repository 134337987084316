/**
 * The below file is the infrastructure department service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-11-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_InfDepartment,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_InfDepartment_Status,
  InfDepartment_Import,
  Edit_InfDepartment,
  Get_All_InfDepartment,
  Get_InfDepartment,
  Get_Export_InfDepartment,
  Multipart_Form_Data,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const InfDepartmentService = {};

//#region Method for infDepartment listing page.

/**
 * Method for getting the infDepartment listing
 * @param {any} param API Parameter
 * @returns Fetch Method
 */
InfDepartmentService.getAllInfDepartment = function (param) {
  return fetch({
    url: Get_All_InfDepartment,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the infDepartment export data
 * @returns Fetch Method
 */
InfDepartmentService.getExportInfDepartmentData = function () {
  return fetch({
    url: Get_Export_InfDepartment,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the infDepartment status
 * @param {any} data API Data
 * @returns Fetch Method
 */
InfDepartmentService.changeInfDepartmentStatus = function (data) {
  return fetch({
    url: Change_InfDepartment_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for infDepartment listing page.

//#region Method for infDepartment add page.

/**
 * Method for adding the infDepartment
 * @param {any} data API Data
 * @returns Fetch Method
 */
InfDepartmentService.addInfDepartment = function (data) {
  return fetch({
    url: Add_InfDepartment,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for infDepartment add page.

//#region Method for infDepartment edit page.

/**
 * Method for editing the infDepartment
 * @param {number} id Infrastructure department id
 * @param {any} data API Data
 * @returns Fetch Method
 */
InfDepartmentService.editInfDepartment = function (id, data) {
  return fetch({
    url: format(Edit_InfDepartment, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the infDepartment detail
 * @param {any} data API Data
 * @returns Fetch Method
 */
InfDepartmentService.getInfDepartment = function (data) {
  return fetch({
    url: format(Get_InfDepartment, data.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion Method for infDepartment edit page.

//#region Method for infDepartment import page.

/**
 * Method for importing the infDepartment
 * @param {any} data API Data
 * @returns Fetch Method
 */
InfDepartmentService.infDepartmentImport = function (data) {
  return fetch({
    url: InfDepartment_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for infDepartment import page.

export default InfDepartmentService;
