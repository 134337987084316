/**
 * The below file contains the Sagas method for Fire reports.
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import {
  BasicSection,
  DiscardButton,
  RemarkSection,
  SubmitButton,
  HllSection,
  LiveStockSection,
  HouseDamagedSection,
  RescueOperationSection,
  ReliefDistributionSection,
  CallerDetailsSection,
  FireDetailsSection,
  PropertyInvolvedSection,
  TurnOutDetailsSection,
  ESPLSection,
  OwnerOfPropertySection,
  approveButton,
  disapproveButton,
} from "constants/ApplicationConstant";
import {
  Fire_Added_Basic_Section,
  Fire_Added_HLL_Section,
  Fire_Added_HouseDamaged_Section,
  Fire_Added_LiveStock_Section,
  Fire_Added_ReliefDistribution_Section,
  Fire_Added_Remark_Section,
  Fire_Added_RescueOperation_Section,
  Fire_Imported,
  Fire_Report_Discard,
  Fire_Report_Submit,
  Fire_Updated_Basic_Section,
  Fire_Added_Caller_Details_Section,
  Fire_Added_Fire_Details_Section,
  Fire_Added_Property_Involved_Section,
  Fire_Added_Turn_Out_Details_Section,
  Fire_Added_ESPL_Section,
  Fire_Added_Owner_of_Property_Section,
  Fire_Incident_Photo_Deleted,
  Fire_Report_Approved,
  Fire_Report_Disapproved,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  fireResetButtonLoading,
  fireSetAllSection,
  fireSetDataSaved,
  fireSetParentId,
  fireSetRedirectToListing,
  setExportFire,
  setFireListing,
  fireSetDeletedPhotoResponse,
  fireSetIncidentPhotoResponse,
  setExportFSLevelFireReport,
} from "redux/actions/Fire";
import {
  GET_ALL_FIRE,
  GET_EXPORT_FIRE_DATA,
  FIRE_ADD_BASIC_SECTION,
  FIRE_ADD_REMARK_SECTION,
  FIRE_EDIT_BASIC_SECTION,
  FIRE_GET_ALL_SECTION,
  FIRE_IMPORT,
  FIRE_REPORT_DISCARD,
  FIRE_REPORT_SUBMIT,
  FIRE_ADD_HLL_SECTION,
  FIRE_ADD_LIVESTOCK_SECTION,
  FIRE_ADD_HOUSEDAMAGED_SECTION,
  FIRE_ADD_RESCUEOPERATION_SECTION,
  FIRE_ADD_RELIEFDISTRIBUTION_SECTION,
  FIRE_ADD_CALLER_DETAILS_SECTION,
  FIRE_ADD_FIRE_DETAILS_SECTION,
  FIRE_ADD_PROPERTY_INVOLVED_SECTION,
  FIRE_ADD_TURN_OUT_DETAILS_SECTION,
  FIRE_ADD_ESPL_SECTION,
  FIRE_ADD_OWNER_OF_PROPERTY_SECTION,
  FIRE_DELETE_INCIDENT_PHOTO,
  FIRE_DFES_REPORT_APPROVE,
  FIRE_DFES_REPORT_DISAPPROVE,
  FIRE_SPFE_REPORT_APPROVE,
  FIRE_SPFE_REPORT_DISAPPROVE,
  FIRE_WING_REPORT_APPROVE,
  FIRE_WING_REPORT_DISAPPROVE,
  FIRE_HQCR_REPORT_APPROVE,
  FIRE_HQCR_REPORT_DISAPPROVE,
  EXPORT_FS_LEVEL_FIRE_REPORT,
} from "redux/constants/Fire";
import FireService from "services/FireService";

//#region Method for listing

/**
 * Method for getting the fires
 */
export function* getAllFire() {
  yield takeEvery(GET_ALL_FIRE, function* ({ payload }) {
    try {
      const fire = yield call(FireService.getAllFire, payload);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(setFireListing(fire));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the fire export details
 */
export function* getExportFsLevelFireReport() {
  yield takeEvery(EXPORT_FS_LEVEL_FIRE_REPORT, function* ({ payload }) {
    try {
      const fire = yield call(FireService.getExportFsLevelFireReport, payload);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        let exportData = {
          url: window.URL.createObjectURL(fire),
          format: payload.format,
        };
        yield put(setExportFSLevelFireReport(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for fire import page

/**
 * Method for importing the fire
 */
export function* fireImport() {
  yield takeEvery(FIRE_IMPORT, function* ({ payload }) {
    try {
      const fire = yield call(FireService.fireImport, payload);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Fire_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for fire import page

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 17-11-2022

/**
 * Method for getting the all section data
 */
export function* fireGetAllSection() {
  yield takeEvery(FIRE_GET_ALL_SECTION, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        FireService.fireGetAllSection,
        payload.id,
        payload.param
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(fireSetAllSection(infDepartment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for submitting the report
 */
export function* fireReportSubmit() {
  yield takeEvery(FIRE_REPORT_SUBMIT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        FireService.fireReportSubmit,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: Fire_Report_Submit, type: Success }));
        yield put(fireSetRedirectToListing(true));
        yield put(fireResetButtonLoading({ section: SubmitButton }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: SubmitButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for discarding the report
 */
export function* fireReportDiscard() {
  yield takeEvery(FIRE_REPORT_DISCARD, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        FireService.fireReportDiscard,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: Fire_Report_Discard, type: Success }));
        yield put(fireSetRedirectToListing(true));
        yield put(fireResetButtonLoading({ section: DiscardButton }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: DiscardButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 17-11-2022

/** Method for adding basic section
 */
export function* fireAddBasicSection() {
  yield takeEvery(FIRE_ADD_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };

      const fire = yield call(FireService.fireAddBasicSection, final);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireSetParentId(fire.data.id));
        yield put(fireResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({ message: Fire_Added_Basic_Section, type: Success })
        );
        yield put(fireSetDataSaved({ section: BasicSection, value: true }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for editing basic section
 */
export function* fireEditBasicSection() {
  yield takeEvery(FIRE_EDIT_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(
        FireService.fireEditBasicSection,
        payload.id,
        final
      );
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: Fire_Updated_Basic_Section,
            type: Success,
          })
        );
        yield put(fireSetDataSaved({ section: BasicSection, value: true }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Basic Section

//#region Remark Section
//@author: shivam.sharma2@velsof.com
//@date : 17-11-2022

/** Method for adding Remark section
 */
export function* fireAddRemarkSection() {
  yield takeEvery(FIRE_ADD_REMARK_SECTION, function* ({ payload }) {
    try {
      const Fire = yield call(FireService.fireAddRemarkSection, payload);
      if (Fire.error != null && Fire.error.message) {
        yield put(showMessage({ message: Fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: RemarkSection }));
        yield put(
          showMessage({
            message: Fire_Added_Remark_Section,
            type: Success,
          })
        );
        yield put(fireSetDataSaved({ section: RemarkSection, value: true }));
        yield put(fireSetIncidentPhotoResponse(Fire.data.incidentPhoto));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: RemarkSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Remark Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 01-12-2022

/** Method for adding Human Live Lost section
 */
export function* fireAddHLLSection() {
  yield takeEvery(FIRE_ADD_HLL_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(FireService.fireAddHLLSection, final);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: HllSection }));
        yield put(
          showMessage({
            message: Fire_Added_HLL_Section,
            type: Success,
          })
        );
        yield put(fireSetDataSaved({ section: HllSection, value: true }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: HllSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Human Live Lost Section

//#region Emergency Service Personnel Life Section
//@author: shivam.sharma2@velsof.com
//@date : 12-12-2022

/** Method for adding Emergency Service Personnel Life section
 */
export function* fireAddESPLSection() {
  yield takeEvery(FIRE_ADD_ESPL_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(FireService.fireAddESPLSection, final);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: ESPLSection }));
        yield put(
          showMessage({
            message: Fire_Added_ESPL_Section,
            type: Success,
          })
        );
        yield put(fireSetDataSaved({ section: ESPLSection, value: true }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: ESPLSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 01-12-2022

/** Method for adding LiveStock section
 */
export function* fireAddLiveStockSection() {
  yield takeEvery(FIRE_ADD_LIVESTOCK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(FireService.fireAddLiveStockSection, final);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: LiveStockSection }));
        yield put(
          showMessage({
            message: Fire_Added_LiveStock_Section,
            type: Success,
          })
        );
        yield put(fireSetDataSaved({ section: LiveStockSection, value: true }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: LiveStockSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: shivam.sharma2@velsof.com
//@date : 01-12-2022

/** Method for adding HouseDamaged section
 */
export function* fireAddHouseDamagedSection() {
  yield takeEvery(FIRE_ADD_HOUSEDAMAGED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(FireService.fireAddHouseDamagedSection, final);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: HouseDamagedSection }));
        yield put(
          showMessage({
            message: Fire_Added_HouseDamaged_Section,
            type: Success,
          })
        );
        yield put(
          fireSetDataSaved({ section: HouseDamagedSection, value: true })
        );
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: HouseDamagedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: shivam.sharma2@velsof.com
//@date : 01-12-2022

/** Method for adding RescueOperation section
 */
export function* fireAddRescueOperationSection() {
  yield takeEvery(FIRE_ADD_RESCUEOPERATION_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(FireService.fireAddRescueOperationSection, final);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: RescueOperationSection }));
        yield put(
          showMessage({
            message: Fire_Added_RescueOperation_Section,
            type: Success,
          })
        );
        yield put(
          fireSetDataSaved({ section: RescueOperationSection, value: true })
        );
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: RescueOperationSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: shivam.sharma2@velsof.com
//@date : 01-12-2022

/** Method for adding ReliefDistribution section
 */
export function* fireAddReliefDistributionSection() {
  yield takeEvery(FIRE_ADD_RELIEFDISTRIBUTION_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(
        FireService.fireAddReliefDistributionSection,
        final
      );
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(
          fireResetButtonLoading({ section: ReliefDistributionSection })
        );
        yield put(
          showMessage({
            message: Fire_Added_ReliefDistribution_Section,
            type: Success,
          })
        );
        yield put(
          fireSetDataSaved({
            section: ReliefDistributionSection,
            value: true,
          })
        );
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: ReliefDistributionSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion ReliefDistribution Section

//#region Caller Details Section
//@author: shivam.sharma2@velsof.com
//@date : 10-01-2023

/** Method for adding Caller Details section
 */
export function* fireAddCallerDetailsSection() {
  yield takeEvery(FIRE_ADD_CALLER_DETAILS_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(FireService.fireAddCallerDetailsSection, final);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: CallerDetailsSection }));
        yield put(
          showMessage({
            message: Fire_Added_Caller_Details_Section,
            type: Success,
          })
        );
        yield put(
          fireSetDataSaved({ section: CallerDetailsSection, value: true })
        );
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: CallerDetailsSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Caller Details Section

//#region Fire Details Section
//@author: shivam.sharma2@velsof.com
//@date : 10-01-2023

/** Method for adding Fire Details section
 */
export function* fireAddTurnoutDetailsSection() {
  yield takeEvery(FIRE_ADD_TURN_OUT_DETAILS_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(FireService.fireAddTurnOutDetailsSection, final);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: TurnOutDetailsSection }));
        yield put(
          showMessage({
            message: Fire_Added_Turn_Out_Details_Section,
            type: Success,
          })
        );
        yield put(
          fireSetDataSaved({ section: TurnOutDetailsSection, value: true })
        );
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: TurnOutDetailsSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for adding Fire Details section
 */
export function* fireAddFireDetailsSection() {
  yield takeEvery(FIRE_ADD_FIRE_DETAILS_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(FireService.fireAddFireDetailsSection, final);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: FireDetailsSection }));
        yield put(
          showMessage({
            message: Fire_Added_Fire_Details_Section,
            type: Success,
          })
        );
        yield put(
          fireSetDataSaved({ section: FireDetailsSection, value: true })
        );
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: FireDetailsSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for adding property involved section
 */
export function* fireAddPropertyInvolvedSection() {
  yield takeEvery(FIRE_ADD_PROPERTY_INVOLVED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(
        FireService.fireAddPropertyInvolvedSection,
        final
      );
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: PropertyInvolvedSection }));
        yield put(
          showMessage({
            message: Fire_Added_Property_Involved_Section,
            type: Success,
          })
        );
        yield put(
          fireSetDataSaved({ section: PropertyInvolvedSection, value: true })
        );
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: PropertyInvolvedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for adding Owner of Property section
 */
export function* fireAddOwnerOfPropertySection() {
  yield takeEvery(FIRE_ADD_OWNER_OF_PROPERTY_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const fire = yield call(FireService.fireAddOwnerOfPropertySection, final);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(fireResetButtonLoading({ section: OwnerOfPropertySection }));
        yield put(
          showMessage({
            message: Fire_Added_Owner_of_Property_Section,
            type: Success,
          })
        );
        yield put(
          fireSetDataSaved({ section: OwnerOfPropertySection, value: true })
        );
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: OwnerOfPropertySection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Fire Details Section

/** Method for deleting the incident photos
 */
export function* fireDeleteIncidentPhoto() {
  yield takeEvery(FIRE_DELETE_INCIDENT_PHOTO, function* ({ payload }) {
    try {
      const fire = yield call(FireService.fireDeleteIncidentPhoto, payload);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(
          showMessage({
            message: Fire_Incident_Photo_Deleted,
            type: Success,
          })
        );
        yield put(fireSetDeletedPhotoResponse(fire));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#region Method for Approval, Disapproval & Verify
/**
 * @author: shivam.sharma2@velsof.com
 * @date : 25-01-2023
 */
/**
 * Method for approving the HQCR report
 */
export function* fireHQCRReportApprove() {
  yield takeEvery(FIRE_HQCR_REPORT_APPROVE, function* ({ payload }) {
    try {
      const fireReportStatus = yield call(
        FireService.fireHQCRReportApprove,
        payload
      );
      if (fireReportStatus.error != null && fireReportStatus.error.message) {
        yield put(
          showMessage({
            message: fireReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Fire_Report_Approved, type: Success })
        );
        yield put(fireSetRedirectToListing(true));
        yield put(fireResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the revenue report
 */
export function* fireHQCRReportDisapprove() {
  yield takeEvery(FIRE_HQCR_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const fireReportStatus = yield call(
        FireService.fireHQCRReportDisapprove,
        payload
      );
      if (fireReportStatus.error != null && fireReportStatus.error.message) {
        yield put(
          showMessage({
            message: fireReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Fire_Report_Disapproved, type: Success })
        );
        yield put(fireSetRedirectToListing(true));
        yield put(fireResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: disapproveButton }));
    }
  });
}

/**
 * @author: shivam.sharma2@velsof.com
 * @date : 30-01-2023
 */
/**
 * Method for approving the Wing report
 */
export function* fireWingReportApprove() {
  yield takeEvery(FIRE_WING_REPORT_APPROVE, function* ({ payload }) {
    try {
      const fireReportStatus = yield call(
        FireService.fireWingReportApprove,
        payload
      );
      if (fireReportStatus.error != null && fireReportStatus.error.message) {
        yield put(
          showMessage({
            message: fireReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Fire_Report_Approved, type: Success })
        );
        yield put(fireSetRedirectToListing(true));
        yield put(fireResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the wing report
 */
export function* fireWingReportDisapprove() {
  yield takeEvery(FIRE_WING_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const fireReportStatus = yield call(
        FireService.fireWingReportDisapprove,
        payload
      );
      if (fireReportStatus.error != null && fireReportStatus.error.message) {
        yield put(
          showMessage({
            message: fireReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Fire_Report_Disapproved, type: Success })
        );
        yield put(fireSetRedirectToListing(true));
        yield put(fireResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: disapproveButton }));
    }
  });
}

/**
 * @author: shivam.sharma2@velsof.com
 * @date : 30-01-2023
 */
/**
 * Method for approving the SPFE report
 */
export function* fireSpfeReportApprove() {
  yield takeEvery(FIRE_SPFE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const fireReportStatus = yield call(
        FireService.fireSpfeReportApprove,
        payload
      );
      if (fireReportStatus.error != null && fireReportStatus.error.message) {
        yield put(
          showMessage({
            message: fireReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Fire_Report_Approved, type: Success })
        );
        yield put(fireSetRedirectToListing(true));
        yield put(fireResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the SPFE report
 */
export function* fireSpfeReportDisapprove() {
  yield takeEvery(FIRE_SPFE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const fireReportStatus = yield call(
        FireService.fireSpfeReportDisapprove,
        payload
      );
      if (fireReportStatus.error != null && fireReportStatus.error.message) {
        yield put(
          showMessage({
            message: fireReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Fire_Report_Disapproved, type: Success })
        );
        yield put(fireSetRedirectToListing(true));
        yield put(fireResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: disapproveButton }));
    }
  });
}

/**
 * Method for approving the DFES report
 */
export function* fireDfesReportApprove() {
  yield takeEvery(FIRE_DFES_REPORT_APPROVE, function* ({ payload }) {
    try {
      const fireReportStatus = yield call(
        FireService.fireDfesReportApprove,
        payload
      );
      if (fireReportStatus.error != null && fireReportStatus.error.message) {
        yield put(
          showMessage({
            message: fireReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Fire_Report_Approved, type: Success })
        );
        yield put(fireSetRedirectToListing(true));
        yield put(fireResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the DFES report
 */
export function* fireDfesReportDisapprove() {
  yield takeEvery(FIRE_DFES_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const fireReportStatus = yield call(
        FireService.fireDfesReportDisapprove,
        payload
      );
      if (fireReportStatus.error != null && fireReportStatus.error.message) {
        yield put(
          showMessage({
            message: fireReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Fire_Report_Disapproved, type: Success })
        );
        yield put(fireSetRedirectToListing(true));
        yield put(fireResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(fireResetButtonLoading({ section: disapproveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllFire),
    fork(getExportFsLevelFireReport),
    fork(fireImport),
    fork(fireAddBasicSection),
    fork(fireEditBasicSection),
    fork(fireGetAllSection),
    fork(fireAddRemarkSection),
    fork(fireReportSubmit),
    fork(fireReportDiscard),
    fork(fireAddHLLSection),
    fork(fireAddLiveStockSection),
    fork(fireAddHouseDamagedSection),
    fork(fireAddRescueOperationSection),
    fork(fireAddReliefDistributionSection),
    fork(fireAddCallerDetailsSection),
    fork(fireAddTurnoutDetailsSection),
    fork(fireAddFireDetailsSection),
    fork(fireAddPropertyInvolvedSection),
    fork(fireAddESPLSection),
    fork(fireAddOwnerOfPropertySection),
    fork(fireDeleteIncidentPhoto),
    fork(fireDfesReportApprove),
    fork(fireDfesReportDisapprove),
    fork(fireSpfeReportApprove),
    fork(fireSpfeReportDisapprove),
    fork(fireWingReportApprove),
    fork(fireWingReportDisapprove),
    fork(fireHQCRReportApprove),
    fork(fireHQCRReportDisapprove),
  ]);
}
