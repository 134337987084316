/**
 * The below file contains the Sagas method for RescueEquipment.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_RESCUEEQUIPMENT,
  CHANGE_RESCUEEQUIPMENT_STATUS,
  RESCUEEQUIPMENT_IMPORT,
  EDIT_RESCUEEQUIPMENT,
  GET_ALL_RESCUEEQUIPMENT,
  GET_RESCUEEQUIPMENT,
  GET_EXPORT_RESCUEEQUIPMENT_DATA,
} from "../constants/RescueEquipment";

import RescueEquipmentService from "services/RescueEquipmentService";
import {
  setRescueEquipment,
  setRescueEquipmentListing,
  setExportRescueEquipment,
  updateRescueEquipmentStatus,
} from "redux/actions/RescueEquipment";
import { showMessage } from "redux/actions/Common";
import {
  RescueEquipment_Added,
  RescueEquipment_Imported,
  RescueEquipment_Updated,
} from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";

//#region Method for rescueEquipment listing

/**
 * Method for getting the rescueEquipment details
 */
export function* getAllRescueEquipment() {
  yield takeEvery(GET_ALL_RESCUEEQUIPMENT, function* ({ payload }) {
    try {
      const rescueEquipment = yield call(
        RescueEquipmentService.getAllRescueEquipment,
        payload
      );
      if (rescueEquipment.error != null && rescueEquipment.error.message) {
        yield put(
          showMessage({ message: rescueEquipment.error.message, type: Error })
        );
      } else {
        yield put(setRescueEquipmentListing(rescueEquipment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the rescueEquipment export details
 */
export function* getRescueEquipmentExport() {
  yield takeEvery(GET_EXPORT_RESCUEEQUIPMENT_DATA, function* () {
    try {
      const rescueEquipment = yield call(
        RescueEquipmentService.getExportRescueEquipmentData
      );
      if (rescueEquipment.error != null && rescueEquipment.error.message) {
        yield put(
          showMessage({ message: rescueEquipment.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(rescueEquipment),
        };
        yield put(setExportRescueEquipment(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the rescueEquipment status details
 */
export function* changeRescueEquipmentStatus() {
  yield takeEvery(CHANGE_RESCUEEQUIPMENT_STATUS, function* ({ payload }) {
    try {
      const rescueEquipment = yield call(
        RescueEquipmentService.changeRescueEquipmentStatus,
        payload
      );
      if (rescueEquipment.error != null && rescueEquipment.error.message) {
        yield put(
          showMessage({ message: rescueEquipment.error.message, type: Error })
        );
      } else {
        let json = {
          id: rescueEquipment.data.id,
          status: rescueEquipment.data.status,
        };
        yield put(updateRescueEquipmentStatus(json));
        yield put(
          showMessage({ message: RescueEquipment_Updated, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for rescueEquipment listing

//#region Method for rescueEquipment add page

/**
 * Method for adding the rescueEquipment
 */
export function* addRescueEquipment() {
  yield takeEvery(ADD_RESCUEEQUIPMENT, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const rescueEquipment = yield call(
        RescueEquipmentService.addRescueEquipment,
        final
      );
      if (rescueEquipment.error != null && rescueEquipment.error.message) {
        yield put(
          showMessage({ message: rescueEquipment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: RescueEquipment_Added, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for rescueEquipment add page

//#region Method for rescueEquipment edit page

/**
 * Method for editing the rescueEquipment
 */
export function* editRescueEquipment() {
  yield takeEvery(EDIT_RESCUEEQUIPMENT, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          rescueEquipmentName: payload.rescueEquipmentName,
          rescueEquipmentCode: payload.rescueEquipmentCode,
          status: payload.status,
          state: payload.state,
        },
      };
      const rescueEquipment = yield call(
        RescueEquipmentService.editRescueEquipment,
        id,
        final
      );
      if (rescueEquipment.error != null && rescueEquipment.error.message) {
        yield put(
          showMessage({ message: rescueEquipment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: RescueEquipment_Updated, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the rescueEquipment detail
 */
export function* getRescueEquipment() {
  yield takeEvery(GET_RESCUEEQUIPMENT, function* ({ payload }) {
    try {
      const rescueEquipment = yield call(
        RescueEquipmentService.getRescueEquipment,
        payload
      );
      if (rescueEquipment.error != null && rescueEquipment.error.message) {
        yield put(
          showMessage({ message: rescueEquipment.error.message, type: Error })
        );
      } else {
        yield put(setRescueEquipment(rescueEquipment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for rescueEquipment edit page

//#region Method for rescueEquipment import page

/**
 * Method for importing the rescueEquipment
 */
export function* rescueEquipmentImport() {
  yield takeEvery(RESCUEEQUIPMENT_IMPORT, function* ({ payload }) {
    try {
      const rescueEquipment = yield call(
        RescueEquipmentService.rescueEquipmentImport,
        payload
      );
      if (rescueEquipment.error != null && rescueEquipment.error.message) {
        yield put(
          showMessage({ message: rescueEquipment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: RescueEquipment_Imported, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for rescueEquipment import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllRescueEquipment),
    fork(addRescueEquipment),
    fork(editRescueEquipment),
    fork(getRescueEquipment),
    fork(getRescueEquipmentExport),
    fork(changeRescueEquipmentStatus),
    fork(rescueEquipmentImport),
  ]);
}
