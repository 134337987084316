/**
 * The below file contains the Sagas method for Origin Area.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Origin_Area_Imported,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setAllOriginArea,
} from "redux/actions/OriginArea";
import {
  GET_ALL_ORIGIN_AREA,
} from "redux/constants/OriginArea";
import OriginAreaService from "services/OriginService";

//#region Method for listing

/**
 * Method for getting the origin area
 */
export function* getAllOriginArea() {
  yield takeEvery(GET_ALL_ORIGIN_AREA, function* () {
    try {
      const originArea = yield call(OriginAreaService.getAllOriginArea);
      if (originArea.error != null && originArea.error.message) {
        yield put(showMessage({ message: originArea.error.message, type: Error }));
      } else {
        yield put(setAllOriginArea(originArea));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion Method for the origin area

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllOriginArea),
  ]);
}
