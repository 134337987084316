/**
 * The below file contains the constants used in the lightning report.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 26-12-2022
 */

/**Constants for lightning Listing page */
export const GET_ALL_LIGHTNING = "GET_ALL_LIGHTNING";
export const SET_LIGHTNING_LISTING = "SET_LIGHTNING_LISTING";
export const SET_LIGHTNING_LISTING_PARAM = "SET_LIGHTNING_LISTING_PARAM";
export const GET_EXPORT_LIGHTNING_DATA = "GET_EXPORT_LIGHTNING_DATA";
export const SET_EXPORT_LIGHTNING_DATA = "SET_EXPORT_LIGHTNING_DATA";
/**Constants for lightning Listing page */

/**Constants for lightning Import page */
export const LIGHTNING_IMPORT = "LIGHTNING_IMPORT";
/**Constants for lightning Import page */

//#region All Section
/**Constants for all section details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_GET_ALL_SECTION = "LIGHTNING_GET_ALL_SECTION";
export const LIGHTNING_SET_ALL_SECTION = "LIGHTNING_SET_ALL_SECTION";
export const LIGHTNING_RESET_BUTTON_LOADING = "LIGHTNING_RESET_BUTTON_LOADING";
export const LIGHTNING_SET_PARENT_ID = "LIGHTNING_SET_PARENT_ID";
export const LIGHTNING_REPORT_SUBMIT = "LIGHTNING_REPORT_SUBMIT";
export const LIGHTNING_REPORT_DISCARD = "LIGHTNING_REPORT_DISCARD";
export const LIGHTNING_SET_REDIRECT_TO_LISTING =
  "LIGHTNING_SET_REDIRECT_TO_LISTING";
export const LIGHTNING_SET_DATA_SAVED = "LIGHTNING_SET_DATA_SAVED";
//#endregion All Section

//#region Basic Section
/**Constants for adding the basic section details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_ADD_BASIC_SECTION = "LIGHTNING_ADD_BASIC_SECTION";
export const LIGHTNING_EDIT_BASIC_SECTION = "LIGHTNING_EDIT_BASIC_SECTION";
export const LIGHTNING_SET_NIL_REPORTING = "LIGHTNING_SET_NIL_REPORTING";
//#endregion Basic Section

//#region InfDamageOther Section
/**Constants for adding the infrastructure others damage details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_ADD_INFDAMAGEOTHER_SECTION =
  "LIGHTNING_ADD_INFDAMAGEOTHER_SECTION";
//#endregion InfDamageOther Section

//#region Remark Section
/**Constants for adding the Remark details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_ADD_REMARK_SECTION = "LIGHTNING_ADD_REMARK_SECTION";
//#endregion Remark Section

//#region PopAffected Section
/**Constants for adding the population affected details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_ADD_POPAFFECTED_SECTION =
  "LIGHTNING_ADD_POPAFFECTED_SECTION";
//#endregion PopAffected Section

//#region Relief Camp Section
/**Constants for adding the relief camp details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_ADD_RELIEFCAMP_SECTION =
  "LIGHTNING_ADD_RELIEFCAMP_SECTION";
//#endregion relief camp Section

//#region Camp Inmates Section
/**Constants for adding the camp Inmates details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_ADD_CAMPINMATES_SECTION =
  "LIGHTNING_ADD_CAMPINMATES_SECTION";
//#endregion Camp Inmates Section

//#region Human Live Lost Section
/**Constants for adding the Human Live Lost details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_ADD_HLL_SECTION = "LIGHTNING_ADD_HLL_SECTION";
//#endregion Human Live Lost Section

//#region LiveStock Section
/**Constants for adding the LiveStock details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_ADD_LIVESTOCK_SECTION =
  "LIGHTNING_ADD_LIVESTOCK_SECTION";
//#endregion LiveStock Section

//#region HouseDamaged Section
/**Constants for adding the HouseDamaged details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_ADD_HOUSEDAMAGED_SECTION =
  "LIGHTNING_ADD_HOUSEDAMAGED_SECTION";
//#endregion HouseDamaged Section

//#region RescueOperation Section
/**Constants for adding the RescueOperation details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_ADD_RESCUEOPERATION_SECTION =
  "LIGHTNING_ADD_RESCUEOPERATION_SECTION";
//#endregion RescueOperation Section

//#region ReliefDistribution Section
/**Constants for adding the ReliefDistribution details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const LIGHTNING_ADD_RELIEFDISTRIBUTION_SECTION =
  "LIGHTNING_ADD_RELIEFDISTRIBUTION_SECTION";
//#endregion ReliefDistribution Section

/**
 * Constants for Reports and Approval and Disapproval
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
//#region Report and Approval and Disapproval
export const LIGHTNING_REVENUE_REPORT_APPROVE =
  "LIGHTNING_REVENUE_REPORT_APPROVE";
export const LIGHTNING_REVENUE_REPORT_DISAPPROVE =
  "LIGHTNING_REVENUE_REPORT_DISAPPROVE";
export const LIGHTNING_REVENUE_REPORT_VERIFY =
  "LIGHTNING_REVENUE_REPORT_VERIFY";

export const EXPORT_FO_LEVEL_LIGHTNING_REPORT =
  "EXPORT_FO_LEVEL_LIGHTNING_REPORT";
export const SET_EXPORT_FO_LEVEL_LIGHTNING_REPORT =
  "SET_EXPORT_FO_LEVEL_LIGHTNING_REPORT";

export const LIGHTNING_DISTRICT_REPORT_APPROVE =
  "LIGHTNING_DISTRICT_REPORT_APPROVE";
export const LIGHTNING_DISTRICT_REPORT_DISAPPROVE =
  "LIGHTNING_DISTRICT_REPORT_DISAPPROVE";
export const LIGHTNING_DISTRICT_REPORT_VERIFY =
  "LIGHTNING_DISTRICT_REPORT_VERIFY";

export const LIGHTNING_STATE_REPORT_APPROVE = "LIGHTNING_STATE_REPORT_APPROVE";
export const LIGHTNING_STATE_REPORT_DISAPPROVE =
  "LIGHTNING_STATE_REPORT_DISAPPROVE";

export const GET_LIGHTNING_DISTRICT_REVIEW_LIST =
  "GET_LIGHTNING_DISTRICT_REVIEW_LIST";
export const SET_LIGHTNING_DISTRICT_REVIEW_LIST =
  "SET_LIGHTNING_DISTRICT_REVIEW_LIST";
export const GET_LIGHTNING_STATE_REVIEW_LIST =
  "GET_LIGHTNING_STATE_REVIEW_LIST";
export const SET_LIGHTNING_STATE_REVIEW_LIST =
  "SET_LIGHTNING_STATE_REVIEW_LIST";

export const SET_DISTRICT_LIGHTNING_REPORT = "SET_DISTRICT_LIGHTNING_REPORT";
export const GET_DISTRICT_LIGHTNING_REPORT = "GET_DISTRICT_LIGHTNING_REPORT";
export const GET_STATE_CUMULATIVE_LIGHTNING_REPORT =
  "GET_STATE_CUMULATIVE_LIGHTNING_REPORT";
export const SET_STATE_CUMULATIVE_LIGHTNING_REPORT =
  "SET_STATE_CUMULATIVE_LIGHTNING_REPORT";
export const GET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT =
  "GET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT";
export const SET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT =
  "SET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT";
//#endregion Report and Approval and Disapproval
