/**
 * The below file is the relief camp service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-11-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_ReliefCamp,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_ReliefCamp_Status,
  Edit_ReliefCamp,
  Get_All_ReliefCamp,
  Get_Export_ReliefCamp,
  Get_ReliefCamp,
  Multipart_Form_Data,
  ReliefCamp_Import,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const ReliefCampService = {};

//#region Method for listing page.

/**
 * Method for getting the reliefCamp listing
 * @param {any} param API Parameter
 * @returns Fetch Method
 */
ReliefCampService.getAllReliefCamp = function (param) {
  return fetch({
    url: Get_All_ReliefCamp,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the reliefCamp export data
 * @returns Fetch Method
 */
ReliefCampService.getExportReliefCampData = function () {
  return fetch({
    url: Get_Export_ReliefCamp,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the relief camp status
 * @param {any} data API Data
 * @returns Fetch Method
 */
ReliefCampService.changeReliefCampStatus = function (data) {
  return fetch({
    url: Change_ReliefCamp_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for listing page.

//#region Method for add page.

/**
 * Method for adding the reliefCamp
 * @param {anu} data API Data
 * @returns Fetch Method
 */
ReliefCampService.addReliefCamp = function (data) {
  return fetch({
    url: Add_ReliefCamp,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for add page.

//#region Method for edit page.

/**
 * Method for editing the reliefCamp
 * @param {number} id Relief Camp Id
 * @param {any} data API Data
 * @returns Fetch Method
 */
ReliefCampService.editReliefCamp = function (id, data) {
  return fetch({
    url: format(Edit_ReliefCamp, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the reliefCamp detail
 * @param {any} reliefCamp Relief Camp Object
 * @param {any} param API Param
 * @returns Fetch Method
 */
ReliefCampService.getReliefCamp = function (reliefCamp, param) {
  return fetch({
    url: format(Get_ReliefCamp, reliefCamp.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};
//#endregion Method for edit page.

//#region Method for reliefCamp import page.

/**
 * Method for importing the reliefCamp
 * @param {any} data API data
 * @returns Fetch Method
 */
ReliefCampService.reliefCampImport = function (data) {
  return fetch({
    url: ReliefCamp_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for reliefCamp import page.

export default ReliefCampService;
