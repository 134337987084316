/**
 * The below file contains the common actions.
 * @author: neeraj.singh.rawat@velsof.com
 * @Date : 21-10-2022
 */

import {
  HIDE_LOADING,
  SHOW_LOADING,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  SHOW_PAGE_LOADING,
  HIDE_PAGE_LOADING,
} from "redux/constants/Common";

/**
 * Method for showing the message
 * @param {any} data API Parameters
 * @returns Json Object
 */
export const showMessage = (data) => {
  return {
    type: SHOW_MESSAGE,
    payload: data,
  };
};

/**
 * Method for hiding the message
 * @returns Json Object
 */
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

/**
 * Method for showing the loader
 * @returns Json Object
 */
export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

/**
 * Method for hiding the loader
 * @returns Json Object
 */
export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
  };
};

/**
 * Method for showing the loader on the page
 * @returns Json Object
 */
export const showPageLoading = () => {
  return {
    type: SHOW_PAGE_LOADING,
  };
};

/**
 * Method for hiding the loader on the page
 * @returns Json Object
 */
export const hidePageLoading = () => {
  return {
    type: HIDE_PAGE_LOADING,
  };
};
