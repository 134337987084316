/**
 * The below file contains the Sagas method for Fire Detection.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Fire_Detection_Imported,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setAllFireDetection,
} from "redux/actions/FireDetection";
import {
  GET_ALL_FIRE_DETECTION,
} from "redux/constants/FireDetection";
import FireDetectionService from "services/FireDetectionService";

//#region Method for listing

/**
 * Method for getting the fire class
 */
export function* getAllFireDetection() {
  yield takeEvery(GET_ALL_FIRE_DETECTION, function* () {
    try {
      const fireDetection = yield call(FireDetectionService.getAllFireDetection);
      if (fireDetection.error != null && fireDetection.error.message) {
        yield put(showMessage({ message: fireDetection.error.message, type: Error }));
      } else {
        yield put(setAllFireDetection(fireDetection));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion Method for listing

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllFireDetection),
  ]);
}
