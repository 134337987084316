/**
 * The below file contains the action methods for Resource Document.
 * @author: dhruv.kumar@velsof.com
 * @date : 28-07-2023
 */

import {
  ADD_RESOURCEDOCUMENT,
  EDIT_RESOURCEDOCUMENT,
  GET_ALL_RESOURCEDOCUMENT,
  GET_RESOURCEDOCUMENT,
  SET_RESOURCEDOCUMENT,
  SET_RESOURCEDOCUMENT_LISTING,
  SET_RESOURCEDOCUMENT_LISTING_PARAM,
} from "../constants/ResourceDocument";

//#region Action methods for resourceDocument listing page

/**
 * Method for getting the resourceDocument listing
 * @param {any} params Parameter Data
 * @returns Json Object
 */
export const getAllResourceDocument = (params) => {
  return {
    type: GET_ALL_RESOURCEDOCUMENT,
    payload: params,
  };
};

/**
 * Method for setting the resourceDocument in the state
 * @param {any} resourceDocument Resource Document Data
 * @returns Json Object
 */
export const setResourceDocumentListing = (resourceDocument) => {
  return {
    type: SET_RESOURCEDOCUMENT_LISTING,
    payload: resourceDocument,
  };
};

/**
 * Method for setting the resourceDocument parameters
 * @param {any} data Resource Document Data
 * @returns Json Object
 */
export const setResourceDocumentTableParam = (data) => {
  return {
    type: SET_RESOURCEDOCUMENT_LISTING_PARAM,
    payload: data,
  };
};
//#endregion Action methods for resourceDocument listing page

//#region Action methods for resourceDocument add page
/**
 * Method for adding the resourceDocument
 * @param {any} data Resource Document Data
 * @returns Json Object
 */
export const addResourceDocument = (data) => {
  return {
    type: ADD_RESOURCEDOCUMENT,
    payload: data,
  };
};
//#endregion Action methods for resourceDocument add page

//#region Action methods for resourceDocument edit page

/**
 * Method for editing the resourceDocument
 * @param {any} data Resource Document Data
 * @returns Json Object
 */
export const editResourceDocument = (data) => {
  return {
    type: EDIT_RESOURCEDOCUMENT,
    payload: data,
  };
};

/**
 * Method for getting the resourceDocument details
 * @param {any} id Resource Document Id
 * @returns Json Object
 */
export const getResourceDocument = (id) => {
  return {
    type: GET_RESOURCEDOCUMENT,
    payload: id,
  };
};

/**
 * Method for setting the resourceDocument details in the state
 * @param {any} resourceDocument Resource Document Data
 * @returns Json Object
 */
export const setResourceDocument = (resourceDocument) => {
  return {
    type: SET_RESOURCEDOCUMENT,
    payload: resourceDocument,
  };
};
//#endregion Action methods for resourceDocument edit page
