/**
 * The below file contains the Sagas method for District.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 04-10-2022
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_DISTRICT,
  CHANGE_DISTRICT_STATUS,
  DISTRICT_IMPORT,
  EDIT_DISTRICT,
  GET_ALL_DISTRICT,
  GET_DISTRICT,
  GET_EXPORT_DISTRICT_DATA,
} from "../constants/District";

import DistrictService from "services/DistrictService";
import {
  setDistrict,
  setDistrictListing,
  setExportDistrict,
  updateDistrictStatus,
} from "redux/actions/District";
import { showMessage } from "redux/actions/Common";
import {
  District_Added,
  District_Imported,
  District_Updated,
} from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";

//#region Method for district listing

/**
 * Method for getting the district details
 */
export function* getAllDistrict() {
  yield takeEvery(GET_ALL_DISTRICT, function* ({ payload }) {
    try {
      const district = yield call(DistrictService.getAllDistrict, payload);
      if (district.error != null && district.error.message) {
        yield put(
          showMessage({ message: district.error.message, type: Error })
        );
      } else {
        yield put(setDistrictListing(district));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the district export details
 */
export function* getDistrictExport() {
  yield takeEvery(GET_EXPORT_DISTRICT_DATA, function* () {
    try {
      const district = yield call(DistrictService.getExportDistrictData);
      if (district.error != null && district.error.message) {
        yield put(
          showMessage({ message: district.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(district),
        };
        yield put(setExportDistrict(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the district status details
 */
export function* changeDistrictStatus() {
  yield takeEvery(CHANGE_DISTRICT_STATUS, function* ({ payload }) {
    try {
      const district = yield call(
        DistrictService.changeDistrictStatus,
        payload
      );
      if (district.error != null && district.error.message) {
        yield put(
          showMessage({ message: district.error.message, type: Error })
        );
      } else {
        let json = {
          id: district.data.id,
          status: district.data.status,
        };
        yield put(updateDistrictStatus(json));
        yield put(showMessage({ message: District_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for district listing

//#region Method for district add page

/**
 * Method for adding the district
 */
export function* addDistrict() {
  yield takeEvery(ADD_DISTRICT, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const district = yield call(DistrictService.addDistrict, final);
      if (district.error != null && district.error.message) {
        yield put(
          showMessage({ message: district.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: District_Added, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for district add page

//#region Method for district edit page

/**
 * Method for editing the district
 */
export function* editDistrict() {
  yield takeEvery(EDIT_DISTRICT, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          districtName: payload.districtName,
          districtCode: payload.districtCode,
          status: payload.status,
          state: payload.state,
        },
      };
      const district = yield call(DistrictService.editDistrict, id, final);
      if (district.error != null && district.error.message) {
        yield put(
          showMessage({ message: district.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: District_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the district detail
 */
export function* getDistrict() {
  yield takeEvery(GET_DISTRICT, function* ({ payload }) {
    try {
      const district = yield call(DistrictService.getDistrict, payload);
      if (district.error != null && district.error.message) {
        yield put(
          showMessage({ message: district.error.message, type: Error })
        );
      } else {
        yield put(setDistrict(district));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for district edit page

//#region Method for district import page

/**
 * Method for importing the district
 */
export function* districtImport() {
  yield takeEvery(DISTRICT_IMPORT, function* ({ payload }) {
    try {
      const district = yield call(DistrictService.districtImport, payload);
      if (district.error != null && district.error.message) {
        yield put(
          showMessage({ message: district.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: District_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for district import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllDistrict),
    fork(addDistrict),
    fork(editDistrict),
    fork(getDistrict),
    fork(getDistrictExport),
    fork(changeDistrictStatus),
    fork(districtImport),
  ]);
}
