/**
 * The below file is the unit service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_Unit,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_Unit_Status,
  Unit_Import,
  Edit_Unit,
  Get_All_Unit,
  Get_Unit,
  Get_Export_Unit,
  Multipart_Form_Data,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const UnitService = {};

//#region Method for unit listing page.

/**
 * Method for getting the unit listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
UnitService.getAllUnit = function (param) {
  return fetch({
    url: Get_All_Unit,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the unit export data
 * @returns Fetch Method
 */
UnitService.getExportUnitData = function () {
  return fetch({
    url: Get_Export_Unit,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the unit status
 * @param {any} data API Data
 * @returns Fetch Method
 */
UnitService.changeUnitStatus = function (data) {
  return fetch({
    url: Change_Unit_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for unit listing page.

//#region Method for unit add page.

/**
 * Method for adding the unit
 * @param {any} data API Data
 * @returns Fetch Method
 */
UnitService.addUnit = function (data) {
  return fetch({
    url: Add_Unit,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for unit add page.

//#region Method for unit edit page.

/**
 * Method for editing the unit
 * @param {number} id Unit Id
 * @param {any} data API Data
 * @returns Fetch Method
 */
UnitService.editUnit = function (id, data) {
  return fetch({
    url: format(Edit_Unit, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the unit detail
 * @param {any} data API Data
 * @returns Fetch Method
 */
UnitService.getUnit = function (data) {
  return fetch({
    url: format(Get_Unit, data.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion Method for unit edit page.

//#region Method for unit import page.

/**
 * Method for importing the unit
 * @param {any} data API Data
 * @returns Fetch Method
 */
UnitService.unitImport = function (data) {
  return fetch({
    url: Unit_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for unit import page.

export default UnitService;
