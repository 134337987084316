/**
 * The below file contains the Sagas method for Types of occupancy
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Types_of_Occupancy_Imported,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setAllTypesOfOccupancy,
} from "redux/actions/TypesOfOccupancy";
import {
  GET_ALL_TYPES_OF_OCCUPANCY,
} from "redux/constants/TypesOfOccupancy";
import TypesOfOccupancyService from "services/TypesOfOccupancy";

//#region Method for listing

/**
 * Method for getting the Types of occupancy
 */
export function* getAllTypesOfOccupancy() {
  yield takeEvery(GET_ALL_TYPES_OF_OCCUPANCY, function* () {
    try {
      const TypesOfOccupancy = yield call(TypesOfOccupancyService.getAllTypesOfOccupancy);
      if (TypesOfOccupancy.error != null && TypesOfOccupancy.error.message) {
        yield put(showMessage({ message: TypesOfOccupancy.error.message, type: Error }));
      } else {
        yield put(setAllTypesOfOccupancy(TypesOfOccupancy));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion Method for the Types of occupancy

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllTypesOfOccupancy),
  ]);
}
