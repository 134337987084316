/**
 * The below file contains the infrastruture department state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-11-2022
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_INFDEPARTMENT,
  EDIT_INFDEPARTMENT,
  SET_INFDEPARTMENT,
  SET_INFDEPARTMENT_LISTING_PARAM,
  SET_INFDEPARTMENT_LISTING,
  UPDATE_INFDEPARTMENT_STATUS,
  SET_EXPORT_INFDEPARTMENT_DATA,
  GET_EXPORT_INFDEPARTMENT_DATA,
} from "../constants/InfDepartment";

//Initial state
const initState = {
  //Keys for infDepartment listing
  infDepartmentList: {},
  isInfDepartmentDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  infDepartmentListingParam: {},
  infDepartmentExportedData: {},
  //Keys for infDepartment listing

  //Keys for infDepartment add page
  addInfDepartmentForm: {},
  //Keys for infDepartment add page

  //Keys for infDepartment edit page
  infDepartmentFormData: {},
  //Keys for infDepartment edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const infDepartment = (state = initState, action) => {
  switch (action.type) {
    //Cases for InfDepartment listing
    case SET_INFDEPARTMENT_LISTING:
      return {
        ...state,
        infDepartmentList: action.payload,
        isInfDepartmentDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_INFDEPARTMENT_LISTING_PARAM:
      return {
        ...state,
        infDepartmentListingParam: action.payload,
      };

    case UPDATE_INFDEPARTMENT_STATUS:
      let updateInfDepartmentListData = {
        ...state.infDepartmentList,
        data: state.infDepartmentList.data?.map((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        }),
      };
      return {
        ...state,
        infDepartmentList: updateInfDepartmentListData,
      };

    case GET_EXPORT_INFDEPARTMENT_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_INFDEPARTMENT_DATA:
      return {
        ...state,
        infDepartmentExportedData: action.payload,
      };
    //Cases for InfDepartment listing

    //Cases for InfDepartment add page
    case ADD_INFDEPARTMENT:
      return {
        ...state,
        addInfDepartmentForm: action.payload,
      };
    //Cases for InfDepartment add page

    //Cases for InfDepartment edit page
    case EDIT_INFDEPARTMENT:
      return {
        ...state,
        addInfDepartmentForm: action.payload,
      };
    case SET_INFDEPARTMENT:
      return {
        ...state,
        infDepartmentFormData: action.payload,
      };
    //Cases for InfDepartment edit page

    default:
      return state;
  }
};

export default infDepartment;
