/**
 * The below file contains the action methods for fire agency master.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import {
  GET_ALL_FIRE_AGENCY,
  SET_ALL_FIRE_AGENCY,
} from "redux/constants/FireAgency";

//#region Action methods for fetching fire agency

/**
 * Method for getting the fire agency
 * @param {any} params Fire Data
 * @returns Json Object
 */
export const getAllFireAgency = () => {
  return {
    type: GET_ALL_FIRE_AGENCY,
  };
};

/**
 * Method for setting the fire agency
 * @param {any} params Fire agency Data
 * @returns Json Object
 */
export const setAllFireAgency = (data) => {
  return {
    type: SET_ALL_FIRE_AGENCY,
    payload: data,
  };
};

//#endregion