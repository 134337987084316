/**
 * The below file contains the action methods for disaster listing page.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-11-2022
 */

import {
  GET_DISASTER_LISTING,
  SET_DISASTER_LISTING,
  SET_DISASTER_LISTING_PARAM,
} from "redux/constants/Disaster";

/**
 * Method for getting the disaster listing
 * @param {any} data Disaster Parameters
 * @returns Json Object
 */
export const getDisasterListing = (data) => {
  return {
    type: GET_DISASTER_LISTING,
    payload: data,
  };
};

/**
 * Method for setting the disaster in the state
 * @param {any} data Disaster data
 * @returns Json Object
 */
export const setDisasterListing = (data) => {
  return {
    type: SET_DISASTER_LISTING,
    payload: data,
  };
};

/**
 * Method for setting the disaster parameters
 * @param {any} data Disaster params
 * @returns Json Object
 */
export const setDisasterListingParam = (data) => {
  return {
    type: SET_DISASTER_LISTING_PARAM,
    payload: data,
  };
};
