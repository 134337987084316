/**
 * The below file contains the Station state objects
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  SET_ALL_STATION,
  SET_STATION_LISTING_PARAM,
} from "redux/constants/Station";

//Initial state
const initState = {
  // Keys for dropdown
  stations: [],
  isStationDataFetched: false,
  stationParam: {},
  // Keys for dropdown
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const station = (state = initState, action) => {
  switch (action.type) {
    //Cases for Station dropdown
    case SET_ALL_STATION:
      return {
        ...state,
        stations: action.payload,
        isStationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_STATION_LISTING_PARAM:
      return {
        ...state,
        stationParam: action.payload,
      };
    //Cases for Station dropdown

    default:
      return state;
  }
};

export default station;
