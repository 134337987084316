/**
 * The below file contains the constants used in the role master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-02-2023
 */

/**Constants for Role Listing page */
export const GET_ALL_ROLE = "GET_ALL_ROLE";
export const SET_ROLE_LISTING = "SET_ROLE_LISTING";
export const SET_ROLE_LISTING_PARAM = "SET_ROLE_LISTING_PARAM";
export const GET_EXPORT_ROLE_DATA = "GET_EXPORT_ROLE_DATA";
export const SET_EXPORT_ROLE_DATA = "SET_EXPORT_ROLE_DATA";
/**Constants for Role Listing page */

/**Constants for Role Add page */
export const ADD_ROLE = "ADD_ROLE";
/**Constants for Role Add page */

/**Constants for Role Edit page */
export const EDIT_ROLE = "EDIT_ROLE";
export const GET_ROLE = "GET_ROLE";
export const SET_ROLE = "SET_ROLE";
/**Constants for Role Edit page */

export const GET_ALL_PERMISSION = "GET_ALL_PERMISSION";
export const SET_ALL_PERMISSION = "SET_ALL_PERMISSION";
export const GET_ALL_ROLE_ID = "GET_ALL_ROLE_ID";
export const SET_ALL_ROLE_ID = "SET_ALL_ROLE_ID";
