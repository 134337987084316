/**
 * File contains the action method related to Apache Superset
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 24-03-2023
 */

import {
  GET_GUEST_TOKEN,
  SET_GUEST_TOKEN,
  SET_SUPERSET_LOGIN_RESPONSE,
  SUPERSET_LOGIN,
} from "../constants/ApacheSuperset";

export const supersetLogin = (data) => {
  return {
    type: SUPERSET_LOGIN,
    payload: data,
  };
};

export const setSupersetLoginResponse = (data) => {
  return {
    type: SET_SUPERSET_LOGIN_RESPONSE,
    payload: data,
  };
};

export const getGuestToken = (data) => {
  return {
    type: GET_GUEST_TOKEN,
    payload: data,
  };
};

export const setGuestToken = (data) => {
  return {
    type: SET_GUEST_TOKEN,
    payload: data,
  };
};
