/**
 * The below file contains the constants used in the disaster page
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-11-2022
 */

/**Constants for Disaster Listing page */
export const GET_DISASTER_LISTING = "GET_DISASTER_LISTING";
export const SET_DISASTER_LISTING = "SET_DISASTER_LISTING";
export const SET_DISASTER_LISTING_PARAM = "SET_DISASTER_LISTING_PARAM";
/**Constants for Disaster Listing page */
