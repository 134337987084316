/**
 * The below file contains the action methods for block master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-10-2022
 */

import {
  ADD_BLOCK,
  BLOCK_IMPORT,
  CHANGE_BLOCK_STATUS,
  EDIT_BLOCK,
  GET_ALL_BLOCK,
  GET_BLOCK,
  GET_EXPORT_BLOCK_DATA,
  SET_BLOCK,
  SET_BLOCK_LISTING,
  SET_BLOCK_LISTING_PARAM,
  SET_EXPORT_BLOCK_DATA,
  UPDATE_BLOCK_STATUS,
} from "redux/constants/Block";

//#region Action methods for block listing page
/**
 * Method for getting the blocks
 * @param {any} params API Parameters
 * @returns Json Object
 */
export const getAllBlock = (params) => {
  return {
    type: GET_ALL_BLOCK,
    payload: params,
  };
};

/**
 * Method for setting the block listing
 * @param {any} block Block listing object
 * @returns Json Object
 */
export const setBlockListing = (block) => {
  return {
    type: SET_BLOCK_LISTING,
    payload: block,
  };
};

/**
 * Method for setting the block parameters
 * @param {any} data Parameters Data
 * @returns Json Object
 */
export const setBlockTableParam = (data) => {
  return {
    type: SET_BLOCK_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the block status
 * @param {any} data Block Data
 * @returns Json Object
 */
export const updateBlockStatus = (data) => {
  return {
    type: UPDATE_BLOCK_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the block status
 * @param {any} data Block Status Data
 * @returns Json Object
 */
export const changeBlockStatus = (data) => {
  return {
    type: CHANGE_BLOCK_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of block
 * @returns Json Object
 */
export const getExportBlock = () => {
  return {
    type: GET_EXPORT_BLOCK_DATA,
  };
};

/**
 * Method for setting the block export data in the state
 * @param {any} data Block Export Data
 * @returns Json Object
 */
export const setExportBlock = (data) => {
  return {
    type: SET_EXPORT_BLOCK_DATA,
    payload: data,
  };
};
//#endregion Action methods for block listing page

//#region Action methods for block add page
/**
 * Method for adding the block
 * @param {any} data Block Data
 * @returns Json Object
 */
export const addBlock = (data) => {
  return {
    type: ADD_BLOCK,
    payload: data,
  };
};
//#endregion Action methods for block add page

//#region Action methods for block edit page
/**
 * Method for edit the block details
 * @param {any} data Block data
 * @returns Json Object
 */
export const editBlock = (data) => {
  return {
    type: EDIT_BLOCK,
    payload: data,
  };
};

/**
 * Method for getting the block details
 * @param {any} id Block Id
 * @returns Json Object
 */
export const getBlock = (id) => {
  return {
    type: GET_BLOCK,
    payload: id,
  };
};

/**
 * Method for setting the block details in the state
 * @param {any} block Block Data
 * @returns Json Object
 */
export const setBlock = (block) => {
  return {
    type: SET_BLOCK,
    payload: block,
  };
};
//#endregion Action methods for block edit page

//#region Action methods for block import page
/**
 * Method for block import
 * @param {any} data Block data
 * @returns Json Object
 */
export const blockImport = (data) => {
  return {
    type: BLOCK_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for block import page
