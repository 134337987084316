/**
 * The below file contains the constants used in the Fire Class
 * @author: vishal.mishra@velsof.com
 * @date : 03-10-2023
 */

/**Constants for fire class */
export const GET_ALL_FLOOD_DEATH_CAUSE = "GET_ALL_FLOOD_DEATH_CAUSE";
export const SET_ALL_FLOOD_DEATH_CAUSE = "SET_ALL_FLOOD_DEATH_CAUSE";
/**Constants for fire class */
