/**
 * The below file is the CWC service which contains method for doing the API calls.
 * @author: vishal.mishra@velsof.com
 * @date : 29-09-2023
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_CWC,
  Application_Json,
  Authorization_Token,
  Blob,
  CWC_Import,
  Get_All_CWC,
  Multipart_Form_Data,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const CwcService = {};

//#region Method for CWC listing page.

/**
 * Method for getting the CWC listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
CwcService.getAllCWC = function (param) {
  return fetch({
    url: Get_All_CWC,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

//#region Method for CWC import page.

/**
 * Method for importing the CWC
 * @param {any} data API Data
 * @returns Fetch Method
 */
CwcService.CWCImport = function (data) {
  return fetch({
    url: CWC_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for CWC import page.

export default CwcService;
