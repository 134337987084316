/**
 * The below file contains the constants related to Auth
 */

export const SIGNIN = "SIGNIN";
export const AUTHENTICATED = "AUTHENTICATED";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const AUTH_TOKEN = "AUTH_TOKEN";
export const SECONDARY_CHECK = "SECONDARY_CHECK";
export const LOGIN_USER = "LOGIN_USER";
export const SET_LOGIN_USER_DETAILS = "SET_LOGIN_USER_DETAILS";
