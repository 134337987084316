/**
 * File contain the method to set the data in the state.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 24-03-2023
 */

import { AUTH_TOKEN, LOGIN_USER } from "redux/constants/Auth";
import {
  SET_GUEST_TOKEN,
  SET_SUPERSET_LOGIN_RESPONSE,
} from "../constants/ApacheSuperset";

const initState = {
  loginResponse: {},
  guestResponse: {},
  token: localStorage.getItem(AUTH_TOKEN),
  loginUser: JSON.parse(localStorage.getItem(LOGIN_USER)),
};

const apacheSuperset = (state = initState, action) => {
  switch (action.type) {
    case SET_SUPERSET_LOGIN_RESPONSE:
      return {
        ...state,
        loginResponse: action.payload,
      };
    case SET_GUEST_TOKEN:
      return {
        ...state,
        guestResponse: action.payload,
      };
    default:
      return state;
  }
};

export default apacheSuperset;
