/**
 * The below file contains the Sagas method for Fire.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Fire_Categories_Imported,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setAllFireCategory,
} from "redux/actions/FireCategory";
import {
  GET_ALL_FIRE_CATEGORY,
} from "redux/constants/FireCategory";
import FireCategoryService from "services/FireCategoryService";

//#region Method for listing

/**
 * Method for getting the fires
 */
export function* getAllFireCategory() {
  yield takeEvery(GET_ALL_FIRE_CATEGORY, function* () {
    try {
      const fire = yield call(FireCategoryService.getAllFireCategory);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(setAllFireCategory(fire));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion Method for listing

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllFireCategory),
  ]);
}
