/**
 * The below file contains the Relief Camp state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-11-2022
 */

import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_RELIEF_CAMP,
  EDIT_RELIEF_CAMP,
  GET_EXPORT_RELIEF_CAMP_DATA,
  SET_EXPORT_RELIEF_CAMP_DATA,
  SET_RELIEF_CAMP,
  SET_RELIEF_CAMP_LISTING,
  SET_RELIEF_CAMP_LISTING_PARAM,
  UPDATE_RELIEF_CAMP_STATUS,
} from "redux/constants/ReliefCamp";

//Initial state
const initState = {
  //Keys for listing
  reliefCampList: {},
  isReliefCampDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  reliefCampListingParam: {},
  reliefCampExportedData: {},
  //Keys for listing

  //Keys for add page
  addReliefCampForm: {},
  //Keys for add page

  //Keys for edit page
  reliefCampFormData: {},
  //Keys for edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const reliefCamp = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_RELIEF_CAMP_LISTING:
      return {
        ...state,
        reliefCampList: action.payload,
        isReliefCampDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_RELIEF_CAMP_LISTING_PARAM:
      return {
        ...state,
        reliefCampListingParam: action.payload,
      };

    case UPDATE_RELIEF_CAMP_STATUS:
      let updateReliefCampListData = {
        ...state.reliefCampList,
        data: state.reliefCampList.data?.map((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        }),
      };
      return {
        ...state,
        reliefCampList: updateReliefCampListData,
      };

    case GET_EXPORT_RELIEF_CAMP_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_RELIEF_CAMP_DATA:
      return {
        ...state,
        reliefCampExportedData: action.payload,
      };
    //Cases for listing

    //Cases for add page
    case ADD_RELIEF_CAMP:
      return {
        ...state,
        addReliefCampForm: action.payload,
      };
    //Cases for add page

    //Cases for edit page
    case EDIT_RELIEF_CAMP:
      return {
        ...state,
        addReliefCampForm: action.payload,
      };
    case SET_RELIEF_CAMP:
      return {
        ...state,
        reliefCampFormData: action.payload,
      };
    //Cases for District edit page

    default:
      return state;
  }
};

export default reliefCamp;
