/**
 * The below file contains the constants which are common.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 21-10-2022
 */

/**Constants for showing and hiding the message */
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";

/**Constants for showing and hidding the loader */
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

/**Constants for showing and hidding the loader of the page */
export const SHOW_PAGE_LOADING = "SHOW_PAGE_LOADING";
export const HIDE_PAGE_LOADING = "HIDE_PAGE_LOADING";
