/**
 * The below file is the Fire Agency service which contains method for doing the API calls.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Get_All_Fire_Agency,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const FireAgencyService = {};

//#region Method for Fire Agency dropdown

/**
 * Method for getting the Fire Agency dropdown
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
FireAgencyService.getAllFireAgency = function () {
  return fetch({
    url: Get_All_Fire_Agency,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

//#endregion Method for Fire Agency dropdown.

export default FireAgencyService;
