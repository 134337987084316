import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_ALL_CREATE_FLOOD_NON_INMATES_REGISTRATION,
  SET_ALL_FLOOD_NON_INMATES_REGISTRATION,
  SET_ALL_UPDATE_FLOOD_NON_INMATES_REGISTRATION,
  SET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_FLOOD_NON_INMATES_REGISTRATION,
} from "redux/constants/FloodNonInmatesRegistration";

// Define an initial state
const initialState = {
  nonInmatesRegistrationData: [],
  isNonInmatesRegistrationDataFetched: false,
  floodNonInmatesRegistrationListingParam: {},
  nonCampInmatesRegistrationExportedData: {},
  token: localStorage.getItem(AUTH_TOKEN),
};

// Reducer function
const nonInmatesRegistration = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_FLOOD_NON_INMATES_REGISTRATION:
      return {
        ...state,
        nonInmatesRegistrationData: action.payload,
        isNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_CREATE_FLOOD_NON_INMATES_REGISTRATION:
      // Handle the logic to create a new report here
      // You can update the state accordingly
      // For example, if you want to store the new report data, you can do something like this:

      return {
        ...state,
        nonInmatesRegistrationData: action.payload,
        isNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_UPDATE_FLOOD_NON_INMATES_REGISTRATION:
      return {
        ...state,
        nonInmatesRegistrationData: action.payload,
        isNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_FLOOD_NON_INMATES_REGISTRATION:
      return {
        ...state,
        floodNonInmatesRegistrationListingParam: action.payload,
      };

    case GET_EXPORT_NON_CAMP_INMATES_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_NON_CAMP_INMATES_DATA:
      return {
        ...state,
        nonCampInmatesRegistrationExportedData: action.payload,
      };

    default:
      return state;
  }
};

export default nonInmatesRegistration;
