/**
 * The below file contains the constants used in the rescue equipment master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

/**Constants for RescueEquipment Listing page */
export const GET_ALL_RESCUEEQUIPMENT = "GET_ALL_RESCUEEQUIPMENT";
export const SET_RESCUEEQUIPMENT_LISTING = "SET_RESCUEEQUIPMENT_LISTING";
export const SET_RESCUEEQUIPMENT_LISTING_PARAM =
  "SET_RESCUEEQUIPMENT_LISTING_PARAM";
export const UPDATE_RESCUEEQUIPMENT_STATUS = "UPDATE_RESCUEEQUIPMENT_STATUS";
export const CHANGE_RESCUEEQUIPMENT_STATUS = "CHANGE_RESCUEEQUIPMENT_STATUS";
export const GET_EXPORT_RESCUEEQUIPMENT_DATA =
  "GET_EXPORT_RESCUEEQUIPMENT_DATA";
export const SET_EXPORT_RESCUEEQUIPMENT_DATA =
  "SET_EXPORT_RESCUEEQUIPMENT_DATA";
/**Constants for RescueEquipment Listing page */

/**Constants for RescueEquipment Add page */
export const ADD_RESCUEEQUIPMENT = "ADD_RESCUEEQUIPMENT";
/**Constants for RescueEquipment Add page */

/**Constants for RescueEquipment Edit page */
export const EDIT_RESCUEEQUIPMENT = "EDIT_RESCUEEQUIPMENT";
export const GET_RESCUEEQUIPMENT = "GET_RESCUEEQUIPMENT";
export const SET_RESCUEEQUIPMENT = "SET_RESCUEEQUIPMENT";
/**Constants for RescueEquipment Edit page */

/**Constants for RescueEquipment Import page */
export const RESCUEEQUIPMENT_IMPORT = "RESCUEEQUIPMENT_IMPORT";
/**Constants for RescueEquipment Import page */
