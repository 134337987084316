/**
 * The below file is the agency service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_Agency,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_Agency_Status,
  Agency_Import,
  Edit_Agency,
  Get_All_Agency,
  Get_Agency,
  Get_Export_Agency,
  Multipart_Form_Data,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const AgencyService = {};

//#region Method for agency listing page.

/**
 * Method for getting the agency listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
AgencyService.getAllAgency = function (param) {
  return fetch({
    url: Get_All_Agency,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the agency export data
 * @returns Fetch Method
 */
AgencyService.getExportAgencyData = function () {
  return fetch({
    url: Get_Export_Agency,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the agency status
 * @param {any} data API Data
 * @returns Fetch Method
 */
AgencyService.changeAgencyStatus = function (data) {
  return fetch({
    url: Change_Agency_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for agency listing page.

//#region Method for agency add page.

/**
 * Method for adding the agency
 * @param {any} data API Data
 * @returns Fetch Method
 */
AgencyService.addAgency = function (data) {
  return fetch({
    url: Add_Agency,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for agency add page.

//#region Method for agency edit page.

/**
 * Method for editing the agency
 * @param {number} id Agency Id
 * @param {any} data API Data
 * @returns Fetch Method
 */
AgencyService.editAgency = function (id, data) {
  return fetch({
    url: format(Edit_Agency, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the agency detail
 * @param {any} data API Data
 * @returns Fetch Method
 */
AgencyService.getAgency = function (data) {
  return fetch({
    url: format(Get_Agency, data.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion Method for agency edit page.

//#region Method for agency import page.

/**
 * Method for importing the agency
 * @param {any} data API Data
 * @returns Fetch Method
 */
AgencyService.agencyImport = function (data) {
  return fetch({
    url: Agency_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for agency import page.

export default AgencyService;
