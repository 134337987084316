/**
 * The below file contains the constants used in the resource document master.
 * @author: dhruv.kumar@velsof.com
 * @date : 28-07-2023
 */

/**Constants for ResourceDocument Listing page */
export const GET_ALL_RESOURCEDOCUMENT = "GET_ALL_RESOURCEDOCUMENT";
export const SET_RESOURCEDOCUMENT_LISTING = "SET_RESOURCEDOCUMENT_LISTING";
export const SET_RESOURCEDOCUMENT_LISTING_PARAM =
  "SET_RESOURCEDOCUMENT_LISTING_PARAM";
/**Constants for ResourceDocument Listing page */

/**Constants for ResourceDocument Add page */
export const ADD_RESOURCEDOCUMENT = "ADD_RESOURCEDOCUMENT";
/**Constants for ResourceDocument Add page */

/**Constants for ResourceDocument Edit page */
export const EDIT_RESOURCEDOCUMENT = "EDIT_RESOURCEDOCUMENT";
export const GET_RESOURCEDOCUMENT = "GET_RESOURCEDOCUMENT";
export const SET_RESOURCEDOCUMENT = "SET_RESOURCEDOCUMENT";
/**Constants for ResourceDocument Edit page */
