import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { AUTH_TOKEN, LOGIN_USER, SIGNIN, SIGNOUT } from "../constants/Auth";
import {
  authenticated,
  getLoginUser,
  setLoginUserDetails,
  signOutSuccess,
} from "../actions/Auth";

import JwtAuthService from "services/JwtAuthService";
import { showMessage } from "redux/actions/Common";
import { Error } from "constants/ApiConstant";

export function* signIn() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    //const {email, password} = payload;
    let bodyParam = {
      identifier: payload.email,
      password: payload.password,
    };
    try {
      let user = yield call(JwtAuthService.login, bodyParam);
      if (user.error != null && user.error.message) {
        yield put(showMessage({ message: user.error.message, type: Error }));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.jwt);
        yield put(authenticated(user));
        let param = {
          "populate[0]": "role.permissions",
          "populate[1]": "block",
          "populate[2]": "district.state",
          "populate[3]": "profilePhoto",
        };
        user = yield call(JwtAuthService.getLoginUserDetails, param);
        if (user.error != null && user.error.message) {
          yield put(showMessage({ message: user.error.message, type: Error }));
        } else {
          localStorage.setItem(LOGIN_USER, JSON.stringify(user));
          yield put(setLoginUserDetails(user));
        }
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(LOGIN_USER);
      yield put(signOutSuccess());
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signIn), fork(signOut)]);
}
