/**
 * The below file contains the action methods for reliefCamp master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-11-2022
 */

import {
  ADD_RELIEF_CAMP,
  CHANGE_RELIEF_CAMP_STATUS,
  EDIT_RELIEF_CAMP,
  GET_ALL_RELIEF_CAMP,
  GET_EXPORT_RELIEF_CAMP_DATA,
  GET_RELIEF_CAMP,
  SET_EXPORT_RELIEF_CAMP_DATA,
  SET_RELIEF_CAMP,
  SET_RELIEF_CAMP_LISTING,
  SET_RELIEF_CAMP_LISTING_PARAM,
  UPDATE_RELIEF_CAMP_STATUS,
  RELIEF_CAMP_IMPORT,
} from "redux/constants/ReliefCamp";

//#region Action methods for reliefCamp listing page

/**
 * Method for getting the reliefCamp listing
 * @param {any} params Relief Camp Data
 * @returns Json Object
 */
export const getAllReliefCamp = (params) => {
  return {
    type: GET_ALL_RELIEF_CAMP,
    payload: params,
  };
};

/**
 * Method for setting the reliefCamp listing details in state
 * @param {any} reliefCamp Relief Camp Data
 * @returns Json Object
 */
export const setReliefCampListing = (reliefCamp) => {
  return {
    type: SET_RELIEF_CAMP_LISTING,
    payload: reliefCamp,
  };
};

/**
 * Method for setting the reliefCamp parameters
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const setReliefCampTableParam = (data) => {
  return {
    type: SET_RELIEF_CAMP_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the reliefCamp status
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const updateReliefCampStatus = (data) => {
  return {
    type: UPDATE_RELIEF_CAMP_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the reliefCamp status
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const changeReliefCampStatus = (data) => {
  return {
    type: CHANGE_RELIEF_CAMP_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of reliefCamp
 * @returns Json Object
 */
export const getExportReliefCamp = () => {
  return {
    type: GET_EXPORT_RELIEF_CAMP_DATA,
  };
};

/**
 * Method for setting the reliefCamp export data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const setExportReliefCamp = (data) => {
  return {
    type: SET_EXPORT_RELIEF_CAMP_DATA,
    payload: data,
  };
};
//#endregion Action methods for reliefCamp listing page

//#region Action methods for reliefCamp add page

/**
 * Method for adding the reliefCamp
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const addReliefCamp = (data) => {
  return {
    type: ADD_RELIEF_CAMP,
    payload: data,
  };
};
//#endregion Action methods for reliefCamp add page

//#region Action methods for reliefCamp edit page

/**
 * Method for editing the reliefCamp
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const editReliefCamp = (data) => {
  return {
    type: EDIT_RELIEF_CAMP,
    payload: data,
  };
};

/**
 * Method for getting the reliefCamp details
 * @param {any} id Relief Camp Id
 * @returns Json Object
 */
export const getReliefCamp = (id) => {
  return {
    type: GET_RELIEF_CAMP,
    payload: id,
  };
};

/**
 * Method for setting the reliefCamp details in the state
 * @param {any} reliefCamp Relief Camp Data
 * @returns Json Object
 */
export const setReliefCamp = (reliefCamp) => {
  return {
    type: SET_RELIEF_CAMP,
    payload: reliefCamp,
  };
};
//#endregion Action methods for reliefCamp edit page

//#region Action methods for reliefCamp import page
/**
 * Method for reliefCamp import
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const reliefCampImport = (data) => {
  return {
    type: RELIEF_CAMP_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for reliefCamp import page
