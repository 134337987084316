/**
 * The below file contains the constants used in the district master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 04-10-2022
 */

/**Constants for District Listing page */
export const GET_ALL_DISTRICT = "GET_ALL_DISTRICT";
export const SET_DISTRICT_LISTING = "SET_DISTRICT_LISTING";
export const SET_DISTRICT_LISTING_PARAM = "SET_DISTRICT_LISTING_PARAM";
export const UPDATE_DISTRICT_STATUS = "UPDATE_DISTRICT_STATUS";
export const CHANGE_DISTRICT_STATUS = "CHANGE_DISTRICT_STATUS";
export const GET_EXPORT_DISTRICT_DATA = "GET_EXPORT_DISTRICT_DATA";
export const SET_EXPORT_DISTRICT_DATA = "SET_EXPORT_DISTRICT_DATA";
/**Constants for District Listing page */

/**Constants for District Add page */
export const ADD_DISTRICT = "ADD_DISTRICT";
/**Constants for District Add page */

/**Constants for District Edit page */
export const EDIT_DISTRICT = "EDIT_DISTRICT";
export const GET_DISTRICT = "GET_DISTRICT";
export const SET_DISTRICT = "SET_DISTRICT";
/**Constants for District Edit page */

/**Constants for District Import page */
export const DISTRICT_IMPORT = "DISTRICT_IMPORT";
/**Constants for District Import page */
