/**
 * The below file contains the Sagas method for Station.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import { Station_Imported } from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import { setAllStation } from "redux/actions/Station";
import { GET_ALL_STATION } from "redux/constants/Station";
import StationService from "services/StationService";

//#region Method for listing

/**
 * Method for getting the Station
 */
export function* getAllStation() {
  yield takeEvery(GET_ALL_STATION, function* ({ payload }) {
    try {
      const station = yield call(StationService.getAllStation, payload);
      if (station.error != null && station.error.message) {
        yield put(showMessage({ message: station.error.message, type: Error }));
      } else {
        yield put(setAllStation(station));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion Method for the Station

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([fork(getAllStation)]);
}
