/**
 * The below file contains the constants used in the Fire Cause
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

/**Constants for fire cause */
export const GET_ALL_FIRE_CAUSE = "GET_ALL_FIRE_CAUSE";
export const SET_ALL_FIRE_CAUSE = "SET_ALL_FIRE_CAUSE";
/**Constants for fire cause */
