/**
 * The below file contains the common cases which will be used to call the correct method.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 21-10-2022
 */

import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  HIDE_LOADING,
  HIDE_MESSAGE,
  HIDE_PAGE_LOADING,
  SHOW_LOADING,
  SHOW_MESSAGE,
  SHOW_PAGE_LOADING,
} from "redux/constants/Common";

//Initial state
const initState = {
  showMessage: {},
  token: localStorage.getItem(AUTH_TOKEN),
  loading: false,
  pageLoading: false,
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const common = (state = initState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        showMessage: {
          message: action.payload.message,
          type: action.payload.type,
          status: true,
        },
      };
    case HIDE_MESSAGE:
      return {
        ...state,
        showMessage: { message: "", type: "", status: false },
      };
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case SHOW_PAGE_LOADING: {
      return {
        ...state,
        pageLoading: true,
      };
    }
    case HIDE_PAGE_LOADING: {
      return {
        ...state,
        pageLoading: false,
      };
    }
    default:
      return state;
  }
};

export default common;
