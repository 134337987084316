/**
 * The below file contains the constants used in the relief other item master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

/**Constants for ReliefOtherItem Listing page */
export const GET_ALL_RELIEFOTHERITEM = "GET_ALL_RELIEFOTHERITEM";
export const SET_RELIEFOTHERITEM_LISTING = "SET_RELIEFOTHERITEM_LISTING";
export const SET_RELIEFOTHERITEM_LISTING_PARAM =
  "SET_RELIEFOTHERITEM_LISTING_PARAM";
export const UPDATE_RELIEFOTHERITEM_STATUS = "UPDATE_RELIEFOTHERITEM_STATUS";
export const CHANGE_RELIEFOTHERITEM_STATUS = "CHANGE_RELIEFOTHERITEM_STATUS";
export const GET_EXPORT_RELIEFOTHERITEM_DATA =
  "GET_EXPORT_RELIEFOTHERITEM_DATA";
export const SET_EXPORT_RELIEFOTHERITEM_DATA =
  "SET_EXPORT_RELIEFOTHERITEM_DATA";
/**Constants for ReliefOtherItem Listing page */

/**Constants for ReliefOtherItem Add page */
export const ADD_RELIEFOTHERITEM = "ADD_RELIEFOTHERITEM";
/**Constants for ReliefOtherItem Add page */

/**Constants for ReliefOtherItem Edit page */
export const EDIT_RELIEFOTHERITEM = "EDIT_RELIEFOTHERITEM";
export const GET_RELIEFOTHERITEM = "GET_RELIEFOTHERITEM";
export const SET_RELIEFOTHERITEM = "SET_RELIEFOTHERITEM";
/**Constants for ReliefOtherItem Edit page */

/**Constants for ReliefOtherItem Import page */
export const RELIEFOTHERITEM_IMPORT = "RELIEFOTHERITEM_IMPORT";
/**Constants for ReliefOtherItem Import page */
