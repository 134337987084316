/**
 * The below file contains the Sagas method for Inf damage reports.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-11-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  BasicSection,
  DiscardButton,
  InfDamageOtherSection,
  InfDamageSection,
  RemarkSection,
  SubmitButton,
} from "constants/ApplicationConstant";
import {
  InfDamage_Added_Basic_Section,
  InfDamage_Added_InfDamageOther_Section,
  InfDamage_Added_InfDamage_Section,
  InfDamage_Added_Remark_Section,
  InfDamage_Imported,
  InfDamage_Report_Discard,
  InfDamage_Report_Submit,
  InfDamage_Updated_Basic_Section,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  infDamageResetButtonLoading,
  infDamageSetAllSection,
  infDamageSetDataSaved,
  infDamageSetParentId,
  infDamageSetRedirectToListing,
  setExportInfDamage,
  setInfDamageListing,
} from "redux/actions/InfDamage";
import {
  GET_ALL_INFDAMAGE,
  GET_EXPORT_INFDAMAGE_DATA,
  INFDAMAGE_ADD_BASIC_SECTION,
  INFDAMAGE_ADD_INFDAMAGEOTHER_SECTION,
  INFDAMAGE_ADD_INFDAMAGE_SECTION,
  INFDAMAGE_ADD_REMARK_SECTION,
  INFDAMAGE_EDIT_BASIC_SECTION,
  INFDAMAGE_GET_ALL_SECTION,
  INFDAMAGE_IMPORT,
  INFDAMAGE_REPORT_DISCARD,
  INFDAMAGE_REPORT_SUBMIT,
} from "redux/constants/InfDamage";
import InfDamageService from "services/InfDamageService";

//#region Method for listing

/**
 * Method for getting the infDamages
 */
export function* getAllInfDamage() {
  yield takeEvery(GET_ALL_INFDAMAGE, function* ({ payload }) {
    try {
      const infDamage = yield call(InfDamageService.getAllInfDamage, payload);
      if (infDamage.error != null && infDamage.error.message) {
        yield put(
          showMessage({ message: infDamage.error.message, type: Error })
        );
      } else {
        yield put(setInfDamageListing(infDamage));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the infDamage export details
 */
export function* getInfDamageExport() {
  yield takeEvery(GET_EXPORT_INFDAMAGE_DATA, function* () {
    try {
      const infDamage = yield call(InfDamageService.getExportInfDamageData);
      if (infDamage.error != null && infDamage.error.message) {
        yield put(
          showMessage({ message: infDamage.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(infDamage),
        };
        yield put(setExportInfDamage(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for infDamage import page

/**
 * Method for importing the infDamage
 */
export function* infDamageImport() {
  yield takeEvery(INFDAMAGE_IMPORT, function* ({ payload }) {
    try {
      const infDamage = yield call(InfDamageService.infDamageImport, payload);
      if (infDamage.error != null && infDamage.error.message) {
        yield put(
          showMessage({ message: infDamage.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: InfDamage_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for infDamage import page

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 11-11-2022

/**
 * Method for getting the all section data
 */
export function* infDamageGetAllSection() {
  yield takeEvery(INFDAMAGE_GET_ALL_SECTION, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        InfDamageService.infDamageGetAllSection,
        payload.id,
        payload.param
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(infDamageSetAllSection(infDepartment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for submitting the report
 */
export function* infDamageReportSubmit() {
  yield takeEvery(INFDAMAGE_REPORT_SUBMIT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        InfDamageService.infDamageReportSubmit,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: InfDamage_Report_Submit, type: Success })
        );
        yield put(infDamageSetRedirectToListing(true));
        yield put(infDamageResetButtonLoading({ section: SubmitButton }));
      }
    } catch (err) {
      yield put(infDamageResetButtonLoading({ section: SubmitButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for discarding the report
 */
export function* infDamageReportDiscard() {
  yield takeEvery(INFDAMAGE_REPORT_DISCARD, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        InfDamageService.infDamageReportDiscard,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: InfDamage_Report_Discard, type: Success })
        );
        yield put(infDamageSetRedirectToListing(true));
        yield put(infDamageResetButtonLoading({ section: DiscardButton }));
      }
    } catch (err) {
      yield put(infDamageResetButtonLoading({ section: DiscardButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-11-2022

/** Method for adding basic section
 */
export function* infDamageAddBasicSection() {
  yield takeEvery(INFDAMAGE_ADD_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };

      const infDamage = yield call(
        InfDamageService.infDamageAddBasicSection,
        final
      );
      if (infDamage.error != null && infDamage.error.message) {
        yield put(
          showMessage({ message: infDamage.error.message, type: Error })
        );
      } else {
        yield put(infDamageSetParentId(infDamage.data.id));
        yield put(infDamageResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({ message: InfDamage_Added_Basic_Section, type: Success })
        );
        yield put(
          infDamageSetDataSaved({ section: BasicSection, value: true })
        );
      }
    } catch (err) {
      yield put(infDamageResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for editing basic section
 */
export function* infDamageEditBasicSection() {
  yield takeEvery(INFDAMAGE_EDIT_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const infDamage = yield call(
        InfDamageService.infDamageEditBasicSection,
        payload.id,
        final
      );
      if (infDamage.error != null && infDamage.error.message) {
        yield put(
          showMessage({ message: infDamage.error.message, type: Error })
        );
      } else {
        yield put(infDamageResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: InfDamage_Updated_Basic_Section,
            type: Success,
          })
        );
        yield put(
          infDamageSetDataSaved({ section: BasicSection, value: true })
        );
      }
    } catch (err) {
      yield put(infDamageResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Basic Section

//#region InfDamage Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 11-11-2022

/** Method for adding infDamage section
 */
export function* infDamageAddInfDamageSection() {
  yield takeEvery(INFDAMAGE_ADD_INFDAMAGE_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const infDamage = yield call(
        InfDamageService.infDamageAddInfDamageSection,
        final
      );
      if (infDamage.error != null && infDamage.error.message) {
        yield put(
          showMessage({ message: infDamage.error.message, type: Error })
        );
      } else {
        yield put(infDamageResetButtonLoading({ section: InfDamageSection }));
        yield put(
          showMessage({
            message: InfDamage_Added_InfDamage_Section,
            type: Success,
          })
        );
        yield put(
          infDamageSetDataSaved({ section: InfDamageSection, value: true })
        );
      }
    } catch (err) {
      yield put(infDamageResetButtonLoading({ section: InfDamageSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 11-11-2022

/** Method for adding infDamageOther section
 */
export function* infDamageAddInfDamageOtherSection() {
  yield takeEvery(
    INFDAMAGE_ADD_INFDAMAGEOTHER_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const infDamage = yield call(
          InfDamageService.infDamageAddInfDamageOtherSection,
          final
        );
        if (infDamage.error != null && infDamage.error.message) {
          yield put(
            showMessage({ message: infDamage.error.message, type: Error })
          );
        } else {
          yield put(
            infDamageResetButtonLoading({ section: InfDamageOtherSection })
          );
          yield put(
            showMessage({
              message: InfDamage_Added_InfDamageOther_Section,
              type: Success,
            })
          );
          yield put(
            infDamageSetDataSaved({
              section: InfDamageOtherSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          infDamageResetButtonLoading({ section: InfDamageOtherSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion InfDamageOther Section

//#region Remark Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 15-11-2022

/** Method for adding Remark section
 */
export function* infDamageAddRemarkSection() {
  yield takeEvery(INFDAMAGE_ADD_REMARK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const infDamage = yield call(
        InfDamageService.infDamageAddRemarkSection,
        final
      );
      if (infDamage.error != null && infDamage.error.message) {
        yield put(
          showMessage({ message: infDamage.error.message, type: Error })
        );
      } else {
        yield put(infDamageResetButtonLoading({ section: RemarkSection }));
        yield put(
          showMessage({
            message: InfDamage_Added_Remark_Section,
            type: Success,
          })
        );
        yield put(
          infDamageSetDataSaved({ section: RemarkSection, value: true })
        );
      }
    } catch (err) {
      yield put(infDamageResetButtonLoading({ section: RemarkSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion InfDamageOther Section

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllInfDamage),
    fork(getInfDamageExport),
    fork(infDamageImport),
    fork(infDamageAddBasicSection),
    fork(infDamageEditBasicSection),
    fork(infDamageGetAllSection),
    fork(infDamageAddInfDamageSection),
    fork(infDamageAddInfDamageOtherSection),
    fork(infDamageAddRemarkSection),
    fork(infDamageReportSubmit),
    fork(infDamageReportDiscard),
  ]);
}
