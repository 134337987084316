/**
 * The below file contains the actions used in the Non Camp Inmates Registration.
 * @author: vishal.mishra@velsof.com
 * @date :01-04-2024
 */

import {
  NON_CAMP_INMATES_REGISTRATION_IMPORT,
  GET_ALL_CREATE_FLOOD_NON_INMATES_REGISTRATION,
  GET_ALL_FLOOD_NON_INMATES_REGISTRATION,
  GET_ALL_UPDATE_FLOOD_NON_INMATES_REGISTRATION,
  GET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_ALL_CREATE_FLOOD_NON_INMATES_REGISTRATION,
  SET_ALL_FLOOD_NON_INMATES_REGISTRATION,
  SET_ALL_UPDATE_FLOOD_NON_INMATES_REGISTRATION,
  SET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_FLOOD_NON_INMATES_REGISTRATION,
} from "redux/constants/FloodNonInmatesRegistration";

// Import the constants you defined

// Action to fetch the Non Camp Inmates Registration
export const getAllNonInmatesRegistration = (data) => {
  return {
    type: GET_ALL_FLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const setAllNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_FLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Non Camp Inmates Registration
export const getAllCreateNonInmatesRegistration = (data) => {
  return {
    type: GET_ALL_CREATE_FLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const setAllCreateNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_CREATE_FLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Non Camp Inmates Registration
export const getAllUpdateNonInmatesRegistration = (data, id) => {
  return {
    type: GET_ALL_UPDATE_FLOOD_NON_INMATES_REGISTRATION,
    payload: data,
    id: id,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const setAllUpdateNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_UPDATE_FLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for setting the flood Non Inmates Registration parameters
 * @param {any} data Flood Data
 * @returns Json Object
 */
export const setNonInmatesRegistrationTableParam = (data) => {
  return {
    type: SET_FLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for Non Camp Inmates Registration import
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const nonCampInmatesRegistrationImport = (data) => {
  return {
    type: NON_CAMP_INMATES_REGISTRATION_IMPORT,
    payload: data,
  };
};

/**
 * Method for getting the export file of Non Camp Inmates Registration
 * @returns Json Object
 */
export const getExportNonInmatesRegistration = (data) => {
  return {
    type: GET_EXPORT_NON_CAMP_INMATES_DATA,
    payload: data,
  };
};

/**
 * Method for setting the reliefCamp export data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const setExportNonInmatesRegistration = (data) => {
  return {
    type: SET_EXPORT_NON_CAMP_INMATES_DATA,
    payload: data,
  };
};
