import { API_BASE_URL_PRODUCTION, API_BASE_URL_STAGING } from "constants/ApiConstant";

const dev = {
	API_ENDPOINT_URL: API_BASE_URL_STAGING
};

const prod = {
	API_ENDPOINT_URL: API_BASE_URL_PRODUCTION
};

const test = {
	API_ENDPOINT_URL: API_BASE_URL_STAGING
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
