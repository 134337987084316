export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_FORMAT_YYYY_MM_DD_HH_mm = "YYYY-MM-DD HH:mm";
export const DATE_FORMAT_DD_MM_YYYY = "DD-MM-YYYY";
export const DATE_FORMAT_DD_MMM_YYYY = "DD-MMM-YYYY";
export const DATE_FORMAT_HH_mm = "HH:mm";
export const DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ = "YYYY-MM-DD'T'HH:mm:ssZ";
export const DATE_FORMAT_YYYY_MM_DDTHH_mm_ss_SSSSZ =
  "YYYY-MM-DDTHH:mm:ss.SSSSZ";
export const DATE_FORMAT_DD_MMM_YYYY_HH_mm_ss = "DD-MMM-YYYY HH:mm:ss";
export const DATE_FORMAT_YYYY_MMM_DD_HH_mm_ss = "YYYY-MMM-DD HH:mm:ss";
