/**
 * The below file contains the Sagas method for Infrastructure Department.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-11-2022
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_INFDEPARTMENT,
  CHANGE_INFDEPARTMENT_STATUS,
  INFDEPARTMENT_IMPORT,
  EDIT_INFDEPARTMENT,
  GET_ALL_INFDEPARTMENT,
  GET_INFDEPARTMENT,
  GET_EXPORT_INFDEPARTMENT_DATA,
} from "../constants/InfDepartment";

import InfDepartmentService from "services/InfDepartmentService";
import {
  setInfDepartment,
  setInfDepartmentListing,
  setExportInfDepartment,
  updateInfDepartmentStatus,
} from "redux/actions/InfDepartment";
import { showMessage } from "redux/actions/Common";
import {
  InfDepartment_Added,
  InfDepartment_Imported,
  InfDepartment_Updated,
} from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";

//#region Method for infDepartment listing

/**
 * Method for getting the infDepartment details
 */
export function* getAllInfDepartment() {
  yield takeEvery(GET_ALL_INFDEPARTMENT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        InfDepartmentService.getAllInfDepartment,
        payload
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(setInfDepartmentListing(infDepartment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the infDepartment export details
 */
export function* getInfDepartmentExport() {
  yield takeEvery(GET_EXPORT_INFDEPARTMENT_DATA, function* () {
    try {
      const infDepartment = yield call(
        InfDepartmentService.getExportInfDepartmentData
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(infDepartment),
        };
        yield put(setExportInfDepartment(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the infDepartment status details
 */
export function* changeInfDepartmentStatus() {
  yield takeEvery(CHANGE_INFDEPARTMENT_STATUS, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        InfDepartmentService.changeInfDepartmentStatus,
        payload
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        let json = {
          id: infDepartment.data.id,
          status: infDepartment.data.status,
        };
        yield put(updateInfDepartmentStatus(json));
        yield put(
          showMessage({ message: InfDepartment_Updated, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for infDepartment listing

//#region Method for infDepartment add page

/**
 * Method for adding the infDepartment
 */
export function* addInfDepartment() {
  yield takeEvery(ADD_INFDEPARTMENT, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const infDepartment = yield call(
        InfDepartmentService.addInfDepartment,
        final
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: InfDepartment_Added, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for infDepartment add page

//#region Method for infDepartment edit page

/**
 * Method for editing the infDepartment
 */
export function* editInfDepartment() {
  yield takeEvery(EDIT_INFDEPARTMENT, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          departmentName: payload.departmentName,
          status: payload.status,
        },
      };
      const infDepartment = yield call(
        InfDepartmentService.editInfDepartment,
        id,
        final
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: InfDepartment_Updated, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the infDepartment detail
 */
export function* getInfDepartment() {
  yield takeEvery(GET_INFDEPARTMENT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        InfDepartmentService.getInfDepartment,
        payload
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(setInfDepartment(infDepartment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for infDepartment edit page

//#region Method for infDepartment import page

/**
 * Method for importing the infDepartment
 */
export function* infDepartmentImport() {
  yield takeEvery(INFDEPARTMENT_IMPORT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        InfDepartmentService.infDepartmentImport,
        payload
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: InfDepartment_Imported, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for infDepartment import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllInfDepartment),
    fork(addInfDepartment),
    fork(editInfDepartment),
    fork(getInfDepartment),
    fork(getInfDepartmentExport),
    fork(changeInfDepartmentStatus),
    fork(infDepartmentImport),
  ]);
}
