/**
 * The below file contains the action methods for fire cause master.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import {
  GET_ALL_FIRE_CAUSE,
  SET_ALL_FIRE_CAUSE,
} from "redux/constants/FireCause";

//#region Action methods for fetching fire causes

/**
 * Method for getting the fire causes
 * @param {any} params Fire Data
 * @returns Json Object
 */
export const getAllFireCause = () => {
  return {
    type: GET_ALL_FIRE_CAUSE,
  };
};

/**
 * Method for setting the fire causes
 * @param {any} params Fire Data
 * @returns Json Object
 */
export const setAllFireCause = (data) => {
  return {
    type: SET_ALL_FIRE_CAUSE,
    payload: data,
  };
};

//#endregion