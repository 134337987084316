/**
 * The below file contains the Sagas method for ReliefOtherItem.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_RELIEFOTHERITEM,
  CHANGE_RELIEFOTHERITEM_STATUS,
  RELIEFOTHERITEM_IMPORT,
  EDIT_RELIEFOTHERITEM,
  GET_ALL_RELIEFOTHERITEM,
  GET_RELIEFOTHERITEM,
  GET_EXPORT_RELIEFOTHERITEM_DATA,
} from "../constants/ReliefOtherItem";

import ReliefOtherItemService from "services/ReliefOtherItemService";
import {
  setReliefOtherItem,
  setReliefOtherItemListing,
  setExportReliefOtherItem,
  updateReliefOtherItemStatus,
} from "redux/actions/ReliefOtherItem";
import { showMessage } from "redux/actions/Common";
import {
  ReliefOtherItem_Added,
  ReliefOtherItem_Imported,
  ReliefOtherItem_Updated,
} from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";

//#region Method for reliefOtherItem listing

/**
 * Method for getting the reliefOtherItem details
 */
export function* getAllReliefOtherItem() {
  yield takeEvery(GET_ALL_RELIEFOTHERITEM, function* ({ payload }) {
    try {
      const reliefOtherItem = yield call(
        ReliefOtherItemService.getAllReliefOtherItem,
        payload
      );
      if (reliefOtherItem.error != null && reliefOtherItem.error.message) {
        yield put(
          showMessage({ message: reliefOtherItem.error.message, type: Error })
        );
      } else {
        yield put(setReliefOtherItemListing(reliefOtherItem));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the reliefOtherItem export details
 */
export function* getReliefOtherItemExport() {
  yield takeEvery(GET_EXPORT_RELIEFOTHERITEM_DATA, function* () {
    try {
      const reliefOtherItem = yield call(
        ReliefOtherItemService.getExportReliefOtherItemData
      );
      if (reliefOtherItem.error != null && reliefOtherItem.error.message) {
        yield put(
          showMessage({ message: reliefOtherItem.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(reliefOtherItem),
        };
        yield put(setExportReliefOtherItem(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the reliefOtherItem status details
 */
export function* changeReliefOtherItemStatus() {
  yield takeEvery(CHANGE_RELIEFOTHERITEM_STATUS, function* ({ payload }) {
    try {
      const reliefOtherItem = yield call(
        ReliefOtherItemService.changeReliefOtherItemStatus,
        payload
      );
      if (reliefOtherItem.error != null && reliefOtherItem.error.message) {
        yield put(
          showMessage({ message: reliefOtherItem.error.message, type: Error })
        );
      } else {
        let json = {
          id: reliefOtherItem.data.id,
          status: reliefOtherItem.data.status,
        };
        yield put(updateReliefOtherItemStatus(json));
        yield put(
          showMessage({ message: ReliefOtherItem_Updated, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for reliefOtherItem listing

//#region Method for reliefOtherItem add page

/**
 * Method for adding the reliefOtherItem
 */
export function* addReliefOtherItem() {
  yield takeEvery(ADD_RELIEFOTHERITEM, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const reliefOtherItem = yield call(
        ReliefOtherItemService.addReliefOtherItem,
        final
      );
      if (reliefOtherItem.error != null && reliefOtherItem.error.message) {
        yield put(
          showMessage({ message: reliefOtherItem.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: ReliefOtherItem_Added, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for reliefOtherItem add page

//#region Method for reliefOtherItem edit page

/**
 * Method for editing the reliefOtherItem
 */
export function* editReliefOtherItem() {
  yield takeEvery(EDIT_RELIEFOTHERITEM, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          reliefOtherItemName: payload.reliefOtherItemName,
          reliefOtherItemCode: payload.reliefOtherItemCode,
          status: payload.status,
          state: payload.state,
        },
      };
      const reliefOtherItem = yield call(
        ReliefOtherItemService.editReliefOtherItem,
        id,
        final
      );
      if (reliefOtherItem.error != null && reliefOtherItem.error.message) {
        yield put(
          showMessage({ message: reliefOtherItem.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: ReliefOtherItem_Updated, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the reliefOtherItem detail
 */
export function* getReliefOtherItem() {
  yield takeEvery(GET_RELIEFOTHERITEM, function* ({ payload }) {
    try {
      const reliefOtherItem = yield call(
        ReliefOtherItemService.getReliefOtherItem,
        payload
      );
      if (reliefOtherItem.error != null && reliefOtherItem.error.message) {
        yield put(
          showMessage({ message: reliefOtherItem.error.message, type: Error })
        );
      } else {
        yield put(setReliefOtherItem(reliefOtherItem));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for reliefOtherItem edit page

//#region Method for reliefOtherItem import page

/**
 * Method for importing the reliefOtherItem
 */
export function* reliefOtherItemImport() {
  yield takeEvery(RELIEFOTHERITEM_IMPORT, function* ({ payload }) {
    try {
      const reliefOtherItem = yield call(
        ReliefOtherItemService.reliefOtherItemImport,
        payload
      );
      if (reliefOtherItem.error != null && reliefOtherItem.error.message) {
        yield put(
          showMessage({ message: reliefOtherItem.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: ReliefOtherItem_Imported, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for reliefOtherItem import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllReliefOtherItem),
    fork(addReliefOtherItem),
    fork(editReliefOtherItem),
    fork(getReliefOtherItem),
    fork(getReliefOtherItemExport),
    fork(changeReliefOtherItemStatus),
    fork(reliefOtherItemImport),
  ]);
}
