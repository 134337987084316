/**
 * The below file is the rescueEquipment service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_RescueEquipment,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_RescueEquipment_Status,
  RescueEquipment_Import,
  Edit_RescueEquipment,
  Get_All_RescueEquipment,
  Get_RescueEquipment,
  Get_Export_RescueEquipment,
  Multipart_Form_Data,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const RescueEquipmentService = {};

//#region Method for rescueEquipment listing page.

/**
 * Method for getting the rescueEquipment listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
RescueEquipmentService.getAllRescueEquipment = function (param) {
  return fetch({
    url: Get_All_RescueEquipment,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the rescueEquipment export data
 * @returns Fetch Method
 */
RescueEquipmentService.getExportRescueEquipmentData = function () {
  return fetch({
    url: Get_Export_RescueEquipment,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the rescueEquipment status
 * @param {any} data API Data
 * @returns Fetch Method
 */
RescueEquipmentService.changeRescueEquipmentStatus = function (data) {
  return fetch({
    url: Change_RescueEquipment_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for rescueEquipment listing page.

//#region Method for rescueEquipment add page.

/**
 * Method for adding the rescueEquipment
 * @param {any} data API Data
 * @returns Fetch Method
 */
RescueEquipmentService.addRescueEquipment = function (data) {
  return fetch({
    url: Add_RescueEquipment,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for rescueEquipment add page.

//#region Method for rescueEquipment edit page.

/**
 * Method for editing the rescueEquipment
 * @param {number} id RescueEquipment Id
 * @param {any} data API Data
 * @returns Fetch Method
 */
RescueEquipmentService.editRescueEquipment = function (id, data) {
  return fetch({
    url: format(Edit_RescueEquipment, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the rescueEquipment detail
 * @param {any} data API Data
 * @returns Fetch Method
 */
RescueEquipmentService.getRescueEquipment = function (data) {
  return fetch({
    url: format(Get_RescueEquipment, data.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion Method for rescueEquipment edit page.

//#region Method for rescueEquipment import page.

/**
 * Method for importing the rescueEquipment
 * @param {any} data API Data
 * @returns Fetch Method
 */
RescueEquipmentService.rescueEquipmentImport = function (data) {
  return fetch({
    url: RescueEquipment_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for rescueEquipment import page.

export default RescueEquipmentService;
