/**
 * The below file contains the rescueEquipment state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_RESCUEEQUIPMENT,
  EDIT_RESCUEEQUIPMENT,
  SET_RESCUEEQUIPMENT,
  SET_RESCUEEQUIPMENT_LISTING_PARAM,
  SET_RESCUEEQUIPMENT_LISTING,
  UPDATE_RESCUEEQUIPMENT_STATUS,
  SET_EXPORT_RESCUEEQUIPMENT_DATA,
  GET_EXPORT_RESCUEEQUIPMENT_DATA,
} from "../constants/RescueEquipment";

//Initial state
const initState = {
  //Keys for rescueEquipment listing
  rescueEquipmentList: {},
  isRescueEquipmentDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  rescueEquipmentListingParam: {},
  rescueEquipmentExportedData: {},
  //Keys for rescueEquipment listing

  //Keys for rescueEquipment add page
  addRescueEquipmentForm: {},
  //Keys for rescueEquipment add page

  //Keys for rescueEquipment edit page
  rescueEquipmentFormData: {},
  //Keys for rescueEquipment edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const rescueEquipment = (state = initState, action) => {
  switch (action.type) {
    //Cases for RescueEquipment listing
    case SET_RESCUEEQUIPMENT_LISTING:
      return {
        ...state,
        rescueEquipmentList: action.payload,
        isRescueEquipmentDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_RESCUEEQUIPMENT_LISTING_PARAM:
      return {
        ...state,
        rescueEquipmentListingParam: action.payload,
      };

    case UPDATE_RESCUEEQUIPMENT_STATUS:
      let updateRescueEquipmentListData = {
        ...state.rescueEquipmentList,
        data: state.rescueEquipmentList.data?.map((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        }),
      };
      return {
        ...state,
        rescueEquipmentList: updateRescueEquipmentListData,
      };

    case GET_EXPORT_RESCUEEQUIPMENT_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_RESCUEEQUIPMENT_DATA:
      return {
        ...state,
        rescueEquipmentExportedData: action.payload,
      };
    //Cases for RescueEquipment listing

    //Cases for RescueEquipment add page
    case ADD_RESCUEEQUIPMENT:
      return {
        ...state,
        addRescueEquipmentForm: action.payload,
      };
    //Cases for RescueEquipment add page

    //Cases for RescueEquipment edit page
    case EDIT_RESCUEEQUIPMENT:
      return {
        ...state,
        addRescueEquipmentForm: action.payload,
      };
    case SET_RESCUEEQUIPMENT:
      return {
        ...state,
        rescueEquipmentFormData: action.payload,
      };
    //Cases for RescueEquipment edit page

    default:
      return state;
  }
};

export default rescueEquipment;
