/**
 * The below file contains the Sagas method for wards Name.
 * @author: shivam.sharma2@velsof.com
 * @date : 29-12-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Wards_Imported,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setAllWards,
} from "redux/actions/Wards";
import {
  GET_ALL_WARDS,
} from "redux/constants/Wards";
import WardsService from "services/WardsService";

//#region Method for Wards Name

/**
 * Method for getting the Wards Name
 */
export function* getAllWards() {
  yield takeEvery(GET_ALL_WARDS, function* ({ payload }) {
    try {
      const wards = yield call(WardsService.getAllWards, payload);
      if (wards.error != null && wards.error.message) {
        yield put(showMessage({ message: wards.error.message, type: Error }));
      } else {
        yield put(setAllWards(wards));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllWards),
  ]);
}
