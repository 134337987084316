/**
 * The below file contains the action methods for erosion report.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 17-11-2022
 */

import {
  GET_ALL_EROSION,
  GET_EXPORT_EROSION_DATA,
  SET_EXPORT_EROSION_DATA,
  SET_EROSION_LISTING,
  SET_EROSION_LISTING_PARAM,
  EROSION_IMPORT,
  EROSION_ADD_BASIC_SECTION,
  EROSION_EDIT_BASIC_SECTION,
  EROSION_ADD_INFDAMAGE_SECTION,
  EROSION_ADD_INFDAMAGEOTHER_SECTION,
  EROSION_GET_ALL_SECTION,
  EROSION_SET_ALL_SECTION,
  EROSION_RESET_BUTTON_LOADING,
  EROSION_SET_PARENT_ID,
  EROSION_ADD_REMARK_SECTION,
  EROSION_SET_NIL_REPORTING,
  EROSION_REPORT_SUBMIT,
  EROSION_REPORT_DISCARD,
  EROSION_SET_REDIRECT_TO_LISTING,
  EROSION_SET_DATA_SAVED,
  EROSION_ADD_POPAFFECTED_SECTION,
  EROSION_ADD_ERODED_SECTION,
  EROSION_ADD_RELIEFCAMP_SECTION,
  EROSION_ADD_CAMPINMATES_SECTION,
  EROSION_ADD_HLL_SECTION,
  EROSION_ADD_LIVESTOCK_SECTION,
  EROSION_ADD_HOUSEDAMAGED_SECTION,
  EROSION_ADD_RESCUEOPERATION_SECTION,
  EROSION_ADD_RELIEFDISTRIBUTION_SECTION,
  EXPORT_FO_LEVEL_EROSION_REPORT,
  SET_EXPORT_FO_LEVEL_EROSION_REPORT,
  EROSION_REVENUE_REPORT_APPROVE,
  EROSION_REVENUE_REPORT_DISAPPROVE,
  EROSION_REVENUE_REPORT_VERIFY,
  GET_EROSION_DISTRICT_REVIEW_LIST,
  SET_EROSION_DISTRICT_REVIEW_LIST,
  GET_EROSION_STATE_REVIEW_LIST,
  SET_EROSION_STATE_REVIEW_LIST,
  EROSION_DISTRICT_REPORT_APPROVE,
  EROSION_DISTRICT_REPORT_DISAPPROVE,
  EROSION_DISTRICT_REPORT_VERIFY,
  EROSION_STATE_REPORT_APPROVE,
  EROSION_STATE_REPORT_DISAPPROVE,
  GET_STATE_CUMULATIVE_EROSION_REPORT,
  SET_STATE_CUMULATIVE_EROSION_REPORT,
  GET_DISTRICT_CUMULATIVE_EROSION_REPORT,
  SET_DISTRICT_CUMULATIVE_EROSION_REPORT,
} from "redux/constants/Erosion";

//#region Action methods for erosion listing page

/**
 * Method for getting the erosion listing
 * @param {any} params Erosion Data
 * @returns Json Object
 */
export const getAllErosion = (params) => {
  return {
    type: GET_ALL_EROSION,
    payload: params,
  };
};

/**
 * Method for setting the erosion listing details in state
 * @param {any} erosion Erosion Data
 * @returns Json Object
 */
export const setErosionListing = (erosion) => {
  return {
    type: SET_EROSION_LISTING,
    payload: erosion,
  };
};

/**
 * Method for setting the erosion parameters
 * @param {any} data Erosion Data
 * @returns Json Object
 */
export const setErosionTableParam = (data) => {
  return {
    type: SET_EROSION_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for getting the export file of erosion
 * @returns Json Object
 */
export const getExportErosion = () => {
  return {
    type: GET_EXPORT_EROSION_DATA,
  };
};

/**
 * Method for setting the erosion export data in the state
 * @param {any} data Erosion Data
 * @returns Json Object
 */
export const setExportErosion = (data) => {
  return {
    type: SET_EXPORT_EROSION_DATA,
    payload: data,
  };
};
//#endregion Action methods for erosion listing page

//#region Action methods for erosion import page
/**
 * Method for erosion import
 * @param {any} data Erosion Data
 * @returns Json Object
 */
export const erosionImport = (data) => {
  return {
    type: EROSION_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for erosion import page

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for getting the all section details
 * @param {number} data Basic Section Data Form Id and param
 * @returns Json Object
 */
export const erosionGetAllSection = (data) => {
  return {
    type: EROSION_GET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const erosionSetAllSection = (data) => {
  return {
    type: EROSION_SET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the parent id
 * @param {number} id Parent Id
 * @returns Json Object
 */
export const erosionSetParentId = (id) => {
  return {
    type: EROSION_SET_PARENT_ID,
    payload: id,
  };
};

/**
 * Method for reseting the button loading status
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const erosionResetButtonLoading = (data) => {
  return {
    type: EROSION_RESET_BUTTON_LOADING,
    payload: data,
  };
};

/**
 * Method for submitting the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const erosionReportSubmit = (data) => {
  return {
    type: EROSION_REPORT_SUBMIT,
    payload: data,
  };
};

/**
 * Method for discarding the report
 * @param {object} data Report Data
 * @returns Json Object
 */
export const erosionReportDiscard = (data) => {
  return {
    type: EROSION_REPORT_DISCARD,
    payload: data,
  };
};

/**
 * Method for setting the redirect to listing variable
 * @param {object} data  Data
 * @returns Json Object
 */
export const erosionSetRedirectToListing = (data) => {
  return {
    type: EROSION_SET_REDIRECT_TO_LISTING,
    payload: data,
  };
};

/**
 * Method for setting the value of erosionDataSaved object
 * @param {object} data Data
 * @returns Json Object
 */
export const erosionSetDataSaved = (data) => {
  return {
    type: EROSION_SET_DATA_SAVED,
    payload: data,
  };
};
//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const erosionAddBasicSection = (data) => {
  return {
    type: EROSION_ADD_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for editing the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const erosionEditBasicSection = (data) => {
  return {
    type: EROSION_EDIT_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the nil reporting value
 * @param {boolean} data Bool value
 * @returns Json Object
 */
export const erosionSetNilReporting = (data) => {
  return {
    type: EROSION_SET_NIL_REPORTING,
    payload: data,
  };
};
//#endregion Basic Section

//#region Infrastructure Damage Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the infDamage section details
 * @param {object} data InfDamage Section Data
 * @returns Json Object
 */
export const erosionAddInfDamageSection = (data) => {
  return {
    type: EROSION_ADD_INFDAMAGE_SECTION,
    payload: data,
  };
};
//#endregion Infrastructure Damage Section

//#region Infrastructure Damage Other Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the infDamageOther section details
 * @param {object} data InfDamageOther Section Data
 * @returns Json Object
 */
export const erosionAddInfDamageOtherSection = (data) => {
  return {
    type: EROSION_ADD_INFDAMAGEOTHER_SECTION,
    payload: data,
  };
};
//#endregion InfDamageOther Section

//#region Remark Section
/**
 * Method for adding the remarks
 * @param {object} data Remark Section Data
 * @returns Json Object
 */
export const erosionAddRemarkSection = (data) => {
  return {
    type: EROSION_ADD_REMARK_SECTION,
    payload: data,
  };
};
//#endregion Remark Section

//#region Population Affected Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 21-11-2022

/**
 * Method for adding the popAffected section details
 * @param {object} data PopAffected Section Data
 * @returns Json Object
 */
export const erosionAddPopAffectedSection = (data) => {
  return {
    type: EROSION_ADD_POPAFFECTED_SECTION,
    payload: data,
  };
};
//#endregion Population Affected Section

//#region Eroded Section
/**
 * Method for adding the Eroded section details
 * @param {object} data Eroded Section Data
 * @returns Json Object
 */
export const erosionAddErodedSection = (data) => {
  return {
    type: EROSION_ADD_ERODED_SECTION,
    payload: data,
  };
};
//#endregion Eroded Section

//#region Relief Camp Section
/**
 * Method for adding the Relief Camp section details
 * @param {object} data Relief Camp Section Data
 * @returns Json Object
 */
export const erosionAddReliefCampSection = (data) => {
  return {
    type: EROSION_ADD_RELIEFCAMP_SECTION,
    payload: data,
  };
};
//#endregion Relief Camp Section

//#region Camp Inmates Section
/**
 * Method for adding the Camp Inmates section details
 * @param {object} data Camp Inmates Section Data
 * @returns Json Object
 */
export const erosionAddCampInmatesSection = (data) => {
  return {
    type: EROSION_ADD_CAMPINMATES_SECTION,
    payload: data,
  };
};
//#endregion Camp Inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the Human Live Lost section details
 * @param {object} data Human Live Lost Section Data
 * @returns Json Object
 */
export const erosionAddHllSection = (data) => {
  return {
    type: EROSION_ADD_HLL_SECTION,
    payload: data,
  };
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the LiveStock section details
 * @param {object} data LiveStock Section Data
 * @returns Json Object
 */
export const erosionAddLiveStockSection = (data) => {
  return {
    type: EROSION_ADD_LIVESTOCK_SECTION,
    payload: data,
  };
};
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the HouseDamaged section details
 * @param {object} data HouseDamaged Section Data
 * @returns Json Object
 */
export const erosionAddHouseDamagedSection = (data) => {
  return {
    type: EROSION_ADD_HOUSEDAMAGED_SECTION,
    payload: data,
  };
};
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the RescueOperation section details
 * @param {object} data RescueOperation Section Data
 * @returns Json Object
 */
export const erosionAddRescueOperationSection = (data) => {
  return {
    type: EROSION_ADD_RESCUEOPERATION_SECTION,
    payload: data,
  };
};
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the ReliefDistribution section details
 * @param {object} data ReliefDistribution Section Data
 * @returns Json Object
 */
export const erosionAddReliefDistributionSection = (data) => {
  return {
    type: EROSION_ADD_RELIEFDISTRIBUTION_SECTION,
    payload: data,
  };
};
//#endregion ReliefDistribution Section

//#region Methods for Reports and Approval and Disapproval
/**
 * Below action will be called when exporting the form data in erosion view form
 * @author: shivam.sharma2@velsof.com
 * @date : 12-12-2022
 */
export const getExportFoLevelErosionReport = (data) => {
  return {
    type: EXPORT_FO_LEVEL_EROSION_REPORT,
    payload: data,
  };
};

export const setExportFOLevelErosionReport = (data) => {
  return {
    type: SET_EXPORT_FO_LEVEL_EROSION_REPORT,
    payload: data,
  };
};
//#endregion

//#region Method for Approval and Disapproval
/**
 * Changes added by
 * @author: shivam.sharma2@velsof.com
 * @date : 10-12-2022
 */
/**
 * Method for approving the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const erosionRevenueReportApprove = (data) => {
  return {
    type: EROSION_REVENUE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const erosionRevenueReportDisapprove = (data) => {
  return {
    type: EROSION_REVENUE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the report
 * @author: shivam.sharma2@velsof.com
 * @date : 14-12-2022
 */
export const erosionRevenueReportVerify = (data) => {
  return {
    type: EROSION_REVENUE_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for getting the district review list
 * @param {object} data Report data
 * @returns Json Object
 */
export const getErosionDistrictReviewList = (data) => {
  return {
    type: GET_EROSION_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the district review list
 * @param {object} data Report data
 * @returns Json Object
 */
export const setErosionDistrictReviewList = (data) => {
  return {
    type: SET_EROSION_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for getting the state review list
 * @param {object} data Report data
 * @returns Json Object
 */
export const getErosionStateReviewList = (data) => {
  return {
    type: GET_EROSION_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the state review list
 * @param {object} data Report data
 * @returns Json Object
 */
export const setErosionStateReviewList = (data) => {
  return {
    type: SET_EROSION_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const erosionDistrictReportApprove = (data) => {
  return {
    type: EROSION_DISTRICT_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the district report
 * * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const erosionDistrictReportDisapprove = (data) => {
  return {
    type: EROSION_DISTRICT_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 * @param {object} data Report Data
 * @returns Json Object
 */
export const erosionDistrictReportVerify = (data) => {
  return {
    type: EROSION_DISTRICT_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const erosionStateReportApprove = (data) => {
  return {
    type: EROSION_STATE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the state report
 * * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const erosionStateReportDisapprove = (data) => {
  return {
    type: EROSION_STATE_REPORT_DISAPPROVE,
    payload: data,
  };
};
//#endregion Method for Approval and Disapproval

//#region
/**
 * Below action will be called when exporting the cumulative state report
 * @author: shivam.sharma2@velsof.com
 * @date : 15-12-2022
 */
export const getExportStateCumulativeErosionReport = (data) => {
  return {
    type: GET_STATE_CUMULATIVE_EROSION_REPORT,
    payload: data,
  };
};

export const setExportStateCumulativeErosionReport = (data) => {
  return {
    type: SET_STATE_CUMULATIVE_EROSION_REPORT,
    payload: data,
  };
};
//#endregion

//#region
/**
 * Below action will be called when exporting the cumulative district report
 * @author: shivam.sharma2@velsof.com
 * @date : 16-12-2022
 */
export const getExportDistrictCumulativeErosionReport = (data) => {
  return {
    type: GET_DISTRICT_CUMULATIVE_EROSION_REPORT,
    payload: data,
  };
};

export const setExportDistrictCumulativeErosionReport = (data) => {
  return {
    type: SET_DISTRICT_CUMULATIVE_EROSION_REPORT,
    payload: data,
  };
};
//#endregion