/**
 * The below file contains the role state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-02-2023
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_ROLE,
  EDIT_ROLE,
  SET_ROLE,
  SET_ROLE_LISTING_PARAM,
  SET_ROLE_LISTING,
  SET_EXPORT_ROLE_DATA,
  GET_EXPORT_ROLE_DATA,
  SET_ALL_PERMISSION,
  SET_ALL_ROLE_ID,
} from "../constants/Role";

//Initial state
const initState = {
  //Keys for role listing
  roleList: {},
  isRoleDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  roleListingParam: {},
  roleExportedData: {},
  //Keys for role listing

  //Keys for role add page
  addRoleForm: {},
  //Keys for role add page

  //Keys for role edit page
  roleFormData: {},
  //Keys for role edit page

  permissionList: {},
  roleIdList: {},
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const role = (state = initState, action) => {
  switch (action.type) {
    //Cases for Role listing
    case SET_ROLE_LISTING:
      return {
        ...state,
        roleList: action.payload,
        isRoleDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ROLE_LISTING_PARAM:
      return {
        ...state,
        roleListingParam: action.payload,
      };

    case GET_EXPORT_ROLE_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_ROLE_DATA:
      return {
        ...state,
        roleExportedData: action.payload,
      };
    //Cases for Role listing

    //Cases for Role add page
    case ADD_ROLE:
      return {
        ...state,
        addRoleForm: action.payload,
      };
    //Cases for Role add page

    //Cases for Role edit page
    case EDIT_ROLE:
      return {
        ...state,
        addRoleForm: action.payload,
      };
    case SET_ROLE:
      return {
        ...state,
        roleFormData: action.payload,
      };
    //Cases for Role edit page

    case SET_ALL_PERMISSION:
      return {
        ...state,
        permissionList: action.payload,
      };

    case SET_ALL_ROLE_ID:
      return {
        ...state,
        roleIdList: action.payload,
      };
    default:
      return state;
  }
};

export default role;
