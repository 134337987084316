/**
 * The below file contains the Sagas method for wards Name.
 * @author: shivam.sharma2@velsof.com
 * @date : 29-12-2022
 */

import { Error, Success } from "constants/ApiConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  getNotification,
  getUpdatedStatus,
  setNotification,
  setUnreadNotification,
} from "redux/actions/Notification";
import {
  CHANGE_NOTIFICATION_STATUS,
  GET_NOTIFICATION,
  GET_UNREAD_NOTIFICATION,
  MARK_ALL_READ,
} from "redux/constants/Notification";
import NotificationService from "../../services/NotificationService";

//#region Method for Notification

/**
 * Method for getting the Notification
 */
export function* getNotifications() {
  yield takeEvery(GET_NOTIFICATION, function* ({ payload }) {
    try {
      const notification = yield call(
        NotificationService.getNotification,
        payload
      );
      if (notification.error != null && notification.error.message) {
        yield put(
          showMessage({ message: notification.error.message, type: Error })
        );
      } else {
        yield put(setNotification(notification.data));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the Notification
 */
export function* setNotificationStatus() {
  yield takeEvery(CHANGE_NOTIFICATION_STATUS, function* ({ payload }) {
    try {
      const notification = yield call(
        NotificationService.changeReadStatus,
        payload
      );
      if (notification.error != null && notification.error.message) {
        yield put(
          showMessage({ message: notification.error.message, type: Error })
        );
      } else {
        yield put(getUpdatedStatus(notification.data));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for marking notification as read
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-02-2023
 */
export function* markAllRead() {
  yield takeEvery(MARK_ALL_READ, function* ({ payload }) {
    try {
      const notification = yield call(NotificationService.markAllRead, payload);
      if (notification.error != null && notification.error.message) {
        yield put(
          showMessage({ message: notification.error.message, type: Error })
        );
      } else {
        let param = {
          sort: "updatedAt:desc",
          "populate[0]": "user",
          "filters[user][id][$eq]": payload.data.userId,
        };
        yield put(getNotification(param));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the Unread notificaiton
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-02-2023
 */
export function* getUnreadNotifications() {
  yield takeEvery(GET_UNREAD_NOTIFICATION, function* ({ payload }) {
    try {
      const notification = yield call(
        NotificationService.getNotification,
        payload
      );
      if (notification.error != null && notification.error.message) {
        yield put(
          showMessage({ message: notification.error.message, type: Error })
        );
      } else {
        yield put(setUnreadNotification(notification.meta.pagination.total));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getNotifications),
    fork(setNotificationStatus),
    fork(markAllRead),
    fork(getUnreadNotifications),
  ]);
}
