/**
 * The below file contains the Sagas method for Village.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-10-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Village_Added,
  Village_Imported,
  Village_Updated,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setExportVillage,
  setVillage,
  setVillageListing,
  updateVillageStatus,
} from "redux/actions/Village";
import {
  ADD_VILLAGE,
  CHANGE_VILLAGE_STATUS,
  EDIT_VILLAGE,
  GET_ALL_VILLAGE,
  GET_EXPORT_VILLAGE_DATA,
  GET_VILLAGE,
  VILLAGE_IMPORT,
} from "redux/constants/Village";
import VillageService from "services/VillageService";

//#region Method for listing

/**
 * Method for getting the villages
 */
export function* getAllVillage() {
  yield takeEvery(GET_ALL_VILLAGE, function* ({ payload }) {
    try {
      const village = yield call(VillageService.getAllVillage, payload);
      if (village.error != null && village.error.message) {
        yield put(showMessage({ message: village.error.message, type: Error }));
      } else {
        yield put(setVillageListing(village));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the village export details
 */
export function* getVillageExport() {
  yield takeEvery(GET_EXPORT_VILLAGE_DATA, function* () {
    try {
      const village = yield call(VillageService.getExportVillageData);
      if (village.error != null && village.error.message) {
        yield put(showMessage({ message: village.error.message, type: Error }));
      } else {
        let exportData = {
          url: window.URL.createObjectURL(village),
        };
        yield put(setExportVillage(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the village status details
 */
export function* changeVillageStatus() {
  yield takeEvery(CHANGE_VILLAGE_STATUS, function* ({ payload }) {
    try {
      const village = yield call(VillageService.changeVillageStatus, payload);
      if (village.error != null && village.error.message) {
        yield put(showMessage({ message: village.error.message, type: Error }));
      } else {
        let json = {
          id: village.data.id,
          status: village.data.status,
        };
        yield put(updateVillageStatus(json));
        yield put(showMessage({ message: Village_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for add page

/**
 * Method for adding the village
 */
export function* addVillage() {
  yield takeEvery(ADD_VILLAGE, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const village = yield call(VillageService.addVillage, final);
      if (village.error != null && village.error.message) {
        yield put(showMessage({ message: village.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Village_Added, type: "success" }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for add page

//#region Method for edit page

/**
 * Method for editing the village
 */
export function* editVillage() {
  yield takeEvery(EDIT_VILLAGE, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          villageName: payload.villageName,
          villageCode: payload.villageCode,
          status: payload.status,
          district: payload.district,
          block: payload.block,
        },
      };
      const village = yield call(VillageService.editVillage, id, final);
      if (village.error != null && village.error.message) {
        yield put(showMessage({ message: village.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Village_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the village
 */
export function* getVillage() {
  yield takeEvery(GET_VILLAGE, function* ({ payload }) {
    try {
      let param = {
        "populate[0]": "block",
        "populate[block][populate][0]": "district",
      };
      const village = yield call(VillageService.getVillage, payload, param);
      if (village.error != null && village.error.message) {
        yield put(showMessage({ message: village.error.message, type: Error }));
      } else {
        yield put(setVillage(village));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for add page

//#region Method for village import page

/**
 * Method for importing the village
 */
export function* villageImport() {
  yield takeEvery(VILLAGE_IMPORT, function* ({ payload }) {
    try {
      const village = yield call(VillageService.villageImport, payload);
      if (village.error != null && village.error.message) {
        yield put(showMessage({ message: village.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Village_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for village import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllVillage),
    fork(addVillage),
    fork(editVillage),
    fork(getVillage),
    fork(getVillageExport),
    fork(changeVillageStatus),
    fork(villageImport),
  ]);
}
