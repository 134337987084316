/**
 * The below file contains the constants used in the resource group master.
 * @author: dhruv.kumar@velsof.com
 * @date : 28-07-2023
 */

/**Constants for ResourceGroup Listing page */
export const GET_ALL_RESOURCEGROUP = "GET_ALL_RESOURCEGROUP";
export const SET_RESOURCEGROUP_LISTING = "SET_RESOURCEGROUP_LISTING";
export const SET_RESOURCEGROUP_LISTING_PARAM =
  "SET_RESOURCEGROUP_LISTING_PARAM";
/**Constants for ResourceGroup Listing page */

/**Constants for ResourceGroup Add page */
export const ADD_RESOURCEGROUP = "ADD_RESOURCEGROUP";
/**Constants for ResourceGroup Add page */

/**Constants for ResourceGroup Edit page */
export const EDIT_RESOURCEGROUP = "EDIT_RESOURCEGROUP";
export const GET_RESOURCEGROUP = "GET_RESOURCEGROUP";
export const SET_RESOURCEGROUP = "SET_RESOURCEGROUP";
/**Constants for ResourceGroup Edit page */
