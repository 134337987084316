/**
 * The below file contains the Sagas method for Fire Class.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Fire_Class_Imported,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setAllFireClass,
} from "redux/actions/FireClass";
import {
  GET_ALL_FIRE_CLASS,
} from "redux/constants/FireClass";
import FireClassService from "services/FireClassService";

//#region Method for listing

/**
 * Method for getting the fire class
 */
export function* getAllFireClass() {
  yield takeEvery(GET_ALL_FIRE_CLASS, function* () {
    try {
      const fire = yield call(FireClassService.getAllFireClass);
      if (fire.error != null && fire.error.message) {
        yield put(showMessage({ message: fire.error.message, type: Error }));
      } else {
        yield put(setAllFireClass(fire));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion Method for listing

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllFireClass),
  ]);
}
