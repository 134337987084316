/**
 * The below file contains the reliefOtherItem state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_RELIEFOTHERITEM,
  EDIT_RELIEFOTHERITEM,
  SET_RELIEFOTHERITEM,
  SET_RELIEFOTHERITEM_LISTING_PARAM,
  SET_RELIEFOTHERITEM_LISTING,
  UPDATE_RELIEFOTHERITEM_STATUS,
  SET_EXPORT_RELIEFOTHERITEM_DATA,
  GET_EXPORT_RELIEFOTHERITEM_DATA,
} from "../constants/ReliefOtherItem";

//Initial state
const initState = {
  //Keys for reliefOtherItem listing
  reliefOtherItemList: {},
  isReliefOtherItemDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  reliefOtherItemListingParam: {},
  reliefOtherItemExportedData: {},
  //Keys for reliefOtherItem listing

  //Keys for reliefOtherItem add page
  addReliefOtherItemForm: {},
  //Keys for reliefOtherItem add page

  //Keys for reliefOtherItem edit page
  reliefOtherItemFormData: {},
  //Keys for reliefOtherItem edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const reliefOtherItem = (state = initState, action) => {
  switch (action.type) {
    //Cases for ReliefOtherItem listing
    case SET_RELIEFOTHERITEM_LISTING:
      return {
        ...state,
        reliefOtherItemList: action.payload,
        isReliefOtherItemDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_RELIEFOTHERITEM_LISTING_PARAM:
      return {
        ...state,
        reliefOtherItemListingParam: action.payload,
      };

    case UPDATE_RELIEFOTHERITEM_STATUS:
      let updateReliefOtherItemListData = {
        ...state.reliefOtherItemList,
        data: state.reliefOtherItemList.data?.map((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        }),
      };
      return {
        ...state,
        reliefOtherItemList: updateReliefOtherItemListData,
      };

    case GET_EXPORT_RELIEFOTHERITEM_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_RELIEFOTHERITEM_DATA:
      return {
        ...state,
        reliefOtherItemExportedData: action.payload,
      };
    //Cases for ReliefOtherItem listing

    //Cases for ReliefOtherItem add page
    case ADD_RELIEFOTHERITEM:
      return {
        ...state,
        addReliefOtherItemForm: action.payload,
      };
    //Cases for ReliefOtherItem add page

    //Cases for ReliefOtherItem edit page
    case EDIT_RELIEFOTHERITEM:
      return {
        ...state,
        addReliefOtherItemForm: action.payload,
      };
    case SET_RELIEFOTHERITEM:
      return {
        ...state,
        reliefOtherItemFormData: action.payload,
      };
    //Cases for ReliefOtherItem edit page

    default:
      return state;
  }
};

export default reliefOtherItem;
