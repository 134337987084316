/**
 * The below file contains the Sagas method for Cwc.
 * @author: vishal.mishra@velsof.com
 * @date : 28-09-2023
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { CWC_IMPORT, GET_ALL_CWC } from "../constants/Cwc";

import CwcService from "services/CwcService";
import { setCWCListing } from "redux/actions/Cwc";
import { showMessage } from "redux/actions/Common";
import { Cwc_Imported } from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";

//#region Method for cwc listing

/**
 * Method for getting the cwc details
 */
export function* getAllCwc() {
  yield takeEvery(GET_ALL_CWC, function* ({ payload }) {
    try {
      const cwc = yield call(CwcService.getAllCWC, payload);
      if (cwc.error != null && cwc.error.message) {
        yield put(showMessage({ message: cwc.error.message, type: Error }));
      } else {
        yield put(setCWCListing(cwc));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#region Method for cwc import page

/**
 * Method for importing the cwc
 */
export function* cwcImport() {
  yield takeEvery(CWC_IMPORT, function* ({ payload }) {
    try {
      const cwc = yield call(CwcService.CWCImport, payload);
      if (cwc.error != null && cwc.error.message) {
        yield put(showMessage({ message: cwc.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Cwc_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for cwc import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([fork(getAllCwc), fork(cwcImport)]);
}
