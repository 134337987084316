import { Alert, Tag } from "antd";
import {
  APPROVAL_LEVEL,
  APPROVAL_STATUS,
  FIRE_APPROVAL_STATUS,
  LEVEL,
  Units,
  PAGE_TYPE,
  smallFire,
  mediumFire,
  seriousFire,
  majorFire,
  specialCall,
  verifyButton,
  approveButton,
  Disaster_Type,
  AES_Encryption,
  Disaster_Url_Slugs,
} from "constants/ApplicationConstant";
import { useHistory } from "react-router-dom";
import { LOGIN_USER } from "redux/constants/Auth";
import { Roles } from "constants/ApplicationConstant";
import {
  majorFireLoss,
  mediumFireLoss,
  houseDamagedFieldsWarnings,
} from "constants/MessageConstant";
import { aesDecrypt, aesEncrypt } from "./crypto";

/**The file contains common methods which will be used in whole project
 * @author: neeraj.singh.rawat@velsof.com
 * @date: 16-11-2022
 */
class CommonMethods {
  /**
   * The below method is for getting the status tag on the basis of params.
   * @param {number} status Status Value
   * @param {string} text String Text
   * @param {string} level String Level
   * @returns Tag Element
   */
  static getReportStatusHtml(status, text, level) {
    if (level === LEVEL.FO_LEVEL) {
      switch (status) {
        case APPROVAL_STATUS.RC_PENDING:
          return <Tag color="#FFC927">{text}</Tag>;
        case APPROVAL_STATUS.D_REVIEWED:
        case APPROVAL_STATUS.S_REVIEWED:
          return <Tag color="#0A6DF3">{text}</Tag>;
        case APPROVAL_STATUS.RC_SUBMIT:
        case APPROVAL_STATUS.RC_APPROVED:
        case APPROVAL_STATUS.D_APPROVED:
        case APPROVAL_STATUS.S_APPROVED:
          return <Tag color="#76B559">{text}</Tag>;
        case APPROVAL_STATUS.RC_REJECTED:
        case APPROVAL_STATUS.D_REJECTED:
        case APPROVAL_STATUS.S_REJECTED:
          return <Tag color="#ED0404">{text}</Tag>;
        default:
          return <Tag color="default">{text}</Tag>;
      }
    } else if (level === LEVEL.CO_LEVEL) {
      switch (status) {
        case APPROVAL_STATUS.RC_PENDING:
        case APPROVAL_STATUS.RC_SUBMIT:
          return <Tag color="#FFC927">{text}</Tag>;
        case APPROVAL_STATUS.D_REVIEWED:
        case APPROVAL_STATUS.S_REVIEWED:
          return <Tag color="#0A6DF3">{text}</Tag>;
        case APPROVAL_STATUS.RC_SUBMIT:
        case APPROVAL_STATUS.RC_APPROVED:
        case APPROVAL_STATUS.D_APPROVED:
        case APPROVAL_STATUS.S_APPROVED:
          return <Tag color="#76B559">{text}</Tag>;
        case APPROVAL_STATUS.RC_REJECTED:
        case APPROVAL_STATUS.D_REJECTED:
        case APPROVAL_STATUS.S_REJECTED:
          return <Tag color="#ED0404">{text}</Tag>;
        default:
          return <Tag color="default">{text}</Tag>;
      }
    } else if (level === LEVEL.DDMA_LEVEL || level === LEVEL.DEOC_LEVEL) {
      switch (status) {
        case APPROVAL_STATUS.RC_PENDING:
        case APPROVAL_STATUS.RC_APPROVED:
          return <Tag color="#FFC927">{text}</Tag>;
        case APPROVAL_STATUS.D_REVIEWED:
        case APPROVAL_STATUS.S_REVIEWED:
          return <Tag color="#0A6DF3">{text}</Tag>;
        case APPROVAL_STATUS.RC_SUBMIT:
        case APPROVAL_STATUS.RC_APPROVED:
        case APPROVAL_STATUS.D_APPROVED:
        case APPROVAL_STATUS.S_APPROVED:
          return <Tag color="#76B559">{text}</Tag>;
        case APPROVAL_STATUS.RC_REJECTED:
        case APPROVAL_STATUS.D_REJECTED:
        case APPROVAL_STATUS.S_REJECTED:
          return <Tag color="#ED0404">{text}</Tag>;
        default:
          return <Tag color="default">{text}</Tag>;
      }
    } else if (level === LEVEL.SEOC_LEVEL || level === LEVEL.CEOASDMA_LEVEL) {
      switch (status) {
        case APPROVAL_STATUS.RC_PENDING:
        case APPROVAL_STATUS.RC_APPROVED:
        case APPROVAL_STATUS.D_APPROVED:
          return <Tag color="#FFC927">{text}</Tag>;
        case APPROVAL_STATUS.D_REVIEWED:
        case APPROVAL_STATUS.S_REVIEWED:
          return <Tag color="#0A6DF3">{text}</Tag>;
        case APPROVAL_STATUS.RC_SUBMIT:
        case APPROVAL_STATUS.RC_APPROVED:
        case APPROVAL_STATUS.D_APPROVED:
        case APPROVAL_STATUS.S_APPROVED:
          return <Tag color="#76B559">{text}</Tag>;
        case APPROVAL_STATUS.RC_REJECTED:
        case APPROVAL_STATUS.D_REJECTED:
        case APPROVAL_STATUS.S_REJECTED:
          return <Tag color="#ED0404">{text}</Tag>;
        default:
          return <Tag color="default">{text}</Tag>;
      }
    } else if (level === LEVEL.FS_LEVEL) {
      switch (status) {
        case FIRE_APPROVAL_STATUS.HQCR_APPROVED:
        case FIRE_APPROVAL_STATUS.WING_APPROVED:
        case FIRE_APPROVAL_STATUS.SFE_APPROVED:
        case FIRE_APPROVAL_STATUS.DFES_APPROVED:
        case FIRE_APPROVAL_STATUS.FS_SUBMIT:
          return <Tag color="#76b559">{text}</Tag>;
        case FIRE_APPROVAL_STATUS.HQCR_REJECTED:
        case FIRE_APPROVAL_STATUS.WING_REJECTED:
        case FIRE_APPROVAL_STATUS.SFE_REJECTED:
        case FIRE_APPROVAL_STATUS.DFES_REJECTED:
          return <Tag color="#ED0404">{text}</Tag>;
        case FIRE_APPROVAL_STATUS.FS_PENDING:
          return <Tag color="#FFC927">{text}</Tag>;
        default:
          return <Tag color="default">{text}</Tag>;
      }
    } else if (level === LEVEL.HQCR_LEVEL) {
      switch (status) {
        case FIRE_APPROVAL_STATUS.HQCR_APPROVED:
        case FIRE_APPROVAL_STATUS.WING_APPROVED:
        case FIRE_APPROVAL_STATUS.SFE_APPROVED:
        case FIRE_APPROVAL_STATUS.DFES_APPROVED:
          return <Tag color="#76b559">{text}</Tag>;
        case FIRE_APPROVAL_STATUS.HQCR_REJECTED:
        case FIRE_APPROVAL_STATUS.WING_REJECTED:
        case FIRE_APPROVAL_STATUS.SFE_REJECTED:
        case FIRE_APPROVAL_STATUS.DFES_REJECTED:
          return <Tag color="#ED0404">{text}</Tag>;
        case FIRE_APPROVAL_STATUS.FS_PENDING:
        case FIRE_APPROVAL_STATUS.FS_SUBMIT:
          return <Tag color="#FFC927">{text}</Tag>;
        default:
          return <Tag color="default">{text}</Tag>;
      }
    } else if (level === LEVEL.WING_LEVEL) {
      switch (status) {
        case FIRE_APPROVAL_STATUS.WING_APPROVED:
        case FIRE_APPROVAL_STATUS.SFE_APPROVED:
        case FIRE_APPROVAL_STATUS.DFES_APPROVED:
        case FIRE_APPROVAL_STATUS.FS_SUBMIT:
          return <Tag color="#76b559">{text}</Tag>;
        case FIRE_APPROVAL_STATUS.HQCR_REJECTED:
        case FIRE_APPROVAL_STATUS.WING_REJECTED:
        case FIRE_APPROVAL_STATUS.SFE_REJECTED:
        case FIRE_APPROVAL_STATUS.DFES_REJECTED:
          return <Tag color="#ED0404">{text}</Tag>;
        case FIRE_APPROVAL_STATUS.FS_PENDING:
        case FIRE_APPROVAL_STATUS.HQCR_APPROVED:
          return <Tag color="#FFC927">{text}</Tag>;
        default:
          return <Tag color="default">{text}</Tag>;
      }
    } else if (level === LEVEL.SPFE_LEVEL) {
      switch (status) {
        case FIRE_APPROVAL_STATUS.HQCR_APPROVED:
        case FIRE_APPROVAL_STATUS.SFE_APPROVED:
        case FIRE_APPROVAL_STATUS.DFES_APPROVED:
        case FIRE_APPROVAL_STATUS.FS_SUBMIT:
          return <Tag color="#76b559">{text}</Tag>;
        case FIRE_APPROVAL_STATUS.HQCR_REJECTED:
        case FIRE_APPROVAL_STATUS.WING_REJECTED:
        case FIRE_APPROVAL_STATUS.SFE_REJECTED:
        case FIRE_APPROVAL_STATUS.DFES_REJECTED:
          return <Tag color="#ED0404">{text}</Tag>;
        case FIRE_APPROVAL_STATUS.FS_PENDING:
        case FIRE_APPROVAL_STATUS.WING_APPROVED:
          return <Tag color="#FFC927">{text}</Tag>;
        default:
          return <Tag color="default">{text}</Tag>;
      }
    } else if (level === LEVEL.DFES_LEVEL) {
      switch (status) {
        case FIRE_APPROVAL_STATUS.HQCR_APPROVED:
        case FIRE_APPROVAL_STATUS.WING_APPROVED:
        case FIRE_APPROVAL_STATUS.DFES_APPROVED:
        case FIRE_APPROVAL_STATUS.FS_SUBMIT:
          return <Tag color="#76b559">{text}</Tag>;
        case FIRE_APPROVAL_STATUS.HQCR_REJECTED:
        case FIRE_APPROVAL_STATUS.WING_REJECTED:
        case FIRE_APPROVAL_STATUS.SFE_REJECTED:
        case FIRE_APPROVAL_STATUS.DFES_REJECTED:
          return <Tag color="#ED0404">{text}</Tag>;
        case FIRE_APPROVAL_STATUS.FS_PENDING:
        case FIRE_APPROVAL_STATUS.SFE_APPROVED:
          return <Tag color="#FFC927">{text}</Tag>;
        default:
          return <Tag color="default">{text}</Tag>;
      }
    }
  }

  /**
   * The below method will use to convert the value in hect as per rules.
   * @author: neeraj.singh.rawat@velsof.com
   * @date : 03-12-2022
   * @param {number} value number
   * @param {string} type string
   * @returns Calculated value in Hect.
   */
  static convertToHect(value, type) {
    switch (type) {
      case Units.Bigha:
        return value * 0.1338688086;
      case Units.Katha:
        return value * 0.0267737617;
      case Units.Lecha:
        return value * 0.0013386881;
      default:
        return value;
    }
  }

  /**
   * The below method will use to convert the value in hect as per rules.
   * @author: shivam.sharma2@velsof.com
   * @date : 14-12-2022
   * @param {number} status number
   * @param {string} redirect string
   * @param {string} level string
   */
  static checkPageAccessValidation(status, redirect, level) {
    if (
      status === APPROVAL_STATUS.D_REVIEWED &&
      level === APPROVAL_LEVEL.LEVEL_DISTRICT
    ) {
      return redirect;
    } else {
      return redirect;
    }
  }

  /**
   * The below method will send the level and button type on the basis of page type.
   * @param {string} pageType Page Type
   * @returns Object
   */
  static getLevelAndButtonType(pageType) {
    /**
     * For getting the login user type in the page
     */
    const loginUser = JSON.parse(localStorage.getItem(LOGIN_USER));

    // local variables for holding the values
    let level = "",
      buttonType = "",
      loginUserId = loginUser.role.id;
    // selecting the page type
    switch (pageType) {
      case PAGE_TYPE.RC.toLowerCase():
        buttonType = approveButton;
        level = APPROVAL_LEVEL.LEVEL_RC;
        break;
      case PAGE_TYPE.DISTRICT_RC.toLowerCase():
        // To decide which button should it be
        if (
          Roles.DEOC.some((id) => id === loginUserId) ||
          Roles.DDMA.some((id) => id === loginUserId) ||
          Roles.ADMIN.some((id) => id === loginUserId)
        ) {
          buttonType = verifyButton;
        }
        level = APPROVAL_LEVEL.LEVEL_DISTRICT;
        break;
      case PAGE_TYPE.DISTRICT:
        // To decide which button should it be
        if (Roles.DEOC.some((id) => id === loginUserId)) {
          buttonType = verifyButton;
        } else if (
          Roles.DDMA.some((id) => id === loginUserId) ||
          Roles.ADMIN.some((id) => id === loginUserId)
        ) {
          buttonType = approveButton;
        }
        level = APPROVAL_LEVEL.LEVEL_DISTRICT;
        break;
      case PAGE_TYPE.STATE_DISTRICT.toLowerCase():
        // To decide which button should it be
        if (
          Roles.SEOC.some((id) => id === loginUserId) ||
          Roles.CEOASDMA.some((id) => id === loginUserId) ||
          Roles.ADMIN.some((id) => id === loginUserId)
        ) {
          buttonType = verifyButton;
        }
        level = APPROVAL_LEVEL.LEVEL_STATE;
        break;
      case PAGE_TYPE.STATE:
        // To decide which button should it be
        if (Roles.SEOC.some((id) => id === loginUserId)) {
          buttonType = verifyButton;
        } else if (
          Roles.CEOASDMA.some((id) => id === loginUserId) ||
          Roles.ADMIN.some((id) => id === loginUserId)
        ) {
          buttonType = approveButton;
        }
        level = APPROVAL_LEVEL.LEVEL_STATE;
        break;
      default:
        break;
    }
    return {
      level: level,
      buttonType: buttonType,
    };
  }

  /* Method for sending the Html Tag on the basis of review status.
   * @author: neeraj.singh.rawat@velsof.com
   * @date : 14-12-2022
   * @param {string} reviewStatus Review Status
   * @returns Html Tag
   */
  static getCRReportStatusHtml(reviewStatus) {
    switch (reviewStatus) {
      case APPROVAL_STATUS.DRAFT_TEXT:
      case APPROVAL_STATUS.PENDING_TEXT:
        return (
          <Tag color="#FFC927" className="ml-2">
            {reviewStatus}
          </Tag>
        );
      case APPROVAL_STATUS.REVIEWED_TEXT:
        return (
          <Tag color="#0A6DF3" className="ml-2">
            {reviewStatus}
          </Tag>
        );
      case APPROVAL_STATUS.APPROVED_TEXT:
        return (
          <Tag color="#76B559" className="ml-2">
            {reviewStatus}
          </Tag>
        );
      case APPROVAL_STATUS.REJECTED_TEXT:
        return (
          <Tag color="#ED0404" className="ml-2">
            {reviewStatus}
          </Tag>
        );
      default:
        return (
          <Tag color="default" className="ml-2">
            {reviewStatus}
          </Tag>
        );
    }
  }

  /**
   * Logic for handling the fields value for the property damaged section in the Fire Form
   * @param {String} fireCategory
   * @param {String} enteredValue
   * @returns {String} message
   */
  static getValuesVerifiedForPropertyDamaged(fireCategory, pdDamaged) {
    switch (fireCategory) {
      case smallFire:
        if (pdDamaged < 50000) {
          return "";
        } else {
          return houseDamagedFieldsWarnings.piSmallFireLoss;
        }
      case mediumFire:
        if (pdDamaged < 50000 || pdDamaged >= 100000) {
          return houseDamagedFieldsWarnings.piMediumFireLoss;
        } else {
          return "";
        }
      case seriousFire:
        return "";
      case majorFire:
        if (pdDamaged >= 100000) {
          return "";
        } else {
          return houseDamagedFieldsWarnings.piMajorFireLoss;
        }
      default:
        return "Please select the fire category first";
    }
  }

  /**
   * Method to generate the random string.
   * @param {number} length Number of characters in the string
   * @returns string
   * @author: neeraj.singh.rawat@velsof.com
   * @date : 02-02-2023
   */
  static randomString(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  /**
   * Method to decide whether the disaster is accessible or not.
   * @param {Array} permissions Permissions of the user
   * @param {String} disasterType Disaster Type
   * @returns Boolean Value
   * @author: neeraj.singh.rawat@velsof.com
   * @date : 04-02-2023
   */
  static disasterIsAccessible(permissions, disasterType) {
    switch (disasterType) {
      case Disaster_Type.Erosion:
        return permissions?.some((item) =>
          item.action.includes(Disaster_Type.Erosion)
        );
      case Disaster_Type.Landslide:
        return permissions?.some((item) =>
          item.action.includes(Disaster_Type.Landslide)
        );
      case Disaster_Type.Storm:
        return permissions?.some((item) =>
          item.action.includes(Disaster_Type.Storm)
        );
      case Disaster_Type.Lightning:
        return permissions?.some((item) =>
          item.action.includes(Disaster_Type.Lightning)
        );
      case Disaster_Type.UrbanFlood:
        return permissions?.some((item) =>
          item.action.includes(Disaster_Type.UrbanFlood)
        );
      case Disaster_Type.Earthquake:
        return permissions?.some((item) =>
          item.action.includes(Disaster_Type.Earthquake)
        );
      case Disaster_Type.Fire:
        return permissions?.some((item) =>
          item.action.includes(Disaster_Type.Fire)
        );
      case Disaster_Type.Flood:
        return permissions?.some((item) =>
          item.action.includes(Disaster_Type.Flood)
        );
      default:
        return false;
    }
  }

  /**
   * Method to get the file name
   * @param {String} format File format
   * @returns File name
   * @author: neeraj.singh.rawat@velsof.com
   * @date : 07-02-2023
   */
  static getFileName(format) {
    switch (format) {
      case "pdf":
        return "Report.pdf";

      case "doc":
        return "Report.docx";

      case "excel":
        return "Report.xlsx";

      default:
        return null;
    }
  }

  /**
   * Method to get report type of earthquake report
   * @param {String} format File format
   * @returns Report Type of earthquake report
   * @author: neeraj.singh.rawat@velsof.com
   * @date : 08-02-2023
   */
  static getEarthquakeReportType(reportType) {
    switch (reportType) {
      case "new":
        return "New";

      case "existing":
        return "Existing";

      case "aftershock":
        return "AfterShock";

      default:
        return null;
    }
  }

  /**
   * Method to encrypt the text using AES.
   * @param {string} text Text to encrypt
   * @returns Encrypted text
   * @author: neeraj.singh.rawat@velsof.com
   * @date : 07-03-2023
   */
  static aesEncrypt = (text) => {
    if (text) {
      const encrypted = aesEncrypt(text);
      return encrypted;
    }
    return text;
  };

  /**
   * Method to decrypt the text using AES.
   * @param {string} text Text to dcrypt
   * @returns Decrypt text
   * @author: neeraj.singh.rawat@velsof.com
   * @date : 07-03-2023
   */
  static aesDecrypt = (text) => {
    if (text) {
      const decrypted = aesDecrypt(text);
      return decrypted;
    }
    return text;
  };

  static getCardDetails = (pathName) => {
    let details = {};
    switch (true) {
      case pathName.includes(Disaster_Url_Slugs.Erosion):
        details = {
          ...details,
          title: "Erosion Reporting",
          backgroundColor: "#9D3F3F",
          color: "#ffffff",
        };
        break;
      case pathName.includes(Disaster_Url_Slugs.Landslide):
        details = {
          ...details,
          title: "Landslide Reporting",
          backgroundColor: "#54B435",
          color: "#ffffff",
        };
        break;
      case pathName.includes(Disaster_Url_Slugs.Storm):
        details = {
          ...details,
          title: "Storm Reporting",
          backgroundColor: "#E14D2A",
          color: "#ffffff",
        };
        break;
      case pathName.includes(Disaster_Url_Slugs.Lightning):
        details = {
          ...details,
          title: "Lightning Reporting",
          backgroundColor: "#004290",
          color: "#ffffff",
        };
        break;
      case pathName.includes(Disaster_Url_Slugs.UrbanFlood):
        details = {
          ...details,
          title: "Urban Flood Reporting",
          backgroundColor: "#01939A",
          color: "#ffffff",
        };
        break;
      case pathName.includes(Disaster_Url_Slugs.Earthquake):
        details = {
          ...details,
          title: "Earthquake Reporting",
          backgroundColor: "#D500E0",
          color: "#ffffff",
        };
        break;
      case pathName.includes(Disaster_Url_Slugs.Fire):
        details = {
          ...details,
          title: "Fire Reporting",
          backgroundColor: "#DC0000",
          color: "#ffffff",
        };
        break;
      case pathName.includes(Disaster_Url_Slugs.Flood):
        details = {
          ...details,
          title: "Flood Reporting",
          backgroundColor: "#004290",
          color: "#ffffff",
        };
        break;
      default:
        details = {};
        break;
    }
    return details;
  };
}

export default CommonMethods;
