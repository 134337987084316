/**
 * The below file contains the river state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 01-12-2022
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_RIVER,
  EDIT_RIVER,
  SET_RIVER,
  SET_RIVER_LISTING_PARAM,
  SET_RIVER_LISTING,
  UPDATE_RIVER_STATUS,
  SET_EXPORT_RIVER_DATA,
  GET_EXPORT_RIVER_DATA,
} from "../constants/River";

//Initial state
const initState = {
  //Keys for river listing
  riverList: {},
  isRiverDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  riverListingParam: {},
  riverExportedData: {},
  //Keys for river listing

  //Keys for river add page
  addRiverForm: {},
  //Keys for river add page

  //Keys for river edit page
  riverFormData: {},
  //Keys for river edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const river = (state = initState, action) => {
  switch (action.type) {
    //Cases for River listing
    case SET_RIVER_LISTING:
      return {
        ...state,
        riverList: action.payload,
        isRiverDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_RIVER_LISTING_PARAM:
      return {
        ...state,
        riverListingParam: action.payload,
      };

    case UPDATE_RIVER_STATUS:
      let updateRiverListData = {
        ...state.riverList,
        data: state.riverList.data?.map((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        }),
      };
      return {
        ...state,
        riverList: updateRiverListData,
      };

    case GET_EXPORT_RIVER_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_RIVER_DATA:
      return {
        ...state,
        riverExportedData: action.payload,
      };
    //Cases for River listing

    //Cases for River add page
    case ADD_RIVER:
      return {
        ...state,
        addRiverForm: action.payload,
      };
    //Cases for River add page

    //Cases for River edit page
    case EDIT_RIVER:
      return {
        ...state,
        addRiverForm: action.payload,
      };
    case SET_RIVER:
      return {
        ...state,
        riverFormData: action.payload,
      };
    //Cases for River edit page

    default:
      return state;
  }
};

export default river;
