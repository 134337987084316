/**
 * The below file contains the action methods for fire detection.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import {
  GET_ALL_FIRE_DETECTION,
  SET_ALL_FIRE_DETECTION,
} from "redux/constants/FireDetection";

//#region Action methods for fetching fire detection

/**
 * Method for getting the fire detection
 * @param {any} params Origin Data
 * @returns Json Object
 */
export const getAllFireDetection = () => {
  return {
    type: GET_ALL_FIRE_DETECTION,
  };
};

/**
 * Method for setting the fire detection
 * @param {any} params Origin Data
 * @returns Json Object
 */
export const setAllFireDetection = (data) => {
  return {
    type: SET_ALL_FIRE_DETECTION,
    payload: data,
  };
};

//#endregion