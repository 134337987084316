/**
 * The below file contains the action methods for infOtherDamage master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-11-2022
 */

import {
  ADD_INFOTHERDAMAGE,
  CHANGE_INFOTHERDAMAGE_STATUS,
  INFOTHERDAMAGE_IMPORT,
  EDIT_INFOTHERDAMAGE,
  GET_ALL_INFOTHERDAMAGE,
  GET_INFOTHERDAMAGE,
  GET_EXPORT_INFOTHERDAMAGE_DATA,
  SET_INFOTHERDAMAGE,
  SET_INFOTHERDAMAGE_LISTING,
  SET_INFOTHERDAMAGE_LISTING_PARAM,
  SET_EXPORT_INFOTHERDAMAGE_DATA,
  UPDATE_INFOTHERDAMAGE_STATUS,
} from "../constants/InfOtherDamage";

//#region Action methods for infOtherDamage listing page

/**
 * Method for getting the infOtherDamage listing
 * @param {any} params Parameter Data
 * @returns Json Object
 */
export const getAllInfOtherDamage = (params) => {
  return {
    type: GET_ALL_INFOTHERDAMAGE,
    payload: params,
  };
};

/**
 * Method for setting the infOtherDamage in the state
 * @param {any} infOtherDamage Infrastructure Department Data
 * @returns Json Object
 */
export const setInfOtherDamageListing = (infOtherDamage) => {
  return {
    type: SET_INFOTHERDAMAGE_LISTING,
    payload: infOtherDamage,
  };
};

/**
 * Method for setting the infOtherDamage parameters
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const setInfOtherDamageTableParam = (data) => {
  return {
    type: SET_INFOTHERDAMAGE_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the infOtherDamage status
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const updateInfOtherDamageStatus = (data) => {
  return {
    type: UPDATE_INFOTHERDAMAGE_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the infOtherDamage status
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const changeInfOtherDamageStatus = (data) => {
  return {
    type: CHANGE_INFOTHERDAMAGE_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of infOtherDamage
 * @returns Json Object
 */
export const getExportInfOtherDamage = () => {
  return {
    type: GET_EXPORT_INFOTHERDAMAGE_DATA,
  };
};

/**
 * Method for setting the export details in the state
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const setExportInfOtherDamage = (data) => {
  return {
    type: SET_EXPORT_INFOTHERDAMAGE_DATA,
    payload: data,
  };
};
//#endregion Action methods for infOtherDamage listing page

//#region Action methods for infOtherDamage add page
/**
 * Method for adding the infOtherDamage
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const addInfOtherDamage = (data) => {
  return {
    type: ADD_INFOTHERDAMAGE,
    payload: data,
  };
};
//#endregion Action methods for infOtherDamage add page

//#region Action methods for infOtherDamage edit page

/**
 * Method for editing the infOtherDamage
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const editInfOtherDamage = (data) => {
  return {
    type: EDIT_INFOTHERDAMAGE,
    payload: data,
  };
};

/**
 * Method for getting the infOtherDamage details
 * @param {any} id Infrastructure Department Id
 * @returns Json Object
 */
export const getInfOtherDamage = (id) => {
  return {
    type: GET_INFOTHERDAMAGE,
    payload: id,
  };
};

/**
 * Method for setting the infOtherDamage details in the state
 * @param {any} infOtherDamage Infrastructure Department Data
 * @returns Json Object
 */
export const setInfOtherDamage = (infOtherDamage) => {
  return {
    type: SET_INFOTHERDAMAGE,
    payload: infOtherDamage,
  };
};
//#endregion Action methods for infOtherDamage edit page

//#region Action methods for infOtherDamage import page
/**
 * Method for infOtherDamage import
 * @param {any} data Infrastructure Department Data
 * @returns Json Object
 */
export const infOtherDamageImport = (data) => {
  return {
    type: INFOTHERDAMAGE_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for infOtherDamage import page
