/**
 * The below file contains the Sagas method for Livestock Types.
 * @author: shivam.sharma2@velsof.com
 * @date : 12-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Livestock_Imported,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setAllLivestocktypes,
} from "redux/actions/LivestockTypes";
import {
  GET_ALL_LIVESTOCK_TYPES,
} from "redux/constants/LivestockTypes";
import LivestocksService from "services/LivestocksService";

//#region Method for listing

/**
 * Method for getting the Livestock Types
 */
export function* getAllLivestockTypes() {
  yield takeEvery(GET_ALL_LIVESTOCK_TYPES, function* () {
    try {
      const livestockTypes = yield call(LivestocksService.getAllLivestockTypes);
      if (livestockTypes.error != null && livestockTypes.error.message) {
        yield put(showMessage({ message: livestockTypes.error.message, type: Error }));
      } else {
        yield put(setAllLivestocktypes(livestockTypes));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion Method for the Livestock Types

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllLivestockTypes),
  ]);
}
