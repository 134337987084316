/**
 * The below file contains the constants used in the landslide report.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */

/**Constants for landslide Listing page */
export const GET_ALL_LANDSLIDE = "GET_ALL_LANDSLIDE";
export const SET_LANDSLIDE_LISTING = "SET_LANDSLIDE_LISTING";
export const SET_LANDSLIDE_LISTING_PARAM = "SET_LANDSLIDE_LISTING_PARAM";
export const GET_EXPORT_LANDSLIDE_DATA = "GET_EXPORT_LANDSLIDE_DATA";
export const SET_EXPORT_LANDSLIDE_DATA = "SET_EXPORT_LANDSLIDE_DATA";
/**Constants for landslide Listing page */

/**Constants for landslide Import page */
export const LANDSLIDE_IMPORT = "LANDSLIDE_IMPORT";
/**Constants for landslide Import page */

//#region All Section
/**Constants for all section details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_GET_ALL_SECTION = "LANDSLIDE_GET_ALL_SECTION";
export const LANDSLIDE_SET_ALL_SECTION = "LANDSLIDE_SET_ALL_SECTION";
export const LANDSLIDE_RESET_BUTTON_LOADING = "LANDSLIDE_RESET_BUTTON_LOADING";
export const LANDSLIDE_SET_PARENT_ID = "LANDSLIDE_SET_PARENT_ID";
export const LANDSLIDE_REPORT_SUBMIT = "LANDSLIDE_REPORT_SUBMIT";
export const LANDSLIDE_REPORT_DISCARD = "LANDSLIDE_REPORT_DISCARD";
export const LANDSLIDE_SET_REDIRECT_TO_LISTING =
  "LANDSLIDE_SET_REDIRECT_TO_LISTING";
export const LANDSLIDE_SET_DATA_SAVED = "LANDSLIDE_SET_DATA_SAVED";
//#endregion All Section

//#region Basic Section
/**Constants for adding the basic section details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_BASIC_SECTION = "LANDSLIDE_ADD_BASIC_SECTION";
export const LANDSLIDE_EDIT_BASIC_SECTION = "LANDSLIDE_EDIT_BASIC_SECTION";
export const LANDSLIDE_SET_NIL_REPORTING = "LANDSLIDE_SET_NIL_REPORTING";
//#endregion Basic Section

//#region InfDamage Section
/**Constants for adding the infrastructure damage details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_INFDAMAGE_SECTION =
  "LANDSLIDE_ADD_INFDAMAGE_SECTION";
//#endregion InfDamage Section

//#region InfDamageOther Section
/**Constants for adding the infrastructure others damage details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_INFDAMAGEOTHER_SECTION =
  "LANDSLIDE_ADD_INFDAMAGEOTHER_SECTION";
//#endregion InfDamageOther Section

//#region Remark Section
/**Constants for adding the Remark details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_REMARK_SECTION = "LANDSLIDE_ADD_REMARK_SECTION";
//#endregion Remark Section

//#region PopAffected Section
/**Constants for adding the population affected details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_POPAFFECTED_SECTION =
  "LANDSLIDE_ADD_POPAFFECTED_SECTION";
//#endregion PopAffected Section

//#region Eroded Section
/**Constants for adding the eroded details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_ERODED_SECTION = "LANDSLIDE_ADD_ERODED_SECTION";
//#endregion Eroded Section

//#region Relief Camp Section
/**Constants for adding the relief camp details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_RELIEFCAMP_SECTION =
  "LANDSLIDE_ADD_RELIEFCAMP_SECTION";
//#endregion relief camp Section

//#region Camp Inmates Section
/**Constants for adding the camp Inmates details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_CAMPINMATES_SECTION =
  "LANDSLIDE_ADD_CAMPINMATES_SECTION";
//#endregion Camp Inmates Section

//#region Human Live Lost Section
/**Constants for adding the Human Live Lost details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_HLL_SECTION = "LANDSLIDE_ADD_HLL_SECTION";
//#endregion Human Live Lost Section

//#region LiveStock Section
/**Constants for adding the LiveStock details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_LIVESTOCK_SECTION =
  "LANDSLIDE_ADD_LIVESTOCK_SECTION";
//#endregion LiveStock Section

//#region HouseDamaged Section
/**Constants for adding the HouseDamaged details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_HOUSEDAMAGED_SECTION =
  "LANDSLIDE_ADD_HOUSEDAMAGED_SECTION";
//#endregion HouseDamaged Section

//#region RescueOperation Section
/**Constants for adding the RescueOperation details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_RESCUEOPERATION_SECTION =
  "LANDSLIDE_ADD_RESCUEOPERATION_SECTION";
//#endregion RescueOperation Section

//#region ReliefDistribution Section
/**Constants for adding the ReliefDistribution details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-12-2022
 */
export const LANDSLIDE_ADD_RELIEFDISTRIBUTION_SECTION =
  "LANDSLIDE_ADD_RELIEFDISTRIBUTION_SECTION";
//#endregion ReliefDistribution Section

/**
 * Constants for Reports and Approval and Disapproval
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
//#region Report and Approval and Disapproval
export const LANDSLIDE_REVENUE_REPORT_APPROVE =
  "LANDSLIDE_REVENUE_REPORT_APPROVE";
export const LANDSLIDE_REVENUE_REPORT_DISAPPROVE =
  "LANDSLIDE_REVENUE_REPORT_DISAPPROVE";
export const LANDSLIDE_REVENUE_REPORT_VERIFY =
  "LANDSLIDE_REVENUE_REPORT_VERIFY";

export const EXPORT_FO_LEVEL_LANDSLIDE_REPORT =
  "EXPORT_FO_LEVEL_LANDSLIDE_REPORT";
export const SET_EXPORT_FO_LEVEL_LANDSLIDE_REPORT =
  "SET_EXPORT_FO_LEVEL_LANDSLIDE_REPORT";

export const LANDSLIDE_DISTRICT_REPORT_APPROVE =
  "LANDSLIDE_DISTRICT_REPORT_APPROVE";
export const LANDSLIDE_DISTRICT_REPORT_DISAPPROVE =
  "LANDSLIDE_DISTRICT_REPORT_DISAPPROVE";
export const LANDSLIDE_DISTRICT_REPORT_VERIFY =
  "LANDSLIDE_DISTRICT_REPORT_VERIFY";

export const LANDSLIDE_STATE_REPORT_APPROVE = "LANDSLIDE_STATE_REPORT_APPROVE";
export const LANDSLIDE_STATE_REPORT_DISAPPROVE =
  "LANDSLIDE_STATE_REPORT_DISAPPROVE";

export const GET_LANDSLIDE_DISTRICT_REVIEW_LIST =
  "GET_LANDSLIDE_DISTRICT_REVIEW_LIST";
export const SET_LANDSLIDE_DISTRICT_REVIEW_LIST =
  "SET_LANDSLIDE_DISTRICT_REVIEW_LIST";
export const GET_LANDSLIDE_STATE_REVIEW_LIST =
  "GET_LANDSLIDE_STATE_REVIEW_LIST";
export const SET_LANDSLIDE_STATE_REVIEW_LIST =
  "SET_LANDSLIDE_STATE_REVIEW_LIST";

export const SET_DISTRICT_LANDSLIDE_REPORT = "SET_DISTRICT_LANDSLIDE_REPORT";
export const GET_DISTRICT_LANDSLIDE_REPORT = "GET_DISTRICT_LANDSLIDE_REPORT";
export const GET_STATE_CUMULATIVE_LANDSLIDE_REPORT =
  "GET_STATE_CUMULATIVE_LANDSLIDE_REPORT";
export const SET_STATE_CUMULATIVE_LANDSLIDE_REPORT =
  "SET_STATE_CUMULATIVE_LANDSLIDE_REPORT";
export const GET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT =
  "GET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT";
export const SET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT =
  "SET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT";
//#endregion Report and Approval and Disapproval
