import { NAV_TYPE_SIDE, DIR_LTR, SIDE_NAV_DARK } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "DRIMS";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/authenticate";
export const STAGING = false;
export const MARQUEE_TEXT = "THIS IS A TESTING ENVIRONMENT.";

/**The below is the config object having theme related configuration
 * Adding menuList for deciding the left hand side menu on the basis of selection
 */
export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_DARK,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  menuList: [],
  menuToken: "",
};
