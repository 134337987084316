/**
 * The below file contains the action methods for types of occupancy
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import {
  GET_ALL_TYPES_OF_OCCUPANCY,
  SET_ALL_TYPES_OF_OCCUPANCY,
} from "redux/constants/TypesOfOccupancy";

//#region Action methods for fetching types of occupancy

/**
 * Method for getting the types of occupancy
 * @param {any} params Origin Data
 * @returns Json Object
 */
export const getAllTypesOfOccupancy = () => {
  return {
    type: GET_ALL_TYPES_OF_OCCUPANCY,
  };
};

/**
 * Method for setting the types of occupancy
 * @param {any} params types of occupancy Data
 * @returns Json Object
 */
export const setAllTypesOfOccupancy = (data) => {
  return {
    type: SET_ALL_TYPES_OF_OCCUPANCY,
    payload: data,
  };
};

//#endregion