/**
 * The below file contains the action methods for vehicle category.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import {
  GET_ALL_VEHICLE_CATEGORY,
  SET_ALL_VEHICLE_CATEGORY,
} from "redux/constants/VehicleCategory";

//#region Action methods for fetching Vehicle Category

/**
 * Method for getting the Vehicle Category
 * @param {any} params Vehicle Category Data
 * @returns Json Object
 */
export const getAllVehicleCategory = () => {
  return {
    type: GET_ALL_VEHICLE_CATEGORY,
  };
};

/**
 * Method for setting the Vehicle Category
 * @param {any} params Vehicle Category Data
 * @returns Json Object
 */
export const setAllVehicleCategory = (data) => {
  return {
    type: SET_ALL_VEHICLE_CATEGORY,
    payload: data,
  };
};

//#endregion