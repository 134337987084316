/**
 * The below file contains the action methods for Resource Group.
 * @author: dhruv.kumar@velsof.com
 * @date : 28-07-2023
 */

import {
  ADD_RESOURCEGROUP,
  EDIT_RESOURCEGROUP,
  GET_ALL_RESOURCEGROUP,
  GET_RESOURCEGROUP,
  SET_RESOURCEGROUP,
  SET_RESOURCEGROUP_LISTING,
  SET_RESOURCEGROUP_LISTING_PARAM,
} from "../constants/ResourceGroup";

//#region Action methods for resourceGroup listing page

/**
 * Method for getting the resourceGroup listing
 * @param {any} params Parameter Data
 * @returns Json Object
 */
export const getAllResourceGroup = (params) => {
  return {
    type: GET_ALL_RESOURCEGROUP,
    payload: params,
  };
};

/**
 * Method for setting the resourceGroup in the state
 * @param {any} resourceGroup Resource Group Data
 * @returns Json Object
 */
export const setResourceGroupListing = (resourceGroup) => {
  return {
    type: SET_RESOURCEGROUP_LISTING,
    payload: resourceGroup,
  };
};

/**
 * Method for setting the resourceGroup parameters
 * @param {any} data Resource Group Data
 * @returns Json Object
 */
export const setResourceGroupTableParam = (data) => {
  return {
    type: SET_RESOURCEGROUP_LISTING_PARAM,
    payload: data,
  };
};
//#endregion Action methods for resourceGroup listing page

//#region Action methods for resourceGroup add page
/**
 * Method for adding the resourceGroup
 * @param {any} data Resource Group Data
 * @returns Json Object
 */
export const addResourceGroup = (data) => {
  return {
    type: ADD_RESOURCEGROUP,
    payload: data,
  };
};
//#endregion Action methods for resourceGroup add page

//#region Action methods for resourceGroup edit page

/**
 * Method for editing the resourceGroup
 * @param {any} data Resource Group Data
 * @returns Json Object
 */
export const editResourceGroup = (data) => {
  return {
    type: EDIT_RESOURCEGROUP,
    payload: data,
  };
};

/**
 * Method for getting the resourceGroup details
 * @param {any} id Resource Group Id
 * @returns Json Object
 */
export const getResourceGroup = (id) => {
  return {
    type: GET_RESOURCEGROUP,
    payload: id,
  };
};

/**
 * Method for setting the resourceGroup details in the state
 * @param {any} resourceGroup Resource Group Data
 * @returns Json Object
 */
export const setResourceGroup = (resourceGroup) => {
  return {
    type: SET_RESOURCEGROUP,
    payload: resourceGroup,
  };
};
//#endregion Action methods for resourceGroup edit page
