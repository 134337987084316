import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SIGNOUT_SUCCESS,
  LOGIN_USER,
  SET_LOGIN_USER_DETAILS,
} from "../constants/Auth";

const initState = {
  //loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN),
  loginUser: JSON.parse(localStorage.getItem(LOGIN_USER)),
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        //loading: false,
        redirect: "/",
        token: action.token,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loginUser: null,
        //loading: false,
      };
    }
    case SET_LOGIN_USER_DETAILS: {
      return {
        ...state,
        loginUser: action.payload,
      };
    }
    default:
      return state;
  }
};

export default auth;
