/**
 * The below file is the reliefOtherItem service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_ReliefOtherItem,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_ReliefOtherItem_Status,
  ReliefOtherItem_Import,
  Edit_ReliefOtherItem,
  Get_All_ReliefOtherItem,
  Get_ReliefOtherItem,
  Get_Export_ReliefOtherItem,
  Multipart_Form_Data,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const ReliefOtherItemService = {};

//#region Method for reliefOtherItem listing page.

/**
 * Method for getting the reliefOtherItem listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
ReliefOtherItemService.getAllReliefOtherItem = function (param) {
  return fetch({
    url: Get_All_ReliefOtherItem,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the reliefOtherItem export data
 * @returns Fetch Method
 */
ReliefOtherItemService.getExportReliefOtherItemData = function () {
  return fetch({
    url: Get_Export_ReliefOtherItem,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the reliefOtherItem status
 * @param {any} data API Data
 * @returns Fetch Method
 */
ReliefOtherItemService.changeReliefOtherItemStatus = function (data) {
  return fetch({
    url: Change_ReliefOtherItem_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for reliefOtherItem listing page.

//#region Method for reliefOtherItem add page.

/**
 * Method for adding the reliefOtherItem
 * @param {any} data API Data
 * @returns Fetch Method
 */
ReliefOtherItemService.addReliefOtherItem = function (data) {
  return fetch({
    url: Add_ReliefOtherItem,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for reliefOtherItem add page.

//#region Method for reliefOtherItem edit page.

/**
 * Method for editing the reliefOtherItem
 * @param {number} id ReliefOtherItem Id
 * @param {any} data API Data
 * @returns Fetch Method
 */
ReliefOtherItemService.editReliefOtherItem = function (id, data) {
  return fetch({
    url: format(Edit_ReliefOtherItem, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the reliefOtherItem detail
 * @param {any} data API Data
 * @returns Fetch Method
 */
ReliefOtherItemService.getReliefOtherItem = function (data) {
  return fetch({
    url: format(Get_ReliefOtherItem, data.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion Method for reliefOtherItem edit page.

//#region Method for reliefOtherItem import page.

/**
 * Method for importing the reliefOtherItem
 * @param {any} data API Data
 * @returns Fetch Method
 */
ReliefOtherItemService.reliefOtherItemImport = function (data) {
  return fetch({
    url: ReliefOtherItem_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for reliefOtherItem import page.

export default ReliefOtherItemService;
