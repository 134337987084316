/**
 * The below file contains the constants used in the rivers master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 01-12-2022
 */

/**Constants for River Listing page */
export const GET_ALL_RIVER = "GET_ALL_RIVER";
export const SET_RIVER_LISTING = "SET_RIVER_LISTING";
export const SET_RIVER_LISTING_PARAM = "SET_RIVER_LISTING_PARAM";
export const UPDATE_RIVER_STATUS = "UPDATE_RIVER_STATUS";
export const CHANGE_RIVER_STATUS = "CHANGE_RIVER_STATUS";
export const GET_EXPORT_RIVER_DATA = "GET_EXPORT_RIVER_DATA";
export const SET_EXPORT_RIVER_DATA = "SET_EXPORT_RIVER_DATA";
/**Constants for River Listing page */

/**Constants for River Add page */
export const ADD_RIVER = "ADD_RIVER";
/**Constants for River Add page */

/**Constants for River Edit page */
export const EDIT_RIVER = "EDIT_RIVER";
export const GET_RIVER = "GET_RIVER";
export const SET_RIVER = "SET_RIVER";
/**Constants for River Edit page */

/**Constants for River Import page */
export const RIVER_IMPORT = "RIVER_IMPORT";
/**Constants for River Import page */
