/**
 * The below file contains the constants used in the agencies master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

/**Constants for Agency Listing page */
export const GET_ALL_AGENCY = "GET_ALL_AGENCY";
export const SET_AGENCY_LISTING = "SET_AGENCY_LISTING";
export const SET_AGENCY_LISTING_PARAM = "SET_AGENCY_LISTING_PARAM";
export const UPDATE_AGENCY_STATUS = "UPDATE_AGENCY_STATUS";
export const CHANGE_AGENCY_STATUS = "CHANGE_AGENCY_STATUS";
export const GET_EXPORT_AGENCY_DATA = "GET_EXPORT_AGENCY_DATA";
export const SET_EXPORT_AGENCY_DATA = "SET_EXPORT_AGENCY_DATA";
/**Constants for Agency Listing page */

/**Constants for Agency Add page */
export const ADD_AGENCY = "ADD_AGENCY";
/**Constants for Agency Add page */

/**Constants for Agency Edit page */
export const EDIT_AGENCY = "EDIT_AGENCY";
export const GET_AGENCY = "GET_AGENCY";
export const SET_AGENCY = "SET_AGENCY";
/**Constants for Agency Edit page */

/**Constants for Agency Import page */
export const AGENCY_IMPORT = "AGENCY_IMPORT";
/**Constants for Agency Import page */
