/**
 * The below file contains the action methods for agency master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import {
  ADD_AGENCY,
  CHANGE_AGENCY_STATUS,
  AGENCY_IMPORT,
  EDIT_AGENCY,
  GET_ALL_AGENCY,
  GET_AGENCY,
  GET_EXPORT_AGENCY_DATA,
  SET_AGENCY,
  SET_AGENCY_LISTING,
  SET_AGENCY_LISTING_PARAM,
  SET_EXPORT_AGENCY_DATA,
  UPDATE_AGENCY_STATUS,
} from "../constants/Agency";

//#region Action methods for agency listing page

/**
 * Method for getting the agency listing
 * @param {any} params Agency Params
 * @returns Json Object
 */
export const getAllAgency = (params) => {
  return {
    type: GET_ALL_AGENCY,
    payload: params,
  };
};

/**
 * Method for setting the agency in the state
 * @param {any} agency Agency Data
 * @returns Json Object
 */
export const setAgencyListing = (agency) => {
  return {
    type: SET_AGENCY_LISTING,
    payload: agency,
  };
};

/**
 * Method for setting the agency parameters
 * @param {any} data Agency Params
 * @returns Json Object
 */
export const setAgencyTableParam = (data) => {
  return {
    type: SET_AGENCY_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the agency status
 * @param {any} data Agency Data
 * @returns Json Object
 */
export const updateAgencyStatus = (data) => {
  return {
    type: UPDATE_AGENCY_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the agency status
 * @param {any} data Agency Data
 * @returns Json Object
 */
export const changeAgencyStatus = (data) => {
  return {
    type: CHANGE_AGENCY_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of agency
 * @returns Json Object
 */
export const getExportAgency = () => {
  return {
    type: GET_EXPORT_AGENCY_DATA,
  };
};

/**
 * Method for setting the export details in the state
 * @param {any} data Export Data
 * @returns Json Object
 */
export const setExportAgency = (data) => {
  return {
    type: SET_EXPORT_AGENCY_DATA,
    payload: data,
  };
};
//#endregion Action methods for agency listing page

//#region Action methods for agency add page
/**
 * Method for adding the agency
 * @param {any} data Agency Data
 * @returns Json Object
 */
export const addAgency = (data) => {
  return {
    type: ADD_AGENCY,
    payload: data,
  };
};
//#endregion Action methods for agency add page

//#region Action methods for agency edit page

/**
 * Method for editing the agency
 * @param {any} data Agency Data
 * @returns Json Object
 */
export const editAgency = (data) => {
  return {
    type: EDIT_AGENCY,
    payload: data,
  };
};

/**
 * Method for getting the agency details
 * @param {any} id Agency Id
 * @returns Json Object
 */
export const getAgency = (id) => {
  return {
    type: GET_AGENCY,
    payload: id,
  };
};

/**
 * Method for setting the agency details in the state
 * @param {any} agency Agency Data
 * @returns Json Object
 */
export const setAgency = (agency) => {
  return {
    type: SET_AGENCY,
    payload: agency,
  };
};
//#endregion Action methods for agency edit page

//#region Action methods for agency edit page
/**
 * Method for agency import
 * @param {any} data Agency Data
 * @returns Json Object
 */
export const agencyImport = (data) => {
  return {
    type: AGENCY_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for agency import page
