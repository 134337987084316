/**
 * The below file contains the constants used in the wards master.
 * @author: shivam.sharma2@velsof.com
 * @date : 29-12-2022
 */

/**Constants for getting all the wards */
export const GET_ALL_WARDS = "GET_ALL_WARDS";
/**Constants for getting all the wards */

/**Constants for setting all the wards */
export const SET_ALL_WARDS = "SET_ALL_WARDS";
/**Constants for setting all the wards */

