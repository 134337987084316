/**
 * The below file contains the block state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-10-2022
 */

import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_BLOCK,
  EDIT_BLOCK,
  GET_EXPORT_BLOCK_DATA,
  SET_BLOCK,
  SET_BLOCK_LISTING,
  SET_BLOCK_LISTING_PARAM,
  SET_EXPORT_BLOCK_DATA,
  UPDATE_BLOCK_STATUS,
} from "redux/constants/Block";

//Initial state
const initState = {
  //Keys for listing
  blockList: {},
  isBlockDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  blockListingParam: {},
  blockExportedData: {},
  //Keys for listing

  //Keys for add page
  addBlockForm: {},
  //Keys for add page

  //Keys for edit page
  blockFormData: {},
  //Keys for edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const block = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_BLOCK_LISTING:
      return {
        ...state,
        blockList: action.payload,
        isBlockDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };
    case SET_BLOCK_LISTING_PARAM:
      return {
        ...state,
        blockListingParam: action.payload,
      };
    case UPDATE_BLOCK_STATUS:
      let updateBlockListData = {
        ...state.blockList,
        data: state.blockList.data?.map((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        }),
      };
      return {
        ...state,
        blockList: updateBlockListData,
      };

    case GET_EXPORT_BLOCK_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_BLOCK_DATA:
      return {
        ...state,
        blockExportedData: action.payload,
      };
    //Cases for listing

    //Cases for add page
    case ADD_BLOCK:
      return {
        ...state,
        addBlockForm: action.payload,
      };
    //Cases for add page

    //Cases for edit page
    case EDIT_BLOCK:
      return {
        ...state,
        addBlockForm: action.payload,
      };
    case SET_BLOCK:
      return {
        ...state,
        blockFormData: action.payload,
      };
    //Cases for block edit page

    default:
      return state;
  }
};

export default block;
