/**
 * The below file contains the actions used in the Freeze Unfreeze Report.
 * @author: vishal.mishra@velsof.com
 * @date :11-10-2023
 */

import {
  GET_ALL_CREATE_FREEZE_UNFREEZE_REPORT,
  GET_ALL_FREEZE_UNFREEZE_REPORT,
  GET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT,
  SET_ALL_CREATE_FREEZE_UNFREEZE_REPORT,
  SET_ALL_FREEZE_UNFREEZE_REPORT,
  SET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT,
} from "redux/constants/FloodFreezeReport";

// Import the constants you defined

// Action to fetch the Freeze Unfreeze report
export const getAllFreezeUnfreezeReport = (data) => {
  return {
    type: GET_ALL_FREEZE_UNFREEZE_REPORT,
    payload: data,
  };
};

// Action to fetch a new Freeze Unfreeze report
export const setAllFreezeUnfreezeReport = (data) => {
  return {
    type: SET_ALL_FREEZE_UNFREEZE_REPORT,
    payload: data,
  };
};

// Action to fetch the Freeze Unfreeze report
export const getAllCreateFreezeUnfreezeReport = (data) => {
  return {
    type: GET_ALL_CREATE_FREEZE_UNFREEZE_REPORT,
    payload: data,
  };
};

// Action to fetch a new Freeze Unfreeze report
export const setAllCreateFreezeUnfreezeReport = (data) => {
  return {
    type: SET_ALL_CREATE_FREEZE_UNFREEZE_REPORT,
    payload: data,
  };
};

// Action to fetch the Freeze Unfreeze report
export const getAllUpdateFreezeUnfreezeReport = (data, id) => {
  return {
    type: GET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT,
    payload: data,
    id: id,
  };
};

// Action to fetch a new Freeze Unfreeze report
export const setAllUpdateFreezeUnfreezeReport = (data) => {
  return {
    type: SET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT,
    payload: data,
  };
};
