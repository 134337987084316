/**
 * The below file contains the action methods for unit master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import {
  ADD_UNIT,
  CHANGE_UNIT_STATUS,
  UNIT_IMPORT,
  EDIT_UNIT,
  GET_ALL_UNIT,
  GET_UNIT,
  GET_EXPORT_UNIT_DATA,
  SET_UNIT,
  SET_UNIT_LISTING,
  SET_UNIT_LISTING_PARAM,
  SET_EXPORT_UNIT_DATA,
  UPDATE_UNIT_STATUS,
} from "../constants/Unit";

//#region Action methods for unit listing page

/**
 * Method for getting the unit listing
 * @param {any} params Unit Params
 * @returns Json Object
 */
export const getAllUnit = (params) => {
  return {
    type: GET_ALL_UNIT,
    payload: params,
  };
};

/**
 * Method for setting the unit in the state
 * @param {any} unit Unit Data
 * @returns Json Object
 */
export const setUnitListing = (unit) => {
  return {
    type: SET_UNIT_LISTING,
    payload: unit,
  };
};

/**
 * Method for setting the unit parameters
 * @param {any} data Unit Params
 * @returns Json Object
 */
export const setUnitTableParam = (data) => {
  return {
    type: SET_UNIT_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for updating the unit status
 * @param {any} data Unit Data
 * @returns Json Object
 */
export const updateUnitStatus = (data) => {
  return {
    type: UPDATE_UNIT_STATUS,
    payload: data,
  };
};

/**
 * Method for changing the unit status
 * @param {any} data Unit Data
 * @returns Json Object
 */
export const changeUnitStatus = (data) => {
  return {
    type: CHANGE_UNIT_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the export file of unit
 * @returns Json Object
 */
export const getExportUnit = () => {
  return {
    type: GET_EXPORT_UNIT_DATA,
  };
};

/**
 * Method for setting the export details in the state
 * @param {any} data Export Data
 * @returns Json Object
 */
export const setExportUnit = (data) => {
  return {
    type: SET_EXPORT_UNIT_DATA,
    payload: data,
  };
};
//#endregion Action methods for unit listing page

//#region Action methods for unit add page
/**
 * Method for adding the unit
 * @param {any} data Unit Data
 * @returns Json Object
 */
export const addUnit = (data) => {
  return {
    type: ADD_UNIT,
    payload: data,
  };
};
//#endregion Action methods for unit add page

//#region Action methods for unit edit page

/**
 * Method for editing the unit
 * @param {any} data Unit Data
 * @returns Json Object
 */
export const editUnit = (data) => {
  return {
    type: EDIT_UNIT,
    payload: data,
  };
};

/**
 * Method for getting the unit details
 * @param {any} id Unit Id
 * @returns Json Object
 */
export const getUnit = (id) => {
  return {
    type: GET_UNIT,
    payload: id,
  };
};

/**
 * Method for setting the unit details in the state
 * @param {any} unit Unit Data
 * @returns Json Object
 */
export const setUnit = (unit) => {
  return {
    type: SET_UNIT,
    payload: unit,
  };
};
//#endregion Action methods for unit edit page

//#region Action methods for unit edit page
/**
 * Method for unit import
 * @param {any} data Unit Data
 * @returns Json Object
 */
export const unitImport = (data) => {
  return {
    type: UNIT_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for unit import page
