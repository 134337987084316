import React from 'react'
import AppBreadcrumb from 'components/layout-components/AppBreadcrumb';
import IntlMessage from '../util-components/IntlMessage';
import { Col, Row } from 'antd';

export const PageHeader = ({ title, display }) => {
	return (
		display ? (
			<div className="app-page-header">

				{/* <Row className='w-85' justify='end'>
					
				</Row> */}
				<Row className='w-100'>
					<Col span={6}>
						<h3 className="mb-0 mr-3 font-weight-semibold">
							<IntlMessage id={title ? title : 'home'} />
						</h3>
					</Col>
					<Col span={18} style={{textAlign:"end"}}>
						<AppBreadcrumb />
					</Col>
				</Row>
			</div>
		)
			: null
	)
}

export default PageHeader