/**
 * The below file contains the Sagas method for Fire Cause.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import { Fire_Cause_Imported } from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { setAllFloodDeathCause } from "redux/actions";
import { showMessage } from "redux/actions/Common";
import { GET_ALL_FLOOD_DEATH_CAUSE } from "redux/constants/FloodDeathCause";
import FloodDeathCauseServices from "services/FloodDeathCauseServices";

//#region Method for listing

/**
 * Method for getting the fire causes
 */
export function* getAllFloodDeathCause() {
  yield takeEvery(GET_ALL_FLOOD_DEATH_CAUSE, function* () {
    try {
      const floodCauses = yield call(FloodDeathCauseServices.getAllFloodDeathCause);
      if (floodCauses.error != null && floodCauses.error.message) {
        yield put(showMessage({ message: floodCauses.error.message, type: Error }));
      } else {
        yield put(setAllFloodDeathCause(floodCauses));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion Method for listing

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([fork(getAllFloodDeathCause)]);
}
