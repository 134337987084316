"use strict";

import { AES_Encryption } from "constants/ApplicationConstant";

const CryptoJS = require("crypto-js");

export function aesEncrypt(content) {
  const key = CryptoJS.enc.Utf8.parse(AES_Encryption.Key);
  const iv = CryptoJS.enc.Utf8.parse(AES_Encryption.IV);
  const encrypted = CryptoJS.AES.encrypt(content, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

export function aesDecrypt(word) {
  const key = CryptoJS.enc.Utf8.parse(AES_Encryption.Key);
  const iv = CryptoJS.enc.Utf8.parse(AES_Encryption.IV);

  //let base64 = CryptoJS.enc.Base64.parse(word);
  //let src = CryptoJS.enc.Base64.stringify(base64);
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypt.toString(CryptoJS.enc.Utf8);
}
