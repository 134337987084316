/**
 * The below file contains the action methods for infDamage report.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-11-2022
 */

import {
  GET_ALL_INFDAMAGE,
  GET_EXPORT_INFDAMAGE_DATA,
  SET_EXPORT_INFDAMAGE_DATA,
  SET_INFDAMAGE_LISTING,
  SET_INFDAMAGE_LISTING_PARAM,
  INFDAMAGE_IMPORT,
  INFDAMAGE_ADD_BASIC_SECTION,
  INFDAMAGE_EDIT_BASIC_SECTION,
  INFDAMAGE_ADD_INFDAMAGE_SECTION,
  INFDAMAGE_ADD_INFDAMAGEOTHER_SECTION,
  INFDAMAGE_GET_ALL_SECTION,
  INFDAMAGE_SET_ALL_SECTION,
  INFDAMAGE_RESET_BUTTON_LOADING,
  INFDAMAGE_SET_PARENT_ID,
  INFDAMAGE_ADD_REMARK_SECTION,
  INFDAMAGE_SET_NIL_REPORTING,
  INFDAMAGE_REPORT_SUBMIT,
  INFDAMAGE_REPORT_DISCARD,
  INFDAMAGE_SET_REDIRECT_TO_LISTING,
  INFDAMAGE_SET_DATA_SAVED,
} from "redux/constants/InfDamage";

//#region Action methods for infDamage listing page

/**
 * Method for getting the infDamage listing
 * @param {any} params InfDamage Data
 * @returns Json Object
 */
export const getAllInfDamage = (params) => {
  return {
    type: GET_ALL_INFDAMAGE,
    payload: params,
  };
};

/**
 * Method for setting the infDamage listing details in state
 * @param {any} infDamage InfDamage Data
 * @returns Json Object
 */
export const setInfDamageListing = (infDamage) => {
  return {
    type: SET_INFDAMAGE_LISTING,
    payload: infDamage,
  };
};

/**
 * Method for setting the infDamage parameters
 * @param {any} data InfDamage Data
 * @returns Json Object
 */
export const setInfDamageTableParam = (data) => {
  return {
    type: SET_INFDAMAGE_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for getting the export file of infDamage
 * @returns Json Object
 */
export const getExportInfDamage = () => {
  return {
    type: GET_EXPORT_INFDAMAGE_DATA,
  };
};

/**
 * Method for setting the infDamage export data in the state
 * @param {any} data InfDamage Data
 * @returns Json Object
 */
export const setExportInfDamage = (data) => {
  return {
    type: SET_EXPORT_INFDAMAGE_DATA,
    payload: data,
  };
};
//#endregion Action methods for infDamage listing page

//#region Action methods for infDamage import page
/**
 * Method for infDamage import
 * @param {any} data InfDamage Data
 * @returns Json Object
 */
export const infDamageImport = (data) => {
  return {
    type: INFDAMAGE_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for infDamage import page

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 11-11-2022

/**
 * Method for getting the all section details
 * @param {number} data Basic Section Data Form Id and param
 * @returns Json Object
 */
export const infDamageGetAllSection = (data) => {
  return {
    type: INFDAMAGE_GET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const infDamageSetAllSection = (data) => {
  return {
    type: INFDAMAGE_SET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the parent id
 * @param {number} id Parent Id
 * @returns Json Object
 */
export const infDamageSetParentId = (id) => {
  return {
    type: INFDAMAGE_SET_PARENT_ID,
    payload: id,
  };
};

/**
 * Method for reseting the button loading status
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const infDamageResetButtonLoading = (data) => {
  return {
    type: INFDAMAGE_RESET_BUTTON_LOADING,
    payload: data,
  };
};

/**
 * Method for submitting the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const infDamageReportSubmit = (data) => {
  return {
    type: INFDAMAGE_REPORT_SUBMIT,
    payload: data,
  };
};

/**
 * Method for discarding the report
 * @param {object} data Report Data
 * @returns Json Object
 */
export const infDamageReportDiscard = (data) => {
  return {
    type: INFDAMAGE_REPORT_DISCARD,
    payload: data,
  };
};

/**
 * Method for setting the redirect to listing variable
 * @param {object} data  Data
 * @returns Json Object
 */
export const infDamageSetRedirectToListing = (data) => {
  return {
    type: INFDAMAGE_SET_REDIRECT_TO_LISTING,
    payload: data,
  };
};

/**
 * Method for setting the value of infDamageDataSaved object
 * @param {object} data Data
 * @returns Json Object
 */
export const infDamageSetDataSaved = (data) => {
  return {
    type: INFDAMAGE_SET_DATA_SAVED,
    payload: data,
  };
};
//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-11-2022

/**
 * Method for adding the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const infDamageAddBasicSection = (data) => {
  return {
    type: INFDAMAGE_ADD_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for editing the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const infDamageEditBasicSection = (data) => {
  return {
    type: INFDAMAGE_EDIT_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the nil reporting value
 * @param {boolean} data Bool value
 * @returns Json Object
 */
export const infDamageSetNilReporting = (data) => {
  return {
    type: INFDAMAGE_SET_NIL_REPORTING,
    payload: data,
  };
};
//#endregion Basic Section

//#region Infrastructure Damage Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 11-11-2022

/**
 * Method for adding the infDamage section details
 * @param {object} data InfDamage Section Data
 * @returns Json Object
 */
export const infDamageAddInfDamageSection = (data) => {
  return {
    type: INFDAMAGE_ADD_INFDAMAGE_SECTION,
    payload: data,
  };
};
//#endregion Infrastructure Damage Section

//#region Infrastructure Damage Other Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 11-11-2022

/**
 * Method for adding the infDamageOther section details
 * @param {object} data InfDamageOther Section Data
 * @returns Json Object
 */
export const infDamageAddInfDamageOtherSection = (data) => {
  return {
    type: INFDAMAGE_ADD_INFDAMAGEOTHER_SECTION,
    payload: data,
  };
};
//#endregion InfDamageOther Section

//#region Remark Section
/**
 * Method for adding the remarks
 * @param {object} data Remark Section Data
 * @returns Json Object
 */
export const infDamageAddRemarkSection = (data) => {
  return {
    type: INFDAMAGE_ADD_REMARK_SECTION,
    payload: data,
  };
};
//#endregion Remark Section
