/**
 * The below file contains the disaster state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-11-2022
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  SET_DISASTER_LISTING_PARAM,
  SET_DISASTER_LISTING,
} from "../constants/Disaster";

//Initial state
const initState = {
  //Keys for disaster listing
  disasterList: {},
  isDisasterDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  disasterListingParam: {},
  //Keys for disaster listing
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const disaster = (state = initState, action) => {
  switch (action.type) {
    //Cases for disaster listing
    case SET_DISASTER_LISTING:
      return {
        ...state,
        disasterList: action.payload,
        isDisasterDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_DISASTER_LISTING_PARAM:
      return {
        ...state,
        disasterListingParam: action.payload,
      };

    default:
      return state;
  }
};

export default disaster;
