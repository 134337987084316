/**
 * The below file contains the constants used in the Flood Inmates Registration
 * @author: vishal.mishra@velsof.com
 * @date :07-03-2024
 */

export const GET_ALL_FLOOD_INMATES_REGISTRATION =
  "GET_ALL_FLOOD_INMATES_REGISTRATION";
export const SET_ALL_FLOOD_INMATES_REGISTRATION =
  "SET_ALL_FLOOD_INMATES_REGISTRATION";

export const REPORT_DATE_PARAM = "reportDate";
export const STATUS_PARAM = "status";
export const GET_ALL_CREATE_FLOOD_INMATES_REGISTRATION =
  "GET_ALL_CREATE_FLOOD_INMATES_REGISTRATION";
export const SET_ALL_CREATE_FLOOD_INMATES_REGISTRATION =
  "SET_ALL_CREATE_FLOOD_INMATES_REGISTRATION";

export const GET_ALL_UPDATE_FLOOD_INMATES_REGISTRATION =
  "GET_ALL_UPDATE_FLOOD_INMATES_REGISTRATION";
export const SET_ALL_UPDATE_FLOOD_INMATES_REGISTRATION =
  "SET_ALL_UPDATE_FLOOD_INMATES_REGISTRATION";

export const SET_FLOOD_INMATES_REGISTRATION = "SET_FLOOD_INMATES_REGISTRATION";

/**Constants for relief camp Import page */
export const CAMP_INMATES_REGISTRATION_IMPORT =
  "CAMP_INMATES_REGISTRATION_IMPORT";
/**Constants for relief camp Import page */

/**Constants for camp inmates registration export */
export const GET_EXPORT_CAMP_INMATES_DATA = "GET_EXPORT_CAMP_INMATES_DATA";
export const SET_EXPORT_CAMP_INMATES_DATA = "SET_EXPORT_CAMP_INMATES_DATA";
/**Constants for camp inmates registration import template */
export const GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA = "GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA";
export const SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA = "SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA";