/**
 * The below file contains the Sagas method for Resource Group.
 * @author: dhruv.kumar@velsof.com
 * @date : 28-07-2023
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_RESOURCEGROUP,
  EDIT_RESOURCEGROUP,
  GET_ALL_RESOURCEGROUP,
  GET_RESOURCEGROUP,
} from "../constants/ResourceGroup";

import ResourceGroupService from "services/ResourceGroupService";
import {
  setResourceGroup,
  setResourceGroupListing,
} from "redux/actions/ResourceGroup";
import { showMessage } from "redux/actions/Common";
import {
  ResourceGroup_Added,
  ResourceGroup_Updated,
} from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";

//#region Method for resourceGroup listing

/**
 * Method for getting the resourceGroup details
 */
export function* getAllResourceGroup() {
  yield takeEvery(GET_ALL_RESOURCEGROUP, function* ({ payload }) {
    try {
      const resourceGroup = yield call(
        ResourceGroupService.getAllResourceGroup,
        payload
      );
      if (resourceGroup.error != null && resourceGroup.error.message) {
        yield put(
          showMessage({ message: resourceGroup.error.message, type: Error })
        );
      } else {
        yield put(setResourceGroupListing(resourceGroup));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for resourceGroup listing

//#region Method for resourceGroup add page

/**
 * Method for adding the resourceGroup
 */
export function* addResourceGroup() {
  yield takeEvery(ADD_RESOURCEGROUP, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const resourceGroup = yield call(
        ResourceGroupService.addResourceGroup,
        final
      );
      if (resourceGroup.error != null && resourceGroup.error.message) {
        yield put(
          showMessage({ message: resourceGroup.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: ResourceGroup_Added, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for resourceGroup add page

//#region Method for resourceGroup edit page

/**
 * Method for editing the resourceGroup
 */
export function* editResourceGroup() {
  yield takeEvery(EDIT_RESOURCEGROUP, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          groupName: payload.groupName,
        },
      };
      const resourceGroup = yield call(
        ResourceGroupService.editResourceGroup,
        id,
        final
      );
      if (resourceGroup.error != null && resourceGroup.error.message) {
        yield put(
          showMessage({ message: resourceGroup.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: ResourceGroup_Updated, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the resourceGroup detail
 */
export function* getResourceGroup() {
  yield takeEvery(GET_RESOURCEGROUP, function* ({ payload }) {
    try {
      const resourceGroup = yield call(
        ResourceGroupService.getResourceGroup,
        payload
      );
      if (resourceGroup.error != null && resourceGroup.error.message) {
        yield put(
          showMessage({ message: resourceGroup.error.message, type: Error })
        );
      } else {
        yield put(setResourceGroup(resourceGroup));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for resourceGroup edit page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllResourceGroup),
    fork(addResourceGroup),
    fork(editResourceGroup),
    fork(getResourceGroup),
  ]);
}
