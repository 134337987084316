/**
 * The below file contains the action methods for wards master.
 * @author: shivam.sharma2@velsof.com
 * @date : 29-12-2022
 */

import {
  GET_ALL_WARDS,
  SET_ALL_WARDS,
} from "redux/constants/Wards";

//#region Action methods for wards name

/**
 * Method for getting the wards name
 * @returns Json Object
 */
export const getAllWards = (data) => {
  return {
    type: GET_ALL_WARDS,
    payload: data,
  };
};

/**
 * Method for setting the wards name
 * @returns Json Object
 */
export const setAllWards = (data) => {
  return {
    type: SET_ALL_WARDS,
    payload: data,
  };
};

//#endregion Action methods for wards name
