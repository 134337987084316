/**
 * The below file contains the user state objects
 * @author: shivam.sharma2@velsof.com
 * @date : 05-01-2023
 */

import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_USER,
  EDIT_USER,
  GET_EXPORT_USER_DATA,
  SET_EXPORT_USER_DATA,
  SET_USER,
  SET_USER_LISTING,
  SET_USER_LISTING_PARAM,
  UPDATE_USER_STATUS,
} from "redux/constants/User";

//Initial state
const initState = {
  //Keys for listing
  userList: {},
  isUserDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  userListingParam: {},
  userExportedData: {},
  //Keys for listing

  //Keys for add page
  addUserForm: {},
  //Keys for add page

  //Keys for edit page
  userFormData: {},
  //Keys for edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const user = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_USER_LISTING:
      return {
        ...state,
        userList: action.payload,
        isUserDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_USER_LISTING_PARAM:
      return {
        ...state,
        userListingParam: action.payload,
      };

    case UPDATE_USER_STATUS:
      let updateUserListData = [...state.userList];
      updateUserListData = updateUserListData.map((item) => {
        if (item.id === action.payload.id) {
          item.confirmed = action.payload.status;
        }
        return item;
      });

      return {
        ...state,
        userList: updateUserListData,
      };

    case GET_EXPORT_USER_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_USER_DATA:
      return {
        ...state,
        userExportedData: action.payload,
      };
    //Cases for listing

    //Cases for add page
    case ADD_USER:
      return {
        ...state,
        addUserForm: action.payload,
      };
    //Cases for add page

    //Cases for edit page
    case EDIT_USER:
      return {
        ...state,
        addUserForm: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        userFormData: action.payload,
      };
    //Cases for District edit page

    default:
      return state;
  }
};

export default user;
