import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Get_Login_User,
  Login_Url,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const JwtAuthService = {};

/**
 * Method for getting the login user details
 * @param {any} data API Data
 * @returns Fetch Method
 */
JwtAuthService.login = function (data) {
  return fetch({
    url: Login_Url,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
};

/**
 * Method for getting the login user details and permission
 * @param {any} data API Data
 * @returns Fetch Method
 */
JwtAuthService.getLoginUserDetails = function (data) {
  return fetch({
    url: Get_Login_User,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
  });
};

export default JwtAuthService;
