/**
 * The below file is the infDamage service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-11-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Blob,
  Get_All_InfDamage,
  Get_Export_InfDamage,
  Multipart_Form_Data,
  InfDamage_Import,
  InfDamage_Add_Basic_Section,
  InfDamage_Edit_Basic_Section,
  InfDamage_Get_All_Section,
  InfDamage_Add_InfDamage_Section,
  InfDamage_Add_InfDamageOther_Section,
  InfDamage_Add_Remark_Section,
  InfDamage_Submit_Report,
  InfDamage_Discard_Report,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const InfDamageService = {};

//#region Method for listing page.

/**
 * Method for getting the infDamage listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
InfDamageService.getAllInfDamage = function (param) {
  return fetch({
    url: Get_All_InfDamage,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the infDamage export data
 * @returns Fetch Method
 */
InfDamageService.getExportInfDamageData = function () {
  return fetch({
    url: Get_Export_InfDamage,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};
//#endregion Method for listing page.

//#region Method for infDamage import page.

/**
 * Method for importing the infDamage
 * @param {any} data API data
 * @returns Fetch Method
 */
InfDamageService.infDamageImport = function (data) {
  return fetch({
    url: InfDamage_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for infDamage import page.

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 11-11-2022

/**Method for getting all section data
 * @param {number} id Form Id
 * @param {object} param Param object
 * @returns Fetch Method
 */
InfDamageService.infDamageGetAllSection = function (id, param) {
  return fetch({
    url: format(InfDamage_Get_All_Section, id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**Method for submitting the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
InfDamageService.infDamageReportSubmit = function (id) {
  return fetch({
    url: format(InfDamage_Submit_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

/**Method for discarding the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
InfDamageService.infDamageReportDiscard = function (id) {
  return fetch({
    url: format(InfDamage_Discard_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-11-2022

/**
 * Method for adding the basic section
 * @param {object} data API data
 * @returns Fetch Method
 */
InfDamageService.infDamageAddBasicSection = function (data) {
  return fetch({
    url: InfDamage_Add_Basic_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for editing the basic section
 * @param {number} id Form Id
 * @param {object} data API data
 * @returns Fetch Method
 */
InfDamageService.infDamageEditBasicSection = function (id, data) {
  return fetch({
    url: format(InfDamage_Edit_Basic_Section, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Basic Section

//#region InfDamage Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 11-11-2022

/**
 * Method for adding the infDamage section
 * @param {object} data API data
 * @returns Fetch Method
 */
InfDamageService.infDamageAddInfDamageSection = function (data) {
  return fetch({
    url: InfDamage_Add_InfDamage_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 11-11-2022

/**
 * Method for adding the infDamageOther section
 * @param {object} data API data
 * @returns Fetch Method
 */
InfDamageService.infDamageAddInfDamageOtherSection = function (data) {
  return fetch({
    url: InfDamage_Add_InfDamageOther_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamageOther Section

//#region Remark Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 15-11-2022

/**
 * Method for adding the remark section
 * @param {object} data API data
 * @returns Fetch Method
 */
InfDamageService.infDamageAddRemarkSection = function (data) {
  return fetch({
    url: InfDamage_Add_Remark_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Remark Section

export default InfDamageService;
