/**
 * The below file contains the Sagas method for Block
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-10-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Block_Added,
  Block_Imported,
  Block_Updated,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  setBlock,
  setBlockListing,
  setExportBlock,
  updateBlockStatus,
} from "redux/actions/Block";
import { showMessage } from "redux/actions/Common";
import {
  ADD_BLOCK,
  BLOCK_IMPORT,
  CHANGE_BLOCK_STATUS,
  EDIT_BLOCK,
  GET_ALL_BLOCK,
  GET_BLOCK,
  GET_EXPORT_BLOCK_DATA,
} from "redux/constants/Block";
import BlockService from "services/BlockService";

//#region Method for listing
/**
 * Method for getting the block listing
 */
export function* getAllBlock() {
  yield takeEvery(GET_ALL_BLOCK, function* ({ payload }) {
    try {
      const block = yield call(BlockService.getAllBlock, payload);
      if (block.error != null && block.error.message) {
        yield put(showMessage({ message: block.error.message, type: Error }));
      } else {
        yield put(setBlockListing(block));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the block export details
 */
export function* getBlockExport() {
  yield takeEvery(GET_EXPORT_BLOCK_DATA, function* () {
    try {
      const block = yield call(BlockService.getExportBlockData);
      if (block.error != null && block.error.message) {
        yield put(showMessage({ message: block.error.message, type: Error }));
      } else {
        let exportData = {
          url: window.URL.createObjectURL(block),
        };
        yield put(setExportBlock(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the block status details
 */
export function* changeBlockStatus() {
  yield takeEvery(CHANGE_BLOCK_STATUS, function* ({ payload }) {
    try {
      const block = yield call(BlockService.changeBlockStatus, payload);
      if (block.error != null && block.error.message) {
        yield put(showMessage({ message: block.error.message, type: Error }));
      } else {
        let json = {
          id: block.data.id,
          status: block.data.status,
        };
        yield put(updateBlockStatus(json));
        yield put(showMessage({ message: Block_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for add page

/**
 * Method for adding the block
 */
export function* addBlock() {
  yield takeEvery(ADD_BLOCK, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const block = yield call(BlockService.addBlock, final);
      if (block.error != null && block.error.message) {
        yield put(showMessage({ message: block.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Block_Added, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for add page

//#region Method for edit page

/**
 * Method for editing the block
 */
export function* editBlock() {
  yield takeEvery(EDIT_BLOCK, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          blockName: payload.blockName,
          blockCode: payload.blockCode,
          district: payload.district,
        },
      };

      const block = yield call(BlockService.editBlock, id, final);
      if (block.error != null && block.error.message) {
        yield put(showMessage({ message: block.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Block_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the block detail
 */
export function* getBlock() {
  yield takeEvery(GET_BLOCK, function* ({ payload }) {
    try {
      let param = {
        "populate[0]": "district",
      };
      const block = yield call(BlockService.getBlock, payload, param);
      if (block.error != null && block.error.message) {
        yield put(showMessage({ message: block.error.message, type: Error }));
      } else {
        yield put(setBlock(block));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for add page

//#region Method for block import page

/**
 * Method for importing the block
 */
export function* blockImport() {
  yield takeEvery(BLOCK_IMPORT, function* ({ payload }) {
    try {
      const block = yield call(BlockService.blockImport, payload);
      if (block.error != null && block.error.message) {
        yield put(showMessage({ message: block.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Block_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for block import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllBlock),
    fork(addBlock),
    fork(editBlock),
    fork(getBlock),
    fork(getBlockExport),
    fork(changeBlockStatus),
    fork(blockImport),
  ]);
}
