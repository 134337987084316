/**
 * The below file contains the constants used in the village master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-10-2022
 */

/**Constants for village Listing page */
export const GET_ALL_VILLAGE = "GET_ALL_VILLAGE";
export const SET_VILLAGE_LISTING = "SET_VILLAGE_LISTING";
export const SET_VILLAGE_LISTING_PARAM = "SET_VILLAGE_LISTING_PARAM";
export const UPDATE_VILLAGE_STATUS = "UPDATE_VILLAGE_STATUS";
export const CHANGE_VILLAGE_STATUS = "CHANGE_VILLAGE_STATUS";
export const GET_EXPORT_VILLAGE_DATA = "GET_EXPORT_VILLAGE_DATA";
export const SET_EXPORT_VILLAGE_DATA = "SET_EXPORT_VILLAGE_DATA";
/**Constants for village Listing page */

/**Constants for village Add page */
export const ADD_VILLAGE = "ADD_VILLAGE";
/**Constants for village Add page */

/**Constants for VILLAGE Edit page */
export const EDIT_VILLAGE = "EDIT_VILLAGE";
export const GET_VILLAGE = "GET_VILLAGE";
export const SET_VILLAGE = "SET_VILLAGE";
/**Constants for VILLAGE Edit page */

/**Constants for village Import page */
export const VILLAGE_IMPORT = "VILLAGE_IMPORT";
/**Constants for village Import page */
