import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Layout, Row } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Logo from "./Logo";
import NavNotification from "./NavNotification";
import NavProfile from "./NavProfile";
import NavSearch from "./NavSearch";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import { useLocation } from "react-router-dom";
import CommonMethods from "utils/CommonMethods";
import { STAGING } from "configs/AppConfig";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    direction,
  } = props;
  const location = useLocation();

  const [searchActive, setSearchActive] = useState(false);
  const [cardDetails, setCardDetails] = useState({});

  // const onSearchActive = () => {
  //   setSearchActive(true)
  // }

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  useEffect(() => {
    let details = CommonMethods.getCardDetails(location.pathname);
    setCardDetails(details);
  }, [location.pathname]);
  return (
    <>
      <Header
        className={`app-header ${navMode}`}
        style={{ backgroundColor: headerNavColor }}
      >
        <div
          className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}
        >
          <Logo logoType={navMode} />
          <div
            className="nav"
            style={{ width: `calc(100% - ${getNavWidth()})` }}
          >
            <div className="nav-left">
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                {isNavTop && !isMobile ? null : (
                  <li
                    className="ant-menu-item ant-menu-item-only-child"
                    onClick={() => {
                      onToggle();
                    }}
                  >
                    {navCollapsed || isMobile ? (
                      <MenuUnfoldOutlined className="nav-icon" />
                    ) : (
                      <MenuFoldOutlined className="nav-icon" />
                    )}
                  </li>
                )}
                {/* {
                isMobile ?
                <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onSearchActive()}}>
                  <SearchOutlined />
                </li>
                :
                <li className="ant-menu-item ant-menu-item-only-child" style={{cursor: 'auto'}}>
                  <SearchInput mode={mode} isMobile={isMobile} />
                </li>
              } */}
              </ul>
            </div>
            <img src="/img/headers/Govt_of_Assam.png" />
            <div className="nav-center"></div>
            <Row className="w-100">
              <Col span={24}>
                <div className="header-title-area">
                  <h1 className="text-black text-center mb-0 header-title">
                    DRIMS
                  </h1>
                  <h3 className="text-black text-center mb-0 mt-n2 header-sub-title">
                    Disaster Reporting And Information Management System
                  </h3>
                </div>
              </Col>
            </Row>
            <div className="nav-right">
              <NavNotification />
              {/* <NavLanguage /> */}
              <NavProfile />
              {/* <NavPanel direction={direction} /> */}
            </div>
            {/* <NavSearch active={searchActive} close={onSearchClose} /> */}
          </div>
        </div>
      </Header>
      {Object.keys(cardDetails).length > 0 && (
        <div
          style={{
            top: STAGING ? 94 : 70,
            zIndex: 999,
            background: cardDetails.backgroundColor,
            color: cardDetails.color,
            fontSize: "1rem",
            fontWeight: 500,
            position: "sticky",
            textAlign: "center",
            width: `calc(100% - ${getNavWidth()})`,
            alignSelf: "flex-end",
          }}
        >
          {cardDetails.title}
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
