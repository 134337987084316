/**
 * The below file contains the action methods for livestock types.
 * @author: shivam.sharma2@velsof.com
 * @date : 12-01-2023
 */

import {
  GET_ALL_LIVESTOCK_TYPES,
  SET_ALL_LIVESTOCK_TYPES,
} from "redux/constants/LivestockTypes";

//#region Action methods for fetching livestock types

/**
 * Method for getting the livestock types
 * @param {any} params livestock types Data
 * @returns Json Object
 */
export const getAllLivestocktypes = () => {
  return {
    type: GET_ALL_LIVESTOCK_TYPES,
  };
};

/**
 * Method for setting the livestock types
 * @param {any} params livestock types Data
 * @returns Json Object
 */
export const setAllLivestocktypes = (data) => {
  return {
    type: SET_ALL_LIVESTOCK_TYPES,
    payload: data,
  };
};

//#endregion