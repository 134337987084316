/**
 * The below file contains the action methods for flood report.
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */

import {
  GET_ALL_FLOOD,
  GET_EXPORT_FLOOD_DATA,
  SET_EXPORT_FLOOD_DATA,
  SET_FLOOD_LISTING,
  SET_FLOOD_LISTING_PARAM,
  FLOOD_IMPORT,
  FLOOD_ADD_BASIC_SECTION,
  FLOOD_EDIT_BASIC_SECTION,
  FLOOD_ADD_INFDAMAGE_SECTION,
  FLOOD_ADD_INFDAMAGEOTHER_SECTION,
  FLOOD_GET_ALL_SECTION,
  FLOOD_SET_ALL_SECTION,
  FLOOD_RESET_BUTTON_LOADING,
  FLOOD_SET_PARENT_ID,
  FLOOD_ADD_REMARK_SECTION,
  FLOOD_SET_NIL_REPORTING,
  FLOOD_REPORT_SUBMIT,
  FLOOD_REPORT_DISCARD,
  FLOOD_SET_REDIRECT_TO_LISTING,
  FLOOD_SET_DATA_SAVED,
  FLOOD_ADD_POPAFFECTED_SECTION,
  FLOOD_ADD_ERODED_SECTION,
  FLOOD_ADD_RELIEFCAMP_SECTION,
  FLOOD_ADD_CAMPINMATES_SECTION,
  FLOOD_ADD_HLL_SECTION,
  Flood_Cause_Of_Death_HLL_Section,
  FLOOD_ADD_LIVESTOCK_SECTION,
  FLOOD_ADD_HOUSEDAMAGED_SECTION,
  FLOOD_ADD_RESCUEOPERATION_SECTION,
  FLOOD_ADD_RELIEFDISTRIBUTION_SECTION,
  EXPORT_FO_LEVEL_FLOOD_REPORT,
  SET_EXPORT_FO_LEVEL_FLOOD_REPORT,
  FLOOD_REVENUE_REPORT_APPROVE,
  FLOOD_REVENUE_REPORT_DISAPPROVE,
  FLOOD_REVENUE_REPORT_VERIFY,
  GET_FLOOD_DISTRICT_REVIEW_LIST,
  SET_FLOOD_DISTRICT_REVIEW_LIST,
  GET_FLOOD_STATE_REVIEW_LIST,
  SET_FLOOD_STATE_REVIEW_LIST,
  FLOOD_DISTRICT_REPORT_APPROVE,
  FLOOD_DISTRICT_REPORT_DISAPPROVE,
  FLOOD_DISTRICT_REPORT_VERIFY,
  FLOOD_STATE_REPORT_APPROVE,
  FLOOD_STATE_REPORT_DISAPPROVE,
  GET_STATE_CUMULATIVE_FLOOD_REPORT,
  SET_STATE_CUMULATIVE_FLOOD_REPORT,
  GET_DISTRICT_CUMULATIVE_FLOOD_REPORT,
  SET_DISTRICT_CUMULATIVE_FLOOD_REPORT,
  FLOOD_ADD_NONCAMPINMATES_SECTION,
  FLOOD_SET_RELIEF_CENTER_SECTION,
  FREEZE_UNFREEZE_FLOOD_RANGE_REPORT,
} from "redux/constants/Flood";

//#region Action methods for flood listing page

/**
 * Method for getting the flood listing
 * @param {any} params Flood Data
 * @returns Json Object
 */
export const getAllFlood = (params) => {
  return {
    type: GET_ALL_FLOOD,
    payload: params,
  };
};

/**
 * Method for setting the flood listing details in state
 * @param {any} flood Flood Data
 * @returns Json Object
 */
export const setFloodListing = (flood) => {
  return {
    type: SET_FLOOD_LISTING,
    payload: flood,
  };
};

/**
 * Method for setting the flood parameters
 * @param {any} data Flood Data
 * @returns Json Object
 */
export const setFloodTableParam = (data) => {
  return {
    type: SET_FLOOD_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for getting the export file of flood
 * @returns Json Object
 */
export const getExportFlood = () => {
  return {
    type: GET_EXPORT_FLOOD_DATA,
  };
};

/**
 * Method for setting the flood export data in the state
 * @param {any} data Flood Data
 * @returns Json Object
 */
export const setExportFlood = (data) => {
  return {
    type: SET_EXPORT_FLOOD_DATA,
    payload: data,
  };
};
//#endregion Action methods for flood listing page

//#region Action methods for flood import page
/**
 * Method for flood import
 * @param {any} data Flood Data
 * @returns Json Object
 */
export const floodImport = (data) => {
  return {
    type: FLOOD_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for flood import page

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for getting the all section details
 * @param {number} data Basic Section Data Form Id and param
 * @returns Json Object
 */
export const floodGetAllSection = (data) => {
  return {
    type: FLOOD_GET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const floodSetAllSection = (data) => {
  return {
    type: FLOOD_SET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const floodSetReliefCenterSection = (data) => {
  return {
    type: FLOOD_SET_RELIEF_CENTER_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the parent id
 * @param {number} id Parent Id
 * @returns Json Object
 */
export const floodSetParentId = (id) => {
  return {
    type: FLOOD_SET_PARENT_ID,
    payload: id,
  };
};

/**
 * Method for reseting the button loading status
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const floodResetButtonLoading = (data) => {
  return {
    type: FLOOD_RESET_BUTTON_LOADING,
    payload: data,
  };
};

/**
 * Method for submitting the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const floodReportSubmit = (data) => {
  return {
    type: FLOOD_REPORT_SUBMIT,
    payload: data,
  };
};

/**
 * Method for discarding the report
 * @param {object} data Report Data
 * @returns Json Object
 */
export const floodReportDiscard = (data) => {
  return {
    type: FLOOD_REPORT_DISCARD,
    payload: data,
  };
};

/**
 * Method for setting the redirect to listing variable
 * @param {object} data  Data
 * @returns Json Object
 */
export const floodSetRedirectToListing = (data) => {
  return {
    type: FLOOD_SET_REDIRECT_TO_LISTING,
    payload: data,
  };
};

/**
 * Method for setting the value of floodDataSaved object
 * @param {object} data Data
 * @returns Json Object
 */
export const floodSetDataSaved = (data) => {
  return {
    type: FLOOD_SET_DATA_SAVED,
    payload: data,
  };
};
//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const floodAddBasicSection = (data) => {
  return {
    type: FLOOD_ADD_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for editing the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const floodEditBasicSection = (data) => {
  return {
    type: FLOOD_EDIT_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the nil reporting value
 * @param {boolean} data Bool value
 * @returns Json Object
 */
export const floodSetNilReporting = (data) => {
  return {
    type: FLOOD_SET_NIL_REPORTING,
    payload: data,
  };
};
//#endregion Basic Section

//#region Infrastructure Damage Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the infDamage section details
 * @param {object} data InfDamage Section Data
 * @returns Json Object
 */
export const floodAddInfDamageSection = (data) => {
  return {
    type: FLOOD_ADD_INFDAMAGE_SECTION,
    payload: data,
  };
};
//#endregion Infrastructure Damage Section

//#region Infrastructure Damage Other Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the infDamageOther section details
 * @param {object} data InfDamageOther Section Data
 * @returns Json Object
 */
export const floodAddInfDamageOtherSection = (data) => {
  return {
    type: FLOOD_ADD_INFDAMAGEOTHER_SECTION,
    payload: data,
  };
};
//#endregion InfDamageOther Section

//#region Remark Section
/**
 * Method for adding the remarks
 * @param {object} data Remark Section Data
 * @returns Json Object
 */
export const floodAddRemarkSection = (data) => {
  return {
    type: FLOOD_ADD_REMARK_SECTION,
    payload: data,
  };
};
//#endregion Remark Section

//#region Population Affected Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the popAffected section details
 * @param {object} data PopAffected Section Data
 * @returns Json Object
 */
export const floodAddPopAffectedSection = (data) => {
  return {
    type: FLOOD_ADD_POPAFFECTED_SECTION,
    payload: data,
  };
};
//#endregion Population Affected Section

//#region Eroded Section
/**
 * Method for adding the Eroded section details
 * @param {object} data Eroded Section Data
 * @returns Json Object
 */
export const floodAddErodedSection = (data) => {
  return {
    type: FLOOD_ADD_ERODED_SECTION,
    payload: data,
  };
};
//#endregion Eroded Section

//#region Relief Camp Section
/**
 * Method for adding the Relief Camp section details
 * @param {object} data Relief Camp Section Data
 * @returns Json Object
 */
export const floodAddReliefCampSection = (data) => {
  return {
    type: FLOOD_ADD_RELIEFCAMP_SECTION,
    payload: data,
  };
};
//#endregion Relief Camp Section

//#region Camp Inmates Section
/**
 * Method for adding the Camp Inmates section details
 * @param {object} data Camp Inmates Section Data
 * @returns Json Object
 */
export const floodAddCampInmatesSection = (data) => {
  return {
    type: FLOOD_ADD_CAMPINMATES_SECTION,
    payload: data,
  };
};
//#endregion Camp Inmates Section

//#region Camp Non Inmates Section
/**
 * Method for adding the Non Camp Inmates section details
 * @param {object} data Non Camp Inmates Section Data
 * @returns Json Object
 */
export const floodAddNonCampInmatesSection = (data) => {
  return {
    type: FLOOD_ADD_NONCAMPINMATES_SECTION,
    payload: data,
  };
};
//#endregion Camp Inmates Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the Human Live Lost section details
 * @param {object} data Human Live Lost Section Data
 * @returns Json Object
 */
export const floodAddHllSection = (data) => {
  return {
    type: FLOOD_ADD_HLL_SECTION,
    payload: data,
  };
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the LiveStock section details
 * @param {object} data LiveStock Section Data
 * @returns Json Object
 */
export const floodAddLiveStockSection = (data) => {
  return {
    type: FLOOD_ADD_LIVESTOCK_SECTION,
    payload: data,
  };
};
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the HouseDamaged section details
 * @param {object} data HouseDamaged Section Data
 * @returns Json Object
 */
export const floodAddHouseDamagedSection = (data) => {
  return {
    type: FLOOD_ADD_HOUSEDAMAGED_SECTION,
    payload: data,
  };
};
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the RescueOperation section details
 * @param {object} data RescueOperation Section Data
 * @returns Json Object
 */
export const floodAddRescueOperationSection = (data) => {
  return {
    type: FLOOD_ADD_RESCUEOPERATION_SECTION,
    payload: data,
  };
};
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the ReliefDistribution section details
 * @param {object} data ReliefDistribution Section Data
 * @returns Json Object
 */
export const floodAddReliefDistributionSection = (data) => {
  return {
    type: FLOOD_ADD_RELIEFDISTRIBUTION_SECTION,
    payload: data,
  };
};
//#endregion ReliefDistribution Section

//#region Methods for Reports and Approval and Disapproval
/**
 * Method for getting the FO level export report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Data
 * @returns Json Object
 */
export const getExportFoLevelFloodReport = (data) => {
  return {
    type: EXPORT_FO_LEVEL_FLOOD_REPORT,
    payload: data,
  };
};

/**
 * Method for setting the exported data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Data
 * @returns Json Object
 */
export const setExportFOLevelFloodReport = (data) => {
  return {
    type: SET_EXPORT_FO_LEVEL_FLOOD_REPORT,
    payload: data,
  };
};

/**
 * Method for approving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const floodRevenueReportApprove = (data) => {
  return {
    type: FLOOD_REVENUE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const floodRevenueReportDisapprove = (data) => {
  return {
    type: FLOOD_REVENUE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const floodRevenueReportVerify = (data) => {
  return {
    type: FLOOD_REVENUE_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for getting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const getFloodDistrictReviewList = (data) => {
  return {
    type: GET_FLOOD_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const setFloodDistrictReviewList = (data) => {
  return {
    type: SET_FLOOD_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for getting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const getFloodStateReviewList = (data) => {
  return {
    type: GET_FLOOD_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const setFloodStateReviewList = (data) => {
  return {
    type: SET_FLOOD_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const floodDistrictReportApprove = (data) => {
  return {
    type: FLOOD_DISTRICT_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const floodDistrictReportDisapprove = (data) => {
  return {
    type: FLOOD_DISTRICT_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report Data
 * @returns Json Object
 */
export const floodDistrictReportVerify = (data) => {
  return {
    type: FLOOD_DISTRICT_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const floodStateReportApprove = (data) => {
  return {
    type: FLOOD_STATE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const floodStateReportDisapprove = (data) => {
  return {
    type: FLOOD_STATE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for exporting state cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportStateCumulativeFloodReport = (data) => {
  return {
    type: GET_STATE_CUMULATIVE_FLOOD_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportStateCumulativeFloodReport = (data) => {
  return {
    type: SET_STATE_CUMULATIVE_FLOOD_REPORT,
    payload: data,
  };
};

/**
 * Method for exporting district cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportDistrictCumulativeFloodReport = (data) => {
  return {
    type: GET_DISTRICT_CUMULATIVE_FLOOD_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportDistrictCumulativeFloodReport = (data) => {
  return {
    type: SET_DISTRICT_CUMULATIVE_FLOOD_REPORT,
    payload: data,
  };
};
//#endregion Methods for Reports and Approval and Disapproval

/**
 * Method for setting Freezing and Unfreezing Flood Form Report for a Date Range
 * @author: vishal.mishra@velsof.com
 * @date : 03-04-2024
 * @param {object} data Report data
 * @returns Json Object
 */
export const floodFreezeUnfreezeReportRange = (data) => {
  return {
    type: FREEZE_UNFREEZE_FLOOD_RANGE_REPORT,
    payload: data,
  };
};
//#endregion Methods for Reports and Approval and Disapproval
