/**
 * The below file is the fire service which contains method for doing the API calls.
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Blob,
  Get_All_Fire,
  Get_Export_Fire,
  Multipart_Form_Data,
  Fire_Import,
  Fire_Add_Basic_Section,
  Fire_Edit_Basic_Section,
  Fire_Get_All_Section,
  Fire_Add_InfDamage_Section,
  Fire_Add_InfDamageOther_Section,
  Fire_Add_Remark_Section,
  Fire_Submit_Report,
  Fire_Discard_Report,
  Fire_Add_PopAffected_Section,
  Fire_Add_Eroded_Section,
  Fire_Add_ReliefCamp_Section,
  Fire_Add_CampInmates_Section,
  Fire_Add_HLL_Section,
  Fire_Add_LiveStock_Section,
  Fire_Add_Caller_Details_Section,
  Fire_Add_RescueOperation_Section,
  Fire_Add_ReliefDistribution_Section,
  Fire_Fo_Level_Report,
  Fire_Get_District_Review_List,
  Fire_Get_State_Review_List,
  Fire_Add_Fire_Details_Section,
  Fire_Add_Property_Involved_Section,
  Fire_Add_PropertyDamaged_Section,
  Fire_Add_Turn_Out_Details_Section,
  Fire_Add_ESPL_Section,
  Fire_Add_Owner_of_Property_Section,
  Fire_Delete_Incident_Photo,
  Fire_Dfes_Report_Approve,
  Fire_Dfes_Report_Reject,
  Fire_Spfe_Report_Approve,
  Fire_Spfe_Report_Reject,
  Fire_Wing_Report_Approve,
  Fire_Wing_Report_Reject,
  Fire_HQCR_Report_Approve,
  Fire_HQCR_Report_Disapprove,
  Fire_Fs_Level_Report,
  // Fire_Get_District_Review_List,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const FireService = {};

//#region Method for listing page.

/**
 * Method for getting the fire listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
FireService.getAllFire = function (param) {
  return fetch({
    url: Get_All_Fire,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the fire export data
 * @returns Fetch Method
 */
FireService.getExportFireData = function () {
  return fetch({
    url: Get_Export_Fire,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};
//#endregion Method for listing page.

//#region Method for fire import page.

/**
 * Method for importing the fire
 * @param {any} data API data
 * @returns Fetch Method
 */
FireService.fireImport = function (data) {
  return fetch({
    url: Fire_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for fire import page.

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023

/**Method for getting all section data
 * @param {number} id Form Id
 * @param {object} param Param object
 * @returns Fetch Method
 */
FireService.fireGetAllSection = function (id, param) {
  return fetch({
    url: format(Fire_Get_All_Section, id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**Method for submitting the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
FireService.fireReportSubmit = function (id) {
  return fetch({
    url: format(Fire_Submit_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

/**Method for discarding the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
FireService.fireReportDiscard = function (id) {
  return fetch({
    url: format(Fire_Discard_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023

/**
 * Method for adding the basic section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddBasicSection = function (data) {
  return fetch({
    url: Fire_Add_Basic_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for editing the basic section
 * @param {number} id Form Id
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireEditBasicSection = function (id, data) {
  return fetch({
    url: format(Fire_Edit_Basic_Section, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Basic Section

//#region InfDamage Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023

/**
 * Method for adding the infDamage section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddInfDamageSection = function (data) {
  return fetch({
    url: Fire_Add_InfDamage_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023

/**
 * Method for adding the infDamageOther section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddInfDamageOtherSection = function (data) {
  return fetch({
    url: Fire_Add_InfDamageOther_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamageOther Section

//#region Remark Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023

/**
 * Method for adding the remark section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddRemarkSection = function (data) {
  return fetch({
    url: Fire_Add_Remark_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Remark Section

//#region Popaffected Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023

/**
 * Method for adding the popaffected section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddPopAffectedSection = function (data) {
  return fetch({
    url: Fire_Add_PopAffected_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Popaffected Section

//#region Eroded Section
//@author: dhruv.kumar@velsof.com
//@date : 02-01-2023

/**
 * Method for adding the eroded section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddErodedSection = function (data) {
  return fetch({
    url: Fire_Add_Eroded_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion eroded Section

//#region Relief Camp Section
//@author: dhruv.kumar@velsof.com
//@date : 02-01-2023

/**
 * Method for adding the relief camp section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddReliefCampSection = function (data) {
  return fetch({
    url: Fire_Add_ReliefCamp_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion relief camp Section

//#region Camp inmates Section
/**
 * Method for adding the camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddCampInmatesSection = function (data) {
  return fetch({
    url: Fire_Add_CampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion camp inmates Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023
/**
 * Method for adding the Human Live Lost section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddHLLSection = function (data) {
  return fetch({
    url: Fire_Add_HLL_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Human Live Lost Section

//#region Emergency Service Personnel Life Section
//@author: shivam.sharma2@velsof.com
//@date : 12-01-2023
/**
 * Method for adding the Emergency Service Personnel Life section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddESPLSection = function (data) {
  return fetch({
    url: Fire_Add_ESPL_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Emergency Service Personnel Life Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023
/**
 * Method for adding the LiveStock section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddLiveStockSection = function (data) {
  return fetch({
    url: Fire_Add_LiveStock_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion LiveStock Section

//#region House Damaged Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023
/**
 * Method for adding the House Damaged section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddHouseDamagedSection = function (data) {
  return fetch({
    url: Fire_Add_PropertyDamaged_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion House Damaged Section

//#region Rescue Operation Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023
/**
 * Method for adding the Rescue Operation section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddRescueOperationSection = function (data) {
  return fetch({
    url: Fire_Add_RescueOperation_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Rescue Operation Section

//#region Relief Distribution Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023
/**
 * Method for adding the Relief Distribution section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddReliefDistributionSection = function (data) {
  return fetch({
    url: Fire_Add_ReliefDistribution_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Relief Distribution Section

//#region Method for Approve and Disapprove
/**
 * @author: shivam.sharma2@velsof.com
 * @date : 25-01-2023
 */
/**Method for approving the HQCR report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
FireService.fireHQCRReportApprove = function (payload) {
  return fetch({
    url: Fire_HQCR_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the HQCR report
 * @param {any} payload Payload
 * @returns Fetch Method
 */
FireService.fireHQCRReportDisapprove = function (payload) {
  return fetch({
    url: Fire_HQCR_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for getting the district review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
FireService.getFireDistrictReviewList = function (payload) {
  return fetch({
    url: Fire_Get_District_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};

/**
 * Method for getting the state review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
FireService.getFireStateReviewList = function (payload) {
  return fetch({
    url: Fire_Get_State_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};
//#endregion Method for Approve and Disapprove

//#region
/**
 * Below method is for downloading the exported file for viewFire.js
 */
FireService.getExportFsLevelFireReport = function (data) {
  return fetch({
    url: Fire_Fs_Level_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region Caller Details Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023
/**
 * Method for adding the Caller Details section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddCallerDetailsSection = function (data) {
  return fetch({
    url: Fire_Add_Caller_Details_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Caller Details Section

//#region Turn out Details Section
//@author: shivam.sharma2@velsof.com
//@date : 11-01-2023
/**
 * Method for adding the Turn out Details section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddTurnOutDetailsSection = function (data) {
  return fetch({
    url: Fire_Add_Turn_Out_Details_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Turn out Details Section

//#region Fire Details Section
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023
/**
 * Method for adding the Fire Details section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddFireDetailsSection = function (data) {
  return fetch({
    url: Fire_Add_Fire_Details_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Fire Details Section

//#region Property Involved Section
//@author: shivam.sharma2@velsof.com
//@date : 11-01-2023
/**
 * Method for adding the Property Involved section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddPropertyInvolvedSection = function (data) {
  return fetch({
    url: Fire_Add_Property_Involved_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Property Involved Section

//#region Owner of Property
//@author: shivam.sharma2@velsof.com
//@date : 11-01-2023
/**
 * Method for adding the Owner of Property section
 * @param {object} data API data
 * @returns Fetch Method
 */
FireService.fireAddOwnerOfPropertySection = function (data) {
  return fetch({
    url: Fire_Add_Owner_of_Property_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Owner of Property Section

//#region for deleting the incident image
//@author: shivam.sharma2@velsof.com
//@date : 17-01-2023
/**
 * Method for deleting the incident image section
 * @param {object} data API data
 * @returns Delete Method
 */
FireService.fireDeleteIncidentPhoto = function (data) {
  return fetch({
    url: format(Fire_Delete_Incident_Photo, data),
    method: "delete",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion for deleting the incident image Section

//#region Method for Approve and Disapprove
/**
 * @author: shivam.sharma2@velsof.com
 * @date : 30-01-2023
 */
/**Method for approving the Wing report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
FireService.fireWingReportApprove = function (payload) {
  return fetch({
    url: Fire_Wing_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * @author: shivam.sharma2@velsof.com
 * @date : 30-01-2023
 */
/**Method for approving the DFES report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
FireService.fireDfesReportApprove = function (payload) {
  return fetch({
    url: Fire_Dfes_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the Wing report
 * @param {any} payload Payload
 * @returns Fetch Method
 */
FireService.fireWingReportDisapprove = function (payload) {
  return fetch({
    url: Fire_Wing_Report_Reject,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the DFES report
 * @param {any} payload Payload
 * @returns Fetch Method
 */
FireService.fireDfesReportDisapprove = function (payload) {
  return fetch({
    url: Fire_Dfes_Report_Reject,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * @author: shivam.sharma2@velsof.com
 * @date : 30-01-2023
 */
/**Method for approving the SPFE report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
FireService.fireSpfeReportApprove = function (payload) {
  return fetch({
    url: Fire_Spfe_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the SPFE report
 * @param {any} payload Payload
 * @returns Fetch Method
 */
FireService.fireSpfeReportDisapprove = function (payload) {
  return fetch({
    url: Fire_Spfe_Report_Reject,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};
export default FireService;
