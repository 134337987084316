/**
 * The below file contains the constants for Apache Superset
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 24-03-2023
 */

export const SUPERSET_LOGIN = "SUPERSET_LOGIN";
export const SET_SUPERSET_LOGIN_RESPONSE = "SET_SUPERSET_LOGIN_RESPONSE";
export const GET_GUEST_TOKEN = "GET_GUEST_TOKEN";
export const SET_GUEST_TOKEN = "SET_GUEST_TOKEN";
