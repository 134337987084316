import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import {
  EditOutlined,
  SettingOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { BASE_URL, FRIMS_URL } from "constants/ApiConstant";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: `${APP_PREFIX_PATH}/masters/user/profile`,
  },

  {
    title: "Change Password",
    icon: SettingOutlined,
    path: `${APP_PREFIX_PATH}/masters/user/change-password`,
  },
];

export const NavProfile = ({ signOut, loginUser }) => {
  const profileImg = BASE_URL + loginUser?.profilePhoto?.url;
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} icon={<UserOutlined />} src={profileImg} />
          <div className="pl-3">
            <h4 className="mt-2">{loginUser?.username}</h4>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={(e) => {
              signOut();
            }}
          >
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar icon={<UserOutlined />} src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

/**
 * State Props sending to reducer
 * @param {any} param0 object
 * @returns object
 */
const mapStateToProps = ({ auth }) => {
  const { loginUser } = auth;
  return {
    loginUser,
  };
};

export default connect(mapStateToProps, { signOut })(NavProfile);
