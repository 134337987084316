/**
 * The below file contains the constants used in the Origin Area
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

/**Constants for Origin Area */
export const GET_ALL_ORIGIN_AREA = "GET_ALL_ORIGIN_AREA";
export const SET_ALL_ORIGIN_AREA = "SET_ALL_ORIGIN_AREA";
/**Constants for Origin Area */
