/**
 * The below file is the village service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-10-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_Village,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_Village_Status,
  Edit_Village,
  Get_All_Village,
  Get_Export_Village,
  Get_Village,
  Multipart_Form_Data,
  Village_Import,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const VillageService = {};

//#region Method for listing page.

/**
 * Method for getting the village listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
VillageService.getAllVillage = function (param) {
  return fetch({
    url: Get_All_Village,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the village export data
 * @returns Fetch Method
 */
VillageService.getExportVillageData = function () {
  return fetch({
    url: Get_Export_Village,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the village status
 * @param {any} data API Data
 * @returns Fetch Method
 */
VillageService.changeVillageStatus = function (data) {
  return fetch({
    url: Change_Village_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for listing page.

//#region Method for add page.

/**
 * Method for adding the village
 * @param {any} data API Data
 * @returns Fetch Method
 */
VillageService.addVillage = function (data) {
  return fetch({
    url: Add_Village,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for add page.

//#region Method for edit page.

/**
 * Method for editing the village
 * @param {number} id Village Id
 * @param {any} data API Data
 * @returns Fetch Method
 */
VillageService.editVillage = function (id, data) {
  return fetch({
    url: format(Edit_Village, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the village detail
 * @param {any} village Village Object
 * @param {any} param API Parameter
 * @returns Fetch Method
 */
VillageService.getVillage = function (village, param) {
  return fetch({
    url: format(Get_Village, village.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};
//#endregion Method for edit page.

//#region Method for village import page.

/**
 * Method for importing the village
 * @param {any} data API data
 * @returns Fetch Method
 */
VillageService.villageImport = function (data) {
  return fetch({
    url: Village_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for village import page.

export default VillageService;
