/**
 * The below file contains the types of occupancy state objects
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  SET_ALL_TYPES_OF_OCCUPANCY,
} from "redux/constants/TypesOfOccupancy";

//Initial state
const initState = {
  // Keys for dropdown 
  typesOfOccupancies: [],
  isTypesOfOccupancyDataFetched: false,
  // Keys for dropdown 
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const typesOfOccupancy = (state = initState, action) => {
  switch (action.type) {
    //Cases for Types of occupancy dropdown
    case SET_ALL_TYPES_OF_OCCUPANCY:
      return {
        ...state,
        typesOfOccupancies: action.payload,
        isTypesOfOccupancyDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };
    //Cases for Types of occupancy dropdown

    default:
      return state;
  }
};

export default typesOfOccupancy;
