/**
 * The below file contains the constants related to the Block master
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-10-2022
 */

/**Constants for Blocks Listing page */
export const GET_ALL_BLOCK = "GET_ALL_BLOCK";
export const SET_BLOCK_LISTING = "SET_BLOCK_LISTING";
export const SET_BLOCK_LISTING_PARAM = "SET_BLOCK_LISTING_PARAM";
export const UPDATE_BLOCK_STATUS = "UPDATE_BLOCK_STATUS";
export const CHANGE_BLOCK_STATUS = "CHANGE_BLOCK_STATUS";
export const GET_EXPORT_BLOCK_DATA = "GET_EXPORT_BLOCK_DATA";
export const SET_EXPORT_BLOCK_DATA = "SET_EXPORT_BLOCK_DATA";
/**Constants for Blocks Listing page */

/**Constants for Block Add page */
export const ADD_BLOCK = "ADD_BLOCK";
/**Constants for Block Add page */

/**Constants for Block Edit page */
export const EDIT_BLOCK = "EDIT_BLOCK";
export const GET_BLOCK = "GET_BLOCK";
export const SET_BLOCK = "SET_BLOCK";
/**Constants for Block Edit page */

/**Constants for Block Import page */
export const BLOCK_IMPORT = "BLOCK_IMPORT";
/**Constants for Block Import page */
