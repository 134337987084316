/**
 * The below file is the urbanflood service which contains method for doing the API calls.
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Blob,
  Get_All_UrbanFlood,
  Get_Export_UrbanFlood,
  Multipart_Form_Data,
  UrbanFlood_Import,
  UrbanFlood_Add_Basic_Section,
  UrbanFlood_Edit_Basic_Section,
  UrbanFlood_Get_All_Section,
  UrbanFlood_Add_InfDamage_Section,
  UrbanFlood_Add_InfDamageOther_Section,
  UrbanFlood_Add_Remark_Section,
  UrbanFlood_Submit_Report,
  UrbanFlood_Discard_Report,
  UrbanFlood_Add_PopAffected_Section,
  UrbanFlood_Add_Eroded_Section,
  UrbanFlood_Add_ReliefCamp_Section,
  UrbanFlood_Add_CampInmates_Section,
  UrbanFlood_Add_HLL_Section,
  UrbanFlood_Add_LiveStock_Section,
  UrbanFlood_Add_HouseDamaged_Section,
  UrbanFlood_Add_RescueOperation_Section,
  UrbanFlood_Add_ReliefDistribution_Section,
  UrbanFlood_Fo_Level_Report,
  UrbanFlood_Cumulative_State_Report,
  UrbanFlood_Cumulative_District_Report,
  UrbanFlood_Get_District_Review_List,
  UrbanFlood_Revenue_Report_Verify,
  UrbanFlood_Revenue_Report_Disapprove,
  UrbanFlood_Revenue_Report_Approve,
  UrbanFlood_Get_State_Review_List,
  UrbanFlood_District_Report_Approve,
  UrbanFlood_District_Report_Disapprove,
  UrbanFlood_District_Report_Verify,
  UrbanFlood_State_Report_Approve,
  UrbanFlood_State_Report_Disapprove,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { GET_URBANFLOOD_DISTRICT_REVIEW_LIST } from "redux/constants/UrbanFlood";

const UrbanFloodService = {};

//#region Method for listing page.

/**
 * Method for getting the urbanflood listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
UrbanFloodService.getAllUrbanFlood = function (param) {
  return fetch({
    url: Get_All_UrbanFlood,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the urbanflood export data
 * @returns Fetch Method
 */
UrbanFloodService.getExportUrbanFloodData = function () {
  return fetch({
    url: Get_Export_UrbanFlood,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};
//#endregion Method for listing page.

//#region Method for urbanflood import page.

/**
 * Method for importing the urbanflood
 * @param {any} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodImport = function (data) {
  return fetch({
    url: UrbanFlood_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for urbanflood import page.

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**Method for getting all section data
 * @param {number} id Form Id
 * @param {object} param Param object
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodGetAllSection = function (id, param) {
  return fetch({
    url: format(UrbanFlood_Get_All_Section, id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**Method for submitting the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodReportSubmit = function (id) {
  return fetch({
    url: format(UrbanFlood_Submit_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

/**Method for discarding the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodReportDiscard = function (id) {
  return fetch({
    url: format(UrbanFlood_Discard_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the basic section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddBasicSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_Basic_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for editing the basic section
 * @param {number} id Form Id
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodEditBasicSection = function (id, data) {
  return fetch({
    url: format(UrbanFlood_Edit_Basic_Section, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Basic Section

//#region InfDamage Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the infDamage section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddInfDamageSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_InfDamage_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the infDamageOther section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddInfDamageOtherSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_InfDamageOther_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamageOther Section

//#region Remark Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the remark section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddRemarkSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_Remark_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Remark Section

//#region Popaffected Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the popaffected section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddPopAffectedSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_PopAffected_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Popaffected Section

//#region Eroded Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the eroded section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddErodedSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_Eroded_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion eroded Section

//#region Relief Camp Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the relief camp section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddReliefCampSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_ReliefCamp_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion relief camp Section

//#region Camp inmates Section
/**
 * Method for adding the camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddCampInmatesSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_CampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion camp inmates Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the Human Live Lost section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddHLLSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_HLL_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the LiveStock section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddLiveStockSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_LiveStock_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion LiveStock Section

//#region House Damaged Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the House Damaged section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddHouseDamagedSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_HouseDamaged_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion House Damaged Section

//#region Rescue Operation Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the Rescue Operation section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddRescueOperationSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_RescueOperation_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Rescue Operation Section

//#region Relief Distribution Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the Relief Distribution section
 * @param {object} data API data
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodAddReliefDistributionSection = function (data) {
  return fetch({
    url: UrbanFlood_Add_ReliefDistribution_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Relief Distribution Section

//#region Method for Approve and Disapprove
/**Method for approving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {number} id Form Id
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodRevenueReportApprove = function (payload) {
  return fetch({
    url: UrbanFlood_Revenue_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {any} payload Payload
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodRevenueReportDisapprove = function (payload) {
  return fetch({
    url: UrbanFlood_Revenue_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {any} payload Payload
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodRevenueReportVerify = function (payload) {
  return fetch({
    url: UrbanFlood_Revenue_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};
/**
 * Method to approve the district approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} payload Payload
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodDistrictReportApprove = function (payload) {
  return fetch({
    url: UrbanFlood_District_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {any} payload Payload
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodDistrictReportDisapprove = function (payload) {
  return fetch({
    url: UrbanFlood_District_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {any} payload Payload
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodDistrictReportVerify = function (payload) {
  return fetch({
    url: UrbanFlood_District_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method to approve the state approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} payload Payload
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodStateReportApprove = function (payload) {
  return fetch({
    url: UrbanFlood_State_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {any} payload Payload
 * @returns Fetch Method
 */
UrbanFloodService.urbanfloodStateReportDisapprove = function (payload) {
  return fetch({
    url: UrbanFlood_State_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for getting the district review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
UrbanFloodService.getUrbanFloodDistrictReviewList = function (payload) {
  return fetch({
    url: UrbanFlood_Get_District_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};

/**
 * Method for getting the state review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
UrbanFloodService.getUrbanFloodStateReviewList = function (payload) {
  return fetch({
    url: UrbanFlood_Get_State_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};
//#endregion Method for Approve and Disapprove

//#region
/**
 * Below method is for downloading the exported file for viewUrbanFlood.js
 */
UrbanFloodService.getExportFoLevelUrbanFloodReport = function (data) {
  return fetch({
    url: UrbanFlood_Fo_Level_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 */
UrbanFloodService.getExportStateCumulativeUrbanFloodReport = function (data) {
  return fetch({
    url: UrbanFlood_Cumulative_State_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 */
UrbanFloodService.getExportDistrictCumulativeUrbanFloodReport = function (
  data
) {
  return fetch({
    url: UrbanFlood_Cumulative_District_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion
export default UrbanFloodService;
