/**
 * The below file contains the constants used in the fire report.
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */

/**Constants for fire Listing page */
export const GET_ALL_FIRE = "GET_ALL_FIRE";
export const SET_FIRE_LISTING = "SET_FIRE_LISTING";
export const SET_FIRE_LISTING_PARAM = "SET_FIRE_LISTING_PARAM";
export const GET_EXPORT_FIRE_DATA = "GET_EXPORT_FIRE_DATA";
export const SET_EXPORT_FIRE_DATA = "SET_EXPORT_FIRE_DATA";
/**Constants for fire Listing page */

/**Constants for fire Import page */
export const FIRE_IMPORT = "FIRE_IMPORT";
/**Constants for fire Import page */

//#region All Section
/**Constants for all section details
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
export const FIRE_GET_ALL_SECTION = "FIRE_GET_ALL_SECTION";
export const FIRE_SET_ALL_SECTION = "FIRE_SET_ALL_SECTION";
export const FIRE_RESET_BUTTON_LOADING = "FIRE_RESET_BUTTON_LOADING";
export const FIRE_SET_PARENT_ID = "FIRE_SET_PARENT_ID";
export const FIRE_REPORT_SUBMIT = "FIRE_REPORT_SUBMIT";
export const FIRE_REPORT_DISCARD = "FIRE_REPORT_DISCARD";
export const FIRE_SET_REDIRECT_TO_LISTING = "FIRE_SET_REDIRECT_TO_LISTING";
export const FIRE_SET_DATA_SAVED = "FIRE_SET_DATA_SAVED";
//#endregion All Section

//#region Basic Section
/**Constants for adding the basic section details
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
export const FIRE_ADD_BASIC_SECTION = "FIRE_ADD_BASIC_SECTION";
export const FIRE_EDIT_BASIC_SECTION = "FIRE_EDIT_BASIC_SECTION";
export const FIRE_SET_NIL_REPORTING = "FIRE_SET_NIL_REPORTING";
//#endregion Basic Section

//#region Caller Details Section
/**Constants for adding the Caller Details details
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */
export const FIRE_ADD_CALLER_DETAILS_SECTION =
  "FIRE_ADD_CALLER_DETAILS_SECTION";
//#endregion Caller Details Section

//#region Turn Out Details Section
/**Constants for adding the Turn Out Details details
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */
export const FIRE_ADD_TURN_OUT_DETAILS_SECTION =
  "FIRE_ADD_TURN_OUT_DETAILS_SECTION";
//#endregion Turn Out Details Section

//#region Fire Details Section
/**Constants for adding the Fire Details details
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */
export const FIRE_ADD_FIRE_DETAILS_SECTION = "FIRE_ADD_FIRE_DETAILS_SECTION";
//#endregion Fire Details Section

//#region Property Involved Section
/**Constants for adding the Property Involved details
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */
export const FIRE_ADD_PROPERTY_INVOLVED_SECTION =
  "FIRE_ADD_PROPERTY_INVOLVED_SECTION";
//#endregion Property Involved Section

//#region Owner of property Section
/**Constants for adding the Owner of property details
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */
export const FIRE_ADD_OWNER_OF_PROPERTY_SECTION =
  "FIRE_ADD_OWNER_OF_PROPERTY_SECTION";
//#endregion Owner of property Section

//#region HouseDamaged Section
/**Constants for adding the HouseDamaged details
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
export const FIRE_ADD_HOUSEDAMAGED_SECTION = "FIRE_ADD_HOUSEDAMAGED_SECTION";
//#endregion HouseDamaged Section

//#region Human Live Lost Section
/**Constants for adding the Human Live Lost details
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
export const FIRE_ADD_HLL_SECTION = "FIRE_ADD_HLL_SECTION";
//#endregion Human Live Lost Section

//#region LiveStock Section
/**Constants for adding the LiveStock details
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
export const FIRE_ADD_LIVESTOCK_SECTION = "FIRE_ADD_LIVESTOCK_SECTION";
//#endregion LiveStock Section

//#region RescueOperation Section
/**Constants for adding the RescueOperation details
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
export const FIRE_ADD_RESCUEOPERATION_SECTION =
  "FIRE_ADD_RESCUEOPERATION_SECTION";
//#endregion RescueOperation Section

//#region ReliefDistribution Section
/**Constants for adding the ReliefDistribution details
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
export const FIRE_ADD_RELIEFDISTRIBUTION_SECTION =
  "FIRE_ADD_RELIEFDISTRIBUTION_SECTION";
//#endregion ReliefDistribution Section

//#region Emergency Service Personel Life Section
/**Constants for adding the Emergency Service Personel Life details
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
export const FIRE_ADD_ESPL_SECTION = "FIRE_ADD_ESPL_SECTION";
//#endregion Emergency Service Personel Life Section

//#region Remark Section
/**Constants for adding the Remark details
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
export const FIRE_ADD_REMARK_SECTION = "FIRE_ADD_REMARK_SECTION";
//#endregion Remark Section

/**
 * Added for incident photo delete
 * @author: shivam.sharma2@velsof.com
 * @date : 17-01-2023
 */
export const FIRE_DELETE_INCIDENT_PHOTO = "DELETE_INCIDENT_PHOTO";
export const FIRE_SET_DELETED_IMAGE_RESPONSE =
  "FIRE_SET_DELETED_IMAGE_RESPONSE";
export const FIRE_SET_INCIDENT_PHOTO_RESPONSE =
  "FIRE_SET_INCIDENT_PHOTO_RESPONSE";

//#region
/**Constants for adding the approve & disapprove constants
 * @author: shivam.sharma2@velsof.com
 * @date : 30-01-2023
 */
export const FIRE_DFES_REPORT_APPROVE = "FIRE_DFES_REPORT_APPROVE";
export const FIRE_DFES_REPORT_DISAPPROVE = "FIRE_DFES_REPORT_DISAPPROVE";
//#endregion
export const FIRE_SPFE_REPORT_APPROVE = "FIRE_SPFE_REPORT_APPROVE";
export const FIRE_SPFE_REPORT_DISAPPROVE = "FIRE_SPFE_REPORT_DISAPPROVE";
export const FIRE_WING_REPORT_APPROVE = "FIRE_WING_REPORT_APPROVE";
export const FIRE_WING_REPORT_DISAPPROVE = "FIRE_WING_REPORT_DISAPPROVE";
//#endregion

/**Constants for adding the approve & disapprove constants
 * @author: shivam.sharma2@velsof.com
 * @date : 25-01-2023
 */
export const FIRE_HQCR_REPORT_APPROVE = "FIRE_HQCR_REPORT_APPROVE";
export const FIRE_HQCR_REPORT_DISAPPROVE = "FIRE_HQCR_REPORT_DISAPPROVE";

/**
 * Constants for exporting the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 07-02-2023
 */
export const EXPORT_FS_LEVEL_FIRE_REPORT = "EXPORT_FS_LEVEL_FIRE_REPORT";
export const SET_EXPORT_FS_LEVEL_FIRE_REPORT =
  "SET_EXPORT_FS_LEVEL_FIRE_REPORT";
