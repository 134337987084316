/**
 * The below file contains the constants used in the Types of occupancy
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

/**Constants for Types of occupancy */
export const GET_ALL_TYPES_OF_OCCUPANCY = "GET_ALL_TYPES_OF_OCCUPANCY";
export const SET_ALL_TYPES_OF_OCCUPANCY = "SET_ALL_TYPES_OF_OCCUPANCY";
/**Constants for Types of occupancy */
