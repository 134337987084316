/**
 * The below file is the user service which contains method for doing the API calls.
 * @author: shivam.sharma2@velsof.com
 * @date : 05-01-2023
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_User,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_User_Status,
  Edit_User,
  Get_All_User,
  Get_Export_User,
  Get_User,
  Multipart_Form_Data,
  Reset_Password,
  User_Import,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { Change_Password } from "../constants/ApiConstant";

const UserService = {};

//#region Method for listing page.

/**
 * Method for getting the user listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
UserService.getAllUser = function (param) {
  return fetch({
    url: Get_All_User,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the user export data
 * @returns Fetch Method
 */
UserService.getExportUserData = function () {
  return fetch({
    url: Get_Export_User,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the user status
 * @param {any} data API Data
 * @returns Fetch Method
 */
UserService.changeUserStatus = function (data) {
  return fetch({
    url: Change_User_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for listing page.

//#region Method for add page.

/**
 * Method for adding the user
 * @param {any} data API Data
 * @returns Fetch Method
 */
UserService.addUser = function (data) {
  return fetch({
    url: Add_User,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for add page.

//#region Method for edit page.

/**
 * Method for editing the user
 * @param {number} id User Id
 * @param {any} data API Data
 * @returns Fetch Method
 */
UserService.editUser = function (id, data) {
  return fetch({
    url: format(Edit_User, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the user detail
 * @param {any} user User Object
 * @param {any} param API Parameter
 * @returns Fetch Method
 */
UserService.getUser = function (user, param) {
  return fetch({
    url: format(Get_User, user.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};
//#endregion Method for edit page.

//#region Method for user import page.

/**
 * Method for importing the user
 * @param {any} data API data
 * @returns Fetch Method
 */
UserService.userImport = function (data) {
  return fetch({
    url: User_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for user import page.

//#region Method for resetting user password

/**
 * Method for reset user password
 * @param {any} data API data
 * @returns Fetch Method
 */
UserService.resetUserPassword = function (data) {
  return fetch({
    url: format(Reset_Password, data.id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for resetting user password

/**
 * Method for changing password
 * @param {any} data API data
 * @returns Fetch Method
 */
UserService.changePassword = function (data) {
  return fetch({
    url: Change_Password,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

export default UserService;
