/**
 * The below file contains the agency state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_AGENCY,
  EDIT_AGENCY,
  SET_AGENCY,
  SET_AGENCY_LISTING_PARAM,
  SET_AGENCY_LISTING,
  UPDATE_AGENCY_STATUS,
  SET_EXPORT_AGENCY_DATA,
  GET_EXPORT_AGENCY_DATA,
} from "../constants/Agency";

//Initial state
const initState = {
  //Keys for agency listing
  agencyList: {},
  isAgencyDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  agencyListingParam: {},
  agencyExportedData: {},
  //Keys for agency listing

  //Keys for agency add page
  addAgencyForm: {},
  //Keys for agency add page

  //Keys for agency edit pag e
  agencyFormData: {},
  //Keys for agency edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const agency = (state = initState, action) => {
  switch (action.type) {
    //Cases for Agency listing
    case SET_AGENCY_LISTING:
      return {
        ...state,
        agencyList: action.payload,
        isAgencyDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_AGENCY_LISTING_PARAM:
      return {
        ...state,
        agencyListingParam: action.payload,
      };

    case UPDATE_AGENCY_STATUS:
      let updateAgencyListData = {
        ...state.agencyList,
        data: state.agencyList.data?.map((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        }),
      };
      return {
        ...state,
        agencyList: updateAgencyListData,
      };

    case GET_EXPORT_AGENCY_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_AGENCY_DATA:
      return {
        ...state,
        agencyExportedData: action.payload,
      };
    //Cases for Agency listing

    //Cases for Agency add page
    case ADD_AGENCY:
      return {
        ...state,
        addAgencyForm: action.payload,
      };
    //Cases for Agency add page

    //Cases for Agency edit page
    case EDIT_AGENCY:
      return {
        ...state,
        addAgencyForm: action.payload,
      };
    case SET_AGENCY:
      return {
        ...state,
        agencyFormData: action.payload,
      };
    //Cases for Agency edit page

    default:
      return state;
  }
};

export default agency;
