/**
 * The below file is the storm service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Blob,
  Get_All_Storm,
  Get_Export_Storm,
  Multipart_Form_Data,
  Storm_Import,
  Storm_Add_Basic_Section,
  Storm_Edit_Basic_Section,
  Storm_Get_All_Section,
  Storm_Add_InfDamage_Section,
  Storm_Add_InfDamageOther_Section,
  Storm_Add_Remark_Section,
  Storm_Submit_Report,
  Storm_Discard_Report,
  Storm_Add_PopAffected_Section,
  Storm_Add_Eroded_Section,
  Storm_Add_ReliefCamp_Section,
  Storm_Add_CampInmates_Section,
  Storm_Add_HLL_Section,
  Storm_Add_LiveStock_Section,
  Storm_Add_HouseDamaged_Section,
  Storm_Add_RescueOperation_Section,
  Storm_Add_ReliefDistribution_Section,
  Storm_Fo_Level_Report,
  Storm_Cumulative_State_Report,
  Storm_Cumulative_District_Report,
  Storm_Get_District_Review_List,
  Storm_Revenue_Report_Verify,
  Storm_Revenue_Report_Disapprove,
  Storm_Revenue_Report_Approve,
  Storm_Get_State_Review_List,
  Storm_District_Report_Approve,
  Storm_District_Report_Disapprove,
  Storm_District_Report_Verify,
  Storm_State_Report_Approve,
  Storm_State_Report_Disapprove,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const StormService = {};

//#region Method for listing page.

/**
 * Method for getting the storm listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
StormService.getAllStorm = function (param) {
  return fetch({
    url: Get_All_Storm,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the storm export data
 * @returns Fetch Method
 */
StormService.getExportStormData = function () {
  return fetch({
    url: Get_Export_Storm,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};
//#endregion Method for listing page.

//#region Method for storm import page.

/**
 * Method for importing the storm
 * @param {any} data API data
 * @returns Fetch Method
 */
StormService.stormImport = function (data) {
  return fetch({
    url: Storm_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for storm import page.

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**Method for getting all section data
 * @param {number} id Form Id
 * @param {object} param Param object
 * @returns Fetch Method
 */
StormService.stormGetAllSection = function (id, param) {
  return fetch({
    url: format(Storm_Get_All_Section, id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**Method for submitting the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
StormService.stormReportSubmit = function (id) {
  return fetch({
    url: format(Storm_Submit_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

/**Method for discarding the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
StormService.stormReportDiscard = function (id) {
  return fetch({
    url: format(Storm_Discard_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the basic section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddBasicSection = function (data) {
  return fetch({
    url: Storm_Add_Basic_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for editing the basic section
 * @param {number} id Form Id
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormEditBasicSection = function (id, data) {
  return fetch({
    url: format(Storm_Edit_Basic_Section, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Basic Section

//#region InfDamage Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the infDamage section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddInfDamageSection = function (data) {
  return fetch({
    url: Storm_Add_InfDamage_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the infDamageOther section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddInfDamageOtherSection = function (data) {
  return fetch({
    url: Storm_Add_InfDamageOther_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamageOther Section

//#region Remark Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the remark section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddRemarkSection = function (data) {
  return fetch({
    url: Storm_Add_Remark_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Remark Section

//#region Popaffected Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the popaffected section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddPopAffectedSection = function (data) {
  return fetch({
    url: Storm_Add_PopAffected_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Popaffected Section

//#region Eroded Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the eroded section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddErodedSection = function (data) {
  return fetch({
    url: Storm_Add_Eroded_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion eroded Section

//#region Relief Camp Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the relief camp section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddReliefCampSection = function (data) {
  return fetch({
    url: Storm_Add_ReliefCamp_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion relief camp Section

//#region Camp inmates Section
/**
 * Method for adding the camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddCampInmatesSection = function (data) {
  return fetch({
    url: Storm_Add_CampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion camp inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the Human Live Lost section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddHLLSection = function (data) {
  return fetch({
    url: Storm_Add_HLL_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the LiveStock section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddLiveStockSection = function (data) {
  return fetch({
    url: Storm_Add_LiveStock_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion LiveStock Section

//#region House Damaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the House Damaged section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddHouseDamagedSection = function (data) {
  return fetch({
    url: Storm_Add_HouseDamaged_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion House Damaged Section

//#region Rescue Operation Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the Rescue Operation section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddRescueOperationSection = function (data) {
  return fetch({
    url: Storm_Add_RescueOperation_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Rescue Operation Section

//#region Relief Distribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the Relief Distribution section
 * @param {object} data API data
 * @returns Fetch Method
 */
StormService.stormAddReliefDistributionSection = function (data) {
  return fetch({
    url: Storm_Add_ReliefDistribution_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Relief Distribution Section

//#region Method for Approve and Disapprove
/**Method for approving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {number} id Form Id
 * @returns Fetch Method
 */
StormService.stormRevenueReportApprove = function (payload) {
  return fetch({
    url: Storm_Revenue_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
StormService.stormRevenueReportDisapprove = function (payload) {
  return fetch({
    url: Storm_Revenue_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
StormService.stormRevenueReportVerify = function (payload) {
  return fetch({
    url: Storm_Revenue_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};
/**
 * Method to approve the district approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} payload Payload
 * @returns Fetch Method
 */
StormService.stormDistrictReportApprove = function (payload) {
  return fetch({
    url: Storm_District_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
StormService.stormDistrictReportDisapprove = function (payload) {
  return fetch({
    url: Storm_District_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
StormService.stormDistrictReportVerify = function (payload) {
  return fetch({
    url: Storm_District_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method to approve the state approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} payload Payload
 * @returns Fetch Method
 */
StormService.stormStateReportApprove = function (payload) {
  return fetch({
    url: Storm_State_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
StormService.stormStateReportDisapprove = function (payload) {
  return fetch({
    url: Storm_State_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for getting the district review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
StormService.getStormDistrictReviewList = function (payload) {
  return fetch({
    url: Storm_Get_District_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};

/**
 * Method for getting the state review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
StormService.getStormStateReviewList = function (payload) {
  return fetch({
    url: Storm_Get_State_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};
//#endregion Method for Approve and Disapprove

//#region
/**
 * Below method is for downloading the exported file for viewStorm.js
 */
StormService.getExportFoLevelStormReport = function (data) {
  return fetch({
    url: Storm_Fo_Level_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
StormService.getExportStateCumulativeStormReport = function (data) {
  return fetch({
    url: Storm_Cumulative_State_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
StormService.getExportDistrictCumulativeStormReport = function (data) {
  return fetch({
    url: Storm_Cumulative_District_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion
export default StormService;
