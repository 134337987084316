/**
 * The below file contains the Sagas method for Disasters.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-11-2022
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { GET_DISASTER_LISTING } from "../constants/Disaster";

import DisasterService from "services/DisasterService";
import { setDisasterListing } from "redux/actions/Disaster";
import { showMessage } from "redux/actions/Common";
import { Error, Success } from "constants/ApiConstant";

//#region Method for disaster listing

/**
 * Method for getting the disaster listing
 */
export function* getDisasterListing() {
  yield takeEvery(GET_DISASTER_LISTING, function* ({ payload }) {
    try {
      const disaster = {
        data: [
          {
            id: 1,
            value: "Erosion",
          },
          {
            id: 2,
            value: "Landslide",
          },
          {
            id: 3,
            value: "Lightning",
          },
          {
            id: 4,
            value: "Storm",
          },
          {
            id: 5,
            value: "Urban Flood",
          },
          {
            id: 6,
            value: "Earthquake",
          },
          {
            id: 7,
            value: "Fire",
          },
          {
            id: 8,
            value: "Flood",
          },
        ],
      };
      yield put(setDisasterListing(disaster));

      // const disaster = yield call(DisasterService.getDisasterListing, payload);
      // if (disaster.error != null && disaster.error.message) {
      //   yield put(
      //     showMessage({ message: disaster.error.message, type: Error })
      //   );
      // } else {
      //   yield put(setDisasterListing(disaster));
      // }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for disaster listing

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([fork(getDisasterListing)]);
}
