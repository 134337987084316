/**
 * The below file contains the Sagas method for Agency.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_AGENCY,
  CHANGE_AGENCY_STATUS,
  AGENCY_IMPORT,
  EDIT_AGENCY,
  GET_ALL_AGENCY,
  GET_AGENCY,
  GET_EXPORT_AGENCY_DATA,
} from "../constants/Agency";

import AgencyService from "services/AgencyService";
import {
  setAgency,
  setAgencyListing,
  setExportAgency,
  updateAgencyStatus,
} from "redux/actions/Agency";
import { showMessage } from "redux/actions/Common";
import {
  Agency_Added,
  Agency_Imported,
  Agency_Updated,
} from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";

//#region Method for agency listing

/**
 * Method for getting the agency details
 */
export function* getAllAgency() {
  yield takeEvery(GET_ALL_AGENCY, function* ({ payload }) {
    try {
      const agency = yield call(AgencyService.getAllAgency, payload);
      if (agency.error != null && agency.error.message) {
        yield put(showMessage({ message: agency.error.message, type: Error }));
      } else {
        yield put(setAgencyListing(agency));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the agency export details
 */
export function* getAgencyExport() {
  yield takeEvery(GET_EXPORT_AGENCY_DATA, function* () {
    try {
      const agency = yield call(AgencyService.getExportAgencyData);
      if (agency.error != null && agency.error.message) {
        yield put(showMessage({ message: agency.error.message, type: Error }));
      } else {
        let exportData = {
          url: window.URL.createObjectURL(agency),
        };
        yield put(setExportAgency(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the agency status details
 */
export function* changeAgencyStatus() {
  yield takeEvery(CHANGE_AGENCY_STATUS, function* ({ payload }) {
    try {
      const agency = yield call(AgencyService.changeAgencyStatus, payload);
      if (agency.error != null && agency.error.message) {
        yield put(showMessage({ message: agency.error.message, type: Error }));
      } else {
        let json = {
          id: agency.data.id,
          status: agency.data.status,
        };
        yield put(updateAgencyStatus(json));
        yield put(showMessage({ message: Agency_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for agency listing

//#region Method for agency add page

/**
 * Method for adding the agency
 */
export function* addAgency() {
  yield takeEvery(ADD_AGENCY, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const agency = yield call(AgencyService.addAgency, final);
      if (agency.error != null && agency.error.message) {
        yield put(showMessage({ message: agency.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Agency_Added, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for agency add page

//#region Method for agency edit page

/**
 * Method for editing the agency
 */
export function* editAgency() {
  yield takeEvery(EDIT_AGENCY, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          agencyName: payload.agencyName,
          agencyCode: payload.agencyCode,
          status: payload.status,
          state: payload.state,
        },
      };
      const agency = yield call(AgencyService.editAgency, id, final);
      if (agency.error != null && agency.error.message) {
        yield put(showMessage({ message: agency.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Agency_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the agency detail
 */
export function* getAgency() {
  yield takeEvery(GET_AGENCY, function* ({ payload }) {
    try {
      const agency = yield call(AgencyService.getAgency, payload);
      if (agency.error != null && agency.error.message) {
        yield put(showMessage({ message: agency.error.message, type: Error }));
      } else {
        yield put(setAgency(agency));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for agency edit page

//#region Method for agency import page

/**
 * Method for importing the agency
 */
export function* agencyImport() {
  yield takeEvery(AGENCY_IMPORT, function* ({ payload }) {
    try {
      const agency = yield call(AgencyService.agencyImport, payload);
      if (agency.error != null && agency.error.message) {
        yield put(showMessage({ message: agency.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Agency_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for agency import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllAgency),
    fork(addAgency),
    fork(editAgency),
    fork(getAgency),
    fork(getAgencyExport),
    fork(changeAgencyStatus),
    fork(agencyImport),
  ]);
}
