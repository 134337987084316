/**
 * The below file contains the Sagas method for Flood reports.
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  BasicSection,
  DiscardButton,
  InfDamageOtherSection,
  InfDamageSection,
  PopAffectedSection,
  ErodedSection,
  RemarkSection,
  SubmitButton,
  approveButton,
  disapproveButton,
  ReliefCampSection,
  CampInmatesSection,
  HllSection,
  LiveStockSection,
  HouseDamagedSection,
  RescueOperationSection,
  ReliefDistributionSection,
  verifyButton,
  NonCampInmatesSection,
  freezeButton,
} from "constants/ApplicationConstant";
import {
  Flood_Added_Basic_Section,
  Flood_Added_CampInmates_Section,
  Flood_Added_Eroded_Section,
  Flood_Added_HLL_Section,
  Flood_Added_HouseDamaged_Section,
  Flood_Added_InfDamageOther_Section,
  Flood_Added_InfDamage_Section,
  Flood_Added_LiveStock_Section,
  Flood_Added_PopAffected_Section,
  Flood_Added_ReliefCamp_Section,
  Flood_Added_ReliefDistribution_Section,
  Flood_Added_Remark_Section,
  Flood_Added_RescueOperation_Section,
  Flood_Imported,
  Flood_Report_Discard,
  Flood_Report_Submit,
  Flood_Report_Approved,
  Flood_Report_Disapproved,
  Flood_Updated_Basic_Section,
  Flood_Report_Verify,
  Flood_Added_NonCampInmates_Section,
  Flood_Freeze_Successfully,
} from "constants/MessageConstant";
import {
  all,
  takeEvery,
  put,
  fork,
  call,
  takeLatest,
} from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  floodResetButtonLoading,
  floodSetAllSection,
  floodSetDataSaved,
  floodSetParentId,
  floodSetRedirectToListing,
  setExportFlood,
  setFloodListing,
  setFloodDistrictReviewList,
  setExportDistrictCumulativeFloodReport,
  setExportStateCumulativeFloodReport,
  setExportFOLevelFloodReport,
  setFloodStateReviewList,
  floodCauseOfDeathSection,
  floodSetReliefCenterSection,
} from "redux/actions/Flood";
import {
  GET_ALL_FLOOD,
  GET_EXPORT_FLOOD_DATA,
  FLOOD_ADD_BASIC_SECTION,
  FLOOD_ADD_INFDAMAGEOTHER_SECTION,
  FLOOD_ADD_INFDAMAGE_SECTION,
  FLOOD_ADD_REMARK_SECTION,
  FLOOD_EDIT_BASIC_SECTION,
  FLOOD_GET_ALL_SECTION,
  FLOOD_IMPORT,
  FLOOD_REPORT_DISCARD,
  FLOOD_REPORT_SUBMIT,
  FLOOD_ADD_POPAFFECTED_SECTION,
  FLOOD_ADD_ERODED_SECTION,
  FLOOD_ADD_RELIEFCAMP_SECTION,
  FLOOD_ADD_CAMPINMATES_SECTION,
  FLOOD_ADD_HLL_SECTION,
  Flood_Cause_Of_Death_HLL_Section,
  FLOOD_ADD_LIVESTOCK_SECTION,
  FLOOD_ADD_HOUSEDAMAGED_SECTION,
  FLOOD_ADD_RESCUEOPERATION_SECTION,
  FLOOD_ADD_RELIEFDISTRIBUTION_SECTION,
  GET_FLOOD_DISTRICT_REVIEW_LIST,
  GET_FLOOD_STATE_REVIEW_LIST,
  EXPORT_FO_LEVEL_FLOOD_REPORT,
  FLOOD_REVENUE_REPORT_APPROVE,
  FLOOD_REVENUE_REPORT_DISAPPROVE,
  FLOOD_REVENUE_REPORT_VERIFY,
  FLOOD_DISTRICT_REPORT_APPROVE,
  FLOOD_DISTRICT_REPORT_DISAPPROVE,
  FLOOD_DISTRICT_REPORT_VERIFY,
  FLOOD_STATE_REPORT_APPROVE,
  FLOOD_STATE_REPORT_DISAPPROVE,
  GET_DISTRICT_CUMULATIVE_FLOOD_REPORT,
  GET_STATE_CUMULATIVE_FLOOD_REPORT,
  FLOOD_ADD_NONCAMPINMATES_SECTION,
  FREEZE_UNFREEZE_FLOOD_RANGE_REPORT,
} from "redux/constants/Flood";
import FloodService from "services/FloodService";

//#region Method for listing

/**
 * Method for getting the floods
 */
export function* getAllFlood() {
  yield takeEvery(GET_ALL_FLOOD, function* ({ payload }) {
    try {
      const flood = yield call(FloodService.getAllFlood, payload);
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(setFloodListing(flood));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the flood export details
 */
export function* getFloodExport() {
  yield takeEvery(GET_EXPORT_FLOOD_DATA, function* () {
    try {
      const flood = yield call(FloodService.getExportFloodData);
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        let exportData = {
          url: window.URL.createObjectURL(flood),
        };
        yield put(setExportFlood(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for flood import page

/**
 * Method for importing the flood
 */
export function* floodImport() {
  yield takeEvery(FLOOD_IMPORT, function* ({ payload }) {
    try {
      const flood = yield call(FloodService.floodImport, payload);
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Flood_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for flood import page

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for getting the all section data
 */
export function* floodGetAllSection() {
  yield takeEvery(FLOOD_GET_ALL_SECTION, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        FloodService.floodGetAllSection,
        payload.id,
        payload.param
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(floodSetAllSection(infDepartment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for submitting the report
 */
export function* floodReportSubmit() {
  yield takeEvery(FLOOD_REPORT_SUBMIT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        FloodService.floodReportSubmit,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: Flood_Report_Submit, type: Success }));
        yield put(floodSetRedirectToListing(true));
        yield put(floodResetButtonLoading({ section: SubmitButton }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: SubmitButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for discarding the report
 */
export function* floodReportDiscard() {
  yield takeEvery(FLOOD_REPORT_DISCARD, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        FloodService.floodReportDiscard,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: Flood_Report_Discard, type: Success })
        );
        yield put(floodSetRedirectToListing(true));
        yield put(floodResetButtonLoading({ section: DiscardButton }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: DiscardButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding basic section
 */
export function* floodAddBasicSection() {
  yield takeEvery(FLOOD_ADD_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };

      const flood = yield call(FloodService.floodAddBasicSection, final);
      if (
        (flood.error != null && flood.error.message) ||
        flood?.data?.status == false
      ) {
        yield put(
          showMessage({
            message: flood?.error?.message || flood?.data?.message,
            type: Error,
          })
        );
        yield put(floodResetButtonLoading({ section: BasicSection }));
      } else {
        yield put(floodSetParentId(flood.data.id));
        yield put(floodResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: Flood_Added_Basic_Section,
            type: Success,
          })
        );
        yield put(floodSetDataSaved({ section: BasicSection, value: true }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for editing basic section
 */
export function* floodEditBasicSection() {
  yield takeEvery(FLOOD_EDIT_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(
        FloodService.floodEditBasicSection,
        payload.id,
        final
      );
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: Flood_Updated_Basic_Section,
            type: Success,
          })
        );
        yield put(floodSetDataSaved({ section: BasicSection, value: true }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Basic Section

//#region InfDamage Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding infDamage section
 */
export function* floodAddInfDamageSection() {
  yield takeEvery(FLOOD_ADD_INFDAMAGE_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(FloodService.floodAddInfDamageSection, final);
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: InfDamageSection }));
        yield put(
          showMessage({
            message: Flood_Added_InfDamage_Section,
            type: Success,
          })
        );
        yield put(
          floodSetDataSaved({ section: InfDamageSection, value: true })
        );
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: InfDamageSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding infDamageOther section
 */
export function* floodAddInfDamageOtherSection() {
  yield takeEvery(FLOOD_ADD_INFDAMAGEOTHER_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(
        FloodService.floodAddInfDamageOtherSection,
        final
      );
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: InfDamageOtherSection }));
        yield put(
          showMessage({
            message: Flood_Added_InfDamageOther_Section,
            type: Success,
          })
        );
        yield put(
          floodSetDataSaved({
            section: InfDamageOtherSection,
            value: true,
          })
        );
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: InfDamageOtherSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion InfDamageOther Section

//#region Remark Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding Remark section
 */
export function* floodAddRemarkSection() {
  yield takeEvery(FLOOD_ADD_REMARK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const Flood = yield call(FloodService.floodAddRemarkSection, final);
      if (Flood.error != null && Flood.error.message) {
        yield put(showMessage({ message: Flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: RemarkSection }));
        yield put(
          showMessage({
            message: Flood_Added_Remark_Section,
            type: Success,
          })
        );
        yield put(floodSetDataSaved({ section: RemarkSection, value: true }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: RemarkSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Remark Section

//#region PopAffected Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding popaffected section
 */
export function* floodAddPopAffectedSection() {
  yield takeEvery(FLOOD_ADD_POPAFFECTED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(FloodService.floodAddPopAffectedSection, final);
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: PopAffectedSection }));
        yield put(
          showMessage({
            message: Flood_Added_PopAffected_Section,
            type: Success,
          })
        );
        yield put(
          floodSetDataSaved({ section: PopAffectedSection, value: true })
        );
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: PopAffectedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion PopAffected Section

//#region Eroded Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding eroded section
 */
export function* floodAddErodedSection() {
  yield takeEvery(FLOOD_ADD_ERODED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(FloodService.floodAddErodedSection, final);
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: ErodedSection }));
        yield put(
          showMessage({
            message: Flood_Added_Eroded_Section,
            type: Success,
          })
        );
        yield put(floodSetDataSaved({ section: ErodedSection, value: true }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: ErodedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Eroded Section

//#region Relief Camp Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding relief camp section
 */
export function* floodAddReliefCampSection() {
  yield takeEvery(FLOOD_ADD_RELIEFCAMP_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(FloodService.floodAddReliefCampSection, final);
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: ReliefCampSection }));
        yield put(
          showMessage({
            message: Flood_Added_ReliefCamp_Section,
            type: Success,
          })
        );
        yield put(
          floodSetDataSaved({ section: ReliefCampSection, value: true })
        );
        yield put(
          floodSetReliefCenterSection(flood?.data?.data?.reliefCenterData)
        );
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: ReliefCampSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Relief Camp Section

//#region Camp inmates Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding camp inmates section
 */
export function* floodAddCampInmatesSection() {
  yield takeEvery(FLOOD_ADD_CAMPINMATES_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(FloodService.floodAddCampInmatesSection, final);
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: CampInmatesSection }));
        yield put(
          showMessage({
            message: Flood_Added_CampInmates_Section,
            type: Success,
          })
        );
        yield put(
          floodSetDataSaved({ section: CampInmatesSection, value: true })
        );
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: CampInmatesSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Camp Inmates Section

//#region Non Camp inmates Section
//@author: vishal.mishra@velsof.com
//@date : 13-03-2024

/** Method for adding non camp inmates section
 */
export function* floodAddNonCampInmatesSection() {
  yield takeEvery(FLOOD_ADD_NONCAMPINMATES_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(
        FloodService.floodAddNonCampInmatesSection,
        final
      );
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: NonCampInmatesSection }));
        yield put(
          showMessage({
            message: Flood_Added_NonCampInmates_Section,
            type: Success,
          })
        );
        yield put(
          floodSetDataSaved({ section: NonCampInmatesSection, value: true })
        );
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: NonCampInmatesSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Camp Inmates Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding Human Live Lost section
 */
export function* floodAddHLLSection() {
  yield takeEvery(FLOOD_ADD_HLL_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(FloodService.floodAddHLLSection, final);
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: HllSection }));
        yield put(
          showMessage({
            message: Flood_Added_HLL_Section,
            type: Success,
          })
        );
        yield put(floodSetDataSaved({ section: HllSection, value: true }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: HllSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding LiveStock section
 */
export function* floodAddLiveStockSection() {
  yield takeEvery(FLOOD_ADD_LIVESTOCK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(FloodService.floodAddLiveStockSection, final);
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: LiveStockSection }));
        yield put(
          showMessage({
            message: Flood_Added_LiveStock_Section,
            type: Success,
          })
        );
        yield put(
          floodSetDataSaved({ section: LiveStockSection, value: true })
        );
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: LiveStockSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding HouseDamaged section
 */
export function* floodAddHouseDamagedSection() {
  yield takeEvery(FLOOD_ADD_HOUSEDAMAGED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(FloodService.floodAddHouseDamagedSection, final);
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: HouseDamagedSection }));
        yield put(
          showMessage({
            message: Flood_Added_HouseDamaged_Section,
            type: Success,
          })
        );
        yield put(
          floodSetDataSaved({ section: HouseDamagedSection, value: true })
        );
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: HouseDamagedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding RescueOperation section
 */
export function* floodAddRescueOperationSection() {
  yield takeEvery(FLOOD_ADD_RESCUEOPERATION_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const flood = yield call(
        FloodService.floodAddRescueOperationSection,
        final
      );
      if (flood.error != null && flood.error.message) {
        yield put(showMessage({ message: flood.error.message, type: Error }));
      } else {
        yield put(floodResetButtonLoading({ section: RescueOperationSection }));
        yield put(
          showMessage({
            message: Flood_Added_RescueOperation_Section,
            type: Success,
          })
        );
        yield put(
          floodSetDataSaved({
            section: RescueOperationSection,
            value: true,
          })
        );
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: RescueOperationSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding ReliefDistribution section
 */
export function* floodAddReliefDistributionSection() {
  yield takeEvery(
    FLOOD_ADD_RELIEFDISTRIBUTION_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const flood = yield call(
          FloodService.floodAddReliefDistributionSection,
          final
        );
        if (flood.error != null && flood.error.message) {
          yield put(showMessage({ message: flood.error.message, type: Error }));
        } else {
          yield put(
            floodResetButtonLoading({ section: ReliefDistributionSection })
          );
          yield put(
            showMessage({
              message: Flood_Added_ReliefDistribution_Section,
              type: Success,
            })
          );
          yield put(
            floodSetDataSaved({
              section: ReliefDistributionSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          floodResetButtonLoading({ section: ReliefDistributionSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion ReliefDistribution Section

//#region
/**
 * The below method is for exporting the report in flood view form
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for getting the district export details
 */
export function* getExportFoLevelFloodReport() {
  yield takeEvery(EXPORT_FO_LEVEL_FLOOD_REPORT, function* ({ payload }) {
    try {
      const exportedReport = yield call(
        FloodService.getExportFoLevelFloodReport,
        payload
      );

      if (exportedReport.error != null && exportedReport.error.message) {
        yield put(
          showMessage({ message: exportedReport.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(exportedReport),
          format: payload.format,
        };
        yield put(setExportFOLevelFloodReport(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#region Method for Approval and Disapproval
/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for approving the revenue report
 */
export function* floodRevenueReportApprove() {
  yield takeEvery(FLOOD_REVENUE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const floodReportStatus = yield call(
        FloodService.floodRevenueReportApprove,
        payload
      );
      if (floodReportStatus.error != null && floodReportStatus.error.message) {
        yield put(
          showMessage({
            message: floodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Flood_Report_Approved, type: Success })
        );
        yield put(floodSetRedirectToListing(true));
        yield put(floodResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the revenue report
 */
export function* floodRevenueReportDisapprove() {
  yield takeEvery(FLOOD_REVENUE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const floodReportStatus = yield call(
        FloodService.floodRevenueReportDisapprove,
        payload
      );
      if (floodReportStatus.error != null && floodReportStatus.error.message) {
        yield put(
          showMessage({
            message: floodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({
            message: Flood_Report_Disapproved,
            type: Success,
          })
        );
        yield put(floodSetRedirectToListing(true));
        yield put(floodResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: disapproveButton }));
    }
  });
}

/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for verify the revenue report
 */
export function* floodRevenueReportVerify() {
  yield takeEvery(FLOOD_REVENUE_REPORT_VERIFY, function* ({ payload }) {
    try {
      const floodReportStatus = yield call(
        FloodService.floodRevenueReportVerify,
        payload
      );
      if (floodReportStatus.error != null && floodReportStatus.error.message) {
        yield put(
          showMessage({
            message: floodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(showMessage({ message: Flood_Report_Verify, type: Success }));
        yield put(floodSetRedirectToListing(true));
        yield put(floodResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* floodDistrictReportApprove() {
  yield takeEvery(FLOOD_DISTRICT_REPORT_APPROVE, function* ({ payload }) {
    try {
      const floodReportStatus = yield call(
        FloodService.floodDistrictReportApprove,
        payload
      );
      if (floodReportStatus.error != null && floodReportStatus.error.message) {
        yield put(
          showMessage({
            message: floodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Flood_Report_Approved, type: Success })
        );
        yield put(floodSetRedirectToListing(true));
        yield put(floodResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* floodDistrictReportDisapprove() {
  yield takeEvery(FLOOD_DISTRICT_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const floodReportStatus = yield call(
        FloodService.floodDistrictReportDisapprove,
        payload
      );
      if (floodReportStatus.error != null && floodReportStatus.error.message) {
        yield put(
          showMessage({
            message: floodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({
            message: Flood_Report_Disapproved,
            type: Success,
          })
        );
        yield put(floodSetRedirectToListing(true));
        yield put(floodResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: disapproveButton }));
    }
  });
}

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* floodDistrictReportVerify() {
  yield takeEvery(FLOOD_DISTRICT_REPORT_VERIFY, function* ({ payload }) {
    try {
      const floodReportStatus = yield call(
        FloodService.floodDistrictReportVerify,
        payload
      );
      if (floodReportStatus.error != null && floodReportStatus.error.message) {
        yield put(
          showMessage({
            message: floodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(showMessage({ message: Flood_Report_Verify, type: Success }));
        yield put(floodSetRedirectToListing(true));
        yield put(floodResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* floodStateReportApprove() {
  yield takeEvery(FLOOD_STATE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const floodReportStatus = yield call(
        FloodService.floodStateReportApprove,
        payload
      );
      if (floodReportStatus.error != null && floodReportStatus.error.message) {
        yield put(
          showMessage({
            message: floodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Flood_Report_Approved, type: Success })
        );
        yield put(floodSetRedirectToListing(true));
        yield put(floodResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* floodStateReportDisapprove() {
  yield takeEvery(FLOOD_STATE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const floodReportStatus = yield call(
        FloodService.floodStateReportDisapprove,
        payload
      );
      if (floodReportStatus.error != null && floodReportStatus.error.message) {
        yield put(
          showMessage({
            message: floodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Flood_Report_Disapproved, type: Success })
        );
        yield put(floodSetRedirectToListing(true));
        yield put(floodResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: disapproveButton }));
    }
  });
}
//#endregion Method for Approval and Disapproval

//#region
/**
 * The below method is for exporting the report in flood cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */

/**
 * Method for getting the cumulative state export details
 */
export function* getExportStateCumulativeFloodReport() {
  yield takeEvery(GET_STATE_CUMULATIVE_FLOOD_REPORT, function* ({ payload }) {
    try {
      const exportedReport = yield call(
        FloodService.getExportStateCumulativeFloodReport,
        payload
      );
      if (exportedReport.error != null && exportedReport.error.message) {
        yield put(
          showMessage({ message: exportedReport.error.message, type: Error })
        );
      } else {
        if (payload?.sendEmail) {
          // Convert the Blob response to text
        const textResponse = yield exportedReport?.text();
        const parsedResponse = JSON.parse(textResponse);
          yield put(
            showMessage({
              message: parsedResponse?.message,
              type: Success,
            })
          );
        } else {
          let exportData = {
            reportData:
              payload.format === "html"
                ? exportedReport
                : window.URL.createObjectURL(exportedReport),
            format: payload.format,
          };
          yield put(setExportStateCumulativeFloodReport(exportData));
        }
      }
    } catch (err) {
      let errPromise = yield err.response.data.text();
      yield put(
        showMessage({
          message: JSON.parse(errPromise).error.message,
          type: Error,
        })
      );
    }
  });
}
//#endregion

/**
 * Method for getting the cumulative district export details
 */
export function* getExportDistrictCumulativeFloodReport() {
  yield takeEvery(
    GET_DISTRICT_CUMULATIVE_FLOOD_REPORT,
    function* ({ payload }) {
      try {
        const exportedReport = yield call(
          FloodService.getExportDistrictCumulativeFloodReport,
          payload
        );
        if (exportedReport.error != null && exportedReport.error.message) {
          yield put(
            showMessage({ message: exportedReport.error.message, type: Error })
          );
        } else {
          let exportData = {
            reportData:
              payload.format === "html"
                ? exportedReport
                : window.URL.createObjectURL(exportedReport),
            format: payload.format,
          };
          yield put(setExportDistrictCumulativeFloodReport(exportData));
        }
      } catch (err) {
        let errPromise = yield err.response.data.text();
        yield put(
          showMessage({
            message: JSON.parse(errPromise).error.message,
            type: Error,
          })
        );
      }
    }
  );
}
//#endregion

/**
 * Method for getting the district review list
 */
export function* getFloodDistrictReviewList() {
  yield takeEvery(GET_FLOOD_DISTRICT_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        FloodService.getFloodDistrictReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setFloodDistrictReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the state review list
 */
export function* getFloodStateReviewList() {
  yield takeEvery(GET_FLOOD_STATE_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        FloodService.getFloodStateReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setFloodStateReviewList(response));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: DiscardButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for Approval and Disapproval

/**
 * Method for Freezing and unfreezing flood report for a selected date range
 * @author: vishal.mishra@velsof.com
 * @date : 03-04-2024
 */
export function* floodFreezeReportRange() {
  yield takeEvery(FREEZE_UNFREEZE_FLOOD_RANGE_REPORT, function* ({ payload }) {
    try {
      const floodReportStatus = yield call(
        FloodService.floodFreezeReportRange,
        payload
      );
      if (floodReportStatus.error != null && floodReportStatus.error.message) {
        yield put(
          showMessage({
            message: floodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Flood_Freeze_Successfully, type: Success })
        );
        yield put(floodResetButtonLoading({ section: freezeButton }));
      }
    } catch (err) {
      yield put(floodResetButtonLoading({ section: freezeButton }));
    }
  });
}
//#endregion Method for Approval and Disapproval

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllFlood),
    fork(getFloodExport),
    fork(floodImport),
    fork(floodAddBasicSection),
    fork(floodEditBasicSection),
    fork(floodGetAllSection),
    fork(floodAddInfDamageSection),
    fork(floodAddInfDamageOtherSection),
    fork(floodAddRemarkSection),
    fork(floodReportSubmit),
    fork(floodReportDiscard),
    fork(floodAddPopAffectedSection),
    fork(floodAddErodedSection),
    fork(floodAddReliefCampSection),
    fork(floodAddCampInmatesSection),
    fork(floodAddNonCampInmatesSection),
    fork(floodAddHLLSection),
    fork(floodAddLiveStockSection),
    fork(floodAddHouseDamagedSection),
    fork(floodAddRescueOperationSection),
    fork(floodAddReliefDistributionSection),
    fork(floodRevenueReportApprove),
    fork(floodRevenueReportDisapprove),
    fork(floodRevenueReportVerify),
    fork(floodDistrictReportApprove),
    fork(floodDistrictReportDisapprove),
    fork(floodDistrictReportVerify),
    fork(floodStateReportApprove),
    fork(floodStateReportDisapprove),
    fork(getExportFoLevelFloodReport),
    fork(getFloodDistrictReviewList),
    fork(getFloodStateReviewList),
    fork(getExportStateCumulativeFloodReport),
    fork(getExportDistrictCumulativeFloodReport),
    fork(floodFreezeReportRange),
  ]);
}
