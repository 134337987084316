/**
 * The below file contains the storm reports state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */

import {
  BasicSection,
  CampInmatesSection,
  DiscardButton,
  ErodedSection,
  HllSection,
  HouseDamagedSection,
  InfDamageOtherSection,
  InfDamageSection,
  LiveStockSection,
  PopAffectedSection,
  ReliefCampSection,
  ReliefDistributionSection,
  RemarkSection,
  RescueOperationSection,
  SubmitButton,
} from "constants/ApplicationConstant";
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_STORM_DATA,
  STORM_ADD_BASIC_SECTION,
  STORM_ADD_INFDAMAGEOTHER_SECTION,
  STORM_ADD_INFDAMAGE_SECTION,
  STORM_ADD_REMARK_SECTION,
  STORM_EDIT_BASIC_SECTION,
  STORM_REPORT_DISCARD,
  STORM_REPORT_SUBMIT,
  STORM_RESET_BUTTON_LOADING,
  STORM_SET_ALL_SECTION,
  STORM_SET_DATA_SAVED,
  STORM_SET_NIL_REPORTING,
  STORM_SET_PARENT_ID,
  STORM_SET_REDIRECT_TO_LISTING,
  SET_EXPORT_STORM_DATA,
  SET_STORM_LISTING,
  SET_STORM_LISTING_PARAM,
  STORM_ADD_POPAFFECTED_SECTION,
  STORM_ADD_ERODED_SECTION,
  STORM_ADD_CAMPINMATES_SECTION,
  STORM_ADD_RELIEFCAMP_SECTION,
  STORM_ADD_HLL_SECTION,
  STORM_ADD_LIVESTOCK_SECTION,
  STORM_ADD_HOUSEDAMAGED_SECTION,
  STORM_ADD_RELIEFDISTRIBUTION_SECTION,
  STORM_ADD_RESCUEOPERATION_SECTION,
  SET_STORM_DISTRICT_REVIEW_LIST,
  SET_STORM_STATE_REVIEW_LIST,
  STORM_REVENUE_REPORT_DISAPPROVE,
  STORM_REVENUE_REPORT_VERIFY,
  STORM_DISTRICT_REPORT_APPROVE,
  STORM_DISTRICT_REPORT_DISAPPROVE,
  STORM_DISTRICT_REPORT_VERIFY,
  STORM_STATE_REPORT_APPROVE,
  STORM_STATE_REPORT_DISAPPROVE,
  SET_EXPORT_FO_LEVEL_STORM_REPORT,
  SET_STATE_CUMULATIVE_STORM_REPORT,
  SET_DISTRICT_CUMULATIVE_STORM_REPORT,
} from "redux/constants/Storm";

//Initial state
const initState = {
  //Keys for listing
  stormList: {},
  isStormDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  stormListingParam: {},
  stormExportedData: {},
  exportFoLevelStormReport: {
    report: "",
    format: "",
  },
  exportStateCumulativeStormReport: {
    report: "",
    format: "",
  },
  exportDistrictCumulativeStormReport: {
    report: "",
    format: "",
  },
  //Keys for listing

  //Keys for All Section Data
  stormParentId: "",
  stormAllSectionData: {},
  stormButtonLoading: {
    basicSection: false,
    popAffectedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
    submitButton: false,
    discardButton: false,
    approveButton: false,
    disapproveButton: false,
    verifyButton: false,
  },
  stormDataSaved: {
    basicSection: false,
    popAffectedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
  },
  stormIsNilReporting: false,
  stormRedirectToListing: false,
  //Keys for All Section Data

  //Keys for Approval Disapproval
  stormDistrictReviewList: {},
  stormStateReviewList: {},
  //Keys for Approval Disapproval
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const storm = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_STORM_LISTING:
      return {
        ...state,
        stormList: action.payload,
        isStormDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_STORM_LISTING_PARAM:
      return {
        ...state,
        stormListingParam: action.payload,
      };

    case GET_EXPORT_STORM_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_STORM_DATA:
      return {
        ...state,
        stormExportedData: action.payload,
      };
    //Cases for listing

    //Cases for All Section
    case STORM_SET_ALL_SECTION:
      return {
        ...state,
        stormAllSectionData: action.payload,
      };

    case STORM_RESET_BUTTON_LOADING:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            basicSection: false,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            popAffectedSection: false,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            reliefCampSection: false,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            campInmatesSection: false,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            hLLSection: false,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            liveStockSection: false,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            houseDamagedSection: false,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            rescueOperationSection: false,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            reliefDistributionSection: false,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            infDamageOtherSection: false,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            remarkSection: false,
          },
        };
      } else if (action.payload.section === SubmitButton) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            submitButton: false,
          },
        };
      } else if (action.payload.section === DiscardButton) {
        return {
          ...state,
          stormButtonLoading: {
            ...state.stormButtonLoading,
            discardButton: false,
          },
        };
      } else {
        return {
          ...state,
          stormButtonLoading: {
            basicSection: false,
            popAffectedSection: false,
            reliefCampSection: false,
            campInmatesSection: false,
            hLLSection: false,
            liveStockSection: false,
            houseDamagedSection: false,
            rescueOperationSection: false,
            reliefDistributionSection: false,
            infDamageOtherSection: false,
            remarkSection: false,
            submitButton: false,
            discardButton: false,
            approveButton: false,
            disapproveButton: false,
            verifyButton: false,
          },
        };
      }

    case STORM_SET_PARENT_ID:
      return {
        ...state,
        stormParentId: action.payload,
      };

    case STORM_SET_REDIRECT_TO_LISTING:
      return {
        ...state,
        stormRedirectToListing: action.payload,
      };

    case STORM_SET_DATA_SAVED:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          stormDataSaved: {
            ...state.stormDataSaved,
            basicSection: action.payload.value,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          stormDataSaved: {
            ...state.stormDataSaved,
            popAffectedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          stormDataSaved: {
            ...state.stormDataSaved,
            reliefCampSection: action.payload.value,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          stormDataSaved: {
            ...state.stormDataSaved,
            campInmatesSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          stormDataSaved: {
            ...state.stormDataSaved,
            hLLSection: action.payload.value,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          stormDataSaved: {
            ...state.stormDataSaved,
            liveStockSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          stormDataSaved: {
            ...state.stormDataSaved,
            houseDamagedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          stormDataSaved: {
            ...state.stormDataSaved,
            rescueOperationSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          stormDataSaved: {
            ...state.stormDataSaved,
            reliefDistributionSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          stormDataSaved: {
            ...state.stormDataSaved,
            infDamageOtherSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          stormDataSaved: {
            ...state.stormDataSaved,
            remarkSection: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case STORM_REPORT_SUBMIT:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          submitButton: true,
        },
      };

    case STORM_REPORT_DISCARD:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          approveButton: true,
        },
      };

    case STORM_REVENUE_REPORT_DISAPPROVE:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          disapproveButton: true,
        },
      };

    case STORM_REVENUE_REPORT_VERIFY:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          verifyButton: true,
        },
      };
    //#endregion

    case STORM_REPORT_DISCARD:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          discardButton: true,
        },
      };

    case STORM_DISTRICT_REPORT_APPROVE:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          approveButton: true,
        },
      };

    case STORM_DISTRICT_REPORT_DISAPPROVE:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          disapproveButton: true,
        },
      };

    case STORM_DISTRICT_REPORT_VERIFY:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          verifyButton: true,
        },
      };

    case STORM_STATE_REPORT_APPROVE:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          approveButton: true,
        },
      };

    case STORM_STATE_REPORT_DISAPPROVE:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          disapproveButton: true,
        },
      };
    //Cases for All Section

    //Cases for Basic Section
    case STORM_ADD_BASIC_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          basicSection: true,
        },
      };

    case STORM_EDIT_BASIC_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          basicSection: true,
        },
      };

    case STORM_SET_NIL_REPORTING:
      return {
        ...state,
        stormIsNilReporting: action.payload,
      };
    //Cases for Basic Section

    //Cases for InfDamageOther Section
    case STORM_ADD_INFDAMAGEOTHER_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          infDamageOtherSection: true,
        },
      };

    //Cases for Remark Section
    case STORM_ADD_REMARK_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          remarkSection: true,
        },
      };

    //Cases for Storm Pop Affected Section
    case STORM_ADD_POPAFFECTED_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          popAffectedSection: true,
        },
      };
    //Cases for Storm Pop Affected Section

    //Cases for Storm Relief Camp Section
    case STORM_ADD_RELIEFCAMP_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          reliefCampSection: true,
        },
      };
    //Cases for Storm Relief Camp Section

    //Cases for Storm Relief Camp Section
    case STORM_ADD_CAMPINMATES_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          campInmatesSection: true,
        },
      };
    //Cases for Storm Relief Camp Section

    //Cases for Storm Hll Section
    case STORM_ADD_HLL_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          hLLSection: true,
        },
      };
    //Cases for Storm Hll Section

    //Cases for Storm LiveStock Section
    case STORM_ADD_LIVESTOCK_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          liveStockSection: true,
        },
      };
    //Cases for Storm LiveStock Section

    //Cases for Storm House Damaged Section
    case STORM_ADD_HOUSEDAMAGED_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          houseDamagedSection: true,
        },
      };
    //Cases for Storm House Damaged Section

    //Cases for Storm Rescue Operation Section
    case STORM_ADD_RESCUEOPERATION_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          rescueOperationSection: true,
        },
      };
    //Cases for Storm Rescue Operation Section

    //Cases for Storm Relief Distribution Section
    case STORM_ADD_RELIEFDISTRIBUTION_SECTION:
      return {
        ...state,
        stormButtonLoading: {
          ...state.stormButtonLoading,
          reliefDistributionSection: true,
        },
      };
    //Cases for Storm Relief Distribution Section

    // Cases for Storm form PDF download section
    case SET_EXPORT_FO_LEVEL_STORM_REPORT:
      return {
        ...state,
        exportFoLevelStormReport: {
          report: action.payload.url,
          format: action.payload.format,
        },
      };
    // Cases for Storm form export file section

    //Cases for Approval and Disapproval
    case SET_STORM_DISTRICT_REVIEW_LIST:
      return {
        ...state,
        stormDistrictReviewList: action.payload,
      };

    case SET_STORM_STATE_REVIEW_LIST:
      return {
        ...state,
        stormStateReviewList: action.payload,
      };
    //Cases for Approval and Disapproval

    // Cases for Storm state cumulative report
    case SET_STATE_CUMULATIVE_STORM_REPORT:
      return {
        ...state,
        exportStateCumulativeStormReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Storm state cumulative report

    // Cases for Storm district cumulative report
    case SET_DISTRICT_CUMULATIVE_STORM_REPORT:
      return {
        ...state,
        exportDistrictCumulativeStormReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Storm district cumulative report

    default:
      return state;
  }
};

export default storm;
