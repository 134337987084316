/**
 * The below file contains the constants used in the Freeze Unfreeze Report.
 * @author: vishal.mishra@velsof.com
 * @date :11-10-2023
 */

export const GET_ALL_FREEZE_UNFREEZE_REPORT = "GET_ALL_FREEZE_UNFREEZE_REPORT";
export const SET_ALL_FREEZE_UNFREEZE_REPORT = "SET_ALL_FREEZE_UNFREEZE_REPORT";

export const REPORT_DATE_PARAM = "reportDate";
export const STATUS_PARAM = "status";
export const GET_ALL_CREATE_FREEZE_UNFREEZE_REPORT =
  "GET_ALL_CREATE_FREEZE_UNFREEZE_REPORT";
export const SET_ALL_CREATE_FREEZE_UNFREEZE_REPORT =
  "SET_ALL_CREATE_FREEZE_UNFREEZE_REPORT";

export const GET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT =
  "GET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT";
export const SET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT =
  "SET_ALL_UPDATE_FREEZE_UNFREEZE_REPORT";
