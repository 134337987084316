/**
 * The below file contains the resource document state objects
 * @author: dhruv.kumar@velsof.com
 * @date : 28-07-2023
 */
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_RESOURCEDOCUMENT,
  EDIT_RESOURCEDOCUMENT,
  SET_RESOURCEDOCUMENT,
  SET_RESOURCEDOCUMENT_LISTING_PARAM,
  SET_RESOURCEDOCUMENT_LISTING,
} from "../constants/ResourceDocument";

//Initial state
const initState = {
  //Keys for resourceDocument listing
  resourceDocumentList: {},
  isResourceDocumentDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  resourceDocumentListingParam: {},
  //Keys for resourceDocument listing

  //Keys for resourceDocument add page
  addResourceDocumentForm: {},
  //Keys for resourceDocument add page

  //Keys for resourceDocument edit page
  resourceDocumentFormData: {},
  //Keys for resourceDocument edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const resourceDocument = (state = initState, action) => {
  switch (action.type) {
    //Cases for ResourceDocument listing
    case SET_RESOURCEDOCUMENT_LISTING:
      return {
        ...state,
        resourceDocumentList: action.payload,
        isResourceDocumentDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_RESOURCEDOCUMENT_LISTING_PARAM:
      return {
        ...state,
        resourceDocumentListingParam: action.payload,
      };

    //Cases for ResourceDocument add page
    case ADD_RESOURCEDOCUMENT:
      return {
        ...state,
        addResourceDocumentForm: action.payload,
      };
    //Cases for ResourceDocument add page

    //Cases for ResourceDocument edit page
    case EDIT_RESOURCEDOCUMENT:
      return {
        ...state,
        addResourceDocumentForm: action.payload,
      };
    case SET_RESOURCEDOCUMENT:
      return {
        ...state,
        resourceDocumentFormData: action.payload,
      };
    //Cases for ResourceDocument edit page

    default:
      return state;
  }
};

export default resourceDocument;
