/**
 * The below file is the block service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-10-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_Block,
  Application_Json,
  Authorization_Token,
  Blob,
  Block_Import,
  Change_Block_Status,
  Edit_Block,
  Get_All_Block,
  Get_Block,
  Get_Export_Block,
  Multipart_Form_Data,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const BlockService = {};

//#region Method for listing page.

/**
 * Method for getting the block listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
BlockService.getAllBlock = function (param) {
  return fetch({
    url: Get_All_Block,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        format(Authorization_Token, localStorage.getItem(AUTH_TOKEN)),
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the block export data
 * @returns Fetch Method
 */
BlockService.getExportBlockData = function () {
  return fetch({
    url: Get_Export_Block,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the block status
 * @param {any} data API data
 * @returns Fetch Method
 */
BlockService.changeBlockStatus = function (data) {
  return fetch({
    url: Change_Block_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for listing page.

//#region Method for add page.

/**
 * Method for adding the block details
 * @param {any} data API Data
 * @returns Fetch Method
 */
BlockService.addBlock = function (data) {
  return fetch({
    url: Add_Block,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        format(Authorization_Token, localStorage.getItem(AUTH_TOKEN)),
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for add page.

//#region Method for edit page.

/**
 * Method for editing the block details
 * @param {number} id Block Id
 * @param {any} data API data
 * @returns Fetch Method
 */
BlockService.editBlock = function (id, data) {
  return fetch({
    url: format(Edit_Block, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the block details
 * @param {any} block Block Object
 * @param {any} param API Parameter
 * @returns Fetch Method
 */
BlockService.getBlock = function (block, param) {
  return fetch({
    url: format(Get_Block, block.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};
//#endregion Method for edit page.

//#region Method for block import page.

/**
 * Method for importing the block
 * @param {any} data API data
 * @returns Fetch Method
 */
BlockService.blockImport = function (data) {
  return fetch({
    url: Block_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for block import page.

export default BlockService;
