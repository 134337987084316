/**
 * The below file contains the action methods for notification master.
 * @author: shivam.sharma2@velsof.com
 * @date : 23-01-2023
 */

import {
  CHANGE_NOTIFICATION_STATUS,
  GET_NOTIFICATION,
  GET_UNREAD_NOTIFICATION,
  GET_UPDATED_STATUS,
  MARK_ALL_READ,
  SET_NOTIFICATION,
  SET_UNREAD_NOTIFICATION,
} from "redux/constants/Notification";

//#region Action methods for notification

/**
 * Method for getting the notification
 * @returns Json Object
 */
export const getNotification = (data) => {
  return {
    type: GET_NOTIFICATION,
    payload: data,
  };
};

/**
 * Method for getting the notification
 * @returns Json Object
 */
export const setNotification = (data) => {
  return {
    type: SET_NOTIFICATION,
    payload: data,
  };
};

/**
 * Method for getting the notification
 * @returns Json Object
 */
export const setNotificationStatus = (data) => {
  return {
    type: CHANGE_NOTIFICATION_STATUS,
    payload: data,
  };
};

/**
 * Method for getting the notification
 * @returns Json Object
 */
export const getUpdatedStatus = (data) => {
  return {
    type: GET_UPDATED_STATUS,
    payload: data,
  };
};

/**
 * Method for mark all notifications as read
 * @returns Json Object
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-02-2023
 */
export const markAllRead = (data) => {
  return {
    type: MARK_ALL_READ,
    payload: data,
  };
};

/**
 * Method for getting unread notification
 * @returns Json Object
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-02-2023
 */
export const getUnreadNotification = (data) => {
  return {
    type: GET_UNREAD_NOTIFICATION,
    payload: data,
  };
};

/**
 * Method for setting unread notification
 * @returns Json Object
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-02-2023
 */
export const setUnreadNotification = (data) => {
  return {
    type: SET_UNREAD_NOTIFICATION,
    payload: data,
  };
};
//#endregion Action methods for notification
