/**
 * The below file contains the constants used in the Livestocks Types
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

/**Constants for Livestocks Types */
export const GET_ALL_LIVESTOCK_TYPES = "GET_ALL_LIVESTOCK_TYPES";
export const SET_ALL_LIVESTOCK_TYPES = "SET_ALL_LIVESTOCK_TYPES";
/**Constants for Livestocks Types */
