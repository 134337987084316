/**
 * The below file is the resource group service which contains method for doing the API calls.
 * @author: dhruv.kumar@velsof.com
 * @date : 28-07-2023
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_ResourceGroup,
  Application_Json,
  Authorization_Token,
  Edit_ResourceGroup,
  Get_All_ResourceGroup,
  Get_ResourceGroup,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const ResourceGroupService = {};

//#region Method for resourceGroup listing page.

/**
 * Method for getting the resourceGroup listing
 * @param {any} param API Parameter
 * @returns Fetch Method
 */
ResourceGroupService.getAllResourceGroup = function (param) {
  return fetch({
    url: Get_All_ResourceGroup,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};
//#endregion Method for resourceGroup listing page.

//#region Method for resourceGroup add page.

/**
 * Method for adding the resourceGroup
 * @param {any} data API Data
 * @returns Fetch Method
 */
ResourceGroupService.addResourceGroup = function (data) {
  return fetch({
    url: Add_ResourceGroup,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for resourceGroup add page.

//#region Method for resourceGroup edit page.

/**
 * Method for editing the resourceGroup
 * @param {number} id Resource Group id
 * @param {any} data API Data
 * @returns Fetch Method
 */
ResourceGroupService.editResourceGroup = function (id, data) {
  return fetch({
    url: format(Edit_ResourceGroup, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the resourceGroup detail
 * @param {any} data API Data
 * @returns Fetch Method
 */
ResourceGroupService.getResourceGroup = function (data) {
  return fetch({
    url: format(Get_ResourceGroup, data.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion Method for resourceGroup edit page.

export default ResourceGroupService;
