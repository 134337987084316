/**
 * The below file contains the constants used in the notification master.
 * @author: shivam.sharma2@velsof.com
 * @date : 23-01-2023
 */

/**Constants for getting all the notification */
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const CHANGE_NOTIFICATION_STATUS = "CHANGE_NOTIFICATION_STATUS";
export const GET_UPDATED_STATUS = "GET_UPDATED_STATUS";
/**Constants for getting all the notification */

/**
 * Constant for Mark all read
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-02-2023
 */
export const MARK_ALL_READ = "MARK_ALL_READ";

/**
 * Constant for unread notification
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-02-2023
 */
export const GET_UNREAD_NOTIFICATION = "GET_UNREAD_NOTIFICATION";
export const SET_UNREAD_NOTIFICATION = "SET_UNREAD_NOTIFICATION";
