/**
 * The below file contains the Sagas method for User.
 * @author: shivam.sharma2@velsof.com
 * @date : 05-01-2023
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Saved_Password,
  User_Added,
  User_Imported,
  User_Updated,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  setExportUser,
  setUser,
  setUserListing,
  updateUserStatus,
} from "redux/actions/User";
import {
  ADD_USER,
  CHANGE_USER_STATUS,
  EDIT_USER,
  GET_ALL_USER,
  GET_EXPORT_USER_DATA,
  GET_USER,
  RESET_USER_PASSWORD,
  USER_IMPORT,
} from "redux/constants/User";
import UserService from "services/UserService";
import CommonMethods from "utils/CommonMethods";
import { User_Change_Password } from "../../constants/MessageConstant";
import { CHANGE_PASSWORD } from "../constants/User";

//#region Method for listing

/**
 * Method for getting the user
 */
export function* getAllUser() {
  yield takeEvery(GET_ALL_USER, function* ({ payload }) {
    try {
      let user = yield call(UserService.getAllUser, payload);
      if (user.error != null && user.error.message) {
        yield put(showMessage({ message: user.error.message, type: Error }));
      } else {
        //Decrypt email and phone from user
        user = user.map((item) => {
          item.email = CommonMethods.aesDecrypt(item.email);
          item.phone = CommonMethods.aesDecrypt(item.phone);
          return item;
        });
        yield put(setUserListing(user));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the user export details
 */
export function* getUserExport() {
  yield takeEvery(GET_EXPORT_USER_DATA, function* () {
    try {
      const user = yield call(UserService.getExportUserData);
      if (user.error != null && user.error.message) {
        yield put(showMessage({ message: user.error.message, type: Error }));
      } else {
        let exportData = {
          url: window.URL.createObjectURL(user),
        };
        yield put(setExportUser(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the user status details
 */
export function* changeUserStatus() {
  yield takeEvery(CHANGE_USER_STATUS, function* ({ payload }) {
    try {
      let user = yield call(UserService.changeUserStatus, payload);
      if (user.error != null && user.error.message) {
        yield put(showMessage({ message: user.error.message, type: Error }));
      } else {
        let param = {
          "populate[0]": "block",
          "populate[block][populate][0]": "district",
          "populate[role][populate][0]": "id",
        };
        user = yield call(UserService.getUser, payload, param);
        let json = {
          id: user.id || payload.id,
          status: user.confirmed,
        };
        yield put(updateUserStatus(json));
        yield put(showMessage({ message: User_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for add page

/**
 * Method for adding the user
 */
export function* addUser() {
  yield takeEvery(ADD_USER, function* ({ payload }) {
    try {
      let final = {
        ...payload,
      };
      const user = yield call(UserService.addUser, final);
      if (user.error != null && user.error.message) {
        yield put(showMessage({ message: user.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: User_Added, type: "success" }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for add page

//#region Method for edit page

/**
 * Method for editing the user
 */
export function* editUser() {
  yield takeEvery(EDIT_USER, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        username: payload.username,
        email: payload.email,
        password: payload.password,
        provider: payload.provider,
        confirmed: payload.confirmed,
        blocked: payload.blocked,
        firstName: payload.firstName,
        middleName: payload.middleName,
        lastName: payload.lastName,
        phone: payload.phone,
        role: payload.role,
        district: payload.district,
        block: payload.block,
        profilePhoto: payload.profilePhoto || null,
      };
      const user = yield call(UserService.editUser, id, final);
      if (user.error != null && user.error.message) {
        yield put(showMessage({ message: user.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: User_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the user
 */
export function* getUser() {
  yield takeEvery(GET_USER, function* ({ payload }) {
    try {
      let param = {
        "populate[district][fields][0]": "id",
        "populate[district][fields][1]": "districtName",
        "populate[block][fields][0]": "id",
        "populate[block][fields][1]": "blockName",
        "populate[role][fields][0]": "id",
        "populate[role][fields][1]": "name",
        "populate[station][fields][0]": "id",
        "populate[station][fields][1]": "name",
        "populate[profilePhoto]": "true",
      };
      let user = yield call(UserService.getUser, payload, param);
      if (user.error != null && user.error.message) {
        yield put(showMessage({ message: user.error.message, type: Error }));
      } else {
        //Decrypt email and phone from user
        user.email = CommonMethods.aesDecrypt(user.email);
        user.phone = CommonMethods.aesDecrypt(user.phone);
        yield put(setUser(user));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for add page

//#region Method for user import page

/**
 * Method for importing the user
 */
export function* userImport() {
  yield takeEvery(USER_IMPORT, function* ({ payload }) {
    try {
      const user = yield call(UserService.userImport, payload);
      if (user.error != null && user.error.message) {
        yield put(showMessage({ message: user.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: User_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for user import page

//#region Method for resetting the user password

/**
 * Method for reset user password
 */
export function* resetUserPassword() {
  yield takeEvery(RESET_USER_PASSWORD, function* ({ payload }) {
    try {
      const user = yield call(UserService.resetUserPassword, payload);
      if (user.error != null && user.error.message) {
        yield put(showMessage({ message: user.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Saved_Password, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for resetting the user password

/**
 * Method for changing the user password
 */
export function* changePassword() {
  yield takeEvery(CHANGE_PASSWORD, function* ({ payload }) {
    try {
      let user = yield call(UserService.changePassword, payload);
      if (user.error != null && user.error.message) {
        yield put(showMessage({ message: user.error.message, type: Error }));
      } else {
        yield put(
          showMessage({ message: User_Change_Password, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllUser),
    fork(addUser),
    fork(editUser),
    fork(getUser),
    fork(getUserExport),
    fork(changeUserStatus),
    fork(userImport),
    fork(resetUserPassword),
    fork(changePassword),
  ]);
}
