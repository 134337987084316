/**
 * The below file contains the constants used in the infrastructure department master.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 03-11-2022
 */

/**Constants for InfDepartment Listing page */
export const GET_ALL_INFDEPARTMENT = "GET_ALL_INFDEPARTMENT";
export const SET_INFDEPARTMENT_LISTING = "SET_INFDEPARTMENT_LISTING";
export const SET_INFDEPARTMENT_LISTING_PARAM =
  "SET_INFDEPARTMENT_LISTING_PARAM";
export const UPDATE_INFDEPARTMENT_STATUS = "UPDATE_INFDEPARTMENT_STATUS";
export const CHANGE_INFDEPARTMENT_STATUS = "CHANGE_INFDEPARTMENT_STATUS";
export const GET_EXPORT_INFDEPARTMENT_DATA = "GET_EXPORT_INFDEPARTMENT_DATA";
export const SET_EXPORT_INFDEPARTMENT_DATA = "SET_EXPORT_INFDEPARTMENT_DATA";
/**Constants for InfDepartment Listing page */

/**Constants for InfDepartment Add page */
export const ADD_INFDEPARTMENT = "ADD_INFDEPARTMENT";
/**Constants for InfDepartment Add page */

/**Constants for InfDepartment Edit page */
export const EDIT_INFDEPARTMENT = "EDIT_INFDEPARTMENT";
export const GET_INFDEPARTMENT = "GET_INFDEPARTMENT";
export const SET_INFDEPARTMENT = "SET_INFDEPARTMENT";
/**Constants for InfDepartment Edit page */

/**Constants for InfDepartment Import page */
export const INFDEPARTMENT_IMPORT = "INFDEPARTMENT_IMPORT";
/**Constants for InfDepartment Import page */
