/**
 * The below file contains the village state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 19-10-2022
 */

import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  ADD_VILLAGE,
  EDIT_VILLAGE,
  GET_EXPORT_VILLAGE_DATA,
  SET_EXPORT_VILLAGE_DATA,
  SET_VILLAGE,
  SET_VILLAGE_LISTING,
  SET_VILLAGE_LISTING_PARAM,
  UPDATE_VILLAGE_STATUS,
} from "redux/constants/Village";

//Initial state
const initState = {
  //Keys for listing
  villageList: {},
  isVillageDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  villageListingParam: {},
  villageExportedData: {},
  //Keys for listing

  //Keys for add page
  addVillageForm: {},
  //Keys for add page

  //Keys for edit page
  villageFormData: {},
  //Keys for edit page
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const village = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_VILLAGE_LISTING:
      return {
        ...state,
        villageList: action.payload,
        isVillageDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_VILLAGE_LISTING_PARAM:
      return {
        ...state,
        villageListingParam: action.payload,
      };

    case UPDATE_VILLAGE_STATUS:
      let updateVillageListData = {
        ...state.villageList,
        data: state.villageList.data?.map((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        }),
      };
      return {
        ...state,
        villageList: updateVillageListData,
      };

    case GET_EXPORT_VILLAGE_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_VILLAGE_DATA:
      return {
        ...state,
        villageExportedData: action.payload,
      };
    //Cases for listing

    //Cases for add page
    case ADD_VILLAGE:
      return {
        ...state,
        addVillageForm: action.payload,
      };
    //Cases for add page

    //Cases for edit page
    case EDIT_VILLAGE:
      return {
        ...state,
        addVillageForm: action.payload,
      };
    case SET_VILLAGE:
      return {
        ...state,
        villageFormData: action.payload,
      };
    //Cases for District edit page

    default:
      return state;
  }
};

export default village;
