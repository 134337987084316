/**
 * The below file contains the Sagas method for Infrastructure Other Damage.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-11-2022
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_INFOTHERDAMAGE,
  CHANGE_INFOTHERDAMAGE_STATUS,
  INFOTHERDAMAGE_IMPORT,
  EDIT_INFOTHERDAMAGE,
  GET_ALL_INFOTHERDAMAGE,
  GET_INFOTHERDAMAGE,
  GET_EXPORT_INFOTHERDAMAGE_DATA,
} from "../constants/InfOtherDamage";

import InfOtherDamageService from "services/InfOtherDamageService";
import {
  setInfOtherDamage,
  setInfOtherDamageListing,
  setExportInfOtherDamage,
  updateInfOtherDamageStatus,
} from "redux/actions/InfOtherDamage";
import { showMessage } from "redux/actions/Common";
import {
  InfOtherDamage_Added,
  InfOtherDamage_Imported,
  InfOtherDamage_Updated,
} from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";

//#region Method for infOtherDamage listing

/**
 * Method for getting the infOtherDamage details
 */
export function* getAllInfOtherDamage() {
  yield takeEvery(GET_ALL_INFOTHERDAMAGE, function* ({ payload }) {
    try {
      const infOtherDamage = yield call(
        InfOtherDamageService.getAllInfOtherDamage,
        payload
      );
      if (infOtherDamage.error != null && infOtherDamage.error.message) {
        yield put(
          showMessage({ message: infOtherDamage.error.message, type: Error })
        );
      } else {
        yield put(setInfOtherDamageListing(infOtherDamage));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the infOtherDamage export details
 */
export function* getInfOtherDamageExport() {
  yield takeEvery(GET_EXPORT_INFOTHERDAMAGE_DATA, function* () {
    try {
      const infOtherDamage = yield call(
        InfOtherDamageService.getExportInfOtherDamageData
      );
      if (infOtherDamage.error != null && infOtherDamage.error.message) {
        yield put(
          showMessage({ message: infOtherDamage.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(infOtherDamage),
        };
        yield put(setExportInfOtherDamage(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the infOtherDamage status details
 */
export function* changeInfOtherDamageStatus() {
  yield takeEvery(CHANGE_INFOTHERDAMAGE_STATUS, function* ({ payload }) {
    try {
      const infOtherDamage = yield call(
        InfOtherDamageService.changeInfOtherDamageStatus,
        payload
      );
      if (infOtherDamage.error != null && infOtherDamage.error.message) {
        yield put(
          showMessage({ message: infOtherDamage.error.message, type: Error })
        );
      } else {
        let json = {
          id: infOtherDamage.data.id,
          status: infOtherDamage.data.status,
        };
        yield put(updateInfOtherDamageStatus(json));
        yield put(
          showMessage({ message: InfOtherDamage_Updated, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for infOtherDamage listing

//#region Method for infOtherDamage add page

/**
 * Method for adding the infOtherDamage
 */
export function* addInfOtherDamage() {
  yield takeEvery(ADD_INFOTHERDAMAGE, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const infOtherDamage = yield call(
        InfOtherDamageService.addInfOtherDamage,
        final
      );
      if (infOtherDamage.error != null && infOtherDamage.error.message) {
        yield put(
          showMessage({ message: infOtherDamage.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: InfOtherDamage_Added, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for infOtherDamage add page

//#region Method for infOtherDamage edit page

/**
 * Method for editing the infOtherDamage
 */
export function* editInfOtherDamage() {
  yield takeEvery(EDIT_INFOTHERDAMAGE, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          departmentName: payload.departmentName,
          status: payload.status,
        },
      };
      const infOtherDamage = yield call(
        InfOtherDamageService.editInfOtherDamage,
        id,
        final
      );
      if (infOtherDamage.error != null && infOtherDamage.error.message) {
        yield put(
          showMessage({ message: infOtherDamage.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: InfOtherDamage_Updated, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the infOtherDamage detail
 */
export function* getInfOtherDamage() {
  yield takeEvery(GET_INFOTHERDAMAGE, function* ({ payload }) {
    try {
      const infOtherDamage = yield call(
        InfOtherDamageService.getInfOtherDamage,
        payload
      );
      if (infOtherDamage.error != null && infOtherDamage.error.message) {
        yield put(
          showMessage({ message: infOtherDamage.error.message, type: Error })
        );
      } else {
        yield put(setInfOtherDamage(infOtherDamage));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for infOtherDamage edit page

//#region Method for infOtherDamage import page

/**
 * Method for importing the infOtherDamage
 */
export function* infOtherDamageImport() {
  yield takeEvery(INFOTHERDAMAGE_IMPORT, function* ({ payload }) {
    try {
      const infOtherDamage = yield call(
        InfOtherDamageService.infOtherDamageImport,
        payload
      );
      if (infOtherDamage.error != null && infOtherDamage.error.message) {
        yield put(
          showMessage({ message: infOtherDamage.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: InfOtherDamage_Imported, type: Success })
        );
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for infOtherDamage import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllInfOtherDamage),
    fork(addInfOtherDamage),
    fork(editInfOtherDamage),
    fork(getInfOtherDamage),
    fork(getInfOtherDamageExport),
    fork(changeInfOtherDamageStatus),
    fork(infOtherDamageImport),
  ]);
}
