/**
 * The below file contains the Sagas method for Unit.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_UNIT,
  CHANGE_UNIT_STATUS,
  UNIT_IMPORT,
  EDIT_UNIT,
  GET_ALL_UNIT,
  GET_UNIT,
  GET_EXPORT_UNIT_DATA,
} from "../constants/Unit";

import UnitService from "services/UnitService";
import {
  setUnit,
  setUnitListing,
  setExportUnit,
  updateUnitStatus,
} from "redux/actions/Unit";
import { showMessage } from "redux/actions/Common";
import {
  Unit_Added,
  Unit_Imported,
  Unit_Updated,
} from "constants/MessageConstant";
import { Error, Success } from "constants/ApiConstant";

//#region Method for unit listing

/**
 * Method for getting the unit details
 */
export function* getAllUnit() {
  yield takeEvery(GET_ALL_UNIT, function* ({ payload }) {
    try {
      const unit = yield call(UnitService.getAllUnit, payload);
      if (unit.error != null && unit.error.message) {
        yield put(showMessage({ message: unit.error.message, type: Error }));
      } else {
        yield put(setUnitListing(unit));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the unit export details
 */
export function* getUnitExport() {
  yield takeEvery(GET_EXPORT_UNIT_DATA, function* () {
    try {
      const unit = yield call(UnitService.getExportUnitData);
      if (unit.error != null && unit.error.message) {
        yield put(showMessage({ message: unit.error.message, type: Error }));
      } else {
        let exportData = {
          url: window.URL.createObjectURL(unit),
        };
        yield put(setExportUnit(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for changing the unit status details
 */
export function* changeUnitStatus() {
  yield takeEvery(CHANGE_UNIT_STATUS, function* ({ payload }) {
    try {
      const unit = yield call(UnitService.changeUnitStatus, payload);
      if (unit.error != null && unit.error.message) {
        yield put(showMessage({ message: unit.error.message, type: Error }));
      } else {
        let json = {
          id: unit.data.id,
          status: unit.data.status,
        };
        yield put(updateUnitStatus(json));
        yield put(showMessage({ message: Unit_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for unit listing

//#region Method for unit add page

/**
 * Method for adding the unit
 */
export function* addUnit() {
  yield takeEvery(ADD_UNIT, function* ({ payload }) {
    try {
      let final = {
        data: payload,
      };
      const unit = yield call(UnitService.addUnit, final);
      if (unit.error != null && unit.error.message) {
        yield put(showMessage({ message: unit.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Unit_Added, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for unit add page

//#region Method for unit edit page

/**
 * Method for editing the unit
 */
export function* editUnit() {
  yield takeEvery(EDIT_UNIT, function* ({ payload }) {
    try {
      const { id } = payload;
      let final = {
        data: {
          unitName: payload.unitName,
          unitCode: payload.unitCode,
          status: payload.status,
          state: payload.state,
        },
      };
      const unit = yield call(UnitService.editUnit, id, final);
      if (unit.error != null && unit.error.message) {
        yield put(showMessage({ message: unit.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Unit_Updated, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the unit detail
 */
export function* getUnit() {
  yield takeEvery(GET_UNIT, function* ({ payload }) {
    try {
      const unit = yield call(UnitService.getUnit, payload);
      if (unit.error != null && unit.error.message) {
        yield put(showMessage({ message: unit.error.message, type: Error }));
      } else {
        yield put(setUnit(unit));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for unit edit page

//#region Method for unit import page

/**
 * Method for importing the unit
 */
export function* unitImport() {
  yield takeEvery(UNIT_IMPORT, function* ({ payload }) {
    try {
      const unit = yield call(UnitService.unitImport, payload);
      if (unit.error != null && unit.error.message) {
        yield put(showMessage({ message: unit.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Unit_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for unit import page

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllUnit),
    fork(addUnit),
    fork(editUnit),
    fork(getUnit),
    fork(getUnitExport),
    fork(changeUnitStatus),
    fork(unitImport),
  ]);
}
