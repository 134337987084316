/**
 * The below file contains the Sagas method for UrbanFlood reports.
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  BasicSection,
  DiscardButton,
  InfDamageOtherSection,
  InfDamageSection,
  PopAffectedSection,
  ErodedSection,
  RemarkSection,
  SubmitButton,
  approveButton,
  disapproveButton,
  ReliefCampSection,
  CampInmatesSection,
  HllSection,
  LiveStockSection,
  HouseDamagedSection,
  RescueOperationSection,
  ReliefDistributionSection,
  verifyButton,
} from "constants/ApplicationConstant";
import {
  UrbanFlood_Added_Basic_Section,
  UrbanFlood_Added_CampInmates_Section,
  UrbanFlood_Added_Eroded_Section,
  UrbanFlood_Added_HLL_Section,
  UrbanFlood_Added_HouseDamaged_Section,
  UrbanFlood_Added_InfDamageOther_Section,
  UrbanFlood_Added_InfDamage_Section,
  UrbanFlood_Added_LiveStock_Section,
  UrbanFlood_Added_PopAffected_Section,
  UrbanFlood_Added_ReliefCamp_Section,
  UrbanFlood_Added_ReliefDistribution_Section,
  UrbanFlood_Added_Remark_Section,
  UrbanFlood_Added_RescueOperation_Section,
  UrbanFlood_Imported,
  UrbanFlood_Report_Discard,
  UrbanFlood_Report_Submit,
  UrbanFlood_Report_Approved,
  UrbanFlood_Report_Disapproved,
  UrbanFlood_Updated_Basic_Section,
  UrbanFlood_Report_Verify,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  urbanfloodResetButtonLoading,
  urbanfloodSetAllSection,
  urbanfloodSetDataSaved,
  urbanfloodSetParentId,
  urbanfloodSetRedirectToListing,
  setExportUrbanFlood,
  setUrbanFloodListing,
  setUrbanFloodDistrictReviewList,
  setExportDistrictCumulativeUrbanFloodReport,
  setExportStateCumulativeUrbanFloodReport,
  setExportFOLevelUrbanFloodReport,
  setUrbanFloodStateReviewList,
} from "redux/actions/UrbanFlood";
import {
  GET_ALL_URBANFLOOD,
  GET_EXPORT_URBANFLOOD_DATA,
  URBANFLOOD_ADD_BASIC_SECTION,
  URBANFLOOD_ADD_INFDAMAGEOTHER_SECTION,
  URBANFLOOD_ADD_INFDAMAGE_SECTION,
  URBANFLOOD_ADD_REMARK_SECTION,
  URBANFLOOD_EDIT_BASIC_SECTION,
  URBANFLOOD_GET_ALL_SECTION,
  URBANFLOOD_IMPORT,
  URBANFLOOD_REPORT_DISCARD,
  URBANFLOOD_REPORT_SUBMIT,
  URBANFLOOD_ADD_POPAFFECTED_SECTION,
  URBANFLOOD_ADD_ERODED_SECTION,
  URBANFLOOD_ADD_RELIEFCAMP_SECTION,
  URBANFLOOD_ADD_CAMPINMATES_SECTION,
  URBANFLOOD_ADD_HLL_SECTION,
  URBANFLOOD_ADD_LIVESTOCK_SECTION,
  URBANFLOOD_ADD_HOUSEDAMAGED_SECTION,
  URBANFLOOD_ADD_RESCUEOPERATION_SECTION,
  URBANFLOOD_ADD_RELIEFDISTRIBUTION_SECTION,
  GET_URBANFLOOD_DISTRICT_REVIEW_LIST,
  GET_URBANFLOOD_STATE_REVIEW_LIST,
  EXPORT_FO_LEVEL_URBANFLOOD_REPORT,
  URBANFLOOD_REVENUE_REPORT_APPROVE,
  URBANFLOOD_REVENUE_REPORT_DISAPPROVE,
  URBANFLOOD_REVENUE_REPORT_VERIFY,
  URBANFLOOD_DISTRICT_REPORT_APPROVE,
  URBANFLOOD_DISTRICT_REPORT_DISAPPROVE,
  URBANFLOOD_DISTRICT_REPORT_VERIFY,
  URBANFLOOD_STATE_REPORT_APPROVE,
  URBANFLOOD_STATE_REPORT_DISAPPROVE,
  GET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT,
  GET_STATE_CUMULATIVE_URBANFLOOD_REPORT,
} from "redux/constants/UrbanFlood";
import UrbanFloodService from "services/UrbanFloodService";

//#region Method for listing

/**
 * Method for getting the urbanfloods
 */
export function* getAllUrbanFlood() {
  yield takeEvery(GET_ALL_URBANFLOOD, function* ({ payload }) {
    try {
      const urbanflood = yield call(
        UrbanFloodService.getAllUrbanFlood,
        payload
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(setUrbanFloodListing(urbanflood));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the urbanflood export details
 */
export function* getUrbanFloodExport() {
  yield takeEvery(GET_EXPORT_URBANFLOOD_DATA, function* () {
    try {
      const urbanflood = yield call(UrbanFloodService.getExportUrbanFloodData);
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(urbanflood),
        };
        yield put(setExportUrbanFlood(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for urbanflood import page

/**
 * Method for importing the urbanflood
 */
export function* urbanfloodImport() {
  yield takeEvery(URBANFLOOD_IMPORT, function* ({ payload }) {
    try {
      const urbanflood = yield call(
        UrbanFloodService.urbanfloodImport,
        payload
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: UrbanFlood_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for urbanflood import page

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for getting the all section data
 */
export function* urbanfloodGetAllSection() {
  yield takeEvery(URBANFLOOD_GET_ALL_SECTION, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        UrbanFloodService.urbanfloodGetAllSection,
        payload.id,
        payload.param
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(urbanfloodSetAllSection(infDepartment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for submitting the report
 */
export function* urbanfloodReportSubmit() {
  yield takeEvery(URBANFLOOD_REPORT_SUBMIT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        UrbanFloodService.urbanfloodReportSubmit,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: UrbanFlood_Report_Submit, type: Success })
        );
        yield put(urbanfloodSetRedirectToListing(true));
        yield put(urbanfloodResetButtonLoading({ section: SubmitButton }));
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: SubmitButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for discarding the report
 */
export function* urbanfloodReportDiscard() {
  yield takeEvery(URBANFLOOD_REPORT_DISCARD, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        UrbanFloodService.urbanfloodReportDiscard,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: UrbanFlood_Report_Discard, type: Success })
        );
        yield put(urbanfloodSetRedirectToListing(true));
        yield put(urbanfloodResetButtonLoading({ section: DiscardButton }));
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: DiscardButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding basic section
 */
export function* urbanfloodAddBasicSection() {
  yield takeEvery(URBANFLOOD_ADD_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };

      const urbanflood = yield call(
        UrbanFloodService.urbanfloodAddBasicSection,
        final
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(urbanfloodSetParentId(urbanflood.data.id));
        yield put(urbanfloodResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: UrbanFlood_Added_Basic_Section,
            type: Success,
          })
        );
        yield put(
          urbanfloodSetDataSaved({ section: BasicSection, value: true })
        );
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for editing basic section
 */
export function* urbanfloodEditBasicSection() {
  yield takeEvery(URBANFLOOD_EDIT_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const urbanflood = yield call(
        UrbanFloodService.urbanfloodEditBasicSection,
        payload.id,
        final
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(urbanfloodResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: UrbanFlood_Updated_Basic_Section,
            type: Success,
          })
        );
        yield put(
          urbanfloodSetDataSaved({ section: BasicSection, value: true })
        );
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Basic Section

//#region InfDamage Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding infDamage section
 */
export function* urbanfloodAddInfDamageSection() {
  yield takeEvery(URBANFLOOD_ADD_INFDAMAGE_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const urbanflood = yield call(
        UrbanFloodService.urbanfloodAddInfDamageSection,
        final
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(urbanfloodResetButtonLoading({ section: InfDamageSection }));
        yield put(
          showMessage({
            message: UrbanFlood_Added_InfDamage_Section,
            type: Success,
          })
        );
        yield put(
          urbanfloodSetDataSaved({ section: InfDamageSection, value: true })
        );
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: InfDamageSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding infDamageOther section
 */
export function* urbanfloodAddInfDamageOtherSection() {
  yield takeEvery(
    URBANFLOOD_ADD_INFDAMAGEOTHER_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const urbanflood = yield call(
          UrbanFloodService.urbanfloodAddInfDamageOtherSection,
          final
        );
        if (urbanflood.error != null && urbanflood.error.message) {
          yield put(
            showMessage({ message: urbanflood.error.message, type: Error })
          );
        } else {
          yield put(
            urbanfloodResetButtonLoading({ section: InfDamageOtherSection })
          );
          yield put(
            showMessage({
              message: UrbanFlood_Added_InfDamageOther_Section,
              type: Success,
            })
          );
          yield put(
            urbanfloodSetDataSaved({
              section: InfDamageOtherSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          urbanfloodResetButtonLoading({ section: InfDamageOtherSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion InfDamageOther Section

//#region Remark Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding Remark section
 */
export function* urbanfloodAddRemarkSection() {
  yield takeEvery(URBANFLOOD_ADD_REMARK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const UrbanFlood = yield call(
        UrbanFloodService.urbanfloodAddRemarkSection,
        final
      );
      if (UrbanFlood.error != null && UrbanFlood.error.message) {
        yield put(
          showMessage({ message: UrbanFlood.error.message, type: Error })
        );
      } else {
        yield put(urbanfloodResetButtonLoading({ section: RemarkSection }));
        yield put(
          showMessage({
            message: UrbanFlood_Added_Remark_Section,
            type: Success,
          })
        );
        yield put(
          urbanfloodSetDataSaved({ section: RemarkSection, value: true })
        );
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: RemarkSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Remark Section

//#region PopAffected Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding popaffected section
 */
export function* urbanfloodAddPopAffectedSection() {
  yield takeEvery(URBANFLOOD_ADD_POPAFFECTED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const urbanflood = yield call(
        UrbanFloodService.urbanfloodAddPopAffectedSection,
        final
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(
          urbanfloodResetButtonLoading({ section: PopAffectedSection })
        );
        yield put(
          showMessage({
            message: UrbanFlood_Added_PopAffected_Section,
            type: Success,
          })
        );
        yield put(
          urbanfloodSetDataSaved({ section: PopAffectedSection, value: true })
        );
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: PopAffectedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion PopAffected Section

//#region Eroded Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding eroded section
 */
export function* urbanfloodAddErodedSection() {
  yield takeEvery(URBANFLOOD_ADD_ERODED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const urbanflood = yield call(
        UrbanFloodService.urbanfloodAddErodedSection,
        final
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(urbanfloodResetButtonLoading({ section: ErodedSection }));
        yield put(
          showMessage({
            message: UrbanFlood_Added_Eroded_Section,
            type: Success,
          })
        );
        yield put(
          urbanfloodSetDataSaved({ section: ErodedSection, value: true })
        );
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: ErodedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Eroded Section

//#region Relief Camp Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding relief camp section
 */
export function* urbanfloodAddReliefCampSection() {
  yield takeEvery(URBANFLOOD_ADD_RELIEFCAMP_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const urbanflood = yield call(
        UrbanFloodService.urbanfloodAddReliefCampSection,
        final
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(urbanfloodResetButtonLoading({ section: ReliefCampSection }));
        yield put(
          showMessage({
            message: UrbanFlood_Added_ReliefCamp_Section,
            type: Success,
          })
        );
        yield put(
          urbanfloodSetDataSaved({ section: ReliefCampSection, value: true })
        );
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: ReliefCampSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Relief Camp Section

//#region Camp inmates Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding camp inmates section
 */
export function* urbanfloodAddCampInmatesSection() {
  yield takeEvery(URBANFLOOD_ADD_CAMPINMATES_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const urbanflood = yield call(
        UrbanFloodService.urbanfloodAddCampInmatesSection,
        final
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(
          urbanfloodResetButtonLoading({ section: CampInmatesSection })
        );
        yield put(
          showMessage({
            message: UrbanFlood_Added_CampInmates_Section,
            type: Success,
          })
        );
        yield put(
          urbanfloodSetDataSaved({ section: CampInmatesSection, value: true })
        );
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: CampInmatesSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Camp Inmates Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding Human Live Lost section
 */
export function* urbanfloodAddHLLSection() {
  yield takeEvery(URBANFLOOD_ADD_HLL_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const urbanflood = yield call(
        UrbanFloodService.urbanfloodAddHLLSection,
        final
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(urbanfloodResetButtonLoading({ section: HllSection }));
        yield put(
          showMessage({
            message: UrbanFlood_Added_HLL_Section,
            type: Success,
          })
        );
        yield put(urbanfloodSetDataSaved({ section: HllSection, value: true }));
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: HllSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding LiveStock section
 */
export function* urbanfloodAddLiveStockSection() {
  yield takeEvery(URBANFLOOD_ADD_LIVESTOCK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const urbanflood = yield call(
        UrbanFloodService.urbanfloodAddLiveStockSection,
        final
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(urbanfloodResetButtonLoading({ section: LiveStockSection }));
        yield put(
          showMessage({
            message: UrbanFlood_Added_LiveStock_Section,
            type: Success,
          })
        );
        yield put(
          urbanfloodSetDataSaved({ section: LiveStockSection, value: true })
        );
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: LiveStockSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding HouseDamaged section
 */
export function* urbanfloodAddHouseDamagedSection() {
  yield takeEvery(URBANFLOOD_ADD_HOUSEDAMAGED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const urbanflood = yield call(
        UrbanFloodService.urbanfloodAddHouseDamagedSection,
        final
      );
      if (urbanflood.error != null && urbanflood.error.message) {
        yield put(
          showMessage({ message: urbanflood.error.message, type: Error })
        );
      } else {
        yield put(
          urbanfloodResetButtonLoading({ section: HouseDamagedSection })
        );
        yield put(
          showMessage({
            message: UrbanFlood_Added_HouseDamaged_Section,
            type: Success,
          })
        );
        yield put(
          urbanfloodSetDataSaved({ section: HouseDamagedSection, value: true })
        );
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: HouseDamagedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding RescueOperation section
 */
export function* urbanfloodAddRescueOperationSection() {
  yield takeEvery(
    URBANFLOOD_ADD_RESCUEOPERATION_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const urbanflood = yield call(
          UrbanFloodService.urbanfloodAddRescueOperationSection,
          final
        );
        if (urbanflood.error != null && urbanflood.error.message) {
          yield put(
            showMessage({ message: urbanflood.error.message, type: Error })
          );
        } else {
          yield put(
            urbanfloodResetButtonLoading({ section: RescueOperationSection })
          );
          yield put(
            showMessage({
              message: UrbanFlood_Added_RescueOperation_Section,
              type: Success,
            })
          );
          yield put(
            urbanfloodSetDataSaved({
              section: RescueOperationSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          urbanfloodResetButtonLoading({ section: RescueOperationSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/** Method for adding ReliefDistribution section
 */
export function* urbanfloodAddReliefDistributionSection() {
  yield takeEvery(
    URBANFLOOD_ADD_RELIEFDISTRIBUTION_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const urbanflood = yield call(
          UrbanFloodService.urbanfloodAddReliefDistributionSection,
          final
        );
        if (urbanflood.error != null && urbanflood.error.message) {
          yield put(
            showMessage({ message: urbanflood.error.message, type: Error })
          );
        } else {
          yield put(
            urbanfloodResetButtonLoading({ section: ReliefDistributionSection })
          );
          yield put(
            showMessage({
              message: UrbanFlood_Added_ReliefDistribution_Section,
              type: Success,
            })
          );
          yield put(
            urbanfloodSetDataSaved({
              section: ReliefDistributionSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          urbanfloodResetButtonLoading({ section: ReliefDistributionSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion ReliefDistribution Section

//#region
/**
 * The below method is for exporting the report in urbanflood view form
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for getting the district export details
 */
export function* getExportFoLevelUrbanFloodReport() {
  yield takeEvery(EXPORT_FO_LEVEL_URBANFLOOD_REPORT, function* ({ payload }) {
    try {
      const exportedReport = yield call(
        UrbanFloodService.getExportFoLevelUrbanFloodReport,
        payload
      );

      if (exportedReport.error != null && exportedReport.error.message) {
        yield put(
          showMessage({ message: exportedReport.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(exportedReport),
          format: payload.format,
        };
        yield put(setExportFOLevelUrbanFloodReport(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#region Method for Approval and Disapproval
/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for approving the revenue report
 */
export function* urbanfloodRevenueReportApprove() {
  yield takeEvery(URBANFLOOD_REVENUE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const urbanfloodReportStatus = yield call(
        UrbanFloodService.urbanfloodRevenueReportApprove,
        payload
      );
      if (
        urbanfloodReportStatus.error != null &&
        urbanfloodReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: urbanfloodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: UrbanFlood_Report_Approved, type: Success })
        );
        yield put(urbanfloodSetRedirectToListing(true));
        yield put(urbanfloodResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the revenue report
 */
export function* urbanfloodRevenueReportDisapprove() {
  yield takeEvery(
    URBANFLOOD_REVENUE_REPORT_DISAPPROVE,
    function* ({ payload }) {
      try {
        const urbanfloodReportStatus = yield call(
          UrbanFloodService.urbanfloodRevenueReportDisapprove,
          payload
        );
        if (
          urbanfloodReportStatus.error != null &&
          urbanfloodReportStatus.error.message
        ) {
          yield put(
            showMessage({
              message: urbanfloodReportStatus.error.message,
              type: Error,
            })
          );
        } else {
          yield put(
            showMessage({
              message: UrbanFlood_Report_Disapproved,
              type: Success,
            })
          );
          yield put(urbanfloodSetRedirectToListing(true));
          yield put(
            urbanfloodResetButtonLoading({ section: disapproveButton })
          );
        }
      } catch (err) {
        yield put(urbanfloodResetButtonLoading({ section: disapproveButton }));
      }
    }
  );
}

/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for verify the revenue report
 */
export function* urbanfloodRevenueReportVerify() {
  yield takeEvery(URBANFLOOD_REVENUE_REPORT_VERIFY, function* ({ payload }) {
    try {
      const urbanfloodReportStatus = yield call(
        UrbanFloodService.urbanfloodRevenueReportVerify,
        payload
      );
      if (
        urbanfloodReportStatus.error != null &&
        urbanfloodReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: urbanfloodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: UrbanFlood_Report_Verify, type: Success })
        );
        yield put(urbanfloodSetRedirectToListing(true));
        yield put(urbanfloodResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* urbanfloodDistrictReportApprove() {
  yield takeEvery(URBANFLOOD_DISTRICT_REPORT_APPROVE, function* ({ payload }) {
    try {
      const urbanfloodReportStatus = yield call(
        UrbanFloodService.urbanfloodDistrictReportApprove,
        payload
      );
      if (
        urbanfloodReportStatus.error != null &&
        urbanfloodReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: urbanfloodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: UrbanFlood_Report_Approved, type: Success })
        );
        yield put(urbanfloodSetRedirectToListing(true));
        yield put(urbanfloodResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* urbanfloodDistrictReportDisapprove() {
  yield takeEvery(
    URBANFLOOD_DISTRICT_REPORT_DISAPPROVE,
    function* ({ payload }) {
      try {
        const urbanfloodReportStatus = yield call(
          UrbanFloodService.urbanfloodDistrictReportDisapprove,
          payload
        );
        if (
          urbanfloodReportStatus.error != null &&
          urbanfloodReportStatus.error.message
        ) {
          yield put(
            showMessage({
              message: urbanfloodReportStatus.error.message,
              type: Error,
            })
          );
        } else {
          yield put(
            showMessage({
              message: UrbanFlood_Report_Disapproved,
              type: Success,
            })
          );
          yield put(urbanfloodSetRedirectToListing(true));
          yield put(
            urbanfloodResetButtonLoading({ section: disapproveButton })
          );
        }
      } catch (err) {
        yield put(urbanfloodResetButtonLoading({ section: disapproveButton }));
      }
    }
  );
}

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* urbanfloodDistrictReportVerify() {
  yield takeEvery(URBANFLOOD_DISTRICT_REPORT_VERIFY, function* ({ payload }) {
    try {
      const urbanfloodReportStatus = yield call(
        UrbanFloodService.urbanfloodDistrictReportVerify,
        payload
      );
      if (
        urbanfloodReportStatus.error != null &&
        urbanfloodReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: urbanfloodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: UrbanFlood_Report_Verify, type: Success })
        );
        yield put(urbanfloodSetRedirectToListing(true));
        yield put(urbanfloodResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* urbanfloodStateReportApprove() {
  yield takeEvery(URBANFLOOD_STATE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const urbanfloodReportStatus = yield call(
        UrbanFloodService.urbanfloodStateReportApprove,
        payload
      );
      if (
        urbanfloodReportStatus.error != null &&
        urbanfloodReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: urbanfloodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: UrbanFlood_Report_Approved, type: Success })
        );
        yield put(urbanfloodSetRedirectToListing(true));
        yield put(urbanfloodResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* urbanfloodStateReportDisapprove() {
  yield takeEvery(URBANFLOOD_STATE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const urbanfloodReportStatus = yield call(
        UrbanFloodService.urbanfloodStateReportDisapprove,
        payload
      );
      if (
        urbanfloodReportStatus.error != null &&
        urbanfloodReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: urbanfloodReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: UrbanFlood_Report_Disapproved, type: Success })
        );
        yield put(urbanfloodSetRedirectToListing(true));
        yield put(urbanfloodResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: disapproveButton }));
    }
  });
}
//#endregion Method for Approval and Disapproval

//#region
/**
 * The below method is for exporting the report in urbanflood cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */

/**
 * Method for getting the cumulative state export details
 */
export function* getExportStateCumulativeUrbanFloodReport() {
  yield takeEvery(
    GET_STATE_CUMULATIVE_URBANFLOOD_REPORT,
    function* ({ payload }) {
      try {
        const exportedReport = yield call(
          UrbanFloodService.getExportStateCumulativeUrbanFloodReport,
          payload
        );
        if (exportedReport.error != null && exportedReport.error.message) {
          yield put(
            showMessage({ message: exportedReport.error.message, type: Error })
          );
        } else {
          if (payload?.sendEmail) {
            // Convert the Blob response to text
            const textResponse = yield exportedReport?.text();
            const parsedResponse = JSON.parse(textResponse);
            yield put(
              showMessage({
                message: parsedResponse?.message,
                type: Success,
              })
            );
          } else {
            let exportData = {
              reportData:
                payload.format === "html"
                  ? exportedReport
                  : window.URL.createObjectURL(exportedReport),
              format: payload.format,
            };
            yield put(setExportStateCumulativeUrbanFloodReport(exportData));
          }
        }
      } catch (err) {
        let errPromise = yield err.response.data.text();
        yield put(
          showMessage({
            message: JSON.parse(errPromise).error.message,
            type: Error,
          })
        );
      }
    }
  );
}
//#endregion

/**
 * Method for getting the cumulative district export details
 */
export function* getExportDistrictCumulativeUrbanFloodReport() {
  yield takeEvery(
    GET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT,
    function* ({ payload }) {
      try {
        const exportedReport = yield call(
          UrbanFloodService.getExportDistrictCumulativeUrbanFloodReport,
          payload
        );
        if (exportedReport.error != null && exportedReport.error.message) {
          yield put(
            showMessage({ message: exportedReport.error.message, type: Error })
          );
        } else {
          let exportData = {
            reportData:
              payload.format === "html"
                ? exportedReport
                : window.URL.createObjectURL(exportedReport),
            format: payload.format,
          };
          yield put(setExportDistrictCumulativeUrbanFloodReport(exportData));
        }
      } catch (err) {
        let errPromise = yield err.response.data.text();
        yield put(
          showMessage({
            message: JSON.parse(errPromise).error.message,
            type: Error,
          })
        );
      }
    }
  );
}
//#endregion

/**
 * Method for getting the district review list
 */
export function* getUrbanFloodDistrictReviewList() {
  yield takeEvery(GET_URBANFLOOD_DISTRICT_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        UrbanFloodService.getUrbanFloodDistrictReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setUrbanFloodDistrictReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the state review list
 */
export function* getUrbanFloodStateReviewList() {
  yield takeEvery(GET_URBANFLOOD_STATE_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        UrbanFloodService.getUrbanFloodStateReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setUrbanFloodStateReviewList(response));
      }
    } catch (err) {
      yield put(urbanfloodResetButtonLoading({ section: DiscardButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for Approval and Disapproval

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllUrbanFlood),
    fork(getUrbanFloodExport),
    fork(urbanfloodImport),
    fork(urbanfloodAddBasicSection),
    fork(urbanfloodEditBasicSection),
    fork(urbanfloodGetAllSection),
    fork(urbanfloodAddInfDamageSection),
    fork(urbanfloodAddInfDamageOtherSection),
    fork(urbanfloodAddRemarkSection),
    fork(urbanfloodReportSubmit),
    fork(urbanfloodReportDiscard),
    fork(urbanfloodAddPopAffectedSection),
    fork(urbanfloodAddErodedSection),
    fork(urbanfloodAddReliefCampSection),
    fork(urbanfloodAddCampInmatesSection),
    fork(urbanfloodAddHLLSection),
    fork(urbanfloodAddLiveStockSection),
    fork(urbanfloodAddHouseDamagedSection),
    fork(urbanfloodAddRescueOperationSection),
    fork(urbanfloodAddReliefDistributionSection),
    fork(urbanfloodRevenueReportApprove),
    fork(urbanfloodRevenueReportDisapprove),
    fork(urbanfloodRevenueReportVerify),
    fork(urbanfloodDistrictReportApprove),
    fork(urbanfloodDistrictReportDisapprove),
    fork(urbanfloodDistrictReportVerify),
    fork(urbanfloodStateReportApprove),
    fork(urbanfloodStateReportDisapprove),
    fork(getExportFoLevelUrbanFloodReport),
    fork(getUrbanFloodDistrictReviewList),
    fork(getUrbanFloodStateReviewList),
    fork(getExportStateCumulativeUrbanFloodReport),
    fork(getExportDistrictCumulativeUrbanFloodReport),
  ]);
}
