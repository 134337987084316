/**
 * The below file is the Vehicle Categories service which contains method for doing the API calls.
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Get_All_Vehicle_Categories,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const VehicleCategoryService = {};

//#region Method for Vehicle Categories dropdown

/**
 * Method for getting the Vehicle Categories dropdown
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
VehicleCategoryService.getAllVehicleCategory = function () {
  return fetch({
    url: Get_All_Vehicle_Categories,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

//#endregion Method for Vehicle Categories dropdown.

export default VehicleCategoryService;
