/**
 * The below file is the infrastructure other damage service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-11-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Add_InfOtherDamage,
  Application_Json,
  Authorization_Token,
  Blob,
  Change_InfOtherDamage_Status,
  InfOtherDamage_Import,
  Edit_InfOtherDamage,
  Get_All_InfOtherDamage,
  Get_InfOtherDamage,
  Get_Export_InfOtherDamage,
  Multipart_Form_Data,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const InfOtherDamageService = {};

//#region Method for infOtherDamage listing page.

/**
 * Method for getting the infOtherDamage listing
 * @param {any} param API Parameter
 * @returns Fetch Method
 */
InfOtherDamageService.getAllInfOtherDamage = function (param) {
  return fetch({
    url: Get_All_InfOtherDamage,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the infOtherDamage export data
 * @returns Fetch Method
 */
InfOtherDamageService.getExportInfOtherDamageData = function () {
  return fetch({
    url: Get_Export_InfOtherDamage,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};

/**
 * Method for changing the infOtherDamage status
 * @param {any} data API Data
 * @returns Fetch Method
 */
InfOtherDamageService.changeInfOtherDamageStatus = function (data) {
  return fetch({
    url: Change_InfOtherDamage_Status,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for infOtherDamage listing page.

//#region Method for infOtherDamage add page.

/**
 * Method for adding the infOtherDamage
 * @param {any} data API Data
 * @returns Fetch Method
 */
InfOtherDamageService.addInfOtherDamage = function (data) {
  return fetch({
    url: Add_InfOtherDamage,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Method for infOtherDamage add page.

//#region Method for infOtherDamage edit page.

/**
 * Method for editing the infOtherDamage
 * @param {number} id Infrastructure department id
 * @param {any} data API Data
 * @returns Fetch Method
 */
InfOtherDamageService.editInfOtherDamage = function (id, data) {
  return fetch({
    url: format(Edit_InfOtherDamage, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the infOtherDamage detail
 * @param {any} data API Data
 * @returns Fetch Method
 */
InfOtherDamageService.getInfOtherDamage = function (data) {
  return fetch({
    url: format(Get_InfOtherDamage, data.id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion Method for infOtherDamage edit page.

//#region Method for infOtherDamage import page.

/**
 * Method for importing the infOtherDamage
 * @param {any} data API Data
 * @returns Fetch Method
 */
InfOtherDamageService.infOtherDamageImport = function (data) {
  return fetch({
    url: InfOtherDamage_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for infOtherDamage import page.

export default InfOtherDamageService;
