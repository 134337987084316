import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { notification } from "antd";
import { hidePageLoading, showPageLoading } from "redux/actions";
import store from "redux/store";
import { AUTH_TOKEN, SECONDARY_CHECK } from "redux/constants/Auth";
import { useHistory } from "react-router-dom";
import {
  Application_Json,
  Authorization_Token,
  Notification,
} from "constants/ApiConstant";
import { format } from "react-string-format";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 600000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
//const TOKEN_PAYLOAD_KEY = 'authorization'
//const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    //const jwtToken = localStorage.getItem(AUTH_TOKEN)

    //   if (jwtToken) {
    //     config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
    //   }

    //   if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
    // 		history.push(ENTRY_ROUTE)
    // 		window.location.reload();
    //   }
    store.dispatch(showPageLoading());
    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    store.dispatch(hidePageLoading());
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    localStorage.removeItem(SECONDARY_CHECK);
    store.dispatch(hidePageLoading());
    return response.data;
  },
  async (error) => {
    let notificationParam = {
      message: "",
      type: "error",
      status: true,
    };

    //notificationParam.message = error.response?.data?.error?.message;

    // Remove token and redirect
    // if (error.response.status === 400 || error.response.status === 403) {
    // 	notificationParam.message = 'Authentication Fail'
    // 	notificationParam.description = 'Please login again'
    // 	localStorage.removeItem(AUTH_TOKEN)
    // 	history.push(ENTRY_ROUTE, notificationParam)
    // 	window.location.reload();
    // }
    // else {
    // 	notificationParam.message = error.message
    // }

    // if (error.response.status === 404) {
    // 	notificationParam.message = 'Not Found'
    // }

    // if (error.response.status === 500) {
    // 	notificationParam.message = 'Internal Server Error'
    // }

    // if (error.response.status === 508) {
    // 	notificationParam.message = 'Time Out'
    // }

    //notification.error(notificationParam)

    //const history = useHistory();

    if (
      error.response.status === 401 &&
      error.response.config.url !== "/auth/local" &&
      error.response.config.baseURL !== "https://drims-superset.veldev.com"
    ) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/notifications`, {
            headers: {
              "Content-Type": Application_Json,
              Authorization: format(
                Authorization_Token,
                localStorage.getItem(AUTH_TOKEN)
              ),
            },
          });
          if (response?.status == 401) {
            localStorage.removeItem(AUTH_TOKEN);
            localStorage.clear();
            window.location.reload();
          }
          return response;
        } catch (error) {
          localStorage.removeItem(AUTH_TOKEN);
          localStorage.clear();
          window.location.reload();
        }
      };
      fetchData();
    }

    store.dispatch(hidePageLoading());
    return Promise.reject(error);
  }
);

export default service;
