/**
 * The below file contains the constants used in the Fire Agency
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

/**Constants for fire agency */
export const GET_ALL_FIRE_AGENCY = "GET_ALL_FIRE_AGENCY";
export const SET_ALL_FIRE_AGENCY = "SET_ALL_FIRE_AGENCY";
/**Constants for fire agency */
